import { useEffect, useRef, useState } from 'react';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import './profile.scss';
import ProfileTab from './tab';
import ProfileIndex from '.';
import { RootState } from '../../../redax/reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegionsRequest } from '../../../redax/actions/regionsActions/regionsActions';
import { useNavigate } from 'react-router-dom';
import Loader from '../../loader/loader';
import { fetchApplicantsRequest } from '../../../redax/actions/applicantsActions/applicantsActions';
import React from 'react';
import { fetchPutRegionRequest } from '../../../redax/actions/putRegionActions/putRegionActions';
export default function ProfilePage4() {
    const [loadScreen, setLoadScreen] = useState(true);
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);    
    const [authenticate, setAuthenticate] = useState(null);    
    const navigate = useNavigate(); 
    const dispatch = useDispatch();
    const loginData = useSelector((state: RootState) => state.login);
    const applicants = useSelector((state: RootState) => state.applicants);
    const regions = useSelector((state: RootState) => state.regions);
    const putregion = useSelector((state: RootState) => state.putregion);
    const [tempolary, settempolary] = useState(false);

    const [languages, setLanguages] = useState<any[]>([]);
    const [levels, setLevels] = useState<any[]>([]);
    const [oa_skill, setOaSkill] = useState<any[]>([]);
    const [qualification_categories, setQualification_Categories] = useState<any[]>([]);
    const [qualifications, setQualifications] = useState<any[]>([]);

    const [word_experience, setWordExperience] = useState(false);
    const [excel_experience, setExcelExperience] = useState(false);
    const [word, setWord] = useState<any[]>([]);
    const [excel, setExcel] = useState<any[]>([]);
    const [design, setDesign] = useState<any[]>([]);
    const [program, setProgram] = useState<any[]>([]);
    const [qualification_id_1, setQualificationId1] = useState(null);
    const [qualification_id_2, setQualificationId2] = useState(null);   
    const [qualification_3, setQualification3] = useState('');   
    const [language_id_1, setLanguageId1] = useState(null);  
    const [language_id_2, setLanguageId2] = useState(null);  
    const [level_id_1, setLevelId1] = useState(null);  
    const [level_id_2, setLevelId2] = useState(null);  
    const [language_3, setLanguage3] = useState('');  
    const [words, setWords] = useState<any[]>([]);
    const [excels, setExcels] = useState<any[]>([]);
    const [programs, setPrograms] = useState<any[]>([]);
    const [designs, setDesigns] = useState<any[]>([]);
    const [qualification_1, setQualification1] = useState(null);
    const [qualification_2, setQualification2] = useState(null);
    const [language_1, setLanguage1] = useState(null);  
    const [language_2, setLanguage2] = useState(null); 
    const [navigation_book, setNavigationBook] = useState(null); 
    const [edited,setEdited] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const pageTitle = 'WEB履歴書（スキル）｜パラちゃんねる';
    document.title = pageTitle;
    const queryParams = new URLSearchParams(window.location.search);
    const refs = useRef<any>(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    useEffect(() => {
      if (!localStorage.getItem('jobParachannelAuthToken')) {
        navigate('/login');
      } 
    }, [navigate, dispatch]);

  useEffect(() => {
    dispatch(fetchRegionsRequest());
    if(queryParams.get('tab') != null || queryParams.get('next') != null) {
      setTimeout(() => {              
          refs.current?.scrollIntoView({behavior:"smooth", block:"start"})
      }, 0);
  }
  }, [dispatch]);

    useEffect(() => {
      if(!edited) return;

      window.addEventListener("beforeunload", confirmSave);

      return () => window.removeEventListener("beforeunload", confirmSave);

  },[edited]);

  function confirmSave (event: any) {
      event.preventDefault();
      if (edited) {
          event.returnValue = "編集中のデータは保存されていませんが、移動しますか？"
      }
  }

  useEffect(() => {
      if (loginData.pending) {
        if(!loginData.login.is_init_complete) {
          navigate('/mypage/init/');
        }
        setResourceId(loginData.login.resource.id);
        setFamylyName(loginData.login.resource.family_name);
        setAuthenticate(loginData.login.resource.id);
        dispatch(fetchApplicantsRequest(loginData.login.resource.id, 'get', 0));
      }
  }, [dispatch, loginData.pending]);

  useEffect(() => {
    if (applicants.pending && !firstLoad) {    
      const applicantData = applicants.applicants.applicant;
      setWordExperience(applicantData.oa_skills.word_experience);
      setExcelExperience(applicantData.oa_skills.excel_experience);
      setWord(applicantData.oa_skills.word);
      setWords(applicantData.oa_skills.word);
      setExcel(applicantData.oa_skills.excel);
      setExcels(applicantData.oa_skills.excel);
      setDesign(applicantData.oa_skills.design);
      setDesigns(applicantData.oa_skills.design);
      setProgram(applicantData.oa_skills.program);
      setPrograms(applicantData.oa_skills.program);
      setQualificationId1(applicantData.qualification.qualification_id_1);
      setQualificationId2(applicantData.qualification.qualification_id_2);
      setQualification3(applicantData.qualification.qualification_3);
      setLanguageId1(applicantData.language_study.language_id_1);
      setLanguageId2(applicantData.language_study.language_id_2);
      setLevelId1(applicantData.language_study.level_id_1);
      setLevelId2(applicantData.language_study.level_id_2);
      setLanguage3(applicantData.language_study.language_3);
      setNavigationBook(applicantData.navigation_book);
      setFirstLoad(true);
      setLoadScreen(false);
    } else if(applicants.error != null) {
      setLoadScreen(false);
      navigate('/error?code=500');
    }
  }, [applicants, firstLoad]);

  useEffect(() => {
    if (regions.pending) {      
      const regionsData = regions.regions;
      setLanguages(regionsData.languages);
      setLevels(regionsData.levels);
      setOaSkill(regionsData.oa_skills);
      setQualification_Categories(regionsData.qualification_categories);
      setQualifications(regionsData.qualifications);
    }
  }, [regions.pending]);

  useEffect(() => {
      if (putregion.pending) {
        if (localStorage.entryFlag) {
          if(window.confirm("エントリーページへ戻りますか？")) {
            let recruitment_id = localStorage.entryFlag;
            localStorage.removeItem('entryFlag');
            window.location.href = '/recruitments/' + recruitment_id + '/entry/';
            return;
          }
        }
      
        if (tempolary) {
            alert('保存しました');
            navigate('/mypage/');
            return;
        }
        // navigate('/mypage/profile5/');
        window.location.href = '/mypage/profile5/?next=true';
      } else if(putregion.error != null) {
        setLoadScreen(false);
        navigate('/error?code=500');
      }
    }, [putregion]);

  const findValue = (value: any, masterName: string | number) => {
    const dataSet = regions.regions[masterName];
    if (dataSet === undefined) {
      return null;
    }
    return value && value !== undefined ? dataSet.find((v: { id: number; }) => v.id === Number(value))?.name : null;
  };

  const [wordModal, setWordModal] = useState(false);
    const wordModalToggle = () => {
      setWordModal(!wordModal);
    };

  const handleCheckboxWord = (event: any, id: any) => {
    if ( id !== 0 ) {
      if (words.includes(id)) {
        setWords(words.filter(skill => skill !== id));
      } else {
        setWords([...words, id]);
      }
    } 
    if (id === 0) {
      setWord(words);
      wordModalToggle();
    }
    setEdited(true);
  };

  const [excelModal, setExcelModal] = useState(false);
    const excelModalToggle = () => {
      setExcelModal(!excelModal);
    };

  const handleCheckboxExcel = (event: any, id: any) => {
    setEdited(true);
    if ( id !== 0 ) {
      if (excels.includes(id)) {
        setExcels(excels.filter(skill => skill !== id));
      } else {
        setExcels([...excels, id]);
      }
    } 
    if (id === 0) {
      setExcel(excels);
      excelModalToggle();
    }
  };

  const [designModal, setDesignModal] = useState(false);
    const designModalToggle = () => {
      setDesignModal(!designModal);
    };

  const handleCheckboxDesign = (event: any, id: any) => {
    if ( id !== 0 ) {
      if (designs.includes(id)) {
        setDesigns(designs.filter(skill => skill !== id));
      } else {
        setDesigns([...designs, id]);
      }
    } 
    if (id === 0) {
      setDesign(designs);
      designModalToggle();
    }
    setEdited(true);
  };

  const [programModal, setProgramModal] = useState(false);
    const programModalToggle = () => {
      setProgramModal(!programModal);
    };

  const handleCheckboxProgram = (event: any, id: any) => {
    if ( id !== 0 ) {
      if (programs.includes(id)) {
        setPrograms(programs.filter(skill => skill !== id));
      } else {
        setPrograms([...programs, id]);
      }
    } 
    if (id === 0) {
      setProgram(programs);
      programModalToggle();
    }
    setEdited(true);
  };

  function prev() {
    setCurrentSlide(0);
  }

  const [qualificationModal1, setQualificationModal1] = useState(false);
    const qualificationModalToggle1 = () => {
      setEdited(true);
      setQualificationModal1(!qualificationModal1);
  };

  function filterLanguages () {
    return languages.filter((v: any) => {
        if ( language_id_1 == v.id ) { return false }
        if ( language_id_2 == v.id ) { return false }
        return true
    });
  }

  function filterLevels () {
    return levels.filter((v: any) => {
        if (language_id_1 == language_id_2 && level_id_1 == v.id ) { return false }
        if (language_id_1 == language_id_2 && level_id_2 == v.id ) { return false }
        return true
    });
  }

  const [qualificationModal2, setQualificationModal2] = useState(false);
  const qualificationModalToggle2 = () => {
    setEdited(true);
    setQualificationModal2(!qualificationModal2);
  };

  const handleInputQualification3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEdited(true);
    setQualification3(e.target.value);
    setCurrentSlide(0);
  };

  const [languageModal1, setLanguageModal1] = useState(false);
    const languageModalToggle1 = () => {
      setEdited(true);
      setLanguageModal1(!languageModal1);
  };

  const [languageModal2, setLanguageModal2] = useState(false);
  const languageModalToggle2 = () => {
    setEdited(true);
    setLanguageModal2(!languageModal2);
  };

  const handleInputLanguage3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEdited(true);
    setLanguage3(e.target.value);
  };

  function submit (e: any, tempolary1 = false) {
    if(!tempolary1) {
        e.preventDefault();
    }
    let error = false
    
    const data = {
      applicant: {
        language_study:{
          language_3: language_3,
          language_id_1: language_id_1,
          level_id_1: level_id_1,
          language_id_2: language_id_2,
          level_id_2: level_id_2,
        },
        oa_skills: {
          word_experience: word_experience,
          word: word,
          excel_experience: excel_experience,
          excel: excel,
          design: design,
          program: program
        },
        qualification: {
          qualification_id_1: qualification_id_1,
          qualification_id_2: qualification_id_2,
          qualification_3: qualification_3
        }
      }
    }
    setEdited(false);
    settempolary(tempolary1);
    setLoadScreen(true);
    dispatch(fetchPutRegionRequest(resourceId, data));
    
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setExcelModal(false);
        setDesignModal(false);
        setProgramModal(false);
        setQualificationModal1(false);
        setQualificationModal2(false);
        setLanguageModal1(false);
        setLanguageModal2(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);


    return (
      <main id="mypage">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="ApplicantList" />
        {loadScreen ? <Loader /> : <></>}
        <section id="mypage_inner">
            <ProfileIndex authenticate={authenticate} navigation_book_url={navigation_book}/>
            <ProfileTab ref={refs} index={4} />
            <form className="profile wrapper">
              <div className="cont1">
                <h3 className="ttl">スキル</h3>
                <p className="ex_info">※ <span className="required">必須</span>が付いている項目はエントリーに必要な情報です。 </p>
                <div className="wrapper">
                  <table>
                    <tbody className="other">
                      <tr>
                        <th>アピールしたいPCスキル <br className="pc_only"/>
                          <span className="any">任意</span>
                        </th>
                        <td className="nest">
                          <div className="nest_child select_cont">
                            <h4>Word</h4>
                            <div className="radio">
                              <label>
                                <input type="radio" name="word_experience"  onClick={() => {setEdited(true); setWordExperience(true)}} checked={word_experience === true} />あり </label>
                              <label>
                                <input type="radio" name="word_experience"  onClick={() => {{setEdited(true);setWordExperience(false)}; setWord([])}} checked={word_experience === false}/>なし </label>
                            </div>
                          </div>
                          { word_experience && word.length === 0 ? (
                              <div className="nest_child acc_list_cont"  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {wordModalToggle()}}}>
                                <p className="acc_ttl fixed"><a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={wordModalToggle}>Wordスキルを追加する</a></p>
                              </div>
                          ) : word.length !== 0 && (
                            <div  className="nest_child">
                            <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                            <div  className="txt_cont" onClick={wordModalToggle}>
                              <h4>Wordスキルを追加する</h4>
                              <p><span>{word.map((id, index) => (
                                    <React.Fragment key={id}>
                                      {index > 0 && ' / '}
                                      {findValue(id, 'oa_skills')}
                                    </React.Fragment>
                                  ))}</span></p>
                            </div>
                            <div className="icon_cont" tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {wordModalToggle()}}}>
                              <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" alt="memo_icon" onClick={wordModalToggle}/></span>
                            </div></a>
                            </div>
                          )}
                          {wordModal ?
                          <div className="modal_box checkbox">
                              <div className="modal_inner">
                              <h4>アピールしたいPCスキル（Word）</h4>
                              <form>
                                  <h5 className="modal_ttl">アピールしたいWordスキルを選択してください</h5>
                                  <p className="note">※複数選択可能</p>
                                  <div className="slider_item">
                                      {oa_skill
                                      .filter(data => data.oa_skill_category_id === 1)
                                      .map((d: any) => (
                                          <label key={d.id}>
                                              <input type="checkbox" name={d.name} defaultChecked={word.includes(d.id)} onChange= {(e) => {handleCheckboxWord(e, d.id)}}/>
                                              <span>{ d.name }</span>
                                          </label>
                                      ))} 
                                  </div>
                              </form>
                              <p className="complete" tabIndex={0} 
                                onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  if (e.shiftKey) {
                                  }else {
                                    wordModalToggle(); 
                                    setTimeout(() => {
                                      const nextElement = document.querySelector(".after-modal") as HTMLElement;
                                      nextElement?.focus(); 
                                    }, 10);
                                  }
                                }
                              }}  onClick= {(e) => handleCheckboxWord(e, 0)}>完了</p>
                              <p className="close" onClick={wordModalToggle}>
                                  <span className="material-icons">close</span>
                              </p>
                              </div>
                              <div className="overlay"onClick={wordModalToggle}></div>
                          </div>
                          : null }
                          <div className="nest_child select_cont">
                            <h4>Excel</h4>
                            <div className="radio">
                              <label>
                                <input type="radio" name="excel_experience"  onClick={() => {setEdited(true); setExcelExperience(true)}} checked={excel_experience === true} />あり </label>
                              <label>
                                <input type="radio" name="excel_experience"  onClick={() => {{setEdited(true);setExcelExperience(false)}; setExcel([])}} checked={excel_experience === false}/>なし </label>
                            </div>
                          </div>
                          {excel_experience && excel.length === 0 ? (
                            <div className="nest_child acc_list_cont">
                              <p className="acc_ttl fixed"  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {excelModalToggle()}}}>
                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={excelModalToggle}>Excelスキルを追加する</a>
                              </p>
                            </div>
                          ) : excel.length !== 0 && (
                            <div className="nest_child">
                              <a title="ポップアップが立ち上がります。TABキーを押して選択してください." className="edit_box fixed">
                                <div className="txt_cont" onClick={excelModalToggle}>
                                  <h4>Excelスキルを追加する</h4>
                                  <p><span>{excel.map((id, index) => (
                                        <React.Fragment key={id}>
                                          {index > 0 && ' / '}
                                          {findValue(id, 'oa_skills')}
                                        </React.Fragment>
                                      ))}
                                    </span>
                                  </p>
                                </div>
                                <div className="icon_cont">
                                  <span className="edit"  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {excelModalToggle()}}}>
                                    <img src="/assets/img/mypage/memo_icon.svg" alt="memo_icon" onClick={excelModalToggle} />
                                  </span>
                                </div>
                              </a>
                            </div>
                          )}
                          {excelModal ?
                          <div className="modal_box checkbox">
                              <div className="modal_inner">
                              <h4>アピールしたいPCスキル（Excel）</h4>
                              <form>
                                  <h5 className="modal_ttl">アピールしたいExcelスキルを選択してください</h5>
                                  <p className="note">※複数選択可能</p>
                                  <div className="slider_item" >
                                      {oa_skill
                                      .filter(data => data.oa_skill_category_id === 2)
                                      .map((d: any) => (
                                          <label key={d.id}>
                                              <input type="checkbox" name={d.name} defaultChecked={excel.includes(d.id)} onChange= {(e) => handleCheckboxExcel(e, d.id)}/>
                                              <span>{ d.name }</span>
                                          </label>
                                      ))} 
                                  </div>
                              </form>
                              <p className="complete" tabIndex={0} 
                                onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  excelModalToggle(); 
                                  setTimeout(() => {
                                    const nextElement = document.querySelector(".after-modal") as HTMLElement;
                                    nextElement?.focus(); 
                                  }, 10);
                                }
                              }} onClick= {(e) => handleCheckboxExcel(e, 0)}>完了</p>
                              <p className="close" onClick={excelModalToggle}>
                                  <span className="material-icons">close</span>
                              </p>
                              </div>
                              <div className="overlay"onClick={excelModalToggle}></div>
                          </div>
                          : null }
                          <div className="nest_child select_cont">
                            <h4>デザイン系・その他事務系</h4>
                            {design.length === 0 ? (
                              <div className="txt_cont">
                                <p className="acc_ttl fixed"  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {designModalToggle()}}} >
                                  <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={designModalToggle}>デザイン系・その他事務系スキルを追加する</a>
                                </p>
                              </div>
                            ) : (
                              <div className="nest_child">
                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください." className="edit_box fixed">
                                  <div className="txt_cont" onClick={designModalToggle}>
                                    <h4>デザイン系・その他事務系スキルを追加する</h4>
                                    <p><span>{design.map((id, index) => (
                                          <React.Fragment key={id}>
                                            {index > 0 && ' / '}
                                            {findValue(id, 'oa_skills')}
                                          </React.Fragment>
                                        ))}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="icon_cont">
                                    <span className="edit"  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {designModalToggle()}}}>
                                      <img src="/assets/img/mypage/memo_icon.svg" alt="memo_icon" onClick={designModalToggle} />
                                    </span>
                                  </div>
                                </a>
                              </div>
                            )}
                          </div>
                          {designModal ?
                          <div className="modal_box checkbox">
                              <div className="modal_inner">
                              <h4>アピールしたいPCスキル（デザイン系・その他事務系）</h4>
                              <form>
                                  <h5 className="modal_ttl">アピールしたいデザイン系・その他事務系スキルを選択してください</h5>
                                  <p className="note">※複数選択可能</p>
                                  <div className="slider_item" >
                                      {oa_skill
                                      .filter(data => data.oa_skill_category_id === 3)
                                      .map((d: any) => (
                                          <label key={d.id}>
                                              <input type="checkbox" name={d.name} defaultChecked={design.includes(d.id)} onChange= {(e) => handleCheckboxDesign(e, d.id)}/>
                                              <span>{ d.name }</span>
                                          </label>
                                      ))} 
                                  </div>
                              </form>
                              <p className="complete"  tabIndex={0} onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  designModalToggle(); 
                                  setTimeout(() => {
                                    const nextElement = document.querySelector(".after-modal") as HTMLElement;
                                    nextElement?.focus(); 
                                  }, 10);
                                }
                              }}
                               onClick= {(e) => handleCheckboxDesign(e, 0)}>完了</p>
                              <p className="close" onClick={designModalToggle}>
                                  <span className="material-icons">close</span>
                              </p>
                              </div>
                              <div className="overlay"onClick={designModalToggle}></div>
                          </div>
                          : null }
                          <div className="nest_child select_cont">
                            <h4>プログラミング系</h4>
                            {program.length === 0 ? (
                              <div className="txt_cont">
                                <p className="acc_ttl fixed"  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {programModalToggle()}}}>
                                  <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={programModalToggle}>プログラミング系スキルを追加する</a>
                                </p>
                              </div>
                            ) : (
                              <div className="nest_child">
                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください." className="edit_box fixed">
                                  <div className="txt_cont" onClick={programModalToggle}>
                                    <h4>プログラミング系スキルを追加する</h4>
                                    <p><span>{program.map((id, index) => (
                                          <React.Fragment key={id}>
                                            {index > 0 && ' / '}
                                            {findValue(id, 'oa_skills')}
                                          </React.Fragment>
                                        ))}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="icon_cont">
                                    <span className="edit"  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {programModalToggle()}}}>
                                      <img src="/assets/img/mypage/memo_icon.svg" alt="memo_icon" onClick={programModalToggle} />
                                    </span>
                                  </div>
                                </a>
                              </div>
                            )}
                          </div>
                          {programModal ?
                          <div className="modal_box checkbox">
                              <div className="modal_inner">
                              <h4>アピールしたいPCスキル（プログラミング系）</h4>
                              <form>
                                  <h5 className="modal_ttl">アピールしたいプログラミング系スキルを選択してください</h5>
                                  <p className="note">※複数選択可能</p>
                                  <div className="slider_item" >
                                      {oa_skill
                                      .filter(data => data.oa_skill_category_id === 4)
                                      .map((d: any) => (
                                          <label key={d.id}>
                                              <input type="checkbox" name={d.name} defaultChecked={program.includes(d.id)} onChange= {(e) => handleCheckboxProgram(e, d.id)}/>
                                              <span>{ d.name }</span>
                                          </label>
                                      ))} 
                                  </div>
                              </form>
                              <p className="complete"  tabIndex={0} onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  programModalToggle(); 
                                  setTimeout(() => {
                                    const nextElement = document.querySelector(".after-modal") as HTMLElement;
                                    nextElement?.focus(); 
                                  }, 10);
                                }
                              }} onClick= {(e) => handleCheckboxProgram(e, 0)}>完了</p>
                              <p className="close" onClick={programModalToggle}>
                                  <span className="material-icons">close</span>
                              </p>
                              </div>
                              <div className="overlay"onClick={programModalToggle}></div>
                          </div>
                          : null }
                        </td>
                      </tr>
                      <tr>
                        <th>アピールしたい資格 <span className="any">任意</span>
                        </th>
                        <td className="nest">
                          <div className="nest_child select_cont">
                            <h4>アピールしたい資格1</h4>
                            <p  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {qualificationModalToggle1()}}} className={qualification_id_1 ? 'select comp' : 'select'}>
                              <a  title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={qualificationModalToggle1}>
                                {findValue(qualification_id_1, 'qualifications') || '選択してください'}
                              </a>
                            </p>
                          </div> 
                          {qualificationModal1 ? (
                            <div className="modal_box selectRadio1">
                              <div className="modal_inner">
                                <h4>アピールしたい資格1</h4>
                                {currentSlide > 0 ?
                                    <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                                : null }
                                <form>
                                  <h5 className="modal_ttl">アピールしたい資格を選択してください</h5>
                                  <div className="slider_item">
                                    {currentSlide == 0 &&
                                      <label className="column3">
                                        <input type="radio" autoFocus = {true} name="radio1" onClick={() => {qualificationModalToggle1(); setQualification1(null); setQualificationId1(null)}} />
                                        <span>なし</span>
                                      </label>
                                    }
                                    {currentSlide == 0 &&
                                     qualification_categories.map((d: any) => (
                                      <label key={d.id}>
                                        <input type="radio" name={d.name} defaultChecked={qualification_1 == d.id} value={d.id} onClick={() => { setQualification1(d.id); setEdited(true); setCurrentSlide(1); }}/>
                                        <span>{d.name}</span>
                                      </label>
                                    ))}
                                    {currentSlide == 1 && qualifications
                                        .filter(data => data.qualification_category_id == qualification_1 && data.id != qualification_id_1 && data.id != qualification_id_2)
                                        .map((d: any, index: any) => (
                                          <label key={d.id}>
                                            <input  autoFocus = {index == 0 ? true : false} type="radio" name={d.name} defaultChecked={qualification_id_1 == d.id} value={d.id} onClick={() => { qualificationModalToggle1(); setQualificationId1(d.id); setCurrentSlide(0);}} />
                                            <span>{d.name}</span>
                                          </label>
                                        ))}
                                  </div>
                                </form>
                                <p className="close" onClick={() => {qualificationModalToggle1(); setCurrentSlide(0);}}>
                                  <span className="material-icons">close</span>
                                </p>
                              </div>
                              <div className="overlay" onClick={() => {qualificationModalToggle1(); setCurrentSlide(0);}}></div>
                            </div>
                          ) : null}
                          <div className="nest_child select_cont">
                            <h4>アピールしたい資格2</h4>
                            <p tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {qualificationModalToggle2()}}} className={qualification_id_2 ? 'select comp' : 'select'}>
                              <a  title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={qualificationModalToggle2}>
                                {findValue(qualification_id_2, 'qualifications') || '選択してください'}
                              </a>
                            </p>
                          </div> 
                          {qualificationModal2 ? (
                            <div className="modal_box selectRadio1">
                              <div className="modal_inner">
                                <h4>アピールしたい資格2</h4>
                                {currentSlide > 0 ?
                                    <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                                : null }
                                <form>
                                  <h5 className="modal_ttl">アピールしたい資格を選択してください</h5>
                                  <div className="slider_item">
                                    {currentSlide == 0 &&
                                      <label className="column3">
                                        <input autoFocus = {true} type="radio" name="radio1" onClick={() => {qualificationModalToggle2(); setQualificationId2(null)}} />
                                        <span>なし</span>
                                      </label>
                                    }
                                    {currentSlide == 0 &&
                                     qualification_categories.map((d: any) => (
                                      <label key={d.id}>
                                        <input type="radio" name={d.name} defaultChecked={qualification_2 == d.id} value={d.id} onClick={() => { setQualification2(d.id); setCurrentSlide(1);}}/>
                                        <span>{d.name}</span>
                                      </label>
                                    ))}
                                    {currentSlide == 1 && qualifications
                                      .filter(data => data.qualification_category_id == qualification_2 && data.id != qualification_id_1 && data.id != qualification_id_2)
                                      .map((d: any, index: any) => (
                                        <label key={d.id}>
                                          <input  autoFocus = {index == 0 ? true : false} type="radio" name={d.name} defaultChecked={qualification_id_2 == d.id} value={d.id} onClick={() => { qualificationModalToggle2(); setQualificationId2(d.id); setCurrentSlide(0);}} />
                                          <span>{d.name}</span>
                                        </label>
                                      ))}
                                  </div>
                                </form>
                                <p className="close" onClick={() => {qualificationModalToggle2(); setCurrentSlide(0);}}>
                                  <span className="material-icons">close</span>
                                </p>
                              </div>
                              <div className="overlay" onClick={() => {qualificationModalToggle2(); setCurrentSlide(0);}}></div>
                            </div>
                          ) : null}
                          <div className="textarea_cont nest_child">
                            <h4>アピールしたい資格3</h4>
                            <label>
                              <input type="text" placeholder="資格名" value={qualification_3} onChange={handleInputQualification3} maxLength={60}/>
                            </label>
                            <p className="note">※一覧にない資格などがあればご入力ください</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>アピールしたい語学 <span className="any">任意</span>
                        </th>
                        <td className="nest">
                          <div className="nest_child select_cont">
                          <h4>アピールしたい語学1</h4>
                            <p  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {languageModalToggle1()}}} className={language_id_1 ? 'select comp' : 'select'}>
                              <a  title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={languageModalToggle1}>
                                {findValue(language_id_1, 'languages') || '選択してください'}{language_id_1 && ','}{findValue(level_id_1, 'levels')}
                              </a>
                            </p>
                          </div> 
                          {languageModal1 ? (
                            <div className="modal_box selectRadio1">
                              <div className="modal_inner">
                                <h4>アピールしたい語学1</h4>
                                {currentSlide > 0 ?
                                    <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                                : null }
                                <form>
                                  <h5 className="modal_ttl">アピールしたい資格を選択してください</h5>
                                  <div className="slider_item">
                                    {currentSlide == 0 &&
                                      <label className="column3">
                                        <input  autoFocus = {true} type="radio" name="radio1" onClick={() => {languageModalToggle1(); setLanguageId1(null); setLevelId1(null)}} />
                                        <span>なし</span>
                                      </label>
                                    }
                                    {currentSlide == 0 &&
                                     languages.map((d: any) => (
                                      <label key={d.id}>
                                        <input type="radio" name={d.name} defaultChecked={language_id_1 == d.id} value={d.id} onClick={() => { setLevelId1(null); setLanguageId1(d.id); setLanguage1(d.id); setCurrentSlide(1);}}/>
                                        <span>{d.name}</span>
                                      </label>
                                    ))}
                                    {currentSlide == 1 && filterLevels()
                                        .map((d: any, index: any) => (
                                          <label key={d.id}>
                                            <input  autoFocus = {index == 0 ? true : false} type="radio" name={d.name} defaultChecked={level_id_1 == d.id} value={d.id} onClick={() => { languageModalToggle1(); setLevelId1(d.id); setLanguage1(null); setCurrentSlide(0);}} />
                                            <span>{d.name}</span>
                                          </label>
                                        ))}
                                  </div>
                                </form>
                                <p className="close" onClick={() => {languageModalToggle1(); setCurrentSlide(0);}}>
                                  <span className="material-icons">close</span>
                                </p>
                              </div>
                              <div className="overlay" onClick={() => {languageModalToggle1(); setCurrentSlide(0);}}></div>
                            </div>
                          ) : null}
                          <div className="nest_child select_cont">
                            <h4>アピールしたい語学2</h4>
                            <p  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {languageModalToggle2()}}} className={language_id_2 ? 'select comp' : 'select'}>
                              <a  title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={languageModalToggle2}>
                              {findValue(language_id_2, 'languages') || '選択してください'}{language_id_2 && ','}{findValue(level_id_2, 'levels')}
                              </a>
                            </p>
                          </div> 
                          {languageModal2 ? (
                            <div className="modal_box selectRadio1">
                              <div className="modal_inner">
                                <h4>アピールしたい語学2</h4>
                                {currentSlide > 0 ?
                                    <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                                : null }
                                <form>
                                  <h5 className="modal_ttl">アピールしたい資格を選択してください</h5>
                                  <div className="slider_item">
                                    {currentSlide == 0 &&
                                      <label className="column3">
                                        <input autoFocus = {true} type="radio" name="radio1" onClick={() => {languageModalToggle2(); setLanguageId2(null); setLevelId2(null)}} />
                                        <span>なし</span>
                                      </label>
                                    }
                                    {currentSlide == 0 &&
                                     languages.map((d: any) => (
                                      <label key={d.id}>
                                        <input type="radio" name={d.name} defaultChecked={language_id_2 == d.id} value={d.id} onClick={() => { setLevelId2(null); setLanguageId2(d.id); setLanguage2(d.id); setCurrentSlide(1);}}/>
                                        <span>{d.name}</span>
                                      </label>
                                    ))}
                                    {currentSlide == 1 && filterLevels()
                                        .map((d: any, index: any) => (
                                          <label key={d.id}>
                                            <input  autoFocus = {index == 0 ? true : false} type="radio" name={d.name} defaultChecked={level_id_2 == d.id} value={d.id} onClick={() => { languageModalToggle2(); setLevelId2(d.id); setLanguage2(null); setCurrentSlide(0);}} />
                                            <span>{d.name}</span>
                                          </label>
                                        ))}
                                  </div>
                                </form>
                                <p className="close" onClick={() => {languageModalToggle2(); setCurrentSlide(0);}}>
                                  <span className="material-icons">close</span>
                                </p>
                              </div>
                              <div className="overlay" onClick={() => {languageModalToggle2(); setCurrentSlide(0);}}></div>
                            </div>
                          ) : null}
                          <div className="textarea_cont nest_child">
                            <h4>アピールしたい語学3</h4>
                            <label>
                              <input type="text" placeholder="言語名、習得レベル" value={language_3} onChange={handleInputLanguage3} maxLength={60}/>
                            </label>
                            <p className="note">※一覧にない言語などがあればご入力ください</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="btn_box">
                <button type="button" onClick={(e) => submit(e, true)} className="btn type3">一時保存する</button> 
                <button type="submit" onClick={(e) => submit(e, false)} className="btn type3 orange">保存して次へ         
                <img src="/assets/img/mypage/arrow02.svg" alt=""/>
              </button>
            </div>
            </form>
        </section>
      </main>
    );
}