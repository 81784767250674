import { useEffect, useRef, useState } from 'react';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import './profile.scss';
import ProfileTab from './tab';
import ProfileIndex from '.';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchApplicantsRequest } from '../../../redax/actions/applicantsActions/applicantsActions';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { fetchMasterInfoRequest } from '../../../redax/actions/masterInfoActions/masterInfoActions';
import { fetchPutRegionRequest } from '../../../redax/actions/putRegionActions/putRegionActions';
import { useNavigate } from 'react-router-dom';
import Loader from '../../loader/loader';

export default function ProfilePage7() {
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    
    const [authenticate, setAuthenticate] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const loginData = useSelector(
        (state: RootState) => state.login
    );
    const { masterInfo } = useSelector((state: RootState) => state.masterInfo);
    const [applicant, setApplicant] = useState<any>(null);
    const [appeal_items, setappeal_items] = useState<any>([]);    
    const [over, setOver] = useState<any>(null);
    const [loadScreen, setLoadScreen] = useState(true);
    const [isValid, setValid] = useState(false);
    const [tempolary, settempolary] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);
    const [modal, setModal] = useState(1);
    const [content, setContent] = useState<any>('');
    const [appeal_item_id_1, setappeal_item_id_1] = useState<any>(null);
    const [appeal_item_id_2, setappeal_item_id_2] = useState<any>(null);
    const [appeal_item_id_3, setappeal_item_id_3] = useState<any>(null);
    const [edited,setEdited] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [contentMaxLengthValid, setContentMaxLengthValid] = useState(false);
    const applicants = useSelector(
      (state: RootState) => state.applicants
    );
    const putregion = useSelector((state: RootState) => state.putregion);
    const pageTitle = 'WEB履歴書（企業に伝えたい項目）｜パラちゃんねる';
    document.title = pageTitle;
    const queryParams = new URLSearchParams(window.location.search);
    const refs = useRef<any>(null);

    useEffect(() => {
      if (!localStorage.getItem('jobParachannelAuthToken')) {
        navigate('/login');
      } 
    }, [navigate, dispatch]);

    useEffect(() => {
        // dispatch(fetchLoginRequest('', '',''));
        let param = 'appeal_items=true';
        dispatch(fetchMasterInfoRequest(param));
        if(queryParams.get('tab') != null || queryParams.get('next') != null) {
          setTimeout(() => {              
              refs.current?.scrollIntoView({behavior:"smooth", block:"start"})
          }, 0);
      }
    }, [dispatch]);

    useEffect(() => {
      if(!edited) return;

        window.addEventListener("beforeunload", confirmSave);

        return () => window.removeEventListener("beforeunload", confirmSave);

    },[edited]);

    function confirmSave (event: any) {
        event.preventDefault();
        if (edited) {
            event.returnValue = "編集中のデータは保存されていませんが、移動しますか？"
        }
    }

    useEffect(() => {
        if (loginData.pending) {
          if(!loginData.login.is_init_complete) {
            navigate('/mypage/init/');
          }
          setResourceId(loginData.login.resource.id);
          setFamylyName(loginData.login.resource.family_name);
          setAuthenticate(loginData.login.resource.id);
          dispatch(fetchApplicantsRequest(loginData.login.resource.id, 'get', 0));
        }
    }, [dispatch, loginData.pending]);

    useEffect(() => {
      if (masterInfo !== undefined) {
        console.log(masterInfo.appeal_items)
          setappeal_items(masterInfo.appeal_items);
      }
    }, [masterInfo]);

    useEffect(() => {
      if (applicants.pending && !firstLoad) {
        setLoadScreen(false);
          setApplicant(applicants.applicants.applicant);
          setContent(applicants.applicants.applicant.special_content);
          if(applicants.applicants.applicant.special_content) {
              if(!charaLimit(applicants.applicants.applicant.special_content, 400)) {
                  setValid(false);
              }
          }
          setappeal_item_id_1(applicants.applicants.applicant.appeal_item.appeal_item_id_1);
          setappeal_item_id_2(applicants.applicants.applicant.appeal_item.appeal_item_id_2);
          setappeal_item_id_3(applicants.applicants.applicant.appeal_item.appeal_item_id_3);
          setFirstLoad(true);
      } else if(applicants.error != null) {
        setLoadScreen(false);
        navigate('/error?code=500');
      }
    }, [applicants]);

    function charaLimit (inputText: any, limit: any) {
      if (typeof inputText != "string") { return '' }
      return inputText.length > limit ? (inputText.length - limit) + '文字オーバー' : ''
    }

    function handleContent(val: any) {
      setEdited(true);
        if(val == '') {
            setOver('');
            setContentMaxLengthValid(false);
        } else if(charaLimit (val, 400)) {
            setValid(true);            
            setContentMaxLengthValid(true);
            setOver(charaLimit (val, 400));
        } else {
            setValid(false);
            setContentMaxLengthValid(false);
            setOver('');
        }        
        setContent(val);
    }

    function handleContentKey(val: any) {
      setEdited(true);
        if(val.value == '') {
            setOver('');
            setContentMaxLengthValid(false);
        } else if(charaLimit (val.value, 400)) {
            setValid(true);            
            setContentMaxLengthValid(true);
            setOver(charaLimit (val.value, 400));
        } else {
            setValid(false);
            setContentMaxLengthValid(false);
            setOver('');
        }        
        console.log(val.value)
        setContent(val.value);
    }

    function filterAppealItems () {
      return appeal_items.filter((v: any) => {
        if ( applicant.job_career_count_id == 1 && (v.id == 1 || v.id == 2)) { return false }
        if ( appeal_item_id_1 == v.id ) { return false }
        if ( appeal_item_id_2 == v.id ) { return false }
        if ( appeal_item_id_3 == v.id ) { return false }
        return true
      })
    }

    function findAppealItems (value: any) {
      return value ? appeal_items.find((v: any) => v.id === Number(value)).name : null
    }

    function modalShow (value: any) {
      switch (value) {
        case 'appeal_item_id_1':
          
          break;
        case 'appeal_item_id_2':
          
          break;
        case 'appeal_item_id_3':
          
          break;
        default: break;
      }
    }

    function submit (e: any, tempolary1 = false) {
      let error = false;
      if(isValid == true) {
        error = true;
      }
      if (error) {
        window.alert('文字数がオーバーしています');
        return
      }
      if(!tempolary1) {
          e.preventDefault();
      }
      const data = {
        applicant: {
          appeal_item: {
            appeal_item_id_1: appeal_item_id_1,
            appeal_item_id_2: appeal_item_id_2,
            appeal_item_id_3: appeal_item_id_3
          },
          job_career_count_id: applicant.job_career_count_id,
          navigation_book: applicant.navigation_book,
          special_content: content
        }
      }
      setEdited(false);
      settempolary(tempolary1);
      setLoadScreen(true);
      dispatch(fetchPutRegionRequest(resourceId, data));
    }

    useEffect(() => {
      if (putregion.pending) {
        setLoadScreen(false);
          if (localStorage.entryFlag) {
              if(window.confirm("エントリーページへ戻りますか？")) {
                let recruitment_id = localStorage.entryFlag;
                localStorage.removeItem('entryFlag');
                window.location.href = '/recruitments/' + recruitment_id + '/entry/';
                return;
              }
          }          
          if (tempolary) {
              window.alert('保存しました');
              navigate('/mypage/');
              return;
          }
          window.alert('保存しました');
          navigate('/mypage/');
      } else if(putregion.error != null) {
        setLoadScreen(false);
        navigate('/error?code=500');
      }
  }, [putregion]);

    function complete (val: any, id: any) {
      if(id == 0) {
        if(modal == 1 && (appeal_item_id_2 == val || appeal_item_id_3 == val)) {
          return;
        }
        if(modal == 2 && (appeal_item_id_1 == val || appeal_item_id_3 == val)) {
          return;
        }
        if(modal == 3 && (appeal_item_id_2 == val || appeal_item_id_1 == val)) {
          return;
        }
      }
      if(modal == 1) {
        setModal1(false);
        if(id == 0) {
          setappeal_item_id_1(val);
        } else {
          setappeal_item_id_1(val.target.value);
        }
      } else if(modal == 2) {
        setModal2(false);
        if(id == 0) {
          setappeal_item_id_2(val);
        } else {
          setappeal_item_id_2(val.target.value);
        }
      } else {
        setModal3(false);
        if(id == 0) {
          setappeal_item_id_3(val);
        } else {
          setappeal_item_id_3(val.target.value);
        }
      }
    }

    function close () {
      if(modal == 1) {
        setModal1(false);
      } else if(modal == 2) {
        setModal2(false);
      } else {
        setModal3(false);
      }
    }

    function clear () {
      if(modal == 1) {
        setModal1(false);
        setappeal_item_id_1(null);
      } else if(modal == 2) {
        setModal2(false);
        setappeal_item_id_2(null);
      } else {
        setModal3(false);
        setappeal_item_id_3(null);
      }      
    }

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          setModal1(false);
          setModal2(false);
          setModal3(false);
        }
      };
  
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    return (
      <main id="mypage">
        <MypageSideBar resource={resourceId} familyName = {familyName}   currentList="ApplicantList"/>
        {loadScreen ? <Loader /> : <></>}
        <section id="mypage_inner">
            <ProfileIndex authenticate={authenticate} navigation_book_url={applicant && applicant.navigation_book}/>
            <ProfileTab ref={refs} index={7} />
            <form className="profile wrapper">
              <div className="cont1">
                <h3 className="ttl">エントリー時に企業に伝えたい項目</h3> 
                <p className="ex_info">※<span className="required">必須</span>が付いている項目はエントリーに必要な情報です。</p> 
                <div className="wrapper">
                  <table>
                    <tbody className="other">
                      <tr>
                        <th>企業に伝えたい項目
                          <span className="any">任意</span>
                        </th> 
                        <td className="nest on">
                        {modal1 || modal2 || modal3 ?
                          <div role="dialog" className="modal_box selectRadio1">
                            <div className="modal_inner">
                              <h4>企業に伝えたい項目{modal}</h4> 
                              <form>
                                <h5 className="modal_ttl">Web履歴書項目の中で企業担当者にアピールしたい項目を選択してください</h5> 
                                <div className="slider_item">
                                  <label className="column3">
                                    <input type="radio" autoFocus = {true} name="radio1" onClick={() => clear()} />
                                    <span>なし</span>
                                  </label>
                                  {applicant && filterAppealItems().map((d: any, index: any) => (
                                  <label key={d.id} onClick={() => complete(d.id, 0)}>
                                    {modal == 1 ?
                                    <input type="radio"  name={d.name} value={d.id} disabled={appeal_item_id_2 == d.id || appeal_item_id_3 == d.id} defaultChecked={appeal_item_id_1 == d.id}
                                     onClick={(e) => {if(appeal_item_id_2 != d.id && appeal_item_id_3 != d.id) {complete(e, 1)}}}/>
                                    : modal == 2 ?
                                    <input type="radio" name={d.name} value={d.id} disabled={appeal_item_id_1 == d.id || appeal_item_id_3 == d.id} defaultChecked={appeal_item_id_2 == d.id}
                                    onClick={(e) => {if(appeal_item_id_1 != d.id && appeal_item_id_3 != d.id) {complete(e, 1)}}}/>
                                    : 
                                    <input type="radio"  name={d.name} value={d.id} disabled={appeal_item_id_2 == d.id || appeal_item_id_1 == d.id} defaultChecked={appeal_item_id_3 == d.id}
                                    onClick={(e) => {if(appeal_item_id_2 != d.id && appeal_item_id_1 != d.id) {complete(e, 1)}}}/>
                                    }
                                    <span>{ d.name }</span>
                                  </label>
                                  ))}
                                </div>
                              </form> 
                              <p className="close">
                                <span className="material-icons" onClick={() => close()}>close</span>
                              </p>
                            </div> 
                            <div className="overlay" onClick={() => close()}></div>
                          </div> 
                          : <></>}
                          <div className="nest_child select_cont">
                            <h4>企業に伝えたい項目1</h4> 
                            <p className={appeal_item_id_1 ? 'select comp' : 'select'}>
                              <a href="javascript:void(0)" title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={(e) => {setEdited(true);setModal(1);setModal1(true); modalShow('appeal_item_id_1');}}>
                              { findAppealItems(appeal_item_id_1) || '選択してください'}
                            </a></p>
                          </div>
                          <div className="nest_child select_cont">
                            <h4>企業に伝えたい項目2</h4> 
                            <p className={appeal_item_id_2 ? 'select comp' : 'select'}>
                              <a href="javascript:void(0)" title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={(e) => {setEdited(true);setModal(2);setModal2(true); modalShow('appeal_item_id_2');}}>
                              { findAppealItems(appeal_item_id_2) || '選択してください'}
                            </a></p>
                          </div>
                          <div className="nest_child select_cont">
                            <h4>企業に伝えたい項目3</h4> 
                            <p className={appeal_item_id_3 ? 'select comp' : 'select'}>
                              <a href="javascript:void(0)" title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={(e) => {setEdited(true);setModal(3);setModal3(true); modalShow('appeal_item_id_3');}}>
                              { findAppealItems(appeal_item_id_3) || '選択してください'}
                            </a></p>
                          </div>
                        </td>
                      </tr> 
                      <tr>
                        <th>特記事項
                          <span className="any">任意</span>
                        </th> 
                        <td className="nest">
                          <div className="textarea_cont">
                            <h4>特記事項（４００文字以内）</h4> 
                            <textarea name="" id="" cols={30} rows={10}
                            onChange={(e) => handleContent(e.target.value)}                            
                            onKeyDown={(e) => handleContentKey(e.target)}
                            onKeyUp={(e) => handleContentKey(e.target)}
                            defaultValue={content}></textarea> 
                            <p className="text_length_alert">{over}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> 
              <div className="btn_box">
                <button type="button" onClick={(e) => submit(e, true)} className="btn type3">一時保存する</button> 
                <button type="submit" disabled={contentMaxLengthValid} onClick={(e) => submit(e, false)} className="btn type3 orange">完了         
                  <img src="/assets/img/mypage/arrow02.svg" alt=""/>
                </button>
              </div>
            </form>
            </section>
      </main>
    );
}