import { fetchLoginRequest } from '../../../../redax/actions/loginActions/loginActions';
import { RootState } from '../../../../redax/reducers/rootReducer';
import MypageSideBar from '../../../../components/MypageSideBar/MypageSideBar';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchArticlesRecruiterRequest } from '../../../../redax/actions/articlesRecruiterActions/articlesRecruiterActions';
import { fetchArticlesDeleteRequest } from '../../../../redax/actions/articlesDeleteActions/articlesDeleteActions';
import { fetchArticlesSubmitRequest } from '../../../../redax/actions/articlesSubmitActions/articlesSubmitActions';
import { fetchArticlesCreateRequest } from '../../../../redax/actions/articlesCreateActions/articlesCreateActions';
import Loader from '../../../loader/loader';


function ColumnIndex() {
  const dispatch = useDispatch();
  const [familyName, setFamylyName] = useState(null);
  const [resourceId, setResourceId] = useState(null);
  const [authenticate, setAuthenticate] = useState(null);
  const [articles, setArticles] = useState<any>([]);
  const [chk, setChk] = useState(false);
  const [pages, setPages] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [resCompanyId, setCompanyId] = useState(null);  
  const articlesCreate = useSelector((state: RootState) => state.articlesCreate);
  const pageTitle = 'コラム投稿・管理　一覧｜パラちゃんねる';
  document.title = pageTitle;
  const [loadScreen, setLoadScreen] = useState(true);
  const [activeSort, setActiveSort] = useState("all");

  const navigate = useNavigate();
  let maxContentLength = 100;
  const breadCrumbs = [
    {
      label: 'トップ',
      path: '/',
    },
    {
      label: '企業様マイページ',
      path: '/recruiter/',
    },
    {
      label: 'コラム',
      path: '#',
    },
  ];

  useEffect(() => {
      if (localStorage.getItem('jobParachannelAuthToken')) {
        // dispatch(fetchLoginRequest('', '',''))
        dispatch(fetchArticlesRecruiterRequest('page=1'))
      } else (
          navigate('/recruiter/login')
      )
    }, [dispatch]);

  const loginData = useSelector((state: RootState) => state.login);
  const articlesRecruiter = useSelector((state: RootState) => state.articlesRecruiter);
  const articlesDelete = useSelector((state: RootState) => state.articlesDelete);
  const articlesSubmit = useSelector((state: RootState) => state.articlesSubmit);


  const reserved = (article: any) => {
    return new Date(article.release_date) > new Date() && article.status === 'publish';
  };

  useEffect(() => {
    if (loginData.pending && loginData !== undefined) {
      if(!loginData.login.is_init_complete) {
        navigate('/recruiter/init/');
      }
      setResourceId(loginData.login.resource.id);
      setFamylyName(loginData.login.resource.family_name);
      setAuthenticate(loginData.login.authenticate.resource_type);
    }
  }, [loginData.pending]);

  useEffect(() => {
    if( articlesRecruiter !== undefined && articlesRecruiter.pending) {
      setLoadScreen(false);
      console.log(articlesRecruiter);
      setArticles(articlesRecruiter.articles.articles);
      let resCompanyId = articlesRecruiter.articles.pagination;
      if (resCompanyId !== null && resCompanyId !== undefined) {
        setPages(resCompanyId.total_pages);
      }   
    } else if(articlesRecruiter.error != null) {
      setLoadScreen(false);
      navigate('/error?code=500');
    }
  }, [articlesRecruiter, pages]);

  useEffect(() => {
    if(resCompanyId !== null && resCompanyId !== undefined) {
      dispatch(fetchArticlesRecruiterRequest('page=1'))
    }
}, [resCompanyId]);  

  useEffect(() => {
    if(articlesDelete.pending) {
      setLoadScreen(false);
      window.location.reload();
    } else if(articlesDelete.error != null) {
      setLoadScreen(false);
      navigate('/error?code=500');
    }
  }, [articlesDelete]);


  useEffect(() => {
    if(articlesCreate.pending) {
      // setArticles(articlesCreate.articles.articles);
      dispatch(fetchArticlesRecruiterRequest('page=1'));
    } else if(articlesCreate.error != null) {
      setLoadScreen(false);
      navigate('/error?code=500');
    }
  }, [articlesCreate]);


  function logout() {
    if (localStorage.entryFlag) {
      localStorage.removeItem('entryFlag')
    }
    if (localStorage.jobParachannelAuthToken) {
      localStorage.removeItem('jobParachannelAuthToken')
      navigate('/');
      window.location.reload();
    }
  }
  const formatDateTime = (dateStr: any) => {
    const d = new Date(dateStr);
    return `
      ${d.getFullYear()}.
      ${d.getMonth() + 1}.
      ${d.getDate()} 
      ${d.getHours().toString().padStart(2, '0')}:
      ${d.getMinutes().toString().padStart(2, '0')}
    `.replace(/\n|\r/g, '');
  };

  const formatDate = (dateStr: any) => {
    const d = new Date(dateStr);
    return `
      ${d.getFullYear()}.
      ${d.getMonth() + 1}.
      ${d.getDate()}
    `.replace(/\n|\r/g, '');
  }
  function updateStatus(id: any, id2: any, val: any) {

    let dispStatus = ''
    if(val == 'draft') {
      dispStatus = '非公開'
    } else if (val === 'publish'){
      dispStatus = '公開'
    }

    if (window.confirm(`ステータスを${dispStatus}にしますか？`)) {
      const newData = {
        status: val,
      };
      setLoadScreen(true);
      dispatch(fetchArticlesSubmitRequest(id2,newData))
      setChk(true);
    }
  }

  useEffect(() => {
    if(chk) {
      if(articlesSubmit.pending) {
        if(articlesSubmit.status == 200 || articlesSubmit.status == 204) {
          window.confirm("ステータスを更新しました。");
        }
        setChk(false); 
      } else if(articlesSubmit.error != null) {
        navigate('/error?code=500');
      } else if(articlesSubmit.status != null) {
        window.confirm("ステータスの更新に失敗しました")
        setChk(false);
      }
    }
  }, [articlesSubmit, chk]);


  const duplicate = async (article: any) => {
    const dt = new Date();
    const releaseDate =
      dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' 00:00';
    
    const newData = {
      content: article.content,
      eye_catch: article.eye_catch,
      release_date: releaseDate,
      status: 'draft',
      title: article.title
    };
    setLoadScreen(true);
    dispatch(fetchArticlesCreateRequest(newData));

    try {
      console.log('create');

    } catch (error) {
      console.error(error);
    }
    setTimeout(function() {
      window.location.reload();
    }, 1500);
  };



  const deleteArticle = async (id: any) => {
    if (window.confirm("削除しますか？")) {
      try {
        setLoadScreen(true);
        dispatch(fetchArticlesDeleteRequest(id));
      } catch (error) {
        console.error(error);
      }
    }
  };
  const pageIncriment = () => {
    if (page >= pages) {
      setPage(pages);
      return;
    }
    setPage((prevPage) => prevPage + 1);
    setLoadScreen(true);
    if(activeSort == 'all'){
      dispatch(fetchArticlesRecruiterRequest('page=' + (page + 1)));
    } else if (activeSort == 'createdAt'){
      dispatch(fetchArticlesRecruiterRequest('page='+ (page + 1) + '&created_at=true'));
    } else if(activeSort ==  'viewer'){
      dispatch(fetchArticlesRecruiterRequest('page='+ (page + 1) + '&viewer=true'));
    }
};

const pageDecriment = () => {
    if (page <= 1) {
        setPage(1);
        return;
    }
    setPage((prevPage) => prevPage - 1);
    setLoadScreen(true);
    if(activeSort == 'all'){
      dispatch(fetchArticlesRecruiterRequest('page=' + (page - 1)));
    } else if (activeSort == 'createdAt'){
      dispatch(fetchArticlesRecruiterRequest('page='+ (page - 1) + '&created_at=true'));
    } else if(activeSort ==  'viewer'){
      dispatch(fetchArticlesRecruiterRequest('page='+ (page - 1) + '&viewer=true'));
    }
};

const updatePage = (p: any) => {
    setPage(p);
    setLoadScreen(true);
    if(activeSort == 'all'){
      dispatch(fetchArticlesRecruiterRequest('page=' + p));
    } else if (activeSort == 'createdAt'){
      dispatch(fetchArticlesRecruiterRequest('page='+ p + '&created_at=true'));
    } else if(activeSort ==  'viewer'){
      dispatch(fetchArticlesRecruiterRequest('page='+ p + '&viewer=true'));
    }
};

  function removeHtmlTag(content: any) {
    const element = document.createElement('div');
    element.innerHTML = content;
    return element.textContent;
  }

  function cutMaxContentLength(content: any) {
    if (content.length > maxContentLength) {
      content = content.substr(0, maxContentLength) + '...';
    }
    return content;
  }
  function sortCreatedAt() {
    setActiveSort("createdAt");
    setPage(1);
    dispatch(fetchArticlesRecruiterRequest("page=1&created_at=true"));
  }

  function sortViewer() {
    setActiveSort("viewer");
    setPage(1);
    dispatch(fetchArticlesRecruiterRequest("page=1&viewer=true"));
  }

  function allSort() {
    setActiveSort("all");
    setPage(1);
    dispatch(fetchArticlesRecruiterRequest("page=1"));
  }
  return (
    
    <main id="company" className="column">
      <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
      {loadScreen ? <Loader /> : <></>}
      <section>
        <ul className="bread_crumb">
            {breadCrumbs.map((breadCrumb, i) => (
              <li key={i}>
                <a href={breadCrumb.path}>{ breadCrumb.label }</a>
              </li>
            ))}
        </ul>
        <div className="ttl_box">
            <h2 className="ttl">コラム ＞ 一覧</h2>
            <ul>
                <li>
                    <a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt="" />ログアウト</a>
                </li>
            </ul>
        </div>
        <div className="columns jobs">
            <a href="/recruiter/columns/create/" className="btn type3 link">コラムを新規作成する</a>
            <div className="edit_box">
              <div>
                <div className="icon">
                </div>
                {/* <ul className="pagination">
                  <li className="arrow prev" >
                    <a><img src="/assets/img/company/icon06.svg" alt="次へ"/></a>
                  </li>
                  <li v-for="p in pages" :key="p" :className="{ active: page == p }">
                    <a href @click.prevent="page = p">{{ p }}</a>
                  </li>
                  <li className="arrow next" >
                    <a><img src="/assets/img/company/icon07.svg" alt="前へ" /></a>
                  </li>
                </ul> */}
                <ul className="pagination">
                  <li className={page <= 1 ? 'arrow prev disabled' : 'arrow prev'}>
                      <a onClick={pageDecriment}>
                          <img src="/assets/img/company/icon06.svg" alt="次へ" />
                      </a>
                  </li> 
                  {Array.from({ length: pages }, (_, i) => i + 1).map((p) => (
                      <li key={p} className={`page ${page === p ? 'active' : ''}`}>
                      <a href="#" onClick={() => updatePage(p)}>
                          <span>{p}</span>
                      </a>
                      </li>
                  ))}
                  <li className={page >= pages ? 'arrow next disabled' : 'arrow next'}>
                      <a onClick={pageIncriment}>
                          <img src="/assets/img/company/icon07.svg" alt="前へ" />
                      </a>
                  </li>
              </ul>
              </div>
              <ul className="category">
                <li className={activeSort === "all" ? "active" : ""}>
                  <a onClick={allSort}>
                  {activeSort === "all" ?
                  <img src="/assets/img/company/icon08_on.svg" alt="" /> 
                   :
                  <img src="/assets/img/company/icon08.svg" alt="" /> 
                  }
                    すべて
                  </a>
                </li>
                <li className={activeSort === "createdAt" ? "active" : ""}>
                  <a onClick={sortCreatedAt}>
                    {activeSort === "createdAt" ?
                      <img src="/assets/img/company/icon09_on.svg" alt="" />
                     :
                      <img src="/assets/img/company/icon09.svg" alt="" />
                     }
                     作成日順
                  </a>
                </li>
                <li className={activeSort === "viewer" ? "active" : ""}>
                  <a onClick={sortViewer}>
                  {activeSort === "viewer" ?
                  <img src="/assets/img/company/icon10_on.svg" alt="" />
                   :
                   <img src="/assets/img/company/icon10.svg" alt="" />
                  }
                     閲覧数
                  </a>
                </li>
              </ul>
              <ul className="list_box">
                {articles && articles.map((article: any, i: any) => (
                  <li key={i}>
                    <div>
                      <div className="cont1">
                        <div className="img_box">
                          <a href={'/recruiter/columns/edit/' + article.id} ><img src={article.eye_catch} alt=""/></a>
                        </div>
                        <div className="text_box">
                          <a href={'/recruiter/columns/edit/' + article.id}
                            ><h3>{ article.title }</h3>
                            <p>{ removeHtmlTag(article.content)  }</p></a>
                            {reserved(article) ? <span className="booked">予約投稿済み</span> : <></>}
                        </div>
                      </div>
                      <div className="cont2">
                        <p className="view">
                          {/* <img src="/assets/img/column/view.svg" alt="" /> */}
                          <span>{ article.view }</span>view
                        </p>    
                        <div className="date">
                          <p className="publish_date">公開日時：{formatDateTime(article.release_date)}</p>
                          <p>最終編集：{ formatDate(article.updated_at) }</p>
                        </div>
                        <select
                          name="status"
                          onChange={(e) => {updateStatus(i,article.id,e.target.value)}}
                        >
                          <option
                            value="draft"
                            selected={article.status == 'draft'}
                          >
                            非公開
                          </option>
                          <option
                            value="publish"
                            selected={article.status == 'publish'}
                          >
                            公開
                          </option>
                        </select>

                        <a href={'/recruiter/columns/edit/' + article.id} className="btn type3 edit" >編集</a>
                      <div onClick={() => duplicate(article)} className="btn type3 edit">複製</div>
                        <a href="javascript:void(0)" className="del" onClick={() => deleteArticle(article.id)}><img src="/assets/img/company/icon05.svg" alt="削除" /></a>
                      </div>
                    </div>
                  </li>
                  ))}
              </ul>
              {pages > 1 ?
              <div className="bottom">
                <div className="icon"></div>
                  <ul className="pagination">
                      <li className={page <= 1 ? 'arrow prev disabled' : 'arrow prev'}>
                          <a onClick={pageDecriment}>
                              <img src="/assets/img/company/icon06.svg" alt="次へ" />
                          </a>
                      </li> 
                      {Array.from({ length: pages }, (_, i) => i + 1).map((p) => (
                          <li key={p} className={`page ${page === p ? 'active' : ''}`}>
                          <a href="#" onClick={() => updatePage(p)}>
                              <span>{p}</span>
                          </a>
                          </li>
                      ))}
                      <li className={page >= pages ? 'arrow next disabled' : 'arrow next'}>
                          <a onClick={pageIncriment}>
                              <img src="/assets/img/company/icon07.svg" alt="前へ" />
                          </a>
                      </li>
                  </ul>
                </div>
                : <></>}
            </div>
        </div>
      </section>
    </main>
  );
  }
  
  export default ColumnIndex;