import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginRequest } from "../../../../redax/actions/loginActions/loginActions";
import { RootState } from "../../../../redax/reducers/rootReducer";
import MypageSideBar from "../../../../components/MypageSideBar/MypageSideBar";
import { fetchRegionsRequest } from "../../../../redax/actions/regionsActions/regionsActions";
import { fetchRecruiterRecruitmentsDetailRequest } from "../../../../redax/actions/recruiterRecruitmentsDetailActions/recruiterRecruitmentsDetailActions";
import { fetchCompaniesRequest } from "../../../../redax/actions/companiesActions/companiesActions";
import SwiperCore, {
  Navigation,
  Pagination,
  FreeMode,
  Thumbs,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./detail.scss";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../../../styles/style.css";
import { fetchRecruitmentsStepTwoRequest } from "../../../../redax/actions/recruitmentsStepTwoActions/recruitmentsStepTwoActions";
import { fetchPostCodeRequest } from "../../../../redax/actions/postcodeActions/postcodeActions";
import React from "react";
import Loader from "../../../loader/loader";
import { fetchOpenAiRequest } from "../../../../redax/actions/openAiActions/openAiActions";
import { fetchOpenAiGoogleRequest } from "../../../../redax/actions/openGoogleAiActions/openGoogleAiActions";
import Icon from "@mdi/react";
import { mdiCloseCircle } from "@mdi/js";
import { GOOGLE_SHEET_KEY } from "../../../../redax/API";
import { fetchMatomeListRequest } from "../../../../redax/actions/matomeListActions/matomeListActions";

interface RecruitmentImage {
  url: string;
}

function RecruitmentsDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [familyName, setFamylyName] = useState(null);
  const [resourceId, setResourceId] = useState(null);
  const [jobCategories, setJobCategories] = useState<any>([]);
  const [jobCategoryTypes, setJobCategoryTypes] = useState<any>([]);
  const [holidays, setHolidays] = useState([]);
  const [vacations, setVacations] = useState([]);
  const [salaryForms, setSalaryForms] = useState<any>([]);
  const [allowances, setAllowances] = useState<any>([]);
  const [socialInsurances, setSocialInsurances] = useState<any>([]);
  const [selectionMethods, setSelectionMethods] = useState<any>([]);
  const [schedules, setSchedules] = useState<any[]>([]);
  const [region, setRegion] = useState<any[]>([]);
  const [handicapTypes, setHandicapTypes] = useState<any[]>([]);
  const [considerationConditions, setConsiderationConditions] = useState<any[]>(
    []
  );
  const [selectedMatomeIds, setSelectedMatomeIds] = useState<number[]>([]); 

  const [selectedConsiderationConditions, setSelectedConsiderationConditions] =
    useState<any[]>([]);
  const [
    selectedConsiderationConditionsId,
    setSelectedConsiderationConditionsId,
  ] = useState<any[]>([]);
  const [idealCandidatesUnique, setIdealCandidatesUnique] = useState<any>([]);
  const [appealItems, setAppealItems] = useState<any[]>([]);
  const [appeal_item, setAppeal_item] = useState<any>([]);
  const [appeal_item_1, setAppeal_item_1] = useState<any>(null);
  const [appeal_item_2, setAppeal_item_2] = useState<any>(null);
  const [appeal_item_3, setAppeal_item_3] = useState<any>(null);
  const [appeal_item_1_name, setAppeal_item_1_name] = useState<any>(null);
  const [appeal_item_2_name, setAppeal_item_2_name] = useState<any>(null);
  const [appeal_item_3_name, setAppeal_item_3_name] = useState<any>(null);
  const [considerationConditionTypes, setConsiderationConditionTypes] =
    useState<any[]>([]);
  const [workEnvironments, setWorkEnvironments] = useState<any[]>([]);
  const [typeOfRecruitingCompanies, setTypeOfRecruitingCompanies] =
    useState<any>([]);
  const [masterWorkFromHome, setMasterWorkFromHome] = useState<any[]>([]);
  const { id } = useParams();
  const [recruiter, setRecruiter] = useState(null);
  const [isCanCreate, setIsCanCreate] = useState(false);
  const [is_new_publish, setIsPublish] = useState(false);
  const [is_new_update, setIsUpdate] = useState(false);
  const [already_applied, setAlreadyApplied] = useState(false);
  const [recruitmentsDetail, setRecruitmentsDetail] = useState([]);
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [isEditJobCategory, setIsEditJobCategory] = useState(false);
  const [isEditBusinessContent, setIsEditBusinessContent] = useState(false);
  const [isEditRequiredExperience, setIsEditRequiredExperience] =
    useState(false);
  const [isEditTypeOfRecruitingCompany, setIsEditTypeOfRecruitingCompany] =
    useState(false);
  const [isErrorBusinessContent, setIsErrorBusinessContent] = useState(false);
  const [isErrorRequiredExperience, setIsErrorRequiredExperience] =
    useState(false);
  const [
    isErrorContractPeriodSupplemental,
    setIsErrorContractPeriodSupplemental,
  ] = useState(false);
  const [isErrorTrialPeriodSupplemental, setIsErrorTrialPeriodSupplemental] =
    useState(false);
  const [isErrorWorkLocationSupplemental, setIsErrorWorkLocationSupplemental] =
    useState(false);
  const [isErrorWorkTimeSupplemental, setIsErrorWorkTimeSupplemental] =
    useState(false);
  const [isErrorRestTimeSupplemental, setIsErrorRestTimeSupplemental] =
    useState(false);
  const [
    isErrorNumberOfWorkingDaysSupplemental,
    setIsErrorNumberOfWorkingDaysSupplemental,
  ] = useState(false);
  const [
    isErrorOverTimeHoursSupplemental,
    setIsErrorOverTimeHoursSupplemental,
  ] = useState(false);
  const [isErrorHolidaysSupplemental, setIsErrorHolidaysSupplemental] =
    useState(false);
  const [isErrorAllowancesSupplemental, setIsErrorAllowancesSupplemental] =
    useState(false);
  const [isErrorBenefitSupplemental, setIsErrorBenefitSupplemental] =
    useState(false);
  const [
    isErrorSelectionMethodSupplemental,
    setIsErrorSelectionMethodSupplemental,
  ] = useState(false);
  const [isErrorPreviewDisplayText0, setIsErrorPreviewDisplayText0] =
    useState(false);
  const [isErrorPreviewDisplayText1, setIsErrorPreviewDisplayText1] =
    useState(false);
  const [isErrorPreviewDisplayText2, setIsErrorPreviewDisplayText2] =
    useState(false);
  const [isErrorPreviewDisplayText3, setIsErrorPreviewDisplayText3] =
    useState(false);
  const [job_title, setJob_title] = useState('');
  const [findValue1, setFindValue1] = useState(null);
  const [business_content, setBusiness_content] = useState<any>(null);
  const [typeOfRecruitingCompanyId, setTypeOfRecruitingCompanyId] =
    useState(null);
  const [employmentStatusId, setEmploymentStatusId] = useState(null);
  const [trial_period_id, setTrial_period_id] = useState(null);
  const [required_experience, setRequired_experience] = useState<any>(null);
  const [images, setImages] = useState<any[]>([]);
  const [comment, setComment] = useState("");
  const [show, setShow] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const matomeList = useSelector((state: RootState) => state.matomeList);
  const [fullArray, setFullArray] = useState<any[]>([]);

  const [appeal_recruiter_items_corporate_culture, setCorporateCulture] =
    useState("");
  const [appeal_recruiter_items_business_flow, setBusinessFlow] = useState("");
  const [appeal_recruiter_items_superior_message, setSuperiorMessage] =
    useState("");
  const [appeal_recruiter_items_career_path, setCareerPath] = useState("");
  const [
    appeal_recruiter_items_consideration_condition,
    setConsiderationCondition,
  ] = useState("");
  const [appeal_recruiter_items_hr_message, setHrMessage] = useState("");
  const [movie_path, setMoviePath] = useState("");
  const [movie_path2, setMoviePath2] = useState("");
  const [isEditMoviePath, setIsEditMoviePath] = useState(false);
  const [recruitment, setRecruitment] = useState<any[]>([]);
  const [isDisplay0, setIsDisplay0] = useState(null);
  const [isDisplay1, setIsDisplay1] = useState(null);
  const [isDisplay2, setIsDisplay2] = useState(null);
  const [isDisplay3, setIsDisplay3] = useState(null);
  const [isDisplay4, setIsDisplay4] = useState(null);
  const [isDisplay5, setIsDisplay5] = useState(null);
  const [company_name, setCompanyName] = useState(null);
  const [company_id, setCompanyId] = useState(null);
  const [selectedJobTypeId, setSelectedJobTypeId] = useState<any>('');
  const [selectedJobCategoryId, setSelectedJobCategoryId] = useState<any>('');
  const [job_category, setJob_category] = useState(null);
  const [jobCategoriesName, setJobCategoriesName] = useState(null);
  const [jobCategoriesArr, setJobCategoriesArr] = useState<any>([]);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [selectedAppeal, setSelectedAppeal] = useState<any>([]);
  const [previewModal, setPreviewModal] = useState(false);
  const [swiperKey, setSwiperKey] = useState(3);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imagesAdvices = [
    "例1）採用ターゲットに近い社員の写真",
    "例2）会社や店舗の雰囲気が伝わる写真",
    "例3）自社のサービスや商品の画像",
    "例4）一緒に働く社員の顔写真",
    "例5）社員旅行・社内イベントなどの写真",
  ];

  const appeal_recruiter_items = [
    "社風・職場の雰囲気★AIによるサンプル文生成機能有り★",
    "1日の業務の流れ★AIによるサンプル文生成機能有り★",
    "先輩社員からのコメント★AIによるサンプル文生成機能有り★",
    "キャリアパス",
    "現場での合理的配慮例",
    "採用担当メッセージ★AIによるサンプル文生成機能有り★",
    "アピール動画",
  ];
  let txt1 =
    "【以下定型文】\n私たちの職場は〇〇な人が多く、職場の雰囲気を一言でいうと〇〇です。\n例えば、こんなイベント・制度があります。\n会社としても〇〇を大切にしています。";
  let txt2 =
    "【以下定型文】\n【出社後】\nまず、〇〇を行います。\n〈1日の主な業務〉・・・\n【休憩】\n〇〇:〇〇〜〇〇:〇〇の間が多いです。\n【退勤前】\n終業前は〇〇を行い、業務終了です。";
  let txt3 =
    "【以下定型文】\n例：\n「弊社は〇〇な会社で、〇〇なところは大変ですが、〇〇なところがやりがいです。\n私はこの業務に携わっていから〇〇のスキルを身につけることができました。\n〇〇な人とぜひ働きたいので、ご応募お待ちしています。」\n（Aさん/入社〇〇年目・〇〇職）";
  let txt4 =
    "【以下定型文】\n入社当初は主に〇〇の業務を行っていただきます。\nその後、職場の状況や適正によって〇〇の仕事を徐々にお任せしていきます。\n評価面接等は年○回です。";
  let txt5 =
    "【以下定型文】\n例：\n弊社では〇〇に障がいがある方には、「」のような配慮や\n現場でのサポートが可能です。";
  let txt6 = "【以下定型文】\n例：";
  // let changeImage1: null;
  // let changeImage2: null;
  // let changeImage3: null;
  // let changeImage4: null;
  // let changeImage5: null;
  let updateBtn = 1;
  let publishBtn = 2;
  let jobTitleMaxLen = 20;
  let businessContentMaxLen = 400;
  let requiredExperienceMaxLen = 200;
  let contractPeriodSupplementalMaxLen = 200;
  let trialPeriodSupplementalMaxLen = 200;
  let workLocationSupplementalMaxLen = 200;
  let workTimeSupplementalMaxLen = 200;
  let restTimeSupplementalMaxLen = 200;
  let numberOfWorkingDaysSupplementalMaxLen = 200;
  let holidaysSupplementalMaxLen = 200;
  let overtimeHoursSupplementalMaxLen = 200;
  let allowancesSupplementalMaxLen = 200;
  let benefitSupplementalMaxLen = 200;
  let selectionMethodSupplementalMaxLen = 400;
  let previewDisplayTextsMaxLen = 20;
  let appealRecruiterItemsCorporateCultureMaxLen = 400;
  let appealRecruiterItemsBusinessFlowMaxLen = 400;
  let appealRecruiterItemsSuperiorMessageMaxLen = 400;
  let appealRecruiterItemsCareerPathMaxLen = 400;
  let appealRecruiterItemsConsiderationConditionMaxLen = 400;
  let appealRecruiterItemsHrMessageMaxLen = 400;
  let maxCheckCount = 4;
  let currentSlideNumber = 0;
  let notEnteredString = "";
  const [jobTitleMaxLength, setJobTitleMaxLength] = useState(false);
  const [businessContentMaxLength, setBusinessContentMaxLength] =
    useState(false);
  const [requiredExperienceMaxLength, SetRequiredExperienceMaxLength] =
    useState(false);
  const [
    contractPeriodSupplementalMaxLength,
    setContractPeriodSupplementalMaxLength,
  ] = useState(false);
  const [
    trialPeriodSupplementalMaxLength,
    setTrialPeriodSupplementalMaxLength,
  ] = useState(false);
  const [
    workLocationSupplementalMaxLength,
    setWorkLocationSupplementalMaxLength,
  ] = useState(false);
  const [workTimeSupplementalMaxLength, setWorkTimeSupplementalMaxLength] =
    useState(false);
  const [restTimeSupplementalMaxLength, setRestTimeSupplementalMaxLength] =
    useState(false);
  const [
    numberOfWorkingDaysSupplementalMaxLength,
    setNumberOfWorkingDaysSupplementalMaxLength,
  ] = useState(false);
  const [holidaysSupplementalMaxLength, setHolidaysSupplementalMaxLength] =
    useState(false);
  const [
    overTimeHoursSupplementalMaxLength,
    setOverTimeHoursSupplementalMaxLength,
  ] = useState(false);
  const [allowancesSupplementalMaxLength, setAllowancesSupplementalMaxLength] =
    useState(false);
  const [benefitSupplementalMaxLength, setBenefitSupplementalMaxLength] =
    useState(false);
  const [
    selectionMethodSupplementalMaxLength,
    setSelectionMethodSupplementalMaxLength,
  ] = useState(false);
  const [previewDisplayText0MaxLength, setPreviewDisplayText0MaxLength] =
    useState(false);
  const [previewDisplayText1MaxLength, setPreviewDisplayText1MaxLength] =
    useState(false);
  const [previewDisplayText2MaxLength, setPreviewDisplayText2MaxLength] =
    useState(false);
  const [previewDisplayText3MaxLength, setPreviewDisplayText3MaxLength] =
    useState(false);
  const [
    appealRecruiterItemsCorporateCultureMaxLength,
    setAppealRecruiterItemsCorporateCultureMaxLength,
  ] = useState(false);
  const [
    appealRecruiterItemsBusinessFlowMaxLength,
    setAppealRecruiterItemsBusinessFlowMaxLength,
  ] = useState(false);
  const [
    appealRecruiterItemsSuperiorMessageMaxLength,
    setAppealRecruiterItemsSuperiorMessageMaxLength,
  ] = useState(false);
  const [
    appealRecruiterItemsCareerPathMaxLength,
    setAppealRecruiterItemsCareerPathMaxLength,
  ] = useState(false);
  const [
    appealRecruiterItemsConsiderationConditionMaxLength,
    setAppealRecruiterItemsConsiderationConditionMaxLength,
  ] = useState(false);
  const [
    appealRecruiterItemsHrMessageMaxLength,
    setAppealRecruiterItemsHrMessageMaxLength,
  ] = useState(false);
  const [isApiLoadingCorporateCulture, setIsApiLoadingCorporateCulture] =
    useState(false);
  const [isApiLoadingHrMessage, setIsApiLoadingHrMessage] = useState(false);
  const [isApiLoadingBusinessFlow, setIsApiLoadingBusinessFlow] =
    useState(false);
  const [isApiLoadingSuperiorMessage, setIsApiLoadingSuperiorMessage] =
    useState(false);
  const [mySelected, setMySelected] = useState<any[]>([]);
  const [mySelectedAllowancesId, setMySelectedAllowancesId] = useState<any[]>(
    []
  );
  const [isPerson, setIsPerson] = useState(false);
  const [isHandicap, setIsHandicap] = useState(false);
  const [recruitmentCompatibilities, setRecruitmentCompatibilities] = useState<
    any[]
  >([]);
  const [
    recruitmentConsiderationConditions,
    setRecruitmentConsiderationConditions,
  ] = useState<any>([]);
  const [isOpenList, setIsOpenList] = useState<boolean[]>([]);
  const [isOpenListChanged, setIsOpenListChanged] = useState<any[]>([]);
  const [isWorkEnvironment, setIsWorkEnvironment] = useState(false);
  const [environmentsText, setEnvironmentsText] = useState("");
  const [recruitmentWorkEnvironments, setRecruitmentWorkEnvironments] =
    useState<any[]>([]);
  const [recruitmentWorkEnvironmentsText, setRecruitmentWorkEnvironmentsText] =
    useState<any[]>([]);
  const [inputText, setInputText] = useState(null);
  const [isEditEmploymentStatus, setIsEditEmploymentStatus] = useState(false);
  const [allowBlank, setAllowBlank] = useState(false);
  const [masterEmploymentStatus, setMasterEmploymentStatus] = useState<any>([]);
  const [
    recruitmentPeriodContractPeriodExists,
    setRecruitmentPeriodContractPeriodExists,
  ] = useState(false);
  const [
    recruitmentPeriodContractRenewalExists,
    setRecruitmentPeriodContractRenewalExists,
  ] = useState(false);
  const [
    recruitmentPeriodTrialPeriodExists,
    setRecruitmentPeriodTrialPeriodExists,
  ] = useState<any>(null);
  const [recruitmentEmploymentStatusId, setRecruitmentEmploymentStatusId] =
    useState(null);
  const [
    recruitmentPeriodContractPeriodId,
    setRecruitmentPeriodContractPeriodId,
  ] = useState(null);
  const [selectedTrailPeriod, setSelectedTrailPeriod] = useState(null);
  const [isEditContractPeriodExists, setIsEditContractPeriodExists] =
    useState(true);
  const [isEditContractPeriod, setIsEditContractPeriod] = useState(false);
  const [masterContractPeriod, setMasterContractPeriod] = useState<any>([]);
  const [isEditContractRenewalExists, setIsEditContractRenewalExists] =
    useState(false);
  const [
    isEditContractPeriodSupplemental,
    setIsEditContractPeriodSupplemental,
  ] = useState(false);
  const [isContractPeriodShow, setIsContractPeriodShow] = useState(false);
  const [
    recruitmentPeriodContractPeriodSupplement,
    setRecruitmentPeriodContractPeriodSupplement,
  ] = useState();
  const [
    recruitmentPeriodTrialPeriodSupplement,
    setRecruitmentPeriodTrialPeriodSupplement,
  ] = useState();
  const [isEditTrialPeriodExists, setIsEditTrialPeriodExists] = useState(false);
  const [isEditTrialPeriod, setIsEditTrialPeriod] = useState(false);
  const [masterTrialPeriod, setMasterTrialPeriod] = useState<any>([]);
  const [isEditFullTimeEmployeeExists, setIsEditFullTimeEmployeeExists] =
    useState(false);
  const [isEditWorkFromHome, setIsEditWorkFromHome] = useState(false);
  const [
    recruitmentPeriodFullTimeEmployeeExists,
    setRecruitmentPeriodFullTimeEmployeeExists,
  ] = useState(false);
  const [
    recruitmentWorkLocatioinWorkFromHomID,
    setRecruitmentWorkLocatioinWorkFromHomID,
  ] = useState();
  const [isEditDepartment, setIsEditDepartment] = useState(false);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [isEditCityCorrection, setIsEditCityCorrection] = useState<any>(true);
  const [cityCorrection, setCityCorrection] = useState(null);
  const [isEditNearestStation, setIsEditNearestStation] = useState(false);
  const [isEditTransportation, setIsEditTransportation] = useState(false);
  const [isEditNationwide, setIsEditNationwide] = useState(false);
  const [isEditWorkLocationSupplemental, setIsEditWorkLocationSupplemental] =
    useState(false);
  const [isEditWorkTime, setIsEditWorkTime] = useState(false);
  const [workTimes, setWorkTimes] = useState<any>([]);
  const [isEditNumberOfWorkingDays, setIsEditNumberOfWorkingDays] =
    useState(false);
  const [isEditOvertimeHoursExists, setIsEditOvertimeHoursExists] =
    useState(false);
  const [isOvertimeHoursExists, setIsOvertimeHoursExists] = useState(false);
  const [isEditHolidays, setIsEditHolidays] = useState(false);
  const [isEditVacations, setIsEditVacations] = useState(false);
  const [isEditHolidaysSupplemental, setIsEditHolidaysSupplemental] =
    useState(false);
  const [isEditSalaryForm, setIsEditSalaryForm] = useState(false);
  const [isEditMonthlyBasicSalary, setIsEditMonthlyBasicSalary] =
    useState(false);
  const [isEditAllowances, setIsEditAllowances] = useState(false);
  const [isEditAllowancesSupplemental, setIsEditAllowancesSupplemental] =
    useState(false);
  const [isEditAnnualNumberOfBonus, setIsEditAnnualNumberOfBonus] =
    useState(false);
  const [isEditEstimatedAnnualIncome, setIsEditEstimatedAnnualIncome] =
    useState(false);
  const [isAnnualIncomeShow, setIsAnnualIncomeShow] = useState(true);
  const [isArea, setIsArea] = useState(false);
  const [isClassification, setIsClassification] = useState(false);
  const [isEditTrialPeriodSupplemental, setIsEditTrialPeriodSupplemental] =
    useState(false);
  const [isEditWorkTimeSupplemental, setIsEditWorkTimeSupplemental] =
    useState(false);
  const [isEditRestTime, setIsEditRestTime] = useState(false);
  const [isEditRestTimeSupplemental, setIsEditRestTimeSupplemental] =
    useState(false);
  const [
    isEditNumberOfWorkingDaysSupplemental,
    setIsEditNumberOfWorkingDaysSupplemental,
  ] = useState(false);
  const [isEditOvertimeHoursSupplemental, setIsEditOvertimeHoursSupplemental] =
    useState(false);
  const [isEditAnnualHoliday, setIsEditAnnualHoliday] = useState(false);
  const [isEditAnnualNumberOfRaises, setIsEditAnnualNumberOfRaises] =
    useState(false);
  const [isEditRetirementAge, setIsEditRetirementAge] = useState(false);
  const [isEditBenefitSupplemental, setIsEditBenefitSupplemental] =
    useState(false);
  const [
    isEditSelectionMethodSupplemental,
    setIsEditSelectionMethodSupplemental,
  ] = useState(false);
  const [isEditNumberOfEmployees, setIsEditNumberOfEmployees] = useState(false);
  const [businessContentMaxLenError, setBusinessContentMaxLenError] =
    useState(null);
  const [requiredExperienceMaxLenError, setRequiredExperienceMaxLenError] =
    useState(null);
  const [
    contractPeriodSupplementalMaxLenError,
    setContractPeriodSupplementalMaxLenError,
  ] = useState(null);
  const [
    trialPeriodSupplementalMaxLenError,
    setTrialPeriodSupplementalMaxLenError,
  ] = useState(null);
  const [
    workLocationSupplementalMaxLenError,
    setWorkLocationSupplementalMaxLenError,
  ] = useState(null);
  const [workTimeSupplementalMaxLenError, setWorkTimeSupplementalMaxLenError] =
    useState(null);
  const [restTimeSupplementalMaxLenError, setRestTimeSupplementalMaxLenError] =
    useState(null);
  const [
    overTimeHoursSupplementalMaxLenError,
    setOverTimeHoursSupplementalMaxLenError,
  ] = useState(null);
  const [holidaysSupplementalMaxLenError, setHolidaysSupplementalMaxLenError] =
    useState(null);
  const [
    allowancesSupplementalMaxLenError,
    setAllowancesSupplementalMaxLenError,
  ] = useState(null);
  const [benefitSupplementalMaxLenError, setBenefitSupplementalMaxLenError] =
    useState(null);
  const [
    numberOfWorkingDaysSupplementalMaxLenError,
    setNumberOfWorkingDaysSupplementalMaxLenError,
  ] = useState(null);
  const [
    selectionMethodSupplementalMaxLenError,
    setSelectionMethodSupplementalMaxLenError,
  ] = useState(null);
  const [previewDisplayText0MaxLenError, setPreviewDisplayText0MaxLenError] =
    useState(null);
  const [previewDisplayText1MaxLenError, setPreviewDisplayText1MaxLenError] =
    useState(null);
  const [previewDisplayText2MaxLenError, setPreviewDisplayText2MaxLenError] =
    useState(null);
  const [previewDisplayText3MaxLenError, setPreviewDisplayText3MaxLenError] =
    useState(null);
  const [
    recruitmentPeriodContractPeriodSupplemental,
    setRecruitmentPeriodContractPeriodSupplemental,
  ] = useState<any>(null);
  const [selectedWorkFromHome, setSelectedWorkFromHom] = useState(null);  
  const [
    recruitmentWorkLocationDepartment,
    setRecruitmentWorkLocationDepartment,
  ] = useState("");
  const [prefectureName, setPrefectureName] = useState("");
  const [prefectureId, setPrefectureId] = useState(null);
  const [postCode, setPostCode] = useState(null);
  const [address, setAddress] = useState("");
  const [cityName, setCityName] = useState("");
  const [cityId, setCityId] = useState(null);
  const [nearestStation, setNearestStation] = useState("");
  const [transportation, setTransportation] = useState("");
  const [nationWide, setNationWide] = useState(false);
  const [workLocationSupplemental, setWorkLocationSupplemental] = useState("");
  const hourList = Array.from(Array(24), (_, i) => ("00" + i).slice(-2));
  const minuteList = Array.from(Array(12), (_, i) => ("00" + i * 5).slice(-2));
  const [startTime1Hour, setStartTime1Hour] = useState(null);
  const [startTime1Minute, setStartTime1Minute] = useState(null);
  const [startTime2Hour, setStartTime2Hour] = useState(null);
  const [startTime2Minute, setStartTime2Minute] = useState(null);
  const [startTime3Hour, setStartTime3Hour] = useState(null);
  const [startTime3Minute, setStartTime3Minute] = useState(null);
  const [endTime1Hour, setEndTime1Hour] = useState(null);
  const [endTime1Minute, setEndTime1Minute] = useState(null);
  const [endTime2Hour, setEndTime2Hour] = useState(null);
  const [endTime2Minute, setEndTime2Minute] = useState(null);
  const [endTime3Hour, setEndTime3Hour] = useState(null);
  const [endTime3Minute, setEndTime3Minute] = useState(null);
  const [startTime1, setStartTime1] = useState<any>(null);
  const [startTime2, setStartTime2] = useState<any>(null);
  const [startTime3, setStartTime3] = useState<any>(null);
  const [endTime1, setEndTime1] = useState<any>(null);
  const [endTime2, setEndTime2] = useState<any>(null);
  const [endTime3, setEndTime3] = useState<any>(null);
  const [workTimeSupplemental, setWorkTimeSupplemental] = useState("");
  const [restTime, setRestTime] = useState("");
  const [restTimeSupplemental, setRestTimeSupplemental] = useState("");
  const [numberOfWorkingDays, setNumberOfWorkingDays] = useState(null);
  const [numberOfWorkingDaysSupplemental, setNumberOfWorkingDaysSupplemental] =
    useState("");
  const [overTimeHoursSupplemental, setOverTimeHoursSupplemental] =
    useState("");
  const [checkedHolidays, setCheckedHolidays] = useState<any[]>([]);
  const [annualHoliday, setAnnualHoliday] = useState(null);
  const [checkedVacations, setCheckedVacations] = useState<any[]>([]);
  const [holidaysSupplemental, setHolidaysSupplemental] = useState("");
  const [salaryFormId, setSalaryFormId] = useState(null);
  const [lowerMonthlyBasicSalary, setLowerMonthlyBasicSalary] = useState(null);
  const [upperMonthlyBasicSalary, setUpperMonthlyBasicSalary] = useState(null);
  const [allowancesSupplemental, setAllowancesSupplemental] = useState("");
  const [annualNumberOfBonusExists, setAnnualNumberOfBonusExists] =
    useState(false);
  const [isEditAnnualNumberOfBonusExists, setIsEditAnnualNumberOfBonusExists] =
    useState(false);
  const [annualNumberOfBonus, setAnnualNumberOfBonus] = useState("");
  const [lowerEstimatedAnnualIncome, setLowerEstimatedAnnualIncome] =
    useState(null);
  const [upperEstimatedAnnualIncome, setUpperEstimatedAnnualIncome] =
    useState(null);
  const [
    isEditAnnualNumberOfRaisesExists,
    setIsEditAnnualNumberOfRaisesExists,
  ] = useState(false);
  const [annualNumberOfRaisesExists, setAnnualNumberOfRaisesExists] =
    useState(false);
  const [annualNumberOfRaises, setAnnualNumberOfRaises] = useState("");
  const [isEditSocialInsurances, setIsEditSocialInsurances] = useState(false);
  const [checkedSocialInsurances, setCheckedSocialInsurances] = useState<any[]>(
    []
  );
  const [isEditRetirementMoneyExists, setIsEditRetirementMoneyExists] =
    useState(false);
  const [isRetirementMoneyExistsVal, setIsRetirementMoneyExistsVal] =
    useState(false);
  const [retirementAge, setRetirementAge] = useState(null);
  const [benefitSupplemental, setBenefitSupplemental] = useState("");
  const [isEditSelectionMethods, setIsEditSelectionMethods] = useState(false);
  const [checkedSelectionMethods, setCheckedSelectionMethods] = useState<any[]>(
    []
  );
  const [selectionMethodSupplemental, setSelectionMethodSupplemental] =
    useState("");
  const [numberOfEmployees, setNumberOfEmployees] = useState(null);
  const [previewDisplayText0, setPreviewDisplayText0] = useState<any>(null);
  const [previewDisplayText1, setPreviewDisplayText1] = useState<any>(null);
  const [previewDisplayText2, setPreviewDisplayText2] = useState<any>(null);
  const [previewDisplayText3, setPreviewDisplayText3] = useState<any>(null);
  const [previewDisplayText, setPreviewDisplayText] = useState<any[]>([]);
  const [previewDisplayTexts, setPreviewDisplayTexts] = useState<any[]>([]);
  const [newGraduate, setNewGraduate] = useState(false);
  const [scheduleId, setScheduleId] = useState(null);
  const [prefectures, setPrefectures] = useState<any[]>([]);
  const [selectedPrefectures, setSelectedPrefectures] = useState<any[]>([]);
  const [masterPrefectures, setMasterPrefectures] = useState<any[]>([]);
  const [mySelectedPrefectures, setMySelectedPrefectures] = useState<any[]>([]);
  const [recruitmentHandicapTypes, setRecruitmentHandicapTypes] = useState<
    any[]
  >([]);
  const [selectHandicapTypesIds, setSelectHandicapTypesIds] = useState<any[]>(
    []
  );
  const [appealitems1, setappealitems1] = useState<any>(null);
  const [appealitems2, setappealitems2] = useState<any>(null);
  const [appealitems3, setappealitems3] = useState<any>(null);
  const [currentStatusName, setCurrentStatusName] = useState(null);
  const [updateStatuses, setUpdateStatuses] = useState<any[]>([]);
  const [currentStatusId, setCurrentStatusId] = useState(null);
  const [onChangeUpdate, setOnChangeUpdate] = useState(false);
  const [statusId, setStatusId] = useState<any>(null);
  const [loadScreen, setLoadScreen] = useState(true);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [editCorporateCultureText, setEditCorporateCultureText] =
    useState(false);
  const [editBusinessFlow, setEditBusinessFlow] = useState(false);
  const [editSuperiorMessage, setEditSuperiorMessage] = useState(false);
  const [editCareerPath, setEditCareerPath] = useState(false);
  const [editConsiderationConditionText, setEditConsiderationConditionText] =
    useState(false);
  const [editHrMessage, setEditHrMessage] = useState(false);
  const [editMoviePath, setEditMoviePath] = useState(false);
  const [btn, setBtn] = useState(false);
  const [lostPost, setLoadPost] = useState(false);
  const [edited, setEdited] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [states, setStates] = useState(0);
  const [publishLimit, setPublishLimit] = useState<any>(null);
  const [isUpdateButtonClicked, setIsUpdateButtonClicked] = useState(false);

  let imagesData = {};
  let param = window.location.pathname.split("/recruiter/recruitments/");
  let recruitmentId = param[1].split("/")[0];
  document.title = "求人投稿・管理";
  useEffect(() => {
    if (localStorage.getItem("jobParachannelAuthToken")) {
      // dispatch(fetchLoginRequest('', '',''));
      dispatch(fetchMatomeListRequest())

      if (!edited) return;
      window.addEventListener("beforeunload", confirmSave);
    } else {
      navigate("/");
    }
    return () => window.removeEventListener("beforeunload", confirmSave);
  }, [firstLoad, edited]);
  function confirmSave(event: any) {
    event.preventDefault();
    if (edited) {
      event.returnValue =
        "編集中のデータは保存されていませんが、移動しますか？";
    }
  }

  const loginData = useSelector((state: RootState) => state.login);
  const regions = useSelector((state: RootState) => state.regions);
  const recruiterRecruitmentsDetail = useSelector(
    (state: RootState) => state.recruiterRecruitmentsDetail
  );
  const companies = useSelector((state: RootState) => state.companies);
  const recruitmentsStepTwoData = useSelector(
    (state: RootState) => state.recruitmentsStepTwo
  );
  const { postcode } = useSelector((state: RootState) => state.postcode);
  const scoutAlert = useSelector((state: RootState) => state.scoutAlert);
  const openAi = useSelector((state: RootState) => state.openAi);
  const openAiGoogle = useSelector((state: RootState) => state.historyGoogle);
  let notEqualList: any[] = [];

  useEffect(() => {
    if (loginData.pending) {
      setResourceId(loginData.login.resource.id);
      setFamylyName(loginData.login.resource.family_name);
      setRecruiter(loginData.login.resource);
      setIsCanCreate(loginData.login.is_can_create);
      setPublishLimit(loginData.login.publish_recruitments_count);
      dispatch(fetchRegionsRequest());
      dispatch(fetchRecruiterRecruitmentsDetailRequest(id));
      dispatch(fetchCompaniesRequest(loginData.login.company.id, "get", null));
    }
  }, [loginData.pending]);

  useEffect(() => {
    if (recruiterRecruitmentsDetail.pending && !lostPost) {
      console.log(recruiterRecruitmentsDetail);
      setSelectedMatomeIds(recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.handicap_genre_ids);
      setRecruitmentsDetail(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
      );
      setAppeal_item(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.appeal_item
      );
      setAppeal_item_1(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.appeal_item
          .appeal_item_1.id
      );
      setAppeal_item_2(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.appeal_item
          .appeal_item_2.id
      );
      setAppeal_item_3(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.appeal_item
          .appeal_item_3.id
      );
      setAppeal_item_1_name(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.appeal_item
          .appeal_item_1.name
      );
      setAppeal_item_2_name(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.appeal_item
          .appeal_item_2.name
      );
      setAppeal_item_3_name(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.appeal_item
          .appeal_item_3.name
      );
      for (let i = 0; i < 3; i++) {
        if (i == 0) {
          setAppeal_item((prevItems: any) => {
            return {
              ...prevItems,
              appeal_item_id_1:
                recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
                  .appeal_item.appeal_item_1.id,
            };
          });
        } else if (i == 1) {
          setAppeal_item((prevItems: any) => {
            return {
              ...prevItems,
              appeal_item_id_2:
                recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
                  .appeal_item.appeal_item_2.id,
            };
          });
        } else {
          setAppeal_item((prevItems: any) => {
            return {
              ...prevItems,
              appeal_item_id_3:
                recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
                  .appeal_item.appeal_item_3.id,
            };
          });
        }
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .consideration_conditions !== null
      ) {
        setSelectedConsiderationConditionsId(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
            .consideration_conditions
        );
        setSelectedConsiderationConditions(
          considerationConditions.filter((condition) =>
            recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.consideration_conditions.includes(
              condition.id
            )
          )
        );
      }
      setJob_title(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.job_title
      );
      setBusiness_content(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.business_content
      );
      setTrial_period_id(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.trial_period_id
      );
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .type_of_recruiting_company_id !== null
      ) {
        setTypeOfRecruitingCompanyId(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
            .type_of_recruiting_company_id
        );
      }
      setRequired_experience(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .required_experience
      );
      // setImages(recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.images);
      setCompanyName(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.company_name
      );
      setJob_category(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.job_category_id
      );
      setRecruitmentCompatibilities(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.compatibilities
      );
      setRecruitmentWorkEnvironments(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .work_environments
      );
      setRecruitmentWorkEnvironmentsText(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .work_environment_text
      );
      setRecruitmentEmploymentStatusId(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .employment_status_id
      );
      if(recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.employment_status_id != null) {
        setIsContractPeriodShow(recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.employment_status_id == 1 ? false : true);
      }
      setRecruitmentPeriodContractPeriodExists(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.period
          .contract_period_exists
      );
      setRecruitmentPeriodContractRenewalExists(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.period
          .contract_renewal_exists
      );
      setRecruitmentPeriodContractPeriodId(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.period
          .contract_period_id
      );
      setRecruitmentPeriodFullTimeEmployeeExists(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.period
          .full_time_employee_exists
      );
      setRecruitmentPeriodContractPeriodSupplemental(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.period
          .contract_period_supplemental
      );
      setPrefectureId(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .prefecture_id
      );
      setPrefectureName(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .prefecture_name
      );
      setPostCode(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .postcode
      );
      setAddress(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .address
      );
      setCityId(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .city_id
      );
      setCityName(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .city_name
      );
      setSelectedJobCategoryId(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.job_category_id
      );
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .nearest_station
      ) {
        setNearestStation(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
            .nearest_station
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.period
          .trial_period_exists !== null
      ) {
        setRecruitmentPeriodTrialPeriodExists(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.period
            .trial_period_exists
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .transportation !== null
      ) {
        setTransportation(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
            .transportation
        );
      }
      setNationWide(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .nationwide == null ? false : recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .nationwide 
      );
      setCityCorrection(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .city_name
      );
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
          .rest_time !== null
      ) {
        setRestTime(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
            .rest_time
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
          .rest_time_supplemental !== null
      ) {
        setRestTimeSupplemental(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
            .rest_time_supplemental
        );
      }
      setNumberOfWorkingDays(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
          .number_of_working_days
      );
      setSelectedWorkFromHom(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .work_from_home_id
      );
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .handicap_types !== null
      ) {
        setSelectHandicapTypesIds(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.handicap_types
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
          .number_of_working_days_supplemental
      ) {
        setNumberOfWorkingDaysSupplemental(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
            .number_of_working_days_supplemental
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
          .overtime_hours_supplemental
      ) {
        setOverTimeHoursSupplemental(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
            .overtime_hours_supplemental
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .department !== null
      ) {
        setRecruitmentWorkLocationDepartment(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
            .department
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.period
          .trial_period_id !== null
      ) {
        setSelectedTrailPeriod(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.period
            .trial_period_id
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
          .work_location_supplemental !== null
      ) {
        setWorkLocationSupplemental(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_location
            .work_location_supplemental
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.period
          .trial_period_supplemental !== null
      ) {
        setRecruitmentPeriodTrialPeriodSupplement(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.period
            .trial_period_supplemental
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
          .work_time_supplemental !== null
      ) {
        setWorkTimeSupplemental(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
            .work_time_supplemental
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.holidays !==
        null
      ) {
        setCheckedHolidays(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.holidays
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.vacations !==
        null
      ) {
        setCheckedVacations(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.vacations
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
          .holidays_supplemental !== null
      ) {
        setHolidaysSupplemental(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
            .holidays_supplemental
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
          .salary_form_id !== null
      ) {
        setSalaryFormId(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
            .salary_form_id
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.allowances !==
        null
      ) {
        setMySelectedAllowancesId(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.allowances
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .social_insurances !== null
      ) {
        setCheckedSocialInsurances(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
            .social_insurances
        );
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .corporate_culture_text !== null
      ) {
        setCorporateCulture(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
            .corporate_culture_text
        );
        show[0] = true;
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .business_flow !== null
      ) {
        setBusinessFlow(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.business_flow
        );
        show[1] = true;
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .superior_message !== null
      ) {
        setSuperiorMessage(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
            .superior_message
        );
        show[2] = true;
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.career_path !==
        null
      ) {
        setCareerPath(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.career_path
        );
        show[3] = true;
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .consideration_condition_text !== null
      ) {
        setConsiderationCondition(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
            .consideration_condition_text
        );
        show[4] = true;
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.hr_message !==
        null
      ) {
        setHrMessage(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.hr_message
        );
        show[5] = true;
      }
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.movie_path !==
        null
      ) {
        setMoviePath(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.movie_path
        );
        show[6] = true;
      }
      setCompanyId(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.company_id
      );
      setAnnualHoliday(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
          .annual_holiday
      );
      setLowerMonthlyBasicSalary(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
          .lower_monthly_basic_salary
      );
      setUpperMonthlyBasicSalary(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
          .upper_monthly_basic_salary
      );
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
          .allowances_supplemental !== null
      ) {
        setAllowancesSupplemental(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
            .allowances_supplemental
        );
      }
      setAnnualNumberOfBonusExists(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
          .annual_number_of_bonus_exists
      );
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
          .annual_number_of_bonus !== null
      ) {
        setAnnualNumberOfBonus(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
            .annual_number_of_bonus
        );
      }
      setLowerEstimatedAnnualIncome(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
          .lower_estimated_annual_income
      );
      setUpperEstimatedAnnualIncome(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
          .upper_estimated_annual_income
      );
      setAnnualNumberOfRaisesExists(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
          .annual_number_of_raises_exists
      );
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
          .annual_number_of_raises
      ) {
        setAnnualNumberOfRaises(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
            .annual_number_of_raises
        );
      }
      setIsRetirementMoneyExistsVal(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .retirement_money_exists
      );
      setRetirementAge(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.retirement_age
      );
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .benefit_supplemental == null
      ) {
        setBenefitSupplemental("");
      } else {
        setBenefitSupplemental(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
            .benefit_supplemental
        );
      }
      setCheckedSelectionMethods(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .selection_methods
      );
      setSelectionMethodSupplemental(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .selection_method_supplemental
      );
      setNumberOfEmployees(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.target
          .number_of_employees
      );
      setPreviewDisplayTexts(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .preview_display_texts
      );
      setPreviewDisplayText(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .preview_display_text
      );
      setNewGraduate(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.new_graduate
      );
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.target
          .schedule_id !== null
      ) {
        setScheduleId(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.target
            .schedule_id
        );
      }
      setSelectedPrefectures(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.prefectures
      );
      if (prefectures.length !== 0) {
        let preSelect: string[] = [];
      
        for (let i = 0; i < prefectures.length; i++) {
          for ( let j = 0;j < recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.prefectures.length;j++) {
            if (
              recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.prefectures[j] ===
              prefectures[i].id
            ) {
              preSelect.push(prefectures[i].region_id.toString());
            }
          }
        }
        const uniqueRegions = [...new Set(preSelect)];
      
        setMySelectedPrefectures(uniqueRegions);
      }
      

      setRecruitmentHandicapTypes(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.handicap_types
      );
      setCurrentStatusName(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.current_status
          .name
      );
      setUpdateStatuses(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.update_statuses
      );
      setCurrentStatusId(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.current_status
          .id
      );
      setStatusId(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.status_id
      );
      setIsOvertimeHoursExists(
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time
          .overtime_hours_exists
      );
      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
          .consideration_conditions !== null
      ) {
        setRecruitmentConsiderationConditions(
          recruiterRecruitmentsDetail.recruiterRecruitmentsDetail
            .consideration_conditions
        );
      }

      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.salary
          .salary_form_id == 1
      ) {
        setIsAnnualIncomeShow(true);
      } else {
        setIsAnnualIncomeShow(false);
      }
      
      if (recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time1) {
        let start1 = recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time1.split(':');
        setStartTime1Hour(start1[0]);
        setStartTime1Minute(start1[1]);
        setStartTime1(recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time1);
      } else {
        setStartTime1(null);
      }

      if (recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time2) {
        let start2 = recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time2.split(':');
        setStartTime2Hour(start2[0]);
        setStartTime2Minute(start2[1]);
        setStartTime2(recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time2);
      } else {
        setStartTime2(null);
      }

      if (recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time3) {
        let start3 = recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time3.split(':');
        setStartTime3Hour(start3[0]);
        setStartTime3Minute(start3[1]);
        setStartTime3(recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time3);
      } else {
        setStartTime3(null);
      }      

      if (recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time1) {
        let end1 = recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time1.split(':');
        setEndTime1Hour(end1[0]);
        setEndTime1Minute(end1[1]);
        setEndTime1(recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time1);
      } else {
        setEndTime1(null);
      }

      if (recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time2) {
        let end2 = recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time2.split(':');
        setEndTime2Hour(end2[0]);
        setEndTime2Minute(end2[1]);
        setEndTime2(recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time2);
      } else {
        setEndTime2(null);
      }

      if (recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time3) {
        let end3 = recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time3.split(':');
        setEndTime3Hour(end3[0]);
        setEndTime3Minute(end3[1]);
        setEndTime3(recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time3);
      } else {
        setEndTime3(null);
      }      

      
      if (recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time1 && recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time1) {
        workTimes[0] = recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time1 + "~" + recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time1;
      } else {
        workTimes[0] = null;
      }

      if (recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time2 && recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time2) {
        workTimes[1] = recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time2 + "~" + recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time2;
      } else {
        workTimes[1] = null;
      }

      if (recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time3 && recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time3) {
        workTimes[2] = recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.start_time3 + "~" + recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.work_time.end_time3;
      } else {
        workTimes[2] = null;
      }

      if (
        recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.images.length ==
        0
      ) {
        const value = [];
        for (let i = 0; i < 5; i++) {
          const image = { priority: i + 1, url: "", comment: "" };
          value.push({ ...image });
        }
        setImages(value);
      } else {
        setImages(recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.images);
      }
      setLoadScreen(false);
    }
  }, [recruiterRecruitmentsDetail.pending, lostPost]);

  useEffect(() => {
    if (job_category !== null && jobCategories !== undefined) {
      for (let i = 0; i < jobCategories.length; i++) {
        if (job_category == jobCategories[i].id) {
          setSelectedJobTypeId(jobCategories[i].job_category_type_id);
          setJobCategoriesName(jobCategories[i].name);
        }
      }
    }
  }, [job_category, jobCategories]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    // Attach the event listener on component mount
    window.addEventListener('resize', handleResize);
    console.log(windowSize.width);
    

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  useEffect(() => {
    if (selectedJobTypeId !== null && jobCategories !== undefined) {
      let tmp = [];
      for (let i = 0; i < jobCategories.length; i++) {
        if (selectedJobTypeId == jobCategories[i].job_category_type_id) {
          tmp.push(jobCategories[i]);
        }
      }
      setJobCategoriesArr(tmp);
    }
  }, [selectedJobTypeId, jobCategories]);

  useEffect(() => {
    if (regions.pending) {
      setJobCategories(regions.regions.job_categories);
      setJobCategoryTypes(regions.regions.job_category_types);
      setMasterContractPeriod(regions.regions.contract_periods);
      setMasterTrialPeriod(regions.regions.trial_periods);
      setMasterEmploymentStatus(regions.regions.employment_statuses);
      setHolidays(regions.regions.holidays);
      setVacations(regions.regions.vacations);
      setSalaryForms(regions.regions.salary_forms);
      setAllowances(regions.regions.allowances);
      setSocialInsurances(regions.regions.social_insurances);
      setSelectionMethods(regions.regions.selection_methods);
      setSchedules(regions.regions.schedules);
      setRegion(regions.regions.regions);
      setPrefectures(regions.regions.prefectures);
      setHandicapTypes(regions.regions.handicap_types);
      setConsiderationConditions(regions.regions.consideration_conditions);
      setIdealCandidatesUnique(regions.regions.ideal_candidates_unique);
      setAppealItems(regions.regions.appeal_items);
      setConsiderationConditionTypes(
        regions.regions.consideration_condition_types
      );
      setWorkEnvironments(regions.regions.work_environments);
      setTypeOfRecruitingCompanies(
        regions.regions.type_of_recruiting_companies
      );
      setMasterWorkFromHome(regions.regions.work_from_home);
      setMasterPrefectures(regions.regions.prefectures);
      let c = [];
      for (
        let i = 0;
        i < regions.regions.consideration_condition_types.length;
        i++
      ) {
        c.push(false);
      }
      setIsOpenList(c);
    }
  }, [regions.pending, jobCategories, masterEmploymentStatus, appealItems]);

  function logout() {
    if (localStorage.entryFlag) {
      localStorage.removeItem("entryFlag");
    }
    if (localStorage.jobParachannelAuthToken) {
      localStorage.removeItem("jobParachannelAuthToken");
      navigate("/");
      window.location.reload();
    }
  }

  function checkStatus() {
    if (statusId === 3) {
      if (
        window.confirm(
          "ステータスを応募終了に変更すると、選考途中の応募者が全員不採用となり、募集の再開はできなくなります。\n本当に実行しますか？"
        )
      ) {
        // this.submit();
        return;
      }

      // this.setState({
      //   recruitment: {
      //     ...this.state.recruitment,
      //     status_id: undefined,
      //   },
      // });
    } else {
      // this.submit();
    }
  }

  useEffect(() => {
    if (btn) {
      setLoadPost(true);
      dispatch(fetchPostCodeRequest(postCode));
      setBtn(false);
    }
    if (postcode !== undefined) {
      if (postcode.region !== undefined) {
        if (postcode.prefecture !== undefined) {
          setPrefectureId(postcode.prefecture.id);
          setPrefectureName(postcode.prefecture.name);
        }
        if (postcode.city !== undefined) {
          setCityId(postcode.city.id);
          setCityName(postcode.city.name);
        }
      }
    }
  }, [postcode, dispatch, btn]);

  function jobtitleChange(event: any) {
    setEdited(true);
    if (event.target.value.length > jobTitleMaxLen) {
      setJobTitleMaxLength(true);
    } else {
      setJobTitleMaxLength(false);
    }
    setJob_title(event.target.value);
  }

  const handleCommentChange = (event: any, index: number) => {
    setEdited(true);
    const text = event.target.value;
    const updatevalue = {
      comment: text,
    };
    const updatedComment = [...images];
    updatedComment[index] = {
      ...updatedComment[index],
      ...updatevalue,
    };
    setImages(updatedComment);
  };

  function delImage(event: any) {
    let c = [];
    for (let i = 0; i < images.length; i++) {
      c.push(images[i]);
    }

    c[event - 1].url = "";
    c[event - 1].comment = "";
    delete c[event - 1].image;
    setImages(c);
  }

  const setImage = (index: number, event: any) => {
    setEdited(true);
    const fileImg = event.target.files[0];

    if (fileImg.type.startsWith("image/")) {
      const updatedImages = [...images];
      const reader = new FileReader();
      reader.readAsDataURL(fileImg);
      reader.onload = function () {
        updatedImages[index].image = reader.result as string;
        updatedImages[index].url = reader.result as string;
        setImages(updatedImages);
      };
    }
  };

  function filterImages() {
    const newArr =
      recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.images.filter(
        (v: any) => v.url != null
      );
    if (newArr.length == 0) {
      return [
        {
          priority: 1,
          url: "/assets/img/jobs/detail_nophoto.png",
          comment: null,
        },
      ];
    }
    return newArr;
  }

  function goToImage(obj: any) {
    const index = filterImages().indexOf(obj);
    // console.log(obj.priority - 1)
    let next: HTMLElement = document.getElementsByClassName(
      "swiper-button-next"
    )[0] as HTMLElement;
    let prev: HTMLElement = document.getElementsByClassName(
      "swiper-button-prev"
    )[0] as HTMLElement;
    setSwiperKey(obj.priority - 1);
  }

  const deleteAppeal = (index: any) => {
    const newShow = [...show];
    newShow[index] = false;
    setShow(newShow);
  };

  const EditMovie = () => {
    let errorFlag = true;
    console.log(movie_path2.indexOf("https://youtu.be/"));
    if (movie_path2.indexOf("https://youtu.be/") !== -1) errorFlag = false;
    if (movie_path2.indexOf("https://www.youtube.com/watch?v=") !== -1)
      errorFlag = false;
    if (errorFlag) {
      window.alert("この動画のURLは対応しておりません。");
      return;
    }
    convertYoutubePath();
    setIsEditMoviePath(false);
  };

  function convertYoutubePath() {
    if (!movie_path2) {
      return;
    }
    let url = "";
    if (movie_path2.indexOf("https://www.youtube.com/watch?v=") != -1) {
      url = "https://www.youtube.com/embed/" + movie_path2.split("watch?v=")[1];
    }
    if (movie_path2.indexOf("https://youtu.be/") != -1) {
      url =
        "https://www.youtube.com/embed/" +
        movie_path2.split("https://youtu.be/")[1];
    }
    setMoviePath(url.trim());
  }

  const sampleTextCorporateCulture = () => {
    openAI("culture");
    // Implement your logic here for generating sample text for corporate culture.
  };

  const sampleTextBusinessFlow = (e: any) => {
    openAI("business");
  };

  const sampleTextSuperiorMessage = () => {
    // Implement your logic here for generating sample text for superior message.
    openAI("superior");
  };

  const sampleTextHrMessage = () => {
    // Implement your logic here for generating sample text for HR message.
    openAI("hr");
  };

  function jobCategoriesTypeChange(event: any) {
    setEdited(true);
    if(event.target.value.length != 0) {
      const value = event.target.value;
      setSelectedJobTypeId(value);
    } else {      
      setSelectedJobTypeId("");
      setSelectedJobCategoryId("")
    }
  }
  function jobCategoriesIdChange(event: any) {
    setEdited(true);    
    if(event.target.value.length != 0) {
      const value = event.target.value;
      setSelectedJobCategoryId(value);
    } else {      
      setSelectedJobCategoryId("");
    }
  }

  function changeBusinessContent(e: any) {
    setEdited(true);
    setBusiness_content(e.target.value);
    if (e.target.value.length > businessContentMaxLen) {
      setIsErrorBusinessContent(true);
      setBusinessContentMaxLength(true);
      setBusinessContentMaxLenError(e.target.value.length);
    } else {
      setIsErrorBusinessContent(false);
      setBusinessContentMaxLength(false);
    }
  }

  function changeRequiredExperience(e: any) {
    setEdited(true);
    setRequired_experience(e.target.value);
    if (e.target.value.length > requiredExperienceMaxLen) {
      setIsErrorRequiredExperience(true);
      SetRequiredExperienceMaxLength(true);
      setRequiredExperienceMaxLenError(e.target.value.length);
    } else {
      setIsErrorRequiredExperience(false);
      SetRequiredExperienceMaxLength(false);
    }
  }

  function changeContractPeriodSupplemental(e: any) {
    setEdited(true);
    setRecruitmentPeriodContractPeriodSupplemental(e.target.value);
    if (e.target.value.length > contractPeriodSupplementalMaxLen) {
      setIsErrorContractPeriodSupplemental(true);
      setContractPeriodSupplementalMaxLength(true);
      setContractPeriodSupplementalMaxLenError(e.target.value.length);
    } else {
      setIsErrorContractPeriodSupplemental(false);
      setContractPeriodSupplementalMaxLength(false);
    }
  }

  function changeTrialPeriodSupplemental(e: any) {
    setEdited(true);
    setRecruitmentPeriodTrialPeriodSupplement(e.target.value);
    if (e.target.value.length > trialPeriodSupplementalMaxLen) {
      setIsErrorTrialPeriodSupplemental(true);
      setTrialPeriodSupplementalMaxLength(true);
      setTrialPeriodSupplementalMaxLenError(e.target.value.length);
    } else {
      setIsErrorTrialPeriodSupplemental(false);
      setTrialPeriodSupplementalMaxLength(false);
    }
  }

  function changeWorkLocationSupplemental(e: any) {
    setEdited(true);
    setWorkLocationSupplemental(e.target.value);
    if (e.target.value.length > workLocationSupplementalMaxLen) {
      setIsErrorWorkLocationSupplemental(true);
      setWorkLocationSupplementalMaxLength(true);
      setWorkLocationSupplementalMaxLenError(e.target.value.length);
    } else {
      setIsErrorWorkLocationSupplemental(false);
      setWorkLocationSupplementalMaxLength(false);
    }
  }

  function changeWorkTimeSupplemental(e: any) {
    setEdited(true);
    setWorkTimeSupplemental(e.target.value);
    if (e.target.value.length > workTimeSupplementalMaxLen) {
      setIsErrorWorkTimeSupplemental(true);
      setWorkTimeSupplementalMaxLength(true);
      setWorkTimeSupplementalMaxLenError(e.target.value.length);
    } else {
      setIsErrorWorkTimeSupplemental(false);
      setWorkTimeSupplementalMaxLength(false);
    }
  }

  function changeRestTimeSupplemental(e: any) {
    setEdited(true);
    setRestTimeSupplemental(e.target.value);
    if (e.target.value.length > restTimeSupplementalMaxLen) {
      setIsErrorRestTimeSupplemental(true);
      setRestTimeSupplementalMaxLength(true);
      setRestTimeSupplementalMaxLenError(e.target.value.length);
    } else {
      setIsErrorRestTimeSupplemental(false);
      setRestTimeSupplementalMaxLength(false);
    }
  }

  function changeNumberOfWorkingDaysSupplemental(e: any) {
    setEdited(true);
    setNumberOfWorkingDaysSupplemental(e.target.value);
    if (e.target.value.length > numberOfWorkingDaysSupplementalMaxLen) {
      setIsErrorNumberOfWorkingDaysSupplemental(true);
      setNumberOfWorkingDaysSupplementalMaxLength(true);
      setNumberOfWorkingDaysSupplementalMaxLenError(e.target.value.length);
    } else {
      setIsErrorNumberOfWorkingDaysSupplemental(false);
      setNumberOfWorkingDaysSupplementalMaxLength(false);
    }
  }

  function changeOverTimeHoursSupplemental(e: any) {
    setEdited(true);
    setOverTimeHoursSupplemental(e.target.value);
    if (e.target.value.length > overtimeHoursSupplementalMaxLen) {
      setIsErrorOverTimeHoursSupplemental(true);
      setOverTimeHoursSupplementalMaxLength(true);
      setOverTimeHoursSupplementalMaxLenError(e.target.value.length);
    } else {
      setIsErrorOverTimeHoursSupplemental(false);
      setOverTimeHoursSupplementalMaxLength(false);
    }
  }

  function changeHolidaysSupplemental(e: any) {
    setEdited(true);
    setHolidaysSupplemental(e.target.value);
    if (e.target.value.length > holidaysSupplementalMaxLen) {
      setIsErrorHolidaysSupplemental(true);
      setHolidaysSupplementalMaxLength(true);
      setHolidaysSupplementalMaxLenError(e.target.value.length);
    } else {
      setIsErrorHolidaysSupplemental(false);
      setHolidaysSupplementalMaxLength(false);
    }
  }

  function changeBenefitSupplemental(e: any) {
    setEdited(true);
    setBenefitSupplemental(e.target.value);
    if (e.target.value.length > benefitSupplementalMaxLen) {
      setIsErrorBenefitSupplemental(true);
      setBenefitSupplementalMaxLength(true);
      setBenefitSupplementalMaxLenError(e.target.value.length);
    } else {
      setIsErrorBenefitSupplemental(false);
      setBenefitSupplementalMaxLength(false);
    }
  }

  function changeSelectionMethodSupplemental(e: any) {
    setEdited(true);
    setSelectionMethodSupplemental(e.target.value);
    if (e.target.value.length > selectionMethodSupplementalMaxLen) {
      setIsErrorSelectionMethodSupplemental(true);
      setSelectionMethodSupplementalMaxLength(true);
      setSelectionMethodSupplementalMaxLenError(e.target.value.length);
    } else {
      setIsErrorSelectionMethodSupplemental(false);
      setSelectionMethodSupplementalMaxLength(false);
    }
  }

  function changeAllowancesSupplemental(e: any) {
    setEdited(true);
    setAllowancesSupplemental(e.target.value);
    if (e.target.value.length > allowancesSupplementalMaxLen) {
      setIsErrorAllowancesSupplemental(true);
      setAllowancesSupplementalMaxLength(true);
      setAllowancesSupplementalMaxLenError(e.target.value.length);
    } else {
      setIsErrorAllowancesSupplemental(false);
      setAllowancesSupplementalMaxLength(false);
    }
  }

  function changeRestTime(e: any) {
    setEdited(true);
    setRestTime(e.target.value);
  }

  function changeAnnaulHoliday(e: any) {
    setEdited(true);
    setAnnualHoliday(e.target.value);
  }

  function changeTypeOfRecruitingCompanies(e: any) {
    setEdited(true);
    setTypeOfRecruitingCompanyId(e.target.value);
  }

  function changeRecruitmentEmploymentStatusId(e: any) {
    setEdited(true);
    setRecruitmentEmploymentStatusId(e.target.value);
    if(e.target.value == 1) { 
      setIsContractPeriodShow(false);
     } else {
      setIsContractPeriodShow(true);
     }
  }

  function changeRecruitmentPeriodContractPeriodId(e: any) {
    setEdited(true);
    setRecruitmentPeriodContractPeriodId(e.target.value);
  }

  function changeTrialPeriod(e: any) {
    setEdited(true);
    setSelectedTrailPeriod(e.target.value);
    setIsEditTrialPeriod(!isEditTrialPeriod);
  }

  function changeWorkFromHome(e: any) {
    setEdited(true);
    setSelectedWorkFromHom(e.target.value);
  }
  function changeWorkDay(e: any) {
    setEdited(true);
    setNumberOfWorkingDays(e.target.value);
    setIsEditNumberOfWorkingDays(false);
  }

  function changeSalaryFormId(e: any) {
    setEdited(true);
    setSalaryFormId(e.target.value);
  }

  function changeScheduleId(e: any) {
    setEdited(true);
    setScheduleId(e.target.value);
  }

  function changeLowerMonthlyBasicSalary(e: any) {
    setEdited(true);
    setLowerMonthlyBasicSalary(e.target.value);
  }

  function changeRetirementMoneyExists(event: any) {
    setEdited(true);
    const value = event.target.value === "true";
    setIsEditRetirementMoneyExists(false);
    setIsRetirementMoneyExistsVal(value);
  }

  function changeAnnualNumberOfRaises(e: any) {
    setEdited(true);
    setAnnualNumberOfRaises(e.target.value);
  }

  function changeRetirementAge(e: any) {
    setEdited(true);
    setRetirementAge(e.target.value);
  }

  function changeNumberOfEmployees(e: any) {
    setEdited(true);
    setNumberOfEmployees(e.target.value);
  }

  function changePreviewDisplayText(e: any) {
    setEdited(true);
    const { name, value } = e.target;
    if (name == "previewText0") {
      setPreviewDisplayText0(e.target.value);
      const updatedTexts = [...previewDisplayTexts];
      updatedTexts[0] = e.target.value;
      setPreviewDisplayTexts(updatedTexts);
      if (value !== null) {
        if (value.length > previewDisplayTextsMaxLen) {
          setIsErrorPreviewDisplayText0(true);
          setPreviewDisplayText0MaxLength(true);
          setPreviewDisplayText0MaxLenError(value.length);
        } else {
          setIsErrorPreviewDisplayText0(false);
          setPreviewDisplayText0MaxLength(false);
        }
      }
    }
    if (name == "previewText1") {
      setPreviewDisplayText1(e.target.value);
      const updatedTexts = [...previewDisplayTexts];
      updatedTexts[1] = e.target.value;
      setPreviewDisplayTexts(updatedTexts);
      if (value !== null) {
        if (value.length > previewDisplayTextsMaxLen) {
          setIsErrorPreviewDisplayText1(true);
          setPreviewDisplayText1MaxLength(true);
          setPreviewDisplayText1MaxLenError(value.length);
        } else {
          setIsErrorPreviewDisplayText1(false);
          setPreviewDisplayText1MaxLength(false);
        }
      }
    }
    if (name == "previewText2") {
      setPreviewDisplayText2(value);
      const updatedTexts = [...previewDisplayTexts];
      updatedTexts[2] = e.target.value;
      setPreviewDisplayTexts(updatedTexts);
      if (value !== null) {
        if (value.length > previewDisplayTextsMaxLen) {
          setIsErrorPreviewDisplayText2(true);
          setPreviewDisplayText2MaxLength(true);
          setPreviewDisplayText2MaxLenError(value.length);
        } else {
          setIsErrorPreviewDisplayText2(false);
          setPreviewDisplayText2MaxLength(false);
        }
      }
    }
    if (name == "previewText3") {
      setPreviewDisplayText3(value);
      const updatedTexts = [...previewDisplayTexts];
      updatedTexts[3] = e.target.value;
      setPreviewDisplayTexts(updatedTexts);
      if (value !== null) {
        if (value.length > previewDisplayTextsMaxLen) {
          setIsErrorPreviewDisplayText3(true);
          setPreviewDisplayText3MaxLength(true);
          setPreviewDisplayText3MaxLenError(value.length);
        } else {
          setIsErrorPreviewDisplayText3(false);
          setPreviewDisplayText3MaxLength(false);
        }
      }
    }
  }

  function changeAnnualNumberOfBonus(e: any) {
    setEdited(true);
    // console.log(e.target.value)
    setAnnualNumberOfBonus(e.target.value);
    setIsEditAnnualNumberOfBonus(e.target.value);
  }

  function changeUpperMonthlyBasicSalary(e: any) {
    setEdited(true);
    setUpperMonthlyBasicSalary(e.target.value);
  }

  function changeWorkLocationDepartment(e: any) {
    setEdited(true);
    setRecruitmentWorkLocationDepartment(e.target.value);
  }

  function changeWorkLocationPostCode(e: any) {
    setEdited(true);
    setPostCode(e.target.value);
  }

  function changeAddress(e: any) {
    setEdited(true);
    setAddress(e.target.value);
  }

  function changeNearestStation(e: any) {
    setEdited(true);
    setNearestStation(e.target.value);
  }

  function changeTransportation(e: any) {
    setEdited(true);
    setTransportation(e.target.value);
  }

  function changeCityCorrection(e: any) {
    setCityCorrection(e.target.value);
  }

  const filter_appeal_recruiter_items = () => {
    return appeal_recruiter_items.filter((v, i) => {
      return true;
    });
  };

  const selectAppeal = (key: number) => {
    setEdited(true);
    let c = [];
    for (let i = 0; i < selectAppeal.length; i++) {
      c.push(selectedAppeal[i]);
    }
    c.push(key);
    if (key == 0) {
      setCorporateCulture(txt1);
    } else if (key == 1) {
      setBusinessFlow(txt2);
    } else if (key == 2) {
      setSuperiorMessage(txt3);
    } else if (key == 3) {
      setCareerPath(txt4);
    } else if (key == 4) {
      setConsiderationCondition(txt5);
    } else if (key == 5) {
      setHrMessage(txt6);
    }
    setSelectedAppeal(c);
    const updatedShow = [...show];
    updatedShow[key] = !updatedShow[key];
    setShow(updatedShow);
    var sel = document.getElementById(
      "select-appeal-items"
    ) as HTMLSelectElement;
    sel.selectedIndex = 0;
  };

  const [tokyoTime, setTokyoTime] = useState<String>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const displayOptions:any = {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
  };

  const [datetime, setDatetime] = useState(
    new Intl.DateTimeFormat("en-US", displayOptions).format(new Date())
  );

  useEffect(() => {
    setDatetime(
      new Intl.DateTimeFormat("en-US", displayOptions).format(new Date()).replace(/\//g, '-')
      .replace('T', ' ')
      .replace(/\..+/, '')
    );
  }, [tokyoTime]);

  function openAI(type: any) {
    if (
      isApiLoadingCorporateCulture ||
      isApiLoadingHrMessage ||
      isApiLoadingSuperiorMessage ||
      isApiLoadingBusinessFlow
    ) {
      window.alert("他の場所で文章の生成中です。しばらくお待ちください。");
      return false;
    }

    const businessContent = companies.companies.company.business_content ?? "";
    const jobType =
      jobCategoryTypes.find((v: { id: number }) => v.id === selectedJobTypeId)
        ?.name ?? "";
    const jobCategory =
      jobCategories.find((v: { id: number }) => v.id === selectedJobCategoryId)
        ?.name ?? "";
    const recruitmentBusinessContent = business_content ?? "";
    const workLocation =
      masterWorkFromHome.find(
        (v: { id: number }) => v.id === selectedWorkFromHome
      )?.name ?? "";
    const employmentStatus =
      masterEmploymentStatus.find(
        (v: { id: number }) => v.id === recruitmentEmploymentStatusId
      )?.name ?? "";
    const industryName = companies.companies.company?.industry?.name ?? "";
    const corporateCultures =
      companies.companies.company?.corporate_cultures.map((value: any) => {
        return value.name;
      }) ?? "";

    let alertText = "";
    let promptTemplate = "";
    const maxLength = 200;

    if (type == "culture") {
      setIsApiLoadingCorporateCulture(true);
      // if (industryName === "") {
      //   alertText += "会社概要の「業種分類」の入力が足りていません。\n";
      // }
      // if (numberOfEmployees === "") {
      //   alertText += "会社概要の「従業員数」の入力が足りていません。\n";
      // }
      // if (corporateCultures === "") {
      //   alertText += "会社概要の「社風」の入力が足りていません。\n";
      // }
      // if (businessContent === "") {
      //   alertText += "会社概要の「事業内容」の入力が足りていません。\n";
      // }
      promptTemplate = `#命令書:
参照情報にある企業の情報を学習したうえで、この企業に務める10年目のベテラン社員になりきってください。なりきった社員の立場から、
求職者向けに自身の務める組織の風土・文化・職場の雰囲気を説明する文章案を作成してください。
参照情報から判断できない要素は想像を膨らませて補足しながら完成させること。

#制約条件:
・${maxLength}字以内にすること
・誰にでもわかりやすく
・参照情報にある表現をそのまま使わず噛み砕いた表現にすること
・事業内容についての説明要素は省略すること
・自分自身の自己紹介文は省略すること
・文章表現は求人票に掲載するための文章として崩しすぎないこと

#参照情報:
・業種分類：${industryName}
・従業員数：${numberOfEmployees}
・社風：${corporateCultures}
・事業内容：${businessContent}

#outputText:`;
    } else if (type == "business") {
      setIsApiLoadingBusinessFlow(true);
      // if (businessContent === "") {
      //   alertText += "会社概要の「事業内容」の入力が足りていません。\n";
      // }
      // if (jobType === "" || jobCategory === "") {
      //   alertText += "「職種」の入力が足りていません。\n";
      // }
      // if (recruitmentBusinessContent === "") {
      //   alertText += "「業務内容」の入力が足りていません。\n";
      // }
      // if (workLocation === "") {
      //   alertText += "「働き方・テレワーク」の入力が足りていません。\n";
      // }
      // if (employmentStatus === "") {
      //   alertText += "「雇用形態」の入力が足りていません。\n";
      // }
      // if (startTime1Hour === "" || endTime1Hour === "") {
      //   alertText += "「勤務時間」の入力が足りていません。\n";
      // }
      const workTimeText = `${startTime1Hour}:${startTime1Minute} 〜 ${endTime1Hour}:${endTime1Minute}`;
      promptTemplate = `#命令書:
あなたは障害者雇用に特化した優秀なエージェントです。
参照情報にある情報を学習したうえで、以下の制約条件を厳密に守り、求人サイトに掲載する「就業者の業務のタイムスケジュール」の例を作成してください。

#制約条件:
・${maxLength}字以内にすること
・誰にでもわかりやすく
・重要なキーワードを取り残さない
・求職者が閲覧をして１日の時間経過とともに業務の流れがわかるようにすること
・リスト形式で出力すること

#参照情報:
・事業内容:${businessContent}
・職種:${jobType} ${jobCategory}
・業務内容：${recruitmentBusinessContent}
・雇用形態:${employmentStatus}
・勤務時間:${workTimeText}
・働き方:${workLocation}

#outputText:`;
    } else if (type == "superior") {
      setIsApiLoadingSuperiorMessage(true);
      // if (jobType === "" || jobCategory === "") {
      //   alertText += "「職種」の入力が足りていません。\n";
      // }
      // if (recruitmentBusinessContent === "") {
      //   alertText += "「業務内容」の入力が足りていません。\n";
      // }
      // if (workLocation === "") {
      //   alertText += "「働き方・テレワーク」の入力が足りていません。\n";
      // }
      // if (employmentStatus === "") {
      //   alertText += "「雇用形態」の入力が足りていません。\n";
      // }
      // if (idealCandidatesUnique === "") {
      //   alertText += "「求める人物像」の入力が足りていません。\n";
      // }
      promptTemplate = `#命令書:
参照情報にある企業の情報を学習したうえで、この企業に務める10年目のベテラン社員になりきってください。
なりきった社員の立場から、以下の制約条件を厳密に守り、求職者へのメッセージ案を作成してください。
参照情報から判断できない要素は想像を膨らませて補足しながら完成させること。

#制約条件:
・${maxLength}字以内にすること
・誰にでもわかりやすく
・参照情報にある表現をそのまま使わず噛み砕いた表現にすること
・事業内容についての説明要素は省略すること
・自分自身の自己紹介文は省略すること
・文章表現は求人票に掲載するための文章として崩しすぎないこと
・職場の先輩として求職者に向けたメッセージにすること
・採用担当者ではなく、あくまで一緒に働くメンバーとして新しい人を迎えいれるためのウェルカムメッセージにすること

#参照情報:
・職種:${jobType} ${jobCategory}
・業務内容：${recruitmentBusinessContent}
・雇用形態:${employmentStatus}
・働き方:${workLocation}
・求める人物像：${idealCandidatesUnique}

#outputText:`;
    } else {
      setIsApiLoadingHrMessage(true);
      // if (industryName === "") {
      //   alertText += "会社概要の「業種分類」の入力が足りていません。\n";
      // }
      // if (numberOfEmployees === "") {
      //   alertText += "会社概要の「従業員数」の入力が足りていません。\n";
      // }
      // if (corporateCultures === "") {
      //   alertText += "会社概要の「社風」の入力が足りていません。\n";
      // }
      // if (corporateCulture === '') {
      //   alertText += 'アピール項目の「社風・職場の雰囲気」の入力が足りていません。\n'
      // }
      // if (businessContent === '') {
      //   alertText += '会社概要の「事業内容」の入力が足りていません。\n'
      // }
      // if (jobType === '' || jobCategory === '') {
      //   alertText += '「職種」の入力が足りていません。\n'
      // }
      // if (workLocation === "") {
      //   alertText += "「働き方・テレワーク」の入力が足りていません。\n";
      // }
      // if (employmentStatus === '') {
      //   alertText += '「雇用形態」の入力が足りていません。\n'
      // }
      // if (salaryForm === '' || (this.recruitment.salary.lower_monthly_basic_salary === '' || this.recruitment.salary.upper_monthly_basic_salary === '')) {
      //   alertText += '給与・賞与の「給与方式」「基本給」の入力が足りていません。\n'
      // }
      // if (this.recruitment.work_time.start_time1 === '' || this.recruitment.work_time.end_time1 === '') {
      //   alertText += '「勤務時間」の入力が足りていません。\n'
      // }
      // if (idealCandidatesUnique === "") {
      //   alertText += "「求める人物像」の入力が足りていません。\n";
      // }
      promptTemplate = `#命令書:
参照情報にある企業の情報を学習したうえで、この企業に務める優秀な採用担当者になりきってください。
なりきった社員の立場から、以下の制約条件を厳密に守り、求職者へのメッセージ案を作成してください。

#制約条件:
・${maxLength}字以内にすること
・参照情報にある表現をそのまま使わず噛み砕いた表現にすること
・誰にでもわかりやすく
・求職者の応募を後押しするような勇気づける表現にすること
・自分自身の自己紹介文は省略すること
・文章表現は求人票に掲載するための文章として崩しすぎないこと
・文章表現の締めは謙虚めにすること、例えば「一緒により良い職場づくりをしていきましょう」など採用担当者として当事者意識をもって職場の環境改善に努める姿勢を伝えること。

#参照情報:
・業種分類：${industryName}
・従業員数：${numberOfEmployees}
・社風：${corporateCultures}
・働き方:${workLocation}
・求める人物像：${idealCandidatesUnique}

#outputText:`;
    }

    const payload = {
      model: "gpt-4",
      messages: [{ role: "user", content: promptTemplate }],
      temperature: 0.7,
      top_p: 1,
      max_tokens: 1800,
      presence_penalty: 0,
      frequency_penalty: 0,
    };
    dispatch(fetchOpenAiRequest(payload));
    let hisType = '';
    let hisLabel = '';
    if (type == "culture") {
      hisType = 'shafu';
      hisLabel = "社風・職場の雰囲気";
    } else if (type == "business") {
      hisType = appeal_recruiter_items_hr_message;
      hisLabel="採用担当メッセージ";
    } else if (type == "superior") {
      hisType = 'flow';
      hisLabel="１日の業務の流れ"
    } else {
      hisType = 'comments';
      hisLabel = '先輩社員からのコメント';
    };


    const payloadHistory = {
      datetime: datetime,
      type: hisType,
      type_label: hisLabel,
      recruit_id: id,
    };
    dispatch(fetchOpenAiGoogleRequest(payloadHistory));
    if (alertText !== "") {
      window.alert(
        "以下項目があるとさらに魅力的な文章が生成されます。\n" + alertText
      );
    }
  }

  useEffect(() => {
    if (openAi.pending) {
      if (isApiLoadingCorporateCulture) {
        setCorporateCulture(openAi.body.choices[0].message.content.trimStart());
      }
      if (isApiLoadingBusinessFlow) {
        setBusinessFlow(openAi.body.choices[0].message.content.trimStart());
      }
      if (isApiLoadingSuperiorMessage) {
        setSuperiorMessage(openAi.body.choices[0].message.content.trimStart());
      }
      if (isApiLoadingHrMessage) {
        setHrMessage(openAi.body.choices[0].message.content.trimStart());
      }
      setIsApiLoadingBusinessFlow(false);
      setIsApiLoadingCorporateCulture(false);
      setIsApiLoadingHrMessage(false);
      setIsApiLoadingSuperiorMessage(false);
    } else if (openAi.error != null) {
      window.alert("通信に失敗しました。");
      setIsApiLoadingBusinessFlow(false);
      setIsApiLoadingCorporateCulture(false);
      setIsApiLoadingHrMessage(false);
      setIsApiLoadingSuperiorMessage(false);
    }
  }, [openAi]);

  function updateValue(array: any, id: any) {
    setEdited(true);
    if (selectedConsiderationConditions.includes(array)) {
      setSelectedConsiderationConditions(
        selectedConsiderationConditions.filter((skill) => skill !== array)
      );
    } else {
      setSelectedConsiderationConditions([
        ...selectedConsiderationConditions,
        array,
      ]);
    }
    if (selectedConsiderationConditionsId.includes(id)) {
      setSelectedConsiderationConditionsId(
        selectedConsiderationConditionsId.filter((skill) => skill !== id)
      );
    } else {
      setSelectedConsiderationConditionsId([
        ...selectedConsiderationConditionsId,
        id,
      ]);
    }
  }
  function changeRecruitmentCompatibilities(id: any) {
    setEdited(true);
    if (id !== 0) {
      if (recruitmentCompatibilities.includes(id)) {
        setRecruitmentCompatibilities(
          recruitmentCompatibilities.filter((skill) => skill !== id)
        );
      } else {
        setRecruitmentCompatibilities([...recruitmentCompatibilities, id]);
      }
    }
  }
  function changeRecruitmentWorkEnvironments(id: any) {
    setEdited(true);
    if (id !== 0) {
      if (recruitmentWorkEnvironments.includes(id)) {
        setRecruitmentWorkEnvironments(
          recruitmentWorkEnvironments.filter((skill) => skill !== id)
        );
      } else {
        setRecruitmentWorkEnvironments([...recruitmentWorkEnvironments, id]);
      }
    }
  }

  function changeAllowances(id: any) {
    setEdited(true);
    if (id !== 0) {
      if (mySelectedAllowancesId.includes(id)) {
        setMySelectedAllowancesId(
          mySelectedAllowancesId.filter((skill) => skill !== id)
        );
      } else {
        setMySelectedAllowancesId([...mySelectedAllowancesId, id]);
      }
    }
  }

  function toggle(index: any) {
    setIsOpenList((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }

  function filterConsiderationConditionsHandicap(typeId: any) {
    return considerationConditions.filter(
      (v) => v.consideration_condition_type_id === typeId
    );
  }

  const findValue = (value: any, masterName: string | number) => {
    const dataSet = regions.regions[masterName];
    if (dataSet === undefined) {
      return null;
    }
    return value && value !== undefined
      ? dataSet.find((v: { id: number }) => v.id === Number(value))?.name
      : "";
  };

  const findValue2 = (value: any, masterName: string | number) => {
    const dataSet = regions.regions[masterName];
    if (dataSet === undefined) {
      return null;
    }
    return value && value !== undefined
      ? dataSet.find((v: { id: number }) => v.id === Number(value))?.name2
      : "";
  };

  function findValueTrialPeriod() {
    if (notEnteredString) {
      return recruitmentPeriodTrialPeriodSupplement
        ? recruitmentPeriodTrialPeriodSupplement
        : notEnteredString;
    } else {
      return recruitmentPeriodTrialPeriodSupplement
        ? recruitmentPeriodTrialPeriodSupplement
        : "未入力";
    }
  }

  function findValueWorkLocationDepartment() {
    if (notEnteredString) {
      return recruitmentWorkLocationDepartment
        ? recruitmentWorkLocationDepartment
        : notEnteredString;
    } else {
      return recruitmentWorkLocationDepartment
        ? recruitmentWorkLocationDepartment
        : "未入力";
    }
  }

  function handleButtonClick(e: any) {
    e.preventDefault();
    changeRecruitmentWorkEnvironmentsText(environmentsText);
  }
  function changeRecruitmentWorkEnvironmentsText(text: any) {
    setEdited(true);
    if (recruitmentWorkEnvironmentsText.includes(text)) {
      setRecruitmentWorkEnvironmentsText(
        recruitmentWorkEnvironmentsText.filter((skill) => skill !== text)
      );
    } else {
      setRecruitmentWorkEnvironmentsText([
        ...recruitmentWorkEnvironmentsText,
        text,
      ]);
    }
    setEnvironmentsText("");
  }

  const deleteTag = (index: any) => {
    const updatedArray = [...recruitmentWorkEnvironmentsText];
    updatedArray.splice(index, 1);
    setRecruitmentWorkEnvironmentsText(updatedArray);
  };

  const handlePreviewModal = () => {
    setPreviewModal(!previewModal);
  };

  function findValueSalaryForm() {
    const id = salaryFormId;
    const blank_msg = allowBlank ? "なし" : "未入力";
    return id ? salaryForms.find((v: any) => v.id == id)?.name : blank_msg;
  }

  function findValueContractPeriod() {
    const id = recruitmentPeriodContractPeriodId;
    const blank_msg = allowBlank ? "なし" : "未入力";
    return id
      ? masterContractPeriod.find((v: any) => v.id == id)?.name
      : blank_msg;
  }

  function findValueTrailPeriod() {
    const id = selectedTrailPeriod;
    const blank_msg = allowBlank ? "なし" : "未入力";
    return id ? masterTrialPeriod.find((v: any) => v.id == id)?.name : blank_msg;
  }

  function buildWorkTime() {
    setEdited(true);
    setIsEditWorkTime(false);
    // if (startTime1Hour && endTime1Hour) {
    //   workTimes[0] = `${startTime1Hour}:${startTime1Minute}～${endTime1Hour}:${endTime1Minute}`;
    // }
    // if (startTime2Hour && endTime2Hour) {
    //   workTimes[1] = `${startTime2Hour}:${startTime2Minute}～${endTime2Hour}:${endTime2Minute}`;
    // }
    // if (startTime3Hour && endTime3Hour) {
    //   workTimes[2] = `${startTime3Hour}:${startTime3Minute}～${endTime3Hour}:${endTime3Minute}`;
    // }
      if (startTime1 && endTime1) {
        workTimes[0] = `${startTime1}～${endTime1}`;
      }
      if (startTime2 && endTime2) {
        workTimes[1] = `${startTime2}～${endTime2}`;
      }
      if (startTime3 && endTime3) {
        workTimes[2] = `${startTime3}～${endTime3}`;
      }
  }

  function workingDays() {
    return [1, 2, 3, 4, 5, 6];
  }

  // useEffect(() => {
  //   if (currentStatusId == 4) {
  //     setIsCanCreate(true);
  //   }
  // });
  function onClickPublish() {
    if (isCanCreate == false) {
      alert(`公開できる求人は${publishLimit}件までです。`);
      return;
    }
    setStatusId(2);
    submit(false, publishBtn);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);
  }

  function findValueRecruitCompanyId() {
    const id = typeOfRecruitingCompanyId;
    const blank_msg = allowBlank ? "なし" : "未入力";
    return id 
      ? typeOfRecruitingCompanies.find((v: any) => v.id == id)?.name
      : blank_msg;
    // return id && id !== undefined ? typeOfRecruitingCompanies.find((v: { id: number; }) => v.id === Number(id))?.name : blank_msg;
  }

  function changeContractPeriod(event: any) {
    setEdited(true);
    const value = event.target.value === 'true';
    setIsEditContractPeriodExists(false);
    setRecruitmentPeriodContractPeriodExists(value);
  }

  function changeContractRenewal(event: any) {
    setEdited(true);
    const value = event.target.value === "true";
    setIsEditContractRenewalExists(!isEditContractRenewalExists);
    setRecruitmentPeriodContractRenewalExists(value);
  }

  function changePeriodTrialPeriodExists(event: any) {
    setEdited(true);
    const value = event.target.value === "true";
    setIsEditTrialPeriodExists(!isEditTrialPeriodExists);
    setRecruitmentPeriodTrialPeriodExists(value);
  }

  function changeFullTimeEmployeeExists(event: any) {
    setEdited(true);
    const value = event.target.value === "true";
    setIsEditFullTimeEmployeeExists(!isEditFullTimeEmployeeExists);
    setRecruitmentPeriodFullTimeEmployeeExists(value);
  }

  function updateHour(v: any) {
    setEdited(true);
    if ("startTime1Hour" === v.target.name) {
      if (v.target.value == null || v.target.value == "") {
        setStartTime1Hour(null);
        setStartTime1Minute(null);
        setStartTime1(null);
      } else {
        setStartTime1Hour(v.target.value);
        if(startTime1Minute !== null && startTime1Minute !== '') {
          setStartTime1(v.target.value + ':' + startTime1Minute);
        } else {
          setStartTime1(v.target.value + ':00');
        }
      }
    }

    if ("startTime2Hour" === v.target.name) {

      if (v.target.value == null || v.target.value == "") {
        setStartTime2Hour(null);
        setStartTime2Minute(null);
        setStartTime2(null);
      } else {
        setStartTime2Hour(v.target.value);
        if(startTime2Minute !== null && startTime2Minute !== '') {
          setStartTime2(v.target.value + ':' + startTime2Minute)
        } else {
          setStartTime2(v.target.value + ':00');
        }
      }
    }

    if ("startTime3Hour" === v.target.name) {
      if (v.target.value == null || v.target.value == "") {
        setStartTime3Hour(null);
        setStartTime3Minute(null);
        setStartTime3(null);
      } else {
        setStartTime3Hour(v.target.value);
        if(startTime3Minute !== null && startTime3Minute !== '') {
          setStartTime3(v.target.value + ':' + startTime3Minute);
        } else {
          setStartTime3(v.target.value + ':00');
        }
      }
    }

    if ("endTime1Hour" === v.target.name) {
      if (v.target.value == null || v.target.value == "") {
        setEndTime1Hour(null);
        setEndTime1Minute(null);
        setEndTime1(null);
      } else {
        setEndTime1Hour(v.target.value);
        if(endTime1Minute !== null && endTime1Minute !== '') {
          setEndTime1(v.target.value + ':' + endTime1Minute);
        } else {
          setEndTime1(v.target.value + ':00');
        }
      }
    }

    if ("endTime2Hour" === v.target.name) {
      if (v.target.value == null || v.target.value == "") {
        setEndTime2Hour(null);
        setEndTime2Minute(null);
        setEndTime2(null);
      } else {
        setEndTime2Hour(v.target.value);
        if(endTime2Minute !== null && endTime2Minute !== '') {
          setEndTime2(v.target.value + ':' + endTime2Minute);
        } else {
          setEndTime2(v.target.value + ':00');
        }
      }
    }

    if ("endTime3Hour" === v.target.name) {
      if (v.target.value == null || v.target.value == "") {
        setEndTime3Hour(null);
        setEndTime3Minute(null);
        setEndTime3(null);
      } else {
        setEndTime3Hour(v.target.value);
        if(endTime3Minute !== null && endTime3Minute !== '') {
          setEndTime3(v.target.value + ':' + endTime3Minute);
        } else {
          setEndTime3(v.target.value + ':00');
        }
      }
    }
  } 

  function updateMinute(v: any) {
    setEdited(true);
    if ("startTime1Minute" === v.target.name) {
      if (v.target.value == null || v.target.value == "" || startTime1Hour == null) {
        setStartTime1Hour(null);
        setStartTime1(null);
      } else {
        setStartTime1Minute(v.target.value);
        if(startTime1Hour !== null && startTime1Hour !== '') {
          setStartTime1(startTime1Hour + ':' + v.target.value);
        }
      }
    }

    if ("startTime2Minute" === v.target.name) {
      if (v.target.value == null || v.target.value == "" || startTime2Hour == null) {
        setStartTime2Hour(null);
        setStartTime2(null);
      } else {
        setStartTime2Minute(v.target.value);
        if(startTime2Hour !== null && startTime2Hour !== '') {
          setStartTime2(startTime2Hour + ':' + v.target.value);
        }
      }
    }

    if ("startTime3Minute" === v.target.name) {
      if (v.target.value == null || v.target.value == "" || startTime3Hour == null) {
        setStartTime3Hour(null);
        setStartTime3(null);
      } else {
        setStartTime3Minute(v.target.value);
        if(startTime3Hour !== null && startTime3Hour !== '') {
          setStartTime3(startTime3Hour + ':' + v.target.value);
        }
      }
    }

    if ("endTime1Minute" === v.target.name) {
      if (v.target.value == null || v.target.value == "" || endTime1Hour == null) {
        setEndTime1Hour(null);
        setEndTime1(null);
      } else {
        setEndTime1Minute(v.target.value);
        if(endTime1Hour !== null && endTime1Hour !== '') {
          setEndTime1(endTime1Hour + ':' + v.target.value);
        }
      }
    }

    if ("endTime2Minute" === v.target.name) {
      if (v.target.value == null || v.target.value == "" || endTime2Hour == null) {
        setEndTime2Hour(null);
        setEndTime2(null);
      } else {
        setEndTime2Minute(v.target.value);
        if(endTime2Hour !== null && endTime2Hour !== '') {
          setEndTime2(endTime2Hour + ':' + v.target.value);
        }
      }
    }

    if ("endTime3Minute" === v.target.name) {
      if (v.target.value == null || v.target.value == "" || endTime3Hour == null) {
        setEndTime3Hour(null);
        setEndTime3(null);
      } else {
        setEndTime3Minute(v.target.value);
        if(endTime3Hour !== null && endTime3Hour !== '') {
          setEndTime3(endTime3Hour + ':' + v.target.value);
        }
      }
    }
  }

  function editHourAndMinute(e: any) {
    setEdited(true);
    setIsEditWorkTime(true);
  }

  function onChangeCityCorrectionOvertimeHours(e: any, index: any) {
    setEdited(true);
    let c = [];
    for (let i = 0; i < checkedHolidays.length; i++) {
      c.push(checkedHolidays[i]);
    }
    let chk = false;
    for (let i = 0; i < c.length; i++) {
      if (c[i] == e.target.value) {
        chk = true;
        index = i;
      }
    }
    if (!chk) {
      c.push(parseInt(e.target.value));
    } else {
      c.splice(index, 1);
    }
    setCheckedHolidays(c);
  }



  function onChangeVacations(e: any, index: any) {
    setEdited(true);
    let c = [];
    for (let i = 0; i < checkedVacations.length; i++) {
      c.push(checkedVacations[i]);
    }
    let chk = false;
    for (let i = 0; i < c.length; i++) {
      if (c[i] == e.target.value) {
        chk = true;
        index = i;
      }
    }
    if (!chk) {
      c.push(parseInt(e.target.value));
    } else {
      c.splice(index, 1);
    }
    setCheckedVacations(c);
  }

  function onChangeSocialInsurances(e: any, index: any) {
    setEdited(true);
    let c = [];
    for (let i = 0; i < checkedSocialInsurances.length; i++) {
      c.push(checkedSocialInsurances[i]);
    }
    let chk = false;
    for (let i = 0; i < c.length; i++) {
      if (c[i] == e.target.value) {
        chk = true;
        index = i;
      }
    }
    if (!chk) {
      c.push(parseInt(e.target.value));
    } else {
      c.splice(index, 1);
    }
    setCheckedSocialInsurances(c);
  }

  function onChangeSelectionMethods(e: any, index: any) {
    setEdited(true);
    let c = [];
    for (let i = 0; i < checkedSelectionMethods.length; i++) {
      c.push(checkedSelectionMethods[i]);
    }
    let chk = false;
    for (let i = 0; i < c.length; i++) {
      if (c[i] == e.target.value) {
        chk = true;
        index = i;
      }
    }
    if (!chk) {
      c.push(e.target.value);
    } else {
      c.splice(index, 1);
    }
    setCheckedSelectionMethods(c);
  }

  function changeLowerEstimatedAnnualIncome(e: any) {
    setEdited(true);
    setLowerEstimatedAnnualIncome(e.target.value);
  }

  function changeUpperEstimatedAnnualIncome(e: any) {
    setEdited(true);
    setUpperEstimatedAnnualIncome(e.target.value);
  }

  function updateValuePrefectures(e: any, index: any) {
    setEdited(true);
    let c = [];
    for (let i = 0; i < mySelectedPrefectures.length; i++) {
      c.push(mySelectedPrefectures[i]);
    }
    let chk = false;
    for (let i = 0; i < c.length; i++) {
      if (c[i] == e.target.value) {
        chk = true;
        index = i;
      }
    }
    if (!chk) {
      c.push(e.target.value);
      prefectures
        .filter((data) => e.target.value.includes(data.region_id.toString()))
        .map((i: any) => selectedPrefectures.push(i.id));
    } else {
      c.splice(index, 1);
      prefectures
        .filter((data) => e.target.value.includes(data.region_id.toString()))
        .forEach((i) => {
          const indexToRemove = selectedPrefectures.indexOf(i.id);
          if (indexToRemove !== -1) {
            selectedPrefectures.splice(indexToRemove, 1);
          }
        });
    }
    setMySelectedPrefectures(c);
  }
  function updateValuePrefectures2(e: any, id: any) {
    setEdited(true);
    setSelectedPrefectures((prevSelectedPrefectures) => {
      const newValue = id;
      const isSelected = prevSelectedPrefectures.includes(newValue);

      if (isSelected) {
        const updatedPrefectures = prevSelectedPrefectures.filter(
          (item) => item !== newValue
        );
        return updatedPrefectures;
      } else {
        const updatedPrefectures = [...prevSelectedPrefectures, newValue];
        return updatedPrefectures;
      }
    });
  }
  
  function updatePurpose(e: any, index: any) {
    setEdited(true);
    let c = [];
    for (let i = 0; i < selectHandicapTypesIds.length; i++) {
      c.push(selectHandicapTypesIds[i]);
    }
    let chk = false;
    for (let i = 0; i < c.length; i++) {
      if (c[i] == e.target.value) {
        chk = true;
        index = i;
      }
    }
    if (!chk) {
      c.push(Number(e.target.value));
    } else {
      c.splice(index, 1);
    }
    setSelectHandicapTypesIds(c);
  }

  // const handler_appeal_item = (id:any, name:any, index:any) => {
  //   // setAppeal_item((prevItems) => {
  //   //   return {
  //   //     ...prevItems,
  //   //     [`appeal_item_${index + 1}`]: { id, name },
  //   //   };
  //   // });
  //   setEdited(true);
  //   if(index == 0) {
  //     setAppeal_item((prevItems: any) => {
  //       return {
  //         ...prevItems,
  //         appeal_item_id_1 : id,
  //       };
  //     });
  //   } else if(index == 1) {
  //     setAppeal_item((prevItems: any) => {
  //       return {
  //         ...prevItems,
  //         appeal_item_id_2 : id,
  //       };
  //     });
  //   } else {
  //     setAppeal_item((prevItems: any) => {
  //       return {
  //         ...prevItems,
  //         appeal_item_id_3 : id,
  //       };
  //     });
  //   }
  // };

  useEffect(() => {
    let c = [];
    setAppealItems([]);
    if (appealitems1 !== null) {
      c.push(appealitems1);
    }
    if (appealitems2 !== null) {
      c.push(appealitems2);
    }
    if (appealitems3 !== null) {
      c.push(appealitems3);
    }
    setAppealItems(c);
  }, []);

  function defaultText() {
    return onChangeUpdate ? currentStatusName : "選択してください";
  }

  function updateStatus(e: any) {
    if (onChangeUpdate) {
      return;
    }
    if (recruitmentId) {
      throw new Error("求人IDが不正です");
    }
    let answer = false;
    switch (e) {
      case 2:
        answer = window.confirm(
          "求人票の記入に誤りはありませんか。\n本当に公開しますか？"
        );
        navigate("/recruiter/recruitments/" + recruitmentId + "/");
        return;
      case 3:
        answer = window.confirm(
          "ステータスを応募終了に変更すると、選考途中の応募者が全員不採用となり、募集の再開はできなくなります。\n本当に実行しますか？"
        );
        break;
      default:
        answer = window.confirm("求人ステータスを変更しますか？");
        break;
    }
    if (!answer) {
      setStatusId(null);
      return;
    }

    dispatch(fetchRecruitmentsStepTwoRequest(recruitmentId, statusId));
    if (recruitmentsStepTwoData !== null) {
      navigate("/recruiter/recruitments/" + recruitmentId);
    } else {
      window.alert("サーバーエラー");
    }
  }

  function isdisabled() {
    if (updateStatuses === undefined || updateStatuses == null) {
      return true;
    }
    if (updateStatuses.length === 0) {
      return true;
    }
    return false;
  }

  function validates() {
    let result = "";
    if (!job_title) {
      result += "職業名\n";
    }
    if (selectedJobCategoryId == null || selectedJobCategoryId == '' || selectedJobCategoryId.length == 0) {
      result += "職種\n";
    }
    if (!business_content) {
      result += "業務内容\n";
    }
    if (!recruitmentEmploymentStatusId) {
      result += "雇用形態\n";
    }
    if (!selectedWorkFromHome) {
      result += "働き方・テレワーク\n";
    }
    if (!nationWide) {
      if (postCode) {
      } else {
        result += "勤務地\n";
      }
    }
    if (recruitmentPeriodTrialPeriodExists === null) {
      result += "試用期間有無\n";
    }
    if (!workTimes[0]) {
      result += "始業時間①\n";
    }
    if (!workTimes[0]) {
      result += "終業時間①\n";
    }
    if (!restTime) {
      result += "休憩時間\n";
    }
    if (!numberOfWorkingDays) {
      result += "勤務日数/週\n";
    }
    if (!checkedHolidays) {
      result += "休日\n";
    }
    if (!salaryFormId) {
      result += "給与方式\n";
    }
    if (!lowerMonthlyBasicSalary || !upperMonthlyBasicSalary) {
      result += "基本給\n";
    }
    if (annualNumberOfBonusExists === null) {
      result += "賞与\n";
    }
    if (
      salaryFormId == 1 &&
      (!lowerEstimatedAnnualIncome || !upperEstimatedAnnualIncome)
    ) {
      result += "想定年収\n";
    }
    if (annualNumberOfRaisesExists === null) {
      result += "昇給\n";
    }
    if (!checkedSocialInsurances) {
      result += "社会保険\n";
    }
    if (isRetirementMoneyExistsVal === null) {
      result += "退職金制度\n";
    }
    // if(appeal_recruiter_items_corporate_culture.length > 255){
    //   result += "社風・職場の雰囲気文字数がオーバーしています。\n";
    // }

    // if(appeal_recruiter_items_business_flow.length > 255){
    //   result += "1日の業務の流れ文字数がオーバーしています。\n";
    // }

    // if(appeal_recruiter_items_superior_message.length > 255){
    //   result += "先輩社員からのコメント文字数がオーバーしています。\n";
    // }

    // if(appeal_recruiter_items_career_path.length > 255){
    //   result += "キャリアパス文字数がオーバーしています。\n";
    // }

    // if(appeal_recruiter_items_consideration_condition.length > 255){
    //   result += "現場での合理的配慮例文字数がオーバーしています。\n";
    // }
    // if(appeal_recruiter_items_hr_message.length > 255){
    //   result += "採用担当メッセージ文字数がオーバーしています。\n";
    // }

    
    let tCount = 0;
    for (let i = 0; i < previewDisplayTexts.length; i++) {
      const textObject = previewDisplayTexts[i];
      if (textObject != undefined && textObject != null && textObject != "") {
        const values = Object.values(textObject);
        if (!values.some((text: any) => text.length > 0)) {
          result += "一覧画面用 業務内容\n";
          break;
        }
      } else {
        tCount++;
      }
    }
    if (previewDisplayTexts.length == 0) {
      result += "一覧画面用 業務内容\n";
    } else if (tCount == previewDisplayTexts.length) {
      result += "一覧画面用 業務内容\n";
    }

    if (newGraduate === null) {
      result += "希望の採用要件 採用種別\n";
    }
    if (numberOfEmployees == "" || numberOfEmployees == null) {
      result += "希望の採用要件 想定採用人数\n";
    }
    if (scheduleId === null) {
      result += "希望の採用要件 採用スケジュール\n";
    }
    if (selectedPrefectures.length == 0) {
      result += "希望の採用要件 地域\n";
    }
    if (selectHandicapTypesIds.length == 0) {
      result += "希望の採用要件 障がい区分\n";
    }
    if (recruitmentCompatibilities.length == 0) {
      result += "希望の採用要件 求める人物像\n";
    }

    if (appeal_item_1 === null) {
      result += "エントリー時に聞きたい内容 アピール項目１\n";
    }
    if (appeal_item_2 === null) {
      result += "エントリー時に聞きたい内容 アピール項目２\n";
    }
    if (appeal_item_3 === null) {
      result += "エントリー時に聞きたい内容 アピール項目３\n";
    }

    return result;
  }



  function validatesSub() {
    let result = "";
    if (isErrorBusinessContent) {
      result += "業務内容文字数がオーバーしています。\n";
    }

    if (isErrorRequiredExperience) {
      result += "必要な経験・資格文字数がオーバーしています。\n";
    }

    if (isErrorBusinessContent) {
      result += "業務内容文字数がオーバーしています。\n";
    }
    if (isErrorTrialPeriodSupplemental) {
      result += "試用期間文字数がオーバーしています。\n";
    }

    
    if (isErrorWorkLocationSupplemental) {
      result += "勤務地文字数がオーバーしています。\n";
    }

    
    if (isErrorWorkTimeSupplemental) {
      result += "勤務時間 文字数がオーバーしています。\n";
    }
    if (isErrorRestTimeSupplemental) {
      result += "休憩時間文字数がオーバーしています。\n";
    }
    if (isErrorNumberOfWorkingDaysSupplemental) {
      result += "勤務日文字数がオーバーしています。\n";
    }
    if (isErrorOverTimeHoursSupplemental) {
      result += "残業時間補足文字数がオーバーしています。\n";
    }
    if (isErrorHolidaysSupplemental) {
      result += "休日文字数がオーバーしています。\n";
    }
    if (isErrorAllowancesSupplemental) {
      result += "給与・賞与文字数がオーバーしています。\n";
    }
    if (isErrorBenefitSupplemental) {
      result += "福利厚生文字数がオーバーしています。\n";
    }

    if (isErrorSelectionMethodSupplemental) {
      result += "選考プロセス文字数がオーバーしています。\n";
    }

    if (isErrorPreviewDisplayText0) {
      result += "一覧画面用 業務内容文字数がオーバーしています。\n";
    }

    if (isErrorPreviewDisplayText1) {
      result += "一覧画面用 業務内容文字数がオーバーしています。\n";
    }

    if (isErrorPreviewDisplayText2) {
      result += "一覧画面用 業務内容文字数がオーバーしています。\n";
    }

    if (isErrorPreviewDisplayText3) {
      result += "一覧画面用 業務内容文字数がオーバーしています。\n";
    }
    

    if(appeal_recruiter_items_corporate_culture.length > 255){
      result += "社風・職場の雰囲気文字数がオーバーしています。\n";
    }

    if(appeal_recruiter_items_business_flow.length > 255){
      result += "1日の業務の流れ文字数がオーバーしています。\n";
    }

    if(appeal_recruiter_items_superior_message.length > 255){
      result += "先輩社員からのコメント文字数がオーバーしています。\n";
    }

    if(appeal_recruiter_items_career_path.length > 255){
      result += "キャリアパス文字数がオーバーしています。\n";
    }

    if(appeal_recruiter_items_consideration_condition.length > 255){
      result += "現場での合理的配慮例文字数がオーバーしています。\n";
    }
    if(appeal_recruiter_items_hr_message.length > 255){
      result += "採用担当メッセージ文字数がオーバーしています。\n";
    }

    return result;
  }

  function submit(notDisplayMessage = false, state: any) {
    setLoadScreen(true);
    const validatedText = validates();
    const validatedTextSub = validatesSub();

    setStates(state);

    if (Number(currentStatusId) == 2 || Number(state) == 2) {
      if (validatedText !== "" ) {
        setLoadScreen(false);
        alert("未入力項目があります\n\n" + validatedText);
        return false;
      }
    }
    if(validatedTextSub !==""){
      setLoadScreen(false);
      alert("未入力項目があります\n\n" + validatedTextSub);
      return false;
    }

    let corporate_culture_text =
      txt1 == appeal_recruiter_items_corporate_culture
        ? null
        : appeal_recruiter_items_corporate_culture;
    let business_flow =
      txt2 == appeal_recruiter_items_business_flow
        ? null
        : appeal_recruiter_items_business_flow;
    let superior_message =
      txt3 == appeal_recruiter_items_superior_message
        ? null
        : appeal_recruiter_items_superior_message;
    let career_path =
      txt4 == appeal_recruiter_items_career_path
        ? null
        : appeal_recruiter_items_career_path;
    let consideration_condition_text =
      txt5 == appeal_recruiter_items_consideration_condition
        ? null
        : appeal_recruiter_items_consideration_condition;
    let hr_message =
      txt6 == appeal_recruiter_items_hr_message
        ? null
        : appeal_recruiter_items_hr_message;

    let result = "";
    const list = [
      { key: job_title, val: "職種名", count: 20 },
      { key: business_content, val: "業務内容", count: 400 },
      { key: required_experience, val: "必要な経験・資格", count: 200 },
      {
        key: recruitmentPeriodContractPeriodSupplement,
        val: "契約期間補足",
        count: 200,
      },
      {
        key: recruitmentPeriodTrialPeriodSupplement,
        val: "試用期間補足",
        count: 200,
      },
      { key: workTimeSupplemental, val: "勤務時間補足", count: 200 },
      { key: restTimeSupplemental, val: "休憩時間補足", count: 200 },
      { key: numberOfWorkingDaysSupplemental, val: "勤務日数補足", count: 200 },
      { key: allowancesSupplemental, val: "各種手当補足", count: 200 },
      { key: holidaysSupplemental, val: "休日 その他特記事項", count: 200 },
      {
        key: overTimeHoursSupplemental,
        val: "福利厚生 その他特記事項",
        count: 200,
      },
      { key: benefitSupplemental, val: "福利厚生 その他特記事項", count: 200 },
      {
        key: selectionMethodSupplemental,
        val: "選考方法 その他特記事項",
        count: 400,
      },
      { key: previewDisplayText0, val: "一覧画面用 業務内容1", count: 20 },
      { key: previewDisplayText1, val: "一覧画面用 業務内容2", count: 20 },
      { key: previewDisplayText2, val: "一覧画面用 業務内容3", count: 20 },
      { key: previewDisplayText3, val: "一覧画面用 業務内容4", count: 20 },
      { key: corporate_culture_text, val: "社風・職場の雰囲気", count: 400 },
      { key: business_flow, val: "1日の業務の流れ", count: 400 },
      { key: superior_message, val: "先輩社員からのコメント", count: 400 },
      { key: career_path, val: "キャリアパス", count: 400 },
      {
        key: consideration_condition_text,
        val: "現場での合理的配慮例",
        count: 400,
      },
      { key: hr_message, val: "採用担当メッセージ", count: 400 },
    ];
    list.forEach((obj) => {
      if (obj.key && obj.key.length > obj.count) {
        result += obj.val + "は" + obj.count + "文字以内で入力してください\n";
      }
    });

    if (Number(state) == 2) {
      if (
        window.confirm(
          "求人票の記入に誤りはありませんか。\n本当に公開しますか？"
        )
      ) {
      } else {
        setLoadScreen(false);
        setStatusId(undefined);
        return false;
      }
    }
    let count = 0;
    for (let i = 0; i < images.length; i++) {
      if (images[i].url == "") {
        images[i].url = null;
      }
      images[i].image = images[i].url;
      if (
        Number(state) == 2 &&
        (images[i].url == null || images[i].url.length == 0)
      ) {
        count += 1;
        if (count == 5) {
          result += "画像（最低1枚はご登録ください）\n";
          window.alert(result);
          count = 0;
          setLoadScreen(false);
          return false;
        }
      }
      // delete images[i].url;
    }

    const updatedRecruitmen = {
      ...recruiterRecruitmentsDetail.recruiterRecruitmentsDetail,
    };
    updatedRecruitmen.job_title = job_title;
    updatedRecruitmen.allowances = mySelectedAllowancesId;
    updatedRecruitmen.appeal_item = appeal_item;
    updatedRecruitmen.appeal_item = {
      appeal_item_1: {
        id: appeal_item_1,
        name: appeal_item_1_name,
      },
      appeal_item_2: {
        id: appeal_item_2,
        name: appeal_item_2_name,
      },
      appeal_item_3: {
        id: appeal_item_3,
        name: appeal_item_3_name,
      },
      appeal_item_id_1: appeal_item_1,
      appeal_item_id_2: appeal_item_2,
      appeal_item_id_3: appeal_item_3,
    };
    updatedRecruitmen.benefit_supplemental = benefitSupplemental;
    updatedRecruitmen.business_content = business_content;
    updatedRecruitmen.business_flow = business_flow ? business_flow : null;
    updatedRecruitmen.career_path = career_path ? career_path : null;
    updatedRecruitmen.company_id = company_id;
    updatedRecruitmen.company_name = company_name;
    updatedRecruitmen.compatibilities = recruitmentCompatibilities;
    updatedRecruitmen.consideration_condition_text =
      consideration_condition_text ? consideration_condition_text : null;
    updatedRecruitmen.consideration_conditions =
      selectedConsiderationConditionsId;
    updatedRecruitmen.corporate_culture_text = corporate_culture_text
      ? corporate_culture_text
      : null;
    // if (Number(state) == 2 ) {
    updatedRecruitmen.status_id = Number(state);
    // }
    updatedRecruitmen.employment_status_id = recruitmentEmploymentStatusId;
    updatedRecruitmen.handicap_types = selectHandicapTypesIds;
    updatedRecruitmen.holidays = checkedHolidays;
    updatedRecruitmen.hr_message = hr_message ? hr_message : null;
    updatedRecruitmen.images = images;
    updatedRecruitmen.job_category_id = selectedJobCategoryId;
    updatedRecruitmen.movie_path = movie_path ? movie_path : null;
    updatedRecruitmen.new_graduate = newGraduate;
    updatedRecruitmen.office_number = null;
    const period = {
      contract_period_exists: recruitmentPeriodContractPeriodExists,
      contract_period_id: recruitmentPeriodContractPeriodId,
      contract_period_supplemental: recruitmentPeriodContractPeriodSupplemental,
      contract_renewal_exists: recruitmentPeriodContractRenewalExists,
      full_time_employee_exists: recruitmentPeriodFullTimeEmployeeExists,
      trial_period_exists: recruitmentPeriodTrialPeriodExists,
      trial_period_id: selectedTrailPeriod,
      trial_period_supplemental: recruitmentPeriodTrialPeriodSupplement,
    };
    updatedRecruitmen.period = period;
    updatedRecruitmen.prefectures = selectedPrefectures;
    updatedRecruitmen.preview_display_text = previewDisplayTexts;
    updatedRecruitmen.preview_display_texts = previewDisplayTexts;
    updatedRecruitmen.required_experience = required_experience;
    updatedRecruitmen.retirement_age = retirementAge;
    updatedRecruitmen.retirement_money_exists = isRetirementMoneyExistsVal;
    const salary = {
      allowances_supplemental: allowancesSupplemental,
      annual_number_of_bonus: annualNumberOfBonus,
      annual_number_of_bonus_exists: annualNumberOfBonusExists,
      annual_number_of_raises: annualNumberOfRaises,
      annual_number_of_raises_exists: annualNumberOfRaisesExists,
      lower_estimated_annual_income: lowerEstimatedAnnualIncome,
      lower_monthly_basic_salary: lowerMonthlyBasicSalary,
      salary_form_id: salaryFormId,
      upper_estimated_annual_income: upperEstimatedAnnualIncome,
      upper_monthly_basic_salary: upperMonthlyBasicSalary,
    };
    updatedRecruitmen.salary = salary;
    updatedRecruitmen.selection_method_supplemental =
      selectionMethodSupplemental;
    updatedRecruitmen.selection_methods = checkedSelectionMethods;
    updatedRecruitmen.social_insurances = checkedSocialInsurances;
    updatedRecruitmen.superior_message = superior_message
      ? superior_message
      : null;
    const target = {
      number_of_employees: numberOfEmployees,
      schedule_id: scheduleId,
    };
    updatedRecruitmen.target = target;
    updatedRecruitmen.type_of_recruiting_company_id = typeOfRecruitingCompanyId;
    updatedRecruitmen.update_statuses = updateStatuses;
    updatedRecruitmen.vacations = checkedVacations;
    updatedRecruitmen.work_environment_text = recruitmentWorkEnvironmentsText;
    updatedRecruitmen.work_environments = recruitmentWorkEnvironments;
    const worklocation = {
      address: address,
      city_id: cityId,
      city_name: cityName,
      department: recruitmentWorkLocationDepartment,
      nationwide: nationWide,
      nearest_station: nearestStation,
      postcode: postCode,
      prefecture_id: prefectureId,
      prefecture_name: prefectureName,
      region_id: region,
      transportation: transportation,
      work_from_home_id: selectedWorkFromHome,
      work_location_supplemental: workLocationSupplemental,
    };
    updatedRecruitmen.work_location = worklocation;

    const workTime = workTimes.map((time: any) =>
      time !== null ? time.split(" ～ ") : [null, null]
    );

    const worktime1 = {
      annual_holiday: annualHoliday,
      end_time1: endTime1,
      end_time2: endTime2,
      end_time3: endTime3,
      holidays_supplemental: holidaysSupplemental,
      number_of_working_days: numberOfWorkingDays,
      number_of_working_days_supplemental: numberOfWorkingDaysSupplemental,
      overtime_hours_exists: isOvertimeHoursExists,
      overtime_hours_supplemental: overTimeHoursSupplemental,
      rest_time: restTime,
      rest_time_supplemental: restTimeSupplemental,
      start_time1: startTime1,
      start_time2: startTime2,
      start_time3: startTime3,
      work_time_supplemental: workTimeSupplemental,
    };
    updatedRecruitmen.work_time = worktime1;
    updatedRecruitmen.handicap_genre_ids  = selectedMatomeIds;
    setEdited(false);
    dispatch(fetchRecruitmentsStepTwoRequest(recruitmentId, updatedRecruitmen));

  }
  function update(notDisplayMessage = false, state: any) {
    setLoadScreen(true);
    const validatedText = validates();
    const validatedTextSub = validatesSub();

    console.log(state);
    setStates(state);

    if (Number(currentStatusId) == 2 || Number(state) == 2) {
      if (validatedText !== "") {
        setLoadScreen(false);
        alert("未入力項目があります\n\n" + validatedText);
        return false;
      }
    } else {
      let result = "";
      if (selectedJobCategoryId == null || selectedJobCategoryId == '' || selectedJobCategoryId.length == 0) {
        setLoadScreen(false);
        result += "職種\n";
        alert("未入力項目があります\n\n" + result);
        return false;
      }
    }
    if(validatedTextSub !=  ''){
        setLoadScreen(false);
        alert("未入力項目があります\n\n" + validatedTextSub);
        return false;
    }

    let corporate_culture_text =
      txt1 == appeal_recruiter_items_corporate_culture
        ? null
        : appeal_recruiter_items_corporate_culture;
    let business_flow =
      txt2 == appeal_recruiter_items_business_flow
        ? null
        : appeal_recruiter_items_business_flow;
    let superior_message =
      txt3 == appeal_recruiter_items_superior_message
        ? null
        : appeal_recruiter_items_superior_message;
    let career_path =
      txt4 == appeal_recruiter_items_career_path
        ? null
        : appeal_recruiter_items_career_path;
    let consideration_condition_text =
      txt5 == appeal_recruiter_items_consideration_condition
        ? null
        : appeal_recruiter_items_consideration_condition;
    let hr_message =
      txt6 == appeal_recruiter_items_hr_message
        ? null
        : appeal_recruiter_items_hr_message;

    let result = "";
    const list = [
      { key: job_title, val: "職種名", count: 20 },
      { key: business_content, val: "業務内容", count: 400 },
      { key: required_experience, val: "必要な経験・資格", count: 200 },
      {
        key: recruitmentPeriodContractPeriodSupplement,
        val: "契約期間補足",
        count: 200,
      },
      {
        key: recruitmentPeriodTrialPeriodSupplement,
        val: "試用期間補足",
        count: 200,
      },
      { key: workTimeSupplemental, val: "勤務時間補足", count: 200 },
      { key: restTimeSupplemental, val: "休憩時間補足", count: 200 },
      { key: numberOfWorkingDaysSupplemental, val: "勤務日数補足", count: 200 },
      { key: allowancesSupplemental, val: "各種手当補足", count: 200 },
      { key: holidaysSupplemental, val: "休日 その他特記事項", count: 200 },
      {
        key: overTimeHoursSupplemental,
        val: "福利厚生 その他特記事項",
        count: 200,
      },
      { key: benefitSupplemental, val: "福利厚生 その他特記事項", count: 200 },
      {
        key: selectionMethodSupplemental,
        val: "選考方法 その他特記事項",
        count: 400,
      },
      { key: previewDisplayText0, val: "一覧画面用 業務内容1", count: 20 },
      { key: previewDisplayText1, val: "一覧画面用 業務内容2", count: 20 },
      { key: previewDisplayText2, val: "一覧画面用 業務内容3", count: 20 },
      { key: previewDisplayText3, val: "一覧画面用 業務内容4", count: 20 },
      { key: corporate_culture_text, val: "社風・職場の雰囲気", count: 400 },
      { key: business_flow, val: "1日の業務の流れ", count: 400 },
      { key: superior_message, val: "先輩社員からのコメント", count: 400 },
      { key: career_path, val: "キャリアパス", count: 400 },
      {
        key: consideration_condition_text,
        val: "現場での合理的配慮例",
        count: 400,
      },
      { key: hr_message, val: "採用担当メッセージ", count: 400 },
    ];
    list.forEach((obj) => {
      if (obj.key && obj.key.length > obj.count) {
        result += obj.val + "は" + obj.count + "文字以内で入力してください\n";
      }
    });

    if (Number(state) == 2) {
      if (
        window.confirm(
          "求人票の記入に誤りはありませんか。\n本当に公開しますか？"
        )
      ) {
      } else {
        setLoadScreen(false);
        setStatusId(undefined);
        return false;
      }
    }
    let count = 0;
    for (let i = 0; i < images.length; i++) {
      if (images[i].url == "") {
        images[i].url = null;
      }
      images[i].image = images[i].url;
      if (
        Number(state) == 2 &&
        (images[i].url == null || images[i].url.length == 0)
      ) {
        count += 1;
        if (count == 5) {
          result += "画像（最低1枚はご登録ください）\n";
          window.alert(result);
          count = 0;
          setLoadScreen(false);
          return false;
        }
      }
      // delete images[i].url;
    }

    const updatedRecruitmen = {
      ...recruiterRecruitmentsDetail.recruiterRecruitmentsDetail,
    };
    updatedRecruitmen.job_title = job_title;
    updatedRecruitmen.allowances = mySelectedAllowancesId;
    updatedRecruitmen.appeal_item = appeal_item;
    updatedRecruitmen.appeal_item = {
      appeal_item_1: {
        id: appeal_item_1,
        name: appeal_item_1_name,
      },
      appeal_item_2: {
        id: appeal_item_2,
        name: appeal_item_2_name,
      },
      appeal_item_3: {
        id: appeal_item_3,
        name: appeal_item_3_name,
      },
      appeal_item_id_1: appeal_item_1,
      appeal_item_id_2: appeal_item_2,
      appeal_item_id_3: appeal_item_3,
    };
    updatedRecruitmen.benefit_supplemental = benefitSupplemental;
    updatedRecruitmen.business_content = business_content;
    updatedRecruitmen.business_flow = business_flow ? business_flow : null;
    updatedRecruitmen.career_path = career_path ? career_path : null;
    updatedRecruitmen.company_id = company_id;
    updatedRecruitmen.company_name = company_name;
    updatedRecruitmen.compatibilities = recruitmentCompatibilities;
    updatedRecruitmen.consideration_condition_text =
      consideration_condition_text ? consideration_condition_text : null;
    updatedRecruitmen.consideration_conditions =
      selectedConsiderationConditionsId;
    updatedRecruitmen.corporate_culture_text = corporate_culture_text
      ? corporate_culture_text
      : null;
    // if (Number(state) == 2 ) {
    // updatedRecruitmen.status_id = Number(state);
    // }
    updatedRecruitmen.employment_status_id = recruitmentEmploymentStatusId;
    updatedRecruitmen.handicap_types = selectHandicapTypesIds;
    updatedRecruitmen.holidays = checkedHolidays;
    updatedRecruitmen.hr_message = hr_message ? hr_message : null;
    updatedRecruitmen.images = images;
    updatedRecruitmen.job_category_id = selectedJobCategoryId;
    updatedRecruitmen.movie_path = movie_path ? movie_path : null;
    updatedRecruitmen.new_graduate = newGraduate;
    updatedRecruitmen.office_number = null;
    const period = {
      contract_period_exists: recruitmentPeriodContractPeriodExists,
      contract_period_id: recruitmentPeriodContractPeriodId,
      contract_period_supplemental: recruitmentPeriodContractPeriodSupplemental,
      contract_renewal_exists: recruitmentPeriodContractRenewalExists,
      full_time_employee_exists: recruitmentPeriodFullTimeEmployeeExists,
      trial_period_exists: recruitmentPeriodTrialPeriodExists,
      trial_period_id: selectedTrailPeriod,
      trial_period_supplemental: recruitmentPeriodTrialPeriodSupplement,
    };
    updatedRecruitmen.period = period;
    updatedRecruitmen.prefectures = selectedPrefectures;
    updatedRecruitmen.preview_display_text = previewDisplayTexts;
    updatedRecruitmen.preview_display_texts = previewDisplayTexts;
    updatedRecruitmen.required_experience = required_experience;
    updatedRecruitmen.retirement_age = retirementAge;
    updatedRecruitmen.retirement_money_exists = isRetirementMoneyExistsVal;
    const salary = {
      allowances_supplemental: allowancesSupplemental,
      annual_number_of_bonus: annualNumberOfBonus,
      annual_number_of_bonus_exists: annualNumberOfBonusExists,
      annual_number_of_raises: annualNumberOfRaises,
      annual_number_of_raises_exists: annualNumberOfRaisesExists,
      lower_estimated_annual_income: lowerEstimatedAnnualIncome,
      lower_monthly_basic_salary: lowerMonthlyBasicSalary,
      salary_form_id: salaryFormId,
      upper_estimated_annual_income: upperEstimatedAnnualIncome,
      upper_monthly_basic_salary: upperMonthlyBasicSalary,
    };
    updatedRecruitmen.salary = salary;
    updatedRecruitmen.selection_method_supplemental =
      selectionMethodSupplemental;
    updatedRecruitmen.selection_methods = checkedSelectionMethods;
    updatedRecruitmen.social_insurances = checkedSocialInsurances;
    updatedRecruitmen.superior_message = superior_message
      ? superior_message
      : null;
    const target = {
      number_of_employees: numberOfEmployees,
      schedule_id: scheduleId,
    };
    updatedRecruitmen.target = target;
    updatedRecruitmen.type_of_recruiting_company_id = typeOfRecruitingCompanyId;
    updatedRecruitmen.update_statuses = updateStatuses;
    updatedRecruitmen.vacations = checkedVacations;
    updatedRecruitmen.work_environment_text = recruitmentWorkEnvironmentsText;
    updatedRecruitmen.work_environments = recruitmentWorkEnvironments;
    const worklocation = {
      address: address,
      city_id: cityId,
      city_name: cityName,
      department: recruitmentWorkLocationDepartment,
      nationwide: nationWide,
      nearest_station: nearestStation,
      postcode: postCode,
      prefecture_id: prefectureId,
      prefecture_name: prefectureName,
      region_id: region,
      transportation: transportation,
      work_from_home_id: selectedWorkFromHome,
      work_location_supplemental: workLocationSupplemental,
    };
    updatedRecruitmen.work_location = worklocation;

    const workTime = workTimes.map((time: any) =>
      time !== null ? time.split(" ～ ") : [null, null]
    );

    const worktime1 = {
      annual_holiday: annualHoliday,
      end_time1: endTime1,
      end_time2: endTime2,
      end_time3: endTime3,
      holidays_supplemental: holidaysSupplemental,
      number_of_working_days: numberOfWorkingDays,
      number_of_working_days_supplemental: numberOfWorkingDaysSupplemental,
      overtime_hours_exists: isOvertimeHoursExists,
      overtime_hours_supplemental: overTimeHoursSupplemental,
      rest_time: restTime,
      rest_time_supplemental: restTimeSupplemental,
      start_time1: startTime1,
      start_time2: startTime2,
      start_time3: startTime3,
      work_time_supplemental: workTimeSupplemental,
    };
    updatedRecruitmen.work_time = worktime1;

    updatedRecruitmen.handicap_genre_ids  = selectedMatomeIds;
    
    setEdited(false);    
    setIsUpdateButtonClicked(true);
    dispatch(fetchRecruitmentsStepTwoRequest(recruitmentId, updatedRecruitmen));
  }

  useEffect(() => {
    if (recruitmentsStepTwoData.pending && isUpdateButtonClicked) {
      setIsUpdateButtonClicked(false);
      setLoadScreen(false);
      setTimeout(() => {     
        window.scrollTo(0, 50);   
        window.location.reload();
      }, 3000);
      let alertText = Number(states) == 2 ? "公開しました" : "保存しました";
      window.alert(alertText);
      // changeImage1 = null;
      // changeImage2 = null;
      // changeImage3 = null;
      // changeImage4 = null;
      // changeImage5 = null;
    } else if (recruitmentsStepTwoData.error != null) {
      setLoadScreen(false);
      navigate("/error?code=500");
    } else if(recruitmentsStepTwoData.status == 204) {   
      setIsUpdateButtonClicked(false);
      setLoadScreen(false);  
      setTimeout(() => {     
        window.scrollTo(0, 50);   
        window.location.reload();
      }, 3000);  
      let alertText = Number(states) == 2 ? "公開しました" : "保存しました";
      window.alert(alertText);
    }
  }, [recruitmentsStepTwoData]);


  useEffect(() => {
    if(matomeList.pending) {
      recArr(matomeList.matome);
    }
  }, [matomeList]);



  function recArr(arr:any){
    let  tmp:any = [];
      if(arr.length > 0){
        for(let i = 0; i <arr.length; i++) {
          if(arr[i].genres.length > 0){
            for(let j = 0; j< arr[i].genres.length; j++){
              tmp.push(arr[i].genres[j]);
            }
          }
        }
        setFullArray(tmp);
      }
  }

  function checkSelectedAppealItem(value: any) {
    if (value == 8) {
      return false;
    }
    if (
      recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.appeal_item
        .appeal_item_id_1 == value
    ) {
      return false;
    }
    if (
      recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.appeal_item
        .appeal_item_id_2 == value
    ) {
      return false;
    }
    if (
      recruiterRecruitmentsDetail.recruiterRecruitmentsDetail.appeal_item
        .appeal_item_id_3 == value
    ) {
      return false;
    }
    return true;
  }

  function onChangeMatome(e:any, index:any) {

    let c = [];
    for(let i = 0; i < selectedMatomeIds.length; i++) {
        c.push(selectedMatomeIds[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == e.target.value) {
            chk = true;
            index = i;
        }
    }
    if(!chk) {
        c.push(parseInt(e.target.value));
    } else {
        c.splice(index, 1);
    }
    setSelectedMatomeIds(c);
}

  function handler_appealItem(e: any) {
    setEdited(true);
    if ("appeal_item_1" === e.target.name) {
      setAppeal_item_1(e.target.value);
      for (let i = 0; i < appealItems.length; i++) {
        if (appealItems[i].id == e.target.value) {
          setAppeal_item_1_name(appealItems[i].name);
        }
      }
      setAppeal_item((prevItems: any) => {
        return {
          ...prevItems,
          appeal_item_id_1: parseInt(e.target.value),
        };
      });
    }
    if ("appeal_item_2" === e.target.name) {
      setAppeal_item_2(e.target.value);
      for (let i = 0; i < appealItems.length; i++) {
        if (appealItems[i].id == e.target.value) {
          setAppeal_item_2_name(appealItems[i].name);
        }
      }
      setAppeal_item((prevItems: any) => {
        return {
          ...prevItems,
          appeal_item_id_2: parseInt(e.target.value),
        };
      });
    }
    if ("appeal_item_3" === e.target.name) {
      setAppeal_item_3(e.target.value);
      for (let i = 0; i < appealItems.length; i++) {
        if (appealItems[i].id == e.target.value) {
          setAppeal_item_3_name(appealItems[i].name);
        }
      }
      setAppeal_item((prevItems: any) => {
        return {
          ...prevItems,
          appeal_item_id_3: parseInt(e.target.value),
        };
      });
    }
  }

  function checkList1(arr: any[]) {
    let newArr: any[] = [];
    newArr = arr.filter(
      (n) =>
        n.id != appeal_item_1 && n.id != appeal_item_2 && n.id != appeal_item_3
    );
    return newArr;
  }

  return (
    <main id="company">
      <MypageSideBar
        resource={resourceId}
        familyName={familyName}
        currentList="RecruiterList"
      />
      {loadScreen ? <Loader /> : <></>}
      <section>
        <ul className="bread_crumb">
          <li>
            <a href="/">トップ</a>
          </li>
          <li>
            <a href="/recruiter/">企業様マイページ</a>
          </li>
          <li>
            <a href="#">求人管理</a>
          </li>
        </ul>
        <div className="ttl_box">
          <h2 className="ttl">求人管理 ＞ 詳細</h2>
          <ul>
            <li>
              <a href="#" onClick={logout}>
                <img src="/assets/img/company/icon02.svg" alt="" />
                ログアウト
              </a>
            </li>
          </ul>
        </div>
        <div className="jobs edit">
          {/* <div className="step_box">
            <ul>
              <li>基本情報</li>
              <li>採用要件・アピール項目</li>
              <li className="active">プレビュー</li>
            </ul>
          </div> */}
          <form id="jobs">
            {/* <button
              className="btn type3 head_btn"
              onClick={(e) => submit(true, updateBtn)}
            >
              一時保存する
            </button> */}
            <br />
            <section className="detail type1">
              <div className="cont_box">
                <h2 className="ttl">
                  {!isEditTitle ? (
                    <span onClick={(e) => setIsEditTitle(true)}>
                      {job_title}
                      <span className="edit_icon" />
                    </span>
                  ) : (
                    <span>
                      <input
                        type="text"
                        placeholder="職種名を入力してください"
                        onChange={jobtitleChange}
                        value={job_title}
                      />
                       {job_title.length >
                        jobTitleMaxLen ? (
                          <div className="error_box">
                            <p className="error_text">
                              {job_title.length -
                                jobTitleMaxLen}
                              文字オーバー
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}
                      
                      <button onClick={(e) => setIsEditTitle(false)}>
                        完了
                      </button>
                    </span>
                  )}
                </h2>
                <Swiper
                  thumbs={{ swiper: thumbsSwiper }}
                  spaceBetween={10}
                  navigation={true}
                  style={{ marginBottom: "30px", height: "340px" }}
                  modules={[FreeMode, Navigation, Thumbs]}
                >
                  {images &&
                    images.map((i: any, index: any) => {
                      return (
                        <SwiperSlide key={index}>
                          {i.url !== "" && (
                            <li
                              key={i.priority}
                              style={{ position: "relative" }}
                            >
                              <img
                                style={{
                                  height: "340px",
                                  objectFit: "contain",
                                }}
                                src={i.url}
                              ></img>
                              <input
                                style={{
                                  position: "absolute",
                                  bottom: "0",
                                  backgroundColor: "rgba(102, 102, 102, 0.6)",
                                }}
                                className="swComment"
                                type="text"
                                value={i.comment || ""}
                                placeholder="この画像の説明文をご入力下さい。"
                                onChange={(event) => {
                                  handleCommentChange(event, index);
                                }}
                              />
                              <img
                                className="imgtrash swImg"
                                src="/assets/img/company/icon05.svg"
                                onClick={(e) => delImage(i.priority)}
                              />
                            </li>
                          )}
                          {i.url == "" && (
                            <li key={"main" + index} className="list">
                              <p className="advice_txt">
                                {imagesAdvices[index]}
                              </p>
                              <label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  ref={fileInputRef}
                                  onChange={(event) => setImage(index, event)}
                                  style={{ display: "none" }}
                                />
                                <img
                                  src="/assets/img/company/icon03.svg"
                                  alt=""
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    left: "50%",
                                    top: "50%",
                                    transform: "translate(-50%, -50%)",
                                    position: "absolute",
                                  }}
                                />
                              </label>
                            </li>
                          )}
                        </SwiperSlide>
                      );
                    })}
                </Swiper>

                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={true}
                  spaceBetween={10}
                  slidesPerView={5}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="slider_edit"
                >
                  <ul className="slider_edit">
                    {images &&
                      images.map((i: any, index: any) => (
                        <SwiperSlide key={index}>
                          {i.url !== "" ? (
                            <li
                              v-if={i.url}
                              key={i.priority}
                              className="img"
                              style={{ width: "100%" }}
                            >
                              <img
                                style={{ objectFit: "cover" }}
                                src={i.url}
                                onClick={(e) => goToImage(i)}
                              />
                            </li>
                          ) : (
                            <li style={{ width: "100%" }}>
                              <p>
                                <img
                                  src="/assets/img/company/icon03.svg"
                                  className="add_icon"
                                  alt=""
                                  onClick={(e) => goToImage(i)}
                                />
                              </p>
                            </li>
                          )}
                        </SwiperSlide>
                      ))}
                  </ul>
                </Swiper>
                <p className="note">
                  ※画像は、最低1枚アップロードしてください。
                </p>
                {show[0] && (
                  <div>
                    <h3>
                      社風・職場の雰囲気
                      <img
                        onClick={() => {
                          setCorporateCulture(
                            appeal_recruiter_items_corporate_culture
                          );
                          deleteAppeal(0);
                        }}
                        src="/assets/img/company/icon05.svg"
                      />
                    </h3>
                    {!isApiLoadingCorporateCulture ? (
                      <div className="load-area-warp">
                        <p>
                          <textarea
                            cols={30}
                            rows={10}
                            value={appeal_recruiter_items_corporate_culture}
                            onChange={(e) =>
                              setCorporateCulture(e.target.value)
                            }
                            v-show={show[0]}
                          />
                        </p>
                        {appeal_recruiter_items_corporate_culture.length >
                        255 ? (
                          <div className="error_box">
                            <p className="error_text">
                              {appeal_recruiter_items_corporate_culture.length -
                                255}
                              文字オーバー
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}
                        <p
                          className="openai_link"
                          v-show={show[0]}
                          onClick={sampleTextCorporateCulture}
                        >
                          会社概要からサンプル文章を生成する
                        </p>
                      </div>
                    ) : (
                      <div className="load-area">
                        <div className="load-text">
                          <h2>サンプル文章の生成中です...</h2>
                          <div className="load-spinner">
                            <span className="spinner"></span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {show[1] && (
                  <div>
                    <h3>
                      1日の業務の流れ
                      <img
                        onClick={() => {
                          setBusinessFlow(appeal_recruiter_items_business_flow);
                          deleteAppeal(1);
                        }}
                        src="/assets/img/company/icon05.svg"
                        alt=""
                      />
                    </h3>
                    {!isApiLoadingBusinessFlow ? (
                      <div className="load-area-warp">
                        <p>
                          <textarea
                            cols={30}
                            rows={10}
                            value={appeal_recruiter_items_business_flow}
                            onChange={(e) => setBusinessFlow(e.target.value)}
                            v-show={show[1]}
                          />
                        </p>
                        {appeal_recruiter_items_business_flow.length > 255 ? (
                          <div className="error_box">
                            <p className="error_text">
                              {appeal_recruiter_items_business_flow.length -
                                255}
                              文字オーバー
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}
                        <p
                          className="openai_link"
                          v-show={show[1]}
                          onClick={(e) => sampleTextBusinessFlow(e)}
                        >
                          採用情報からサンプル文章を生成する
                        </p>
                      </div>
                    ) : (
                      <div className="load-area">
                        <div className="load-text">
                          <h2>サンプル文章の生成中です...</h2>
                          <div className="load-spinner">
                            <span className="spinner"></span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {show[2] && (
                  <div>
                    <h3>
                      先輩社員からのコメント
                      <img
                        onClick={() => {
                          setSuperiorMessage(
                            appeal_recruiter_items_superior_message
                          );
                          deleteAppeal(2);
                        }}
                        src="/assets/img/company/icon05.svg"
                        alt=""
                      />
                    </h3>
                    {!isApiLoadingSuperiorMessage ? (
                      <div className="load-area-warp">
                        <p>
                          <textarea
                            cols={30}
                            rows={10}
                            value={appeal_recruiter_items_superior_message}
                            onChange={(e) => setSuperiorMessage(e.target.value)}
                            v-show={show[2]}
                          />
                        </p>
                        {appeal_recruiter_items_superior_message.length >
                        255 ? (
                          <div className="error_box">
                            <p className="error_text">
                              {appeal_recruiter_items_superior_message.length -
                                255}
                              文字オーバー
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}
                        <p
                          className="openai_link"
                          v-show={show[2]}
                          onClick={sampleTextSuperiorMessage}
                        >
                          採用情報からサンプル文章を生成する
                        </p>
                      </div>
                    ) : (
                      <div className="load-area">
                        <div className="load-text">
                          <h2>サンプル文章の生成中です...</h2>
                          <div className="load-spinner">
                            <span className="spinner"></span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {show[3] && (
                  <div>
                    <h3>
                      キャリアパス
                      <img
                        onClick={() => {
                          setCareerPath(appeal_recruiter_items_career_path);
                          deleteAppeal(3);
                        }}
                        src="/assets/img/company/icon05.svg"
                        alt=""
                      />
                    </h3>
                    <div className="load-area-warp">
                      <p>
                        <textarea
                          cols={30}
                          rows={10}
                          value={appeal_recruiter_items_career_path}
                          onChange={(e) => setCareerPath(e.target.value)}
                          v-show={show[3]}
                        />
                      </p>
                      {appeal_recruiter_items_career_path.length > 255 ? (
                        <div className="error_box">
                          <p className="error_text">
                            {appeal_recruiter_items_career_path.length - 255}
                            文字オーバー
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}

                {show[4] && (
                  <div>
                    <h3>
                      現場での合理的配慮例
                      <img
                        onClick={() => {
                          setConsiderationCondition(
                            appeal_recruiter_items_consideration_condition
                          );
                          deleteAppeal(4);
                        }}
                        src="/assets/img/company/icon05.svg"
                        alt=""
                      />
                    </h3>
                    <p>
                      <textarea
                        cols={30}
                        rows={10}
                        // onBlur={() => setShow(4)}
                        // onKeyDown={(e) => e.key === 'Tab' && setShow(4)}
                        value={appeal_recruiter_items_consideration_condition}
                        onChange={(e) =>
                          setConsiderationCondition(e.target.value)
                        }
                        v-show={show[4]}
                      />
                    </p>
                    {appeal_recruiter_items_consideration_condition.length >
                    255 ? (
                      <div className="error_box">
                        <p className="error_text">
                          {appeal_recruiter_items_consideration_condition.length -
                            255}
                          文字オーバー
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}

                {show[5] && (
                  <div>
                    <h3>
                    採用担当メッセージ
                      <img
                        onClick={() => {
                          setCareerPath(appeal_recruiter_items_hr_message);
                          deleteAppeal(5);
                        }}
                        src="/assets/img/company/icon05.svg"
                        alt=""
                      />
                    </h3>
                    {!isApiLoadingHrMessage ? (
                      <div className="load-area-warp">
                        <p>
                          <textarea
                            cols={30}
                            rows={10}
                            value={appeal_recruiter_items_hr_message}
                            onChange={(e) => setHrMessage(e.target.value)}
                            v-show={show[5]}
                          />
                        </p>
                        {appeal_recruiter_items_hr_message.length > 255 ? (
                          <div className="error_box">
                            <p className="error_text">
                              {appeal_recruiter_items_hr_message.length - 255}
                              文字オーバー
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}
                        <p
                          className="openai_link"
                          v-show={show[5]}
                          onClick={sampleTextHrMessage}
                        >
                          採用情報からサンプル文章を生成する
                        </p>
                      </div>
                    ) : (
                      <div className="load-area">
                        <div className="load-text">
                          <h2>サンプル文章の生成中です...</h2>
                          <div className="load-spinner">
                            <span className="spinner"></span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {show[6] && (
                  <div>
                    <h3>
                      動画
                      <img
                        onClick={() => {
                          deleteAppeal(6);
                        }}
                        src="/assets/img/company/icon05.svg"
                        alt=""
                      />
                    </h3>
                    <div className="movie_box">
                      {!movie_path || isEditMoviePath ? (
                        <>
                          <label>
                            Youtube動画のURLを入力してください
                            <input
                              type="text"
                              value={movie_path2}
                              placeholder="https://www.youtube.com/xxxxxx"
                              onChange={(e) => setMoviePath2(e.target.value)}
                            />
                          </label>
                          <button onClick={EditMovie}>保存</button>
                        </>
                      ) : (
                        <>
                          <img
                            src="/assets/img/company/icon28.svg"
                            alt=""
                            onClick={() => setIsEditMoviePath(true)}
                          />
                          <div>
                            <iframe
                              width="854"
                              height="480"
                              src={movie_path}
                              frameBorder="0"
                              allow="autoplay; encrypted-media"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}

                <div className="appeal_area">
                  <p>
                    アピール項目を追加すると
                    <br className="sp_only" />
                    エントリーの可能性がアップします!!
                  </p>
                  {show.includes(false) ? (
                    <select
                      id="select-appeal-items"
                      v-model="selectedAppeal"
                      onChange={(e) =>
                        selectAppeal(
                          filter_appeal_recruiter_items().indexOf(
                            e.target.value
                          )
                        )
                      }
                    >
                      <option disabled selected>
                        アピール項目を追加する場合はご選択ください
                      </option>
                      {filter_appeal_recruiter_items().map(
                        (i, index) =>
                          !show[index] && (
                            <option key={index} value={i}>
                              {i}
                            </option>
                          )
                      )}
                    </select>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="consideration_area">
                  <h3>
                    求める人物像<span className="required">必須</span>
                  </h3>
                  <div className="edit_area">
                    <span
                      className="edit_icon"
                      onClick={(e) => setIsPerson(true)}
                    />
                    {!isPerson && recruitmentCompatibilities.length === 0 ? (
                      <p className="edit null_txt">未選択</p>
                    ) : (
                      <></>
                    )}
                    {recruitmentCompatibilities.length > 0 ? (
                      <div>
                        {!isPerson ? (
                          <ul className="tag">
                            {recruitmentCompatibilities &&
                              recruitmentCompatibilities.map(
                                (i: any, index: any) => (
                                  <div>
                                    {idealCandidatesUnique.find(
                                      (v: any) => v.id === Number(i)
                                    ) ? (
                                      <li key={index}>
                                        {
                                          idealCandidatesUnique.find(
                                            (v: any) => v.id === Number(i)
                                          ).name
                                        }
                                      </li>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )
                              )}
                          </ul>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {isPerson ? (
                      <div className="select_area _flex">
                        <div className="select_area_child">
                          {idealCandidatesUnique &&
                            idealCandidatesUnique.map((i: any, index: any) => (
                              <label key={i.id}>
                                <input
                                  type="checkbox"
                                  v-model="mySelected"
                                  value={i.id}
                                  defaultChecked={recruitmentCompatibilities.includes(
                                    i.id
                                  )}
                                  onChange={(e) =>
                                    changeRecruitmentCompatibilities(i.id)
                                  }
                                  disabled={
                                    recruitmentCompatibilities.length >
                                      maxCheckCount &&
                                    !recruitmentCompatibilities.includes(i.id)
                                  }
                                />
                                {i.name}
                              </label>
                            ))}
                        </div>
                        <p className="note">※最大5つまで選択可能です。</p>
                      </div>
                    ) : (
                      <></>
                    )}
                    {isPerson ? (
                      <button onClick={(e) => setIsPerson(false)}>完了</button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="consideration_area">
                  <h3>合理的配慮</h3>
                  <div className="edit_area">
                    <span
                      className="edit_icon"
                      onClick={(e) => setIsHandicap(true)}
                    />
                    {selectedConsiderationConditions.length > 0 &&
                      !isHandicap && (
                        <>
                          {considerationConditionTypes.map((type) => (
                            <div key={type.id}>
                              {selectedConsiderationConditions.filter(
                                (i) =>
                                  i.consideration_condition_type_id == type.id
                              ).length > 0 && (
                                <p className="tag_ttl">{type.name}</p>
                              )}
                              {selectedConsiderationConditions.filter(
                                (i) =>
                                  i.consideration_condition_type_id == type.id
                              ).length > 0 && (
                                <ul className="tag">
                                  {selectedConsiderationConditions
                                    .filter(
                                      (i) =>
                                        i.consideration_condition_type_id ==
                                        type.id
                                    )
                                    .map((i) => (
                                      <li key={i}>
                                        {findValue(
                                          i.id,
                                          "consideration_conditions"
                                        )}
                                      </li>
                                    ))}
                                </ul>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    {!isHandicap &&
                      selectedConsiderationConditions.length === 0 && (
                        <p className="edit">未選択</p>
                      )}
                    {isHandicap ? (
                      <div className="select_area _flex">
                        {considerationConditionTypes &&
                          considerationConditionTypes.map(
                            (i: any, index: any) => (
                              <div key={i.id}>
                                <div className="consideration_condition_type">
                                  <a onClick={(e) => toggle(index)}>{i.name}</a>
                                </div>
                                {isOpenList[index] ? (
                                  <div className="select_area_child">
                                    {filterConsiderationConditionsHandicap(
                                      i.id
                                    ).map((j: any) => (
                                      <label key={j.id}>
                                        <input
                                          type="checkbox"
                                          v-model="mySelected"
                                          defaultChecked={selectedConsiderationConditionsId.includes(
                                            j.id
                                          )}
                                          value={j.id}
                                          onChange={(e) => updateValue(j, j.id)}
                                        />
                                        {j.name}
                                      </label>
                                    ))}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            )
                          )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {isHandicap ? (
                      <button onClick={(e) => setIsHandicap(false)}>
                        完了
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <p className="note">
                    ※大項目を選択し、表示される小項目を選択してください。
                  </p>
                </div>

                <div className="consideration_area">
                  <h3>職場環境</h3>
                  <div className="edit_area">
                    <span
                      className="edit_icon"
                      onClick={(e) => setIsWorkEnvironment(true)}
                    />
                    {!isWorkEnvironment &&
                    recruitmentWorkEnvironments.length === 0 &&
                    recruitmentWorkEnvironmentsText.length === 0 ? (
                      <p className="edit">未選択</p>
                    ) : (
                      <></>
                    )}
                    {recruitmentWorkEnvironments.length > 0 ||
                    recruitmentWorkEnvironmentsText.length > 0 ? (
                      <div>
                        {!isWorkEnvironment ? (
                          <ul className="tag">
                            {recruitmentWorkEnvironments.map((i: any) => (
                              <li key={i}>
                                {findValue(i, "work_environments")}
                              </li>
                            ))}
                            {recruitmentWorkEnvironmentsText.map(
                              (data: any, idx: any) => (
                                <li key={idx}>{data}</li>
                              )
                            )}
                          </ul>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {isWorkEnvironment ? (
                      <div className="select_area _flex">
                        <div className="select_area_child">
                          {workEnvironments.map((i: any) => (
                            <label key={i.id}>
                              <input
                                type="checkbox"
                                v-model="mySelected"
                                value={i.id}
                                defaultChecked={recruitmentWorkEnvironments.includes(
                                  i.id
                                )}
                                onChange={(e) =>
                                  changeRecruitmentWorkEnvironments(i.id)
                                }
                              />
                              {i.name}
                            </label>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {isWorkEnvironment ? (
                      <div className="free_tag_box">
                        <div className="free_tag_input_area">
                          <input
                            type="text"
                            placeholder="一覧にないタグ"
                            value={environmentsText}
                            onChange={(e) =>
                              setEnvironmentsText(e.target.value)
                            }
                          />
                          <button onClick={handleButtonClick}>追加</button>
                        </div>
                        <ul className="free_tag_display_area">
                          {recruitmentWorkEnvironmentsText.map(
                            (data: any, idx: any) => (
                              <li key={idx}>
                                <a onClick={(e) => deleteTag(idx)}>
                                  <Icon
                                    path={mdiCloseCircle}
                                    size={0.5}
                                    color="#00a0e9"
                                  />
                                </a>
                                {data}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    ) : (
                      <></>
                    )}
                    {isWorkEnvironment ? (
                      <button
                        onClick={(e) => setIsWorkEnvironment(false)}
                        v-show="isWorkEnvironment"
                      >
                        完了
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="sidebar">
                <a
                  target="_blank"
                  href="https://job.parachannel.jp/recruitments/48262/"
                  style={{ paddingBottom: "20px", textDecoration: "underline" }}
                >
                  求人票のサンプルを確認する
                </a>
                <ul className="tab_btn top">
                  <li className="active">
                    <a>採用情報</a>
                  </li>
                  <li>
                    <a href="/recruiter/preview/">会社概要</a>
                  </li>
                </ul>
                <div className="tab_box">
                  <h3>{company_name || "未入力"}</h3>
                  <p className="ex">
                    <span className="required_icon">※</span>は必須項目です。
                  </p>

                  <dl>
                    <dt>
                      職種<span className="required_icon">※</span>
                    </dt>
                    <dd>
                      {isEditJobCategory ? (
                        <span className="mt10">
                          <select
                            name="selectedJobTypeId"
                            value={selectedJobTypeId || ""}
                            onChange={jobCategoriesTypeChange}
                          >
                            <option value="">選択してください</option>
                            {jobCategoryTypes &&
                              jobCategoryTypes.map((i: any) => (
                                <option value={i.id} key={i.id}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                          <select
                            name="selectedJobCategoryId"
                            value={selectedJobCategoryId || ""}
                            onChange={jobCategoriesIdChange}
                          >
                            <option value="">選択してください </option>
                            {jobCategoriesArr &&
                              jobCategoriesArr.map((i: any) => (
                                <option value={i.id} key={i.id}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                          <button onClick={(e) => setIsEditJobCategory(false)}>
                            完了
                          </button>
                        </span>
                      ) : (
                        <span
                          onClick={(e) => setIsEditJobCategory(true)}
                          className={`edit mt addClass ${
                            selectedJobCategoryId === null ||
                            selectedJobCategoryId === ""
                              ? "null_txt"
                              : ""
                          }`}
                        >
                          <span>
                            {findValue(
                              selectedJobCategoryId,
                              "job_categories"
                            ) || "未入力"}
                          </span>
                          <span className="edit_icon" />
                        </span>
                      )}
                    </dd>
                  </dl>

                  <dl>
                    <dt>
                      業務内容<span className="required_icon">※</span>
                    </dt>
                    <dd className="txt_box">
                      {isEditBusinessContent ? (
                        <span className="mt10">
                          <textarea
                            aria-colspan={30}
                            aria-rowspan={10}
                            aria-valuemax={400}
                            value={business_content || ""}
                            onBlur={(e) => setIsEditBusinessContent(false)}
                            onChange={(e) => changeBusinessContent(e)}
                          ></textarea>
                        </span>
                      ) : (
                        <span
                          className={`edit mt5 ${
                            business_content === "" ? "null_txt" : ""
                          }`}
                          onClick={(e) => setIsEditBusinessContent(true)}
                        >
                          <span>{business_content || "未入力"}</span>
                          <span className="edit_icon" />
                        </span>
                      )}
                      {isErrorBusinessContent ? (
                        <div className="error_box">
                          <p className="error_text">
                            {businessContentMaxLenError ? businessContentMaxLenError - 400 :''} 文字オーバー
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      必要な経験・
                      <br />
                      資格
                    </dt>
                    <dd className="txt_box">
                      {isEditRequiredExperience ? (
                        <span className="mt10">
                          <textarea
                            aria-colspan={30}
                            aria-rowspan={10}
                            value={required_experience || ""}
                            onBlur={(e) => setIsEditRequiredExperience(false)}
                            onChange={(e) => changeRequiredExperience(e)}
                          ></textarea>
                        </span>
                      ) : (
                        <span
                          className={`edit mt5 ${
                            required_experience === "" ? "" : ""
                          }`}
                          onClick={(e) => setIsEditRequiredExperience(true)}
                        >
                          {required_experience != '' ? (
                            <span className="text">
                              { required_experience }
                            </span>
                          ) : (
                            <span>未入力</span>
                          )}
                          <span className="edit_icon" />
                        </span>
                      )}
                      {isErrorRequiredExperience ? (
                        <div className="error_box">
                          <p className="error_text">
                            {requiredExperienceMaxLenError ? requiredExperienceMaxLenError - 200 : ''} 文字オーバー
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      募集する
                      <br />
                      企業の区分
                    </dt>
                    <dd>
                      {isEditTypeOfRecruitingCompany ? (
                        <span>
                          <select
                            value={typeOfRecruitingCompanyId || ""}
                            onChange={(e) => changeTypeOfRecruitingCompanies(e)}
                          >
                            {allowBlank ? (
                              <option value="">なし</option>
                            ) : (
                              <option
                                value=""
                                disabled
                                selected
                                style={{ display: "none" }}
                              >
                                選択してください
                              </option>
                            )}
                            {typeOfRecruitingCompanies &&
                              typeOfRecruitingCompanies.map((i: any) => (
                                <option value={i.id} key={i.id}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                          <button
                            onClick={(e) =>
                              setIsEditTypeOfRecruitingCompany(false)
                            }
                          >
                            完了
                          </button>
                        </span>
                      ) : (
                        <span
                          className="edit mt5"
                          onClick={(e) =>
                            setIsEditTypeOfRecruitingCompany(true)
                          }
                        >
                          <span>{findValueRecruitCompanyId()}</span>
                          <span className="edit_icon" />
                        </span>
                      )}
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      雇用形態<span className="required_icon">※</span>
                    </dt>
                    <dd>
                      {isEditEmploymentStatus ? (
                        <span>
                          <select
                            value={recruitmentEmploymentStatusId || ""}
                            onChange={(e) =>
                              changeRecruitmentEmploymentStatusId(e)
                            }
                          >
                            {allowBlank ? (
                              <option value="">なし</option>
                            ) : (
                              <option
                                value=""
                                disabled
                                selected
                                style={{ display: "none" }}
                              >
                                選択してください
                              </option>
                            )}
                            {masterEmploymentStatus &&
                              masterEmploymentStatus.map((i: any) => (
                                <option key={i.id} value={i.id}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                          <button
                            onClick={(e) => setIsEditEmploymentStatus(false)}
                          >
                            完了
                          </button>
                        </span>
                      ) : (
                        <span
                          onClick={(e) => setIsEditEmploymentStatus(true)}
                          className={`edit mt5 ${
                            recruitmentEmploymentStatusId === ""
                              ? "null_txt"
                              : ""
                          }`}
                        >
                          <span>
                            {findValue(
                              recruitmentEmploymentStatusId,
                              "employment_statuses"
                            ) || "未入力"}
                          </span>
                          <span className="edit_icon" />
                        </span>
                      )}
                      {isContractPeriodShow ? (
                        <div>
                          <dl className="single">
                            <dt>
                              契約期間有無
                              <span className="required_icon">※</span>
                            </dt>
                            <dd>
                              {isEditContractPeriodExists ? (
                                <span>
                                  <label>
                                    <input
                                      type="radio"
                                      name="ContractPeriodExists"
                                      value="true"
                                      onChange={changeContractPeriod}
                                      defaultChecked={
                                        recruitmentPeriodContractPeriodExists ===
                                        true
                                      }
                                    />
                                    有
                                  </label>
                                  <label>
                                    <input
                                      type="radio"
                                      name="ContractPeriodExists"
                                      value="false"
                                      onChange={changeContractPeriod}
                                      defaultChecked={
                                        recruitmentPeriodContractPeriodExists ===
                                        false
                                      }
                                    />
                                    無
                                  </label>
                                </span>
                              ) : (
                                <span
                                  onClick={(e) =>
                                    setIsEditContractPeriodExists(true)
                                  }
                                  className={
                                    isEditContractPeriodExists == null
                                      ? "edit error"
                                      : "edit"
                                  }
                                >
                                  <span>
                                    {recruitmentPeriodContractPeriodExists
                                      ? "有"
                                      : isEditContractPeriodExists == null
                                      ? "未入力"
                                      : "無"}
                                  </span>
                                  <span className="edit_icon" />
                                </span>
                              )}
                            </dd>
                          </dl>

                          {recruitmentPeriodContractPeriodExists ? (
                            <dl className="single">
                              <dt>契約期間</dt>
                              <dd>
                                {isEditContractPeriod ? (
                                  <span>
                                    <select
                                      value={
                                        recruitmentPeriodContractPeriodId || ""
                                      }
                                      onChange={(e) =>
                                        changeRecruitmentPeriodContractPeriodId(
                                          e
                                        )
                                      }
                                    >
                                      {allowBlank ? (
                                        <option value="">なし</option>
                                      ) : (
                                        <option
                                          value=""
                                          disabled
                                          selected
                                          style={{ display: "none" }}
                                        >
                                          選択してください
                                        </option>
                                      )}
                                      {masterContractPeriod &&
                                        masterContractPeriod.map((i: any) => (
                                          <option value={i.id} key={i.id}>
                                            {i.name}
                                          </option>
                                        ))}
                                    </select>
                                    <button
                                      onClick={(e) =>
                                        setIsEditContractPeriod(false)
                                      }
                                    >
                                      完了
                                    </button>
                                  </span>
                                ) : (
                                  <span
                                    className={`edit mt5 ${
                                      recruitmentPeriodContractPeriodId === null
                                        ? "null_txt"
                                        : ""
                                    }`}
                                    onClick={(e) =>
                                      setIsEditContractPeriod(true)
                                    }
                                  >
                                    <span>{findValueContractPeriod()}</span>
                                    <span className="edit_icon" />
                                  </span>
                                )}
                              </dd>
                            </dl>
                          ) : (
                            <></>
                          )}
                          {recruitmentPeriodContractPeriodExists ? (
                            <dl className="single">
                              <dt>契約更新</dt>
                              <dd>
                                {isEditContractRenewalExists ? (
                                  <span>
                                    <label>
                                      <input
                                        type="radio"
                                        name="ContractRenewalExists"
                                        value="true"
                                        onChange={changeContractRenewal}
                                        defaultChecked={
                                          recruitmentPeriodContractRenewalExists ===
                                          true
                                        }
                                      />
                                      有
                                    </label>
                                    <label>
                                      <input
                                        type="radio"
                                        name="ContractRenewalExists"
                                        value="false"
                                        onChange={changeContractRenewal}
                                        defaultChecked={
                                          recruitmentPeriodContractRenewalExists ===
                                          false
                                        }
                                      />
                                      無
                                    </label>
                                  </span>
                                ) : (
                                  <></>
                                )}
                                {!isEditContractRenewalExists ? (
                                  <span
                                    onClick={(e) =>
                                      setIsEditContractRenewalExists(true)
                                    }
                                    className={
                                      isEditContractRenewalExists == null
                                        ? "edit error"
                                        : "edit"
                                    }
                                  >
                                    <span>
                                      {recruitmentPeriodContractRenewalExists
                                        ? "有"
                                        : isEditContractRenewalExists == null
                                        ? "未入力"
                                        : "無"}
                                    </span>
                                    <span className="edit_icon" />
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </dd>
                            </dl>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {recruitmentPeriodContractPeriodExists ? (
                        <dl
                          className="single"
                          v-show="recruitment.period.contract_period_exists"
                        >
                          <dt>その他補足</dt>
                          <dd className="txt_box">
                            {isEditContractPeriodSupplemental ? (
                              <span className="mt10">
                                <textarea
                                  aria-colspan={30}
                                  aria-rowspan={10}
                                  value={
                                    recruitmentPeriodContractPeriodSupplemental ||
                                    ""
                                  }
                                  onBlur={(e) =>
                                    setIsEditContractPeriodSupplemental(false)
                                  }
                                  onChange={(e) =>
                                    changeContractPeriodSupplemental(e)
                                  }
                                ></textarea>
                              </span>
                            ) : (
                              <span
                                onClick={(e) =>
                                  setIsEditContractPeriodSupplemental(true)
                                }
                                className={`edit mt5 addClass ${
                                  recruitmentPeriodContractPeriodSupplemental == null
                                    ? "null_txt"
                                    : ""
                                }`}
                              >
                                <span>
                                  {recruitmentPeriodContractPeriodSupplemental ||
                                    "未入力"}
                                </span>
                                <span className="edit_icon" />
                              </span>
                            )}
                            {isErrorContractPeriodSupplemental ? (
                              <div className="error_box">
                                <p className="error_text">
                                  {contractPeriodSupplementalMaxLenError ? contractPeriodSupplementalMaxLenError - 200 : ''}{" "}
                                  文字オーバー
                                </p>
                              </div>
                            ) : (
                              <></>
                            )}
                          </dd>
                        </dl>
                      ) : (
                        <></>
                      )}
                    </dd>
                  </dl>

                  <dl>
                    <dt>試用期間</dt>
                    <dd>
                      <div>
                        <dl>
                          <dt>
                            試用期間有無<span className="required_icon">※</span>
                          </dt>
                          <dd>
                            {isEditTrialPeriodExists ? (
                              <span>
                                <label>
                                  <input
                                    type="radio"
                                    name="TrialPeriodExists"
                                    value="true"
                                    onClick={changePeriodTrialPeriodExists}
                                    checked={
                                      recruitmentPeriodTrialPeriodExists ===
                                      true
                                    }
                                  />
                                  有
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name="TrialPeriodExists"
                                    value="false"
                                    onClick={changePeriodTrialPeriodExists}
                                    checked={
                                      recruitmentPeriodTrialPeriodExists ===
                                      false
                                    }
                                  />
                                  無
                                </label>
                              </span>
                            ) : (
                              <span
                                onClick={(e) =>
                                  setIsEditTrialPeriodExists(true)
                                }
                                className={
                                  isEditTrialPeriodExists == null
                                    ? "edit error"
                                    : "edit"
                                }
                              >
                                <span>
                                  {recruitmentPeriodTrialPeriodExists
                                    ? "有"
                                    : isEditTrialPeriodExists == null
                                    ? "未入力"
                                    : "無"}
                                </span>
                                <span className="edit_icon" />
                              </span>
                            )}
                          </dd>
                        </dl>
                        {recruitmentPeriodTrialPeriodExists ? (
                          <dl className="single">
                            <dt>試用期間</dt>
                            <dd>
                              {isEditTrialPeriod ? (
                                <span>
                                  <select
                                    value={selectedTrailPeriod || ""}
                                    onChange={(e) => changeTrialPeriod(e)}
                                  >
                                    {allowBlank ? (
                                      <option value="">なし</option>
                                    ) : (
                                      <option
                                        value=""
                                        disabled
                                        selected
                                        style={{ display: "none" }}
                                      >
                                        選択してください
                                      </option>
                                    )}
                                    {masterTrialPeriod &&
                                      masterTrialPeriod.map((i: any) => (
                                        <option value={i.id} key={i.id}>
                                          {i.name}
                                        </option>
                                      ))}
                                  </select>
                                  <button
                                    onClick={(e) => setIsEditTrialPeriod(false)}
                                  >
                                    完了
                                  </button>
                                </span>
                              ) : (
                                <span
                                  className="edit mt5"
                                  onClick={(e) => setIsEditTrialPeriod(true)}
                                >
                                  <span>{findValueTrailPeriod()}</span>
                                  <span className="edit_icon" />
                                </span>
                              )}
                            </dd>
                          </dl>
                        ) : (
                          <></>
                        )}
                      </div>

                      <dl className="single">
                        <dt>その他補足</dt>
                        <dd className="txt_box">
                          {isEditTrialPeriodSupplemental ? (
                            <span className="mt10">
                              <textarea
                                aria-colspan={30}
                                aria-rowspan={10}
                                value={
                                  recruitmentPeriodTrialPeriodSupplement || ""
                                }
                                onBlur={(e) =>
                                  setIsEditTrialPeriodSupplemental(false)
                                }
                                onChange={(e) =>
                                  changeTrialPeriodSupplemental(e)
                                }
                              />
                            </span>
                          ) : (
                            <span
                              onClick={(e) =>
                                setIsEditTrialPeriodSupplemental(true)
                              }
                              className="edit mt addClass"
                            >
                              <span>{findValueTrialPeriod()}</span>
                              <span className="edit_icon" />
                            </span>
                          )}
                          {isErrorTrialPeriodSupplemental ? (
                            <div className="error_box">
                              <p className="error_text">
                                {trialPeriodSupplementalMaxLenError ? trialPeriodSupplementalMaxLenError - 200 : ''}{" "}
                                文字オーバー
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </dd>
                      </dl>
                    </dd>
                  </dl>
                  {isContractPeriodShow ? (
                    <dl>
                      <dt>
                        契約社員or
                        <br />
                        正社員登用
                      </dt>
                      <dd>
                        <dl className="single">
                          <dt>契約社員or正社員登用</dt>
                          <dd>
                            {isEditFullTimeEmployeeExists ? (
                              <span>
                                <label>
                                  <input
                                    type="radio"
                                    name="FullTimeEmployeeExists"
                                    value="true"
                                    v-model="selected"
                                    checked={
                                      recruitmentPeriodFullTimeEmployeeExists ===
                                      true
                                    }
                                    onClick={changeFullTimeEmployeeExists}
                                  />
                                  有
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name="FullTimeEmployeeExists"
                                    value="false"
                                    v-model="selected"
                                    checked={
                                      recruitmentPeriodFullTimeEmployeeExists ===
                                      false
                                    }
                                    onClick={changeFullTimeEmployeeExists}
                                  />
                                  無
                                </label>
                              </span>
                            ) : (
                              <span
                                onClick={(e) =>
                                  setIsEditFullTimeEmployeeExists(true)
                                }
                                className={
                                  isEditFullTimeEmployeeExists == null
                                    ? "edit error"
                                    : "edit"
                                }
                              >
                                <span>
                                  {recruitmentPeriodFullTimeEmployeeExists
                                    ? "有"
                                    : isEditFullTimeEmployeeExists == null
                                    ? "未入力"
                                    : "無"}
                                </span>
                                <span className="edit_icon" />
                              </span>
                            )}
                          </dd>
                        </dl>
                      </dd>
                    </dl>
                  ) : (
                    <></>
                  )}
                  <dl>
                    <dt>
                      働き方・
                      <br />
                      テレワーク
                    </dt>
                    <dd>
                      {isEditWorkFromHome ? (
                        <span>
                          <select
                            value={selectedWorkFromHome || ""}
                            onChange={(e) => changeWorkFromHome(e)}
                          >
                            {allowBlank ? (
                              <option value="">なし</option>
                            ) : (
                              <option
                                value=""
                                disabled
                                selected
                                style={{ display: "none" }}
                              >
                                選択してください
                              </option>
                            )}
                            {masterWorkFromHome &&
                              masterWorkFromHome.map((i, index) => (
                                <option value={i.id} key={index}>
                                  {i.name2}
                                </option>
                              ))}
                          </select>
                          <button onClick={(e) => setIsEditWorkFromHome(false)}>
                            完了
                          </button>
                        </span>
                      ) : (
                        <span
                          onClick={(e) =>
                            setIsEditWorkFromHome(!isEditWorkFromHome)
                          }
                          className={`edit mt5 addClass ${
                            selectedWorkFromHome ? "" : "null_txt"
                          }`}
                        >
                          <span>
                            {findValue2(
                              selectedWorkFromHome,
                              "work_from_home"
                            ) || "未入力"}
                          </span>
                          <span className="edit_icon" />
                        </span>
                      )}
                    </dd>
                  </dl>

                  <dl>
                    <dt>
                      勤務地<span className="required_icon">※</span>
                    </dt>
                    <dd>
                      <dl className="single">
                        <dt>支店・部署名</dt>
                        <dd className="txt_box">
                          {isEditDepartment ? (
                            <span className="mt10">
                              <input
                                type="text"
                                v-model="recruitment.work_location.department"
                                placeholder="本社、人事部など"
                                onChange={(e) =>
                                  changeWorkLocationDepartment(e)
                                }
                                onBlur={(e) => setIsEditDepartment(false)}
                                value={recruitmentWorkLocationDepartment || ""}
                              />
                            </span>
                          ) : (
                            <span
                              onClick={(e) => setIsEditDepartment(true)}
                              className={`edit mt5 addClass ${
                                recruitmentWorkLocationDepartment.length === 0
                                  ? "null_txt"
                                  : ""
                              }`}
                            >
                              <span>{findValueWorkLocationDepartment()}</span>
                              <span className="edit_icon" />
                            </span>
                          )}
                        </dd>
                      </dl>

                      {Number(selectedWorkFromHome) !== 1 ? (
                        <dl className="single">
                          <span className="mt10">
                            {!isEditCityCorrection ? (
                              <>
                                <label>
                                  郵便番号（ハイフン不要）{prefectureName}
                                  <input
                                    type="text"
                                    v-model="recruitment.work_location.postcode"
                                    placeholder="郵便番号"
                                    onChange={(e) =>
                                      changeWorkLocationPostCode(e)
                                    }
                                    value={postCode || ""}
                                  />
                                </label>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setBtn(true);
                                  }}
                                >
                                  郵便番号検索
                                </button>
                                <label>
                                  都道府県 市・区
                                  {!isEditAddress ? (
                                    <p>{prefectureName} {cityName}</p>
                                  ) : (
                                    <input
                                      type="text"
                                      v-model="cityCorrectiontext"
                                      onBlur={(e) => setIsEditAddress(false)}
                                      value={prefectureName + cityName}
                                    />
                                  )}
                                </label>
                                <label>
                                  市区町村を修正する
                                  <input
                                    type="checkbox"
                                    defaultChecked={isEditAddress}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      setIsEditAddress(true);
                                    }}
                                  />
                                </label>
                                <br />
                                <br />
                                <label>
                                  住所（それ以降）
                                  <input
                                    type="text"
                                    value={address || ""}
                                    placeholder="住所"
                                    onChange={(e) => changeAddress(e)}
                                  />
                                </label>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsEditCityCorrection(true);
                                  }}
                                >
                                  完了
                                </button>
                              </>
                            ) : (
                              <span
                                onClick={(e) => setIsEditCityCorrection(false)}
                                className="edit mt5 addClass"
                              >
                                <div>
                                  <dl>
                                    <dt style={{ width: "150px" }}>郵便番号</dt>
                                    <dd>{postCode}</dd>
                                    <dt>住所</dt>
                                    <dd>
                                      {prefectureName}{cityName}
                                      {address}
                                    </dd>
                                  </dl>
                                  <span className="edit_icon" />
                                </div>
                              </span>
                            )}
                          </span>
                        </dl>
                      ) : (
                        <></>
                      )}

                      {Number(selectedWorkFromHome) !== 1 ? (
                        <dl className="single">
                          <dt>最寄り駅</dt>
                          <dd className="txt_box">
                            {isEditNearestStation ? (
                              <span className="mt10">
                                <input
                                  type="text"
                                  v-model="recruitment.work_location.nearest_station"
                                  placeholder="東京メトロ半蔵門線 半蔵門駅など"
                                  onBlur={(e) => setIsEditNearestStation(false)}
                                  value={nearestStation || ""}
                                  onChange={(e) => changeNearestStation(e)}
                                />
                              </span>
                            ) : (
                              <span
                                onClick={(e) => setIsEditNearestStation(true)}
                                className={`edit mt5 addClass ${
                                  nearestStation.length === 0 ? "null_txt" : ""
                                }`}
                              >
                                <span>{nearestStation || "未入力"}</span>
                                <span className="edit_icon" />
                              </span>
                            )}
                          </dd>
                        </dl>
                      ) : (
                        <></>
                      )}

                      {Number(selectedWorkFromHome) !== 1 ? (
                        <dl className="single">
                          <dt>最寄り駅からの交通手段</dt>
                          <dd className="txt_box">
                            {isEditTransportation ? (
                              <span className="mt10">
                                <input
                                  type="text"
                                  v-model="recruitment.work_location.transportation"
                                  placeholder="徒歩5分など"
                                  onBlur={(e) => setIsEditTransportation(false)}
                                  onChange={(e) => changeTransportation(e)}
                                  value={transportation || ""}
                                />
                              </span>
                            ) : (
                              <span
                                onClick={(e) => setIsEditTransportation(true)}
                                className={`edit mt5 addClass ${
                                  transportation.length === 0 ? "null_txt" : ""
                                }`}
                              >
                                <span>{transportation || "未入力"}</span>
                                <span className="edit_icon" />
                              </span>
                            )}
                          </dd>
                        </dl>
                      ) : (
                        <></>
                      )}
                      {Number(selectedWorkFromHome) !== 1 ? (
                        <dl v-show="recruitment.work_location.work_from_home_id != 1">
                          <dt>転勤の有無</dt>
                          <dd>
                            {isEditNationwide ? (
                              <span>
                                <label>
                                  <input
                                    type="radio"
                                    name="nationwide"
                                    value="true"
                                    v-model="selected"
                                    checked={
                                      nationWide ===
                                      true
                                    }
                                    onClick={() => {
                                      setEdited(true);
                                      setNationWide(true);
                                      setIsEditNationwide(false);
                                    }}
                                  />
                                  有
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name="nationwide"
                                    value="false"
                                    v-model="selected"
                                    checked={
                                      nationWide ===
                                      false
                                    }
                                    onClick={() => {
                                      setEdited(true);
                                      setNationWide(false);
                                      setIsEditNationwide(false);
                                    }}
                                  />
                                  無
                                </label>
                              </span>
                            ) : (
                              <></>
                            )}
                            {!isEditNationwide ? (
                              <span
                                onClick={(e) => setIsEditNationwide(true)}
                                className={nationWide == null ? "edit" : "edit"}
                              >
                                <span>
                                  {nationWide
                                    ? "有"
                                    : nationWide == null
                                    ? "未入力"
                                    : "無"}
                                </span>
                                <span className="edit_icon" />
                              </span>
                            ) : (
                              <></>
                            )}
                          </dd>
                        </dl>
                      ) : (
                        <></>
                      )}
                      <dl className="single">
                        <dt>勤務地補足</dt>
                        <dd className="txt_box">
                          {isEditWorkLocationSupplemental ? (
                            <span className="mt10">
                              <textarea
                                aria-colspan={30}
                                aria-rowspan={10}
                                v-show="isEditWorkLocationSupplemental"
                                onBlur={(e) =>
                                  setIsEditWorkLocationSupplemental(false)
                                }
                                v-model="recruitment.work_location.work_location_supplemental"
                                value={workLocationSupplemental || ""}
                                onChange={(e) =>
                                  changeWorkLocationSupplemental(e)
                                }
                              />
                            </span>
                          ) : (
                            <span
                              onClick={(e) =>
                                setIsEditWorkLocationSupplemental(true)
                              }
                              className={`edit mt5 addClass ${
                                workLocationSupplemental.length === 0
                                  ? "optionGrey"
                                  : ""
                              }`}
                            >
                              <span>
                                {workLocationSupplemental ||
                                  "「新宿支店、大宮支店でも応募可能です」など"}
                              </span>
                              <span className="edit_icon" />
                            </span>
                          )}
                          {isErrorWorkLocationSupplemental ? (
                            <div className="error_box">
                              <p className="error_text">
                                {workLocationSupplementalMaxLenError ? workLocationSupplementalMaxLenError - 200 : ''}{" "}
                                文字オーバー
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </dd>
                      </dl>
                    </dd>
                  </dl>

                  <dl>
                    <dt>
                      勤務時間 <span className="required_icon">※</span>
                    </dt>
                    <dd>
                      <dl>
                        <dt>勤務時間</dt>
                        <dd>
                          {isEditWorkTime ? (
                            <span>
                              <label>
                                始業時間①
                                <div className="time_selector">
                                  <select
                                    onChange={(e) => updateHour(e)}
                                    name="startTime1Hour"
                                  >
                                    {allowBlank ? (
                                      <option value="" selected />
                                    ) : (
                                      <></>
                                    )}
                                    {hourList &&
                                      hourList.map((h: any) => (
                                        <option
                                          selected={startTime1Hour == h}
                                          value={h}
                                          key={h}
                                        >
                                          {h}
                                        </option>
                                      ))}
                                  </select>
                                  <span>:</span>
                                  <select
                                    onChange={(e) => updateMinute(e)}
                                    name="startTime1Minute"
                                    className={
                                      !startTime1Hour ? "disabled" : ""
                                    }
                                  >
                                    {allowBlank ? (
                                      <option value="" selected />
                                    ) : (
                                      <></>
                                    )}
                                    {minuteList &&
                                      minuteList.map((m: any) => (
                                        <option
                                          selected={startTime1Minute == m}
                                          value={m}
                                          key={m}
                                        >
                                          {m}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </label>
                              <label>
                                終業時間①
                                <div className="time_selector">
                                  <select
                                    onChange={(e) => updateHour(e)}
                                    name="endTime1Hour"
                                  >
                                    {allowBlank ? (
                                      <option value="" selected />
                                    ) : (
                                      <></>
                                    )}
                                    {hourList &&
                                      hourList.map((h: any) => (
                                        <option
                                          selected={endTime1Hour == h}
                                          value={h}
                                          key={h}
                                        >
                                          {h}
                                        </option>
                                      ))}
                                  </select>
                                  <span>:</span>
                                  <select
                                    onChange={(e) => updateMinute(e)}
                                    name="endTime1Minute"
                                    className={!endTime1Hour ? "disabled" : ""}
                                  >
                                    {allowBlank ? (
                                      <option value="" selected />
                                    ) : (
                                      <></>
                                    )}
                                    {minuteList &&
                                      minuteList.map((m: any) => (
                                        <option
                                          selected={endTime1Minute == m}
                                          value={m}
                                          key={m}
                                        >
                                          {m}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </label>
                              <label>
                                始業時間②
                                <div className="time_selector">
                                  <select
                                    onChange={(e) => updateHour(e)}
                                    name="startTime2Hour"
                                  >
                                    {allowBlank ? (
                                      <option value="" selected />
                                    ) : (
                                      <></>
                                    )}
                                    {hourList &&
                                      hourList.map((h: any) => (
                                        <option
                                          selected={startTime2Hour == h}
                                          value={h}
                                          key={h}
                                        >
                                          {h}
                                        </option>
                                      ))}
                                  </select>
                                  <span>:</span>
                                  <select
                                    onChange={(e) => updateMinute(e)}
                                    name="startTime2Minute"
                                    className={
                                      !startTime2Hour ? "disabled" : ""
                                    }
                                  >
                                    {allowBlank ? (
                                      <option value="" selected />
                                    ) : (
                                      <></>
                                    )}
                                    {minuteList &&
                                      minuteList.map((m: any) => (
                                        <option
                                          selected={startTime2Minute == m}
                                          value={m}
                                          key={m}
                                        >
                                          {m}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </label>
                              <label>
                                終業時間②
                                <div className="time_selector">
                                  <select
                                    onChange={(e) => updateHour(e)}
                                    name="endTime2Hour"
                                  >
                                    {allowBlank ? (
                                      <option value="" selected />
                                    ) : (
                                      <></>
                                    )}
                                    {hourList &&
                                      hourList.map((h: any) => (
                                        <option
                                          selected={endTime2Hour == h}
                                          value={h}
                                          key={h}
                                        >
                                          {h}
                                        </option>
                                      ))}
                                  </select>
                                  <span>:</span>
                                  <select
                                    onChange={(e) => updateMinute(e)}
                                    name="endTime2Minute"
                                    className={!endTime2Hour ? "disabled" : ""}
                                  >
                                    {allowBlank ? (
                                      <option value="" selected />
                                    ) : (
                                      <></>
                                    )}
                                    {minuteList &&
                                      minuteList.map((m: any) => (
                                        <option
                                          selected={endTime2Minute == m}
                                          value={m}
                                          key={m}
                                        >
                                          {m}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </label>
                              <label>
                                始業時間③
                                <div className="time_selector">
                                  <select
                                    onChange={(e) => updateHour(e)}
                                    name="startTime3Hour"
                                  >
                                    {allowBlank ? (
                                      <option value="" selected />
                                    ) : (
                                      <></>
                                    )}
                                    {hourList &&
                                      hourList.map((h: any) => (
                                        <option
                                          selected={startTime3Hour == h}
                                          value={h}
                                          key={h}
                                        >
                                          {h}
                                        </option>
                                      ))}
                                  </select>
                                  <span>:</span>
                                  <select
                                    onChange={(e) => updateMinute(e)}
                                    name="startTime3Minute"
                                    className={
                                      !startTime3Hour ? "disabled" : ""
                                    }
                                  >
                                    {allowBlank ? (
                                      <option value="" selected />
                                    ) : (
                                      <></>
                                    )}
                                    {minuteList &&
                                      minuteList.map((m: any) => (
                                        <option
                                          selected={startTime3Minute == m}
                                          value={m}
                                          key={m}
                                        >
                                          {m}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </label>
                              <label>
                                終業時間③
                                <div className="time_selector">
                                  <select
                                    onChange={(e) => updateHour(e)}
                                    name="endTime3Hour"
                                  >
                                    {allowBlank ? (
                                      <option
                                        value=""
                                        selected
                                        v-show="allowBlank"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {hourList &&
                                      hourList.map((h: any) => (
                                        <option
                                          selected={endTime3Hour == h}
                                          value={h}
                                          key={h}
                                        >
                                          {h}
                                        </option>
                                      ))}
                                  </select>
                                  <span>:</span>
                                  <select
                                    onChange={(e) => updateMinute(e)}
                                    name="endTime3Minute"
                                    className={!endTime3Hour ? "disabled" : ""}
                                  >
                                    {allowBlank ? (
                                      <option
                                        value=""
                                        selected
                                        v-show="allowBlank"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {minuteList &&
                                      minuteList.map((m: any) => (
                                        <option
                                          selected={endTime3Minute == m}
                                          value={m}
                                          key={m}
                                        >
                                          {m}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </label>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  buildWorkTime();
                                }}
                              >
                                完了
                              </button>
                            </span>
                          ) : (
                            <span onClick={(e) => editHourAndMinute(e)}>
                              <span
                                style={{ zIndex: 100 }}
                                onClick={(e) => editHourAndMinute(e)}
                                className="edit_icon"
                              />
                              {workTimes &&
                                workTimes.map((i: any) => (
                                  <div key={i}>{i}</div>
                                ))}
                            </span>
                          )}
                        </dd>
                        <dd>
                          <dt>勤務時間補足</dt>
                          <dd className="txt_box">
                            {isEditWorkTimeSupplemental ? (
                              <span className="mt10">
                                <textarea
                                  aria-colspan={30}
                                  aria-rowspan={10}
                                  v-show="isEditWorkTimeSupplemental"
                                  onBlur={(e) =>
                                    setIsEditWorkTimeSupplemental(false)
                                  }
                                  v-model="recruitment.work_time.work_time_supplemental"
                                  value={workTimeSupplemental || ""}
                                  onChange={(e) =>
                                    changeWorkTimeSupplemental(e)
                                  }
                                />
                              </span>
                            ) : (
                              <span
                                onClick={(e) =>
                                  setIsEditWorkTimeSupplemental(true)
                                }
                                className={`edit mt5 addClass ${
                                  workTimeSupplemental.length === 0
                                    ? "optionGrey"
                                    : ""
                                }`}
                              >
                                <span>
                                  {workTimeSupplemental || "補足事項を記入する"}
                                </span>
                                <span className="edit_icon" />
                              </span>
                            )}
                            {isErrorWorkTimeSupplemental ? (
                              <div className="error_box">
                                <p className="error_text">
                                  {workTimeSupplementalMaxLenError ? workTimeSupplementalMaxLenError - 200 : ''} 文字オーバー
                                </p>
                              </div>
                            ) : (
                              <></>
                            )}
                          </dd>
                        </dd>
                      </dl>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      休憩時間<span className="required_icon">※</span>
                    </dt>
                    <dd>
                      <dl>
                        <dt>休憩時間</dt>
                        <dd>
                          {isEditRestTime ? (
                            <span className="mt10">
                              <input
                                type="text"
                                v-model="recruitment.work_time.rest_time"
                                placeholder="45分"
                                value={restTime || ""}
                                onBlur={(e) => setIsEditRestTime(false)}
                                onChange={changeRestTime}
                              />
                            </span>
                          ) : (
                            <span
                              onClick={(e) => setIsEditRestTime(true)}
                              className={`edit mt5 addClass ${
                                restTime.length === 0 ? "null_txt" : ""
                              }`}
                            >
                              <span>{restTime || "未入力"}</span>
                              <span className="edit_icon" />
                            </span>
                          )}
                        </dd>
                        <dt>休憩時間補足（小休憩など）</dt>
                        <dd className="txt_box">
                          {isEditRestTimeSupplemental ? (
                            <span className="mt10">
                              <textarea
                                aria-colspan={30}
                                aria-rowspan={10}
                                v-show="isEditRestTimeSupplemental"
                                onBlur={(e) =>
                                  setIsEditRestTimeSupplemental(false)
                                }
                                v-model="recruitment.work_time.rest_time_supplemental"
                                value={restTimeSupplemental || ""}
                                onChange={(e) => changeRestTimeSupplemental(e)}
                              />
                            </span>
                          ) : (
                            <span
                              onClick={(e) =>
                                setIsEditRestTimeSupplemental(true)
                              }
                              className={`edit mt5 addClass ${
                                restTimeSupplemental.length === 0
                                  ? "optionGrey"
                                  : ""
                              }`}
                            >
                              <span>
                                {restTimeSupplemental || "補足事項を記入する"}
                              </span>
                              <span className="edit_icon" />
                            </span>
                          )}
                          {isErrorRestTimeSupplemental ? (
                            <div className="error_box">
                              <p className="error_text">
                                {restTimeSupplementalMaxLenError ? restTimeSupplementalMaxLenError - 200 : ''} 文字オーバー
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </dd>
                      </dl>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      勤務日<span className="required_icon">※</span>
                    </dt>
                    <dd>
                      {isEditNumberOfWorkingDays ? (
                        <span>
                          <select
                            v-model="recruitment.work_time.number_of_working_days"
                            value={numberOfWorkingDays || ""}
                            onChange={(e) => {
                              changeWorkDay(e);
                            }}
                          >
                            <option value="">選択してください</option>
                            {workingDays() &&
                              workingDays().map((i: any) => (
                                <option key={i} value={i}>
                                  {i}
                                </option>
                              ))}
                          </select>
                          <p>日/週</p>
                        </span>
                      ) : (
                        <span
                          onClick={(e) => setIsEditNumberOfWorkingDays(true)}
                        >
                          {numberOfWorkingDays || "0"}日/週
                          <span className="edit_icon edit_icon_t10" />
                        </span>
                      )}
                      <dl className="mt10">
                        <dt>勤務日数補足</dt>
                        <dd className="txt_box">
                          {isEditNumberOfWorkingDaysSupplemental ? (
                            <span className="mt10">
                              <textarea
                                aria-colspan={30}
                                aria-rowspan={10}
                                v-show="isEditNumberOfWorkingDaysSupplemental"
                                onBlur={(e) =>
                                  setIsEditNumberOfWorkingDaysSupplemental(
                                    false
                                  )
                                }
                                value={numberOfWorkingDaysSupplemental || ""}
                                onChange={changeNumberOfWorkingDaysSupplemental}
                                v-model="recruitment.work_time.number_of_working_days_supplemental"
                              />
                            </span>
                          ) : (
                            <span
                              onClick={(e) =>
                                setIsEditNumberOfWorkingDaysSupplemental(true)
                              }
                              className={`edit mt5 addClass ${
                                numberOfWorkingDaysSupplemental.length === 0
                                  ? "optionGrey"
                                  : ""
                              }`}
                            >
                              <span>
                                {numberOfWorkingDaysSupplemental ||
                                  "補足事項を記入する"}
                              </span>
                              <span className="edit_icon" />
                            </span>
                          )}
                          {isErrorNumberOfWorkingDaysSupplemental ? (
                            <div className="error_box">
                              <p className="error_text">
                                {numberOfWorkingDaysSupplementalMaxLenError ? numberOfWorkingDaysSupplementalMaxLenError - 200 : ''}{" "}
                                文字オーバー
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </dd>
                      </dl>
                    </dd>
                  </dl>
                  <dl>
                    <dt>残業時間</dt>
                    <dd>
                      {isEditOvertimeHoursExists ? (
                        <span>
                          <label>
                            <input
                              type="radio"
                              name="OvertimeHoursExists"
                              value="true"
                              v-model="selected"
                              checked={
                                isOvertimeHoursExists ===
                                true
                              }
                              onClick={(e) => {
                                setEdited(true);
                                setIsOvertimeHoursExists(true);
                                setIsEditOvertimeHoursExists(false);
                              }}
                            />
                            有
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="OvertimeHoursExists"
                              value="false"
                              v-model="selected"
                              checked={
                                isOvertimeHoursExists ===
                                false
                              }
                              onClick={(e) => {
                                setEdited(true);
                                setIsOvertimeHoursExists(false);
                                setIsEditOvertimeHoursExists(false);
                              }}
                            />
                            無
                          </label>
                        </span>
                      ) : (
                        <></>
                      )}
                      {!isEditOvertimeHoursExists ? (
                        <span
                          onClick={(e) => setIsEditOvertimeHoursExists(true)}
                          className={
                            isOvertimeHoursExists == null ? "edit" : "edit"
                          }
                        >
                          <span>
                            {isOvertimeHoursExists
                              ? "有"
                              : isOvertimeHoursExists == null
                              ? "未入力"
                              : "無"}
                          </span>
                          <span className="edit_icon" />
                        </span>
                      ) : (
                        <></>
                      )}
                    </dd>
                    <dt>残業時間補足</dt>
                    <dd className="txt_box">
                      {isEditOvertimeHoursSupplemental ? (
                        <span className="mt10">
                          <textarea
                            aria-colspan={30}
                            aria-rowspan={10}
                            v-show="isEditOvertimeHoursSupplemental"
                            onBlur={(e) =>
                              setIsEditOvertimeHoursSupplemental(false)
                            }
                            v-model="recruitment.work_time.overtime_hours_supplemental"
                            value={overTimeHoursSupplemental || ""}
                            onChange={changeOverTimeHoursSupplemental}
                          />
                        </span>
                      ) : (
                        <span
                          onClick={(e) =>
                            setIsEditOvertimeHoursSupplemental(true)
                          }
                          className={`edit mt5 addClass ${
                            overTimeHoursSupplemental.length === 0
                              ? "optionGrey"
                              : ""
                          }`}
                        >
                          <span>
                            {overTimeHoursSupplemental || "補足事項を記入する"}
                          </span>
                          <span className="edit_icon" />
                        </span>
                      )}
                      {isErrorOverTimeHoursSupplemental ? (
                        <div className="error_box">
                          <p className="error_text">
                            {overTimeHoursSupplementalMaxLenError ? overTimeHoursSupplementalMaxLenError - 200 : ''} 文字オーバー
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </dd>
                  </dl>
                  <dl>
                    <dt>休日</dt>
                    <dd>
                      <div>
                        <dl className="half">
                          <dt>休日</dt>
                          <dd>
                            {isEditHolidays ? (
                              <span>
                                {holidays &&
                                  holidays.map((i: any, index) => (
                                    <label key={i.id}>
                                      <input
                                        type="checkbox"
                                        name="OvertimeHoursExists"
                                        value={i.id}
                                        v-model="selected"
                                        checked={checkedHolidays.includes(i.id) ? true : false}
                                        onChange={(e) =>
                                          onChangeCityCorrectionOvertimeHours(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                      {i.name}
                                    </label>
                                  ))}
                                <button
                                  onClick={(e) => setIsEditHolidays(false)}
                                >
                                  完了
                                </button>
                              </span>
                            ) : (
                              <span
                                onClick={(e) => setIsEditHolidays(true)}
                                className={
                                  checkedHolidays == null ||
                                  checkedHolidays == undefined
                                    ? "error"
                                    : "edit mb10"
                                }
                              >
                                {checkedHolidays.length !== 0 ? (
                                  <span className="text">
                                    {checkedHolidays.map((id, index) => (
                                      <React.Fragment key={id}>
                                        {index > 0 && ","}
                                        {findValue(id, "holidays")}
                                      </React.Fragment>
                                    ))}
                                  </span>
                                ) : (
                                  <span>未入力</span>
                                )}
                                <span className="edit_icon" />
                              </span>
                            )}
                          </dd>
                        </dl>
                        <dl className="half">
                          <dt>年間休日</dt>
                          <dd>
                            {isEditAnnualHoliday ? (
                              <span className="mt10">
                                <input
                                  type="number"
                                  v-model="recruitment.work_time.annual_holiday"
                                  placeholder="45"
                                  onBlur={(e) => setIsEditAnnualHoliday(false)}
                                  value={annualHoliday || ""}
                                  onChange={changeAnnaulHoliday}
                                />
                                日
                              </span>
                            ) : (
                              <span
                                onClick={(e) => setIsEditAnnualHoliday(true)}
                                className={`edit mt addClass ${
                                  annualHoliday === null || annualHoliday === ""
                                    ? ""
                                    : ""
                                }`}
                              >
                                <span>
                                  {annualHoliday
                                    ? annualHoliday + "日"
                                    : "未入力"}
                                </span>
                                <span className="edit_icon" />
                              </span>
                            )}
                          </dd>
                        </dl>
                      </div>
                      <dl className="mt10">
                        <dt>その他</dt>
                        <dd className="txt_box">
                          {isEditVacations ? (
                            <span>
                              {vacations &&
                                vacations.map((i: any, index) => (
                                  <label key={i.id}>
                                    <input
                                      type="checkbox"
                                      name="OvertimeHoursExists"
                                      value={i.id}
                                      v-model="selected"
                                      checked={checkedVacations.includes(i.id) ? true : false}
                                      onChange={(e) =>
                                        onChangeVacations(e, index)
                                      }
                                    />
                                    {i.name}
                                  </label>
                                ))}
                              <button
                                onClick={(e) => setIsEditVacations(false)}
                              >
                                完了
                              </button>
                            </span>
                          ) : (
                            <span
                              onClick={(e) => setIsEditVacations(true)}
                              className={
                                checkedVacations == null ||
                                checkedVacations == undefined
                                  ? "error"
                                  : "edit mb10"
                              }
                            >
                              {checkedVacations.length !== 0 ? (
                                <span className="text">
                                  {checkedVacations.map((id, index) => (
                                    <React.Fragment key={id}>
                                      {index > 0 && ","}
                                      {findValue(id, "vacations")}
                                    </React.Fragment>
                                  ))}
                                </span>
                              ) : (
                                <span>未入力</span>
                              )}
                              <span className="edit_icon" />
                            </span>
                          )}
                          {isEditHolidaysSupplemental ? (
                            <span className="mt10">
                              <textarea
                                aria-colspan={30}
                                aria-rowspan={10}
                                v-show="isEditHolidaysSupplemental"
                                onBlur={(e) =>
                                  setIsEditHolidaysSupplemental(false)
                                }
                                v-model="recruitment.work_time.holidays_supplemental"
                                value={holidaysSupplemental || ""}
                                onChange={changeHolidaysSupplemental}
                              />
                            </span>
                          ) : (
                            <span
                              onClick={(e) =>
                                setIsEditHolidaysSupplemental(true)
                              }
                              className={`edit mt5 addClass ${
                                holidaysSupplemental.length === 0
                                  ? "optionGrey"
                                  : ""
                              }`}
                            >
                              <span>
                                {holidaysSupplemental || "補足事項を記入する"}
                              </span>
                              <span className="edit_icon" />
                            </span>
                          )}
                          {isErrorHolidaysSupplemental ? (
                            <div className="error_box">
                              <p className="error_text">
                                {holidaysSupplementalMaxLenError ? holidaysSupplementalMaxLenError - 200 : ''} 文字オーバー
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </dd>
                      </dl>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      給与・賞与<small>※評価・業績により変動します。</small>
                    </dt>
                    <dd>
                      <dl>
                        <dt>
                          給与方式<span className="required_icon">※</span>
                        </dt>
                        <dd>
                          {isEditSalaryForm ? (
                            <span>
                              <select
                                value={salaryFormId || ""}
                                onChange={(e) => changeSalaryFormId(e)}
                              >
                                {allowBlank ? (
                                  <option value="">なし</option>
                                ) : (
                                  <option
                                    value=""
                                    disabled
                                    selected
                                    style={{ display: "none" }}
                                  >
                                    選択してください
                                  </option>
                                )}
                                {salaryForms &&
                                  salaryForms.map((i: any) => (
                                    <option value={i.id} key={i.id}>
                                      {i.name}
                                    </option>
                                  ))}
                              </select>
                              <button
                                onClick={(e) => setIsEditSalaryForm(false)}
                              >
                                完了
                              </button>
                            </span>
                          ) : (
                            <span
                              className={
                                salaryFormId == null ||
                                salaryFormId == undefined
                                  ? "null_txt"
                                  : "edit mt5 addClass"
                              }
                              onClick={(e) => setIsEditSalaryForm(true)}
                            >
                              <span>{findValueSalaryForm()}</span>
                              <span className="edit_icon" />
                            </span>
                          )}
                        </dd>
                        <dt>
                          基本給<span className="required_icon">※</span>
                        </dt>
                        <dd>
                          {isEditMonthlyBasicSalary ? (
                            <span>
                              <label>
                                <input
                                  type="number"
                                  value={lowerMonthlyBasicSalary || ""}
                                  placeholder="下限"
                                  onChange={(e) =>
                                    changeLowerMonthlyBasicSalary(e)
                                  }
                                />
                                円 ～
                                <input
                                  type="number"
                                  value={upperMonthlyBasicSalary || ""}
                                  placeholder="上限"
                                  onChange={(e) =>
                                    changeUpperMonthlyBasicSalary(e)
                                  }
                                />
                                円
                              </label>
                              <button
                                onClick={(e) =>
                                  setIsEditMonthlyBasicSalary(false)
                                }
                              >
                                完了
                              </button>
                            </span>
                          ) : (
                            <span
                              onClick={(e) => setIsEditMonthlyBasicSalary(true)}
                            >
                              {Number(lowerMonthlyBasicSalary).toLocaleString()}
                              円 ～{" "}
                              {Number(upperMonthlyBasicSalary).toLocaleString()}
                              円
                              <span className="edit_icon" />
                            </span>
                          )}
                        </dd>
                        <dt>各種手当・その他手当て</dt>
                        <dd>
                          {isEditAllowances ? (
                            <span>
                              {allowances &&
                                allowances.map((i: any) => (
                                  <label key={i.id}>
                                    <input
                                      type="checkbox"
                                      name="Allowances"
                                      value={i.id}
                                      v-model="selected"
                                      defaultChecked={mySelectedAllowancesId.includes(
                                        i.id
                                      )}
                                      onChange={(e) => changeAllowances(i.id)}
                                    />
                                    {i.name}
                                  </label>
                                ))}
                              <button
                                onClick={(e) => setIsEditAllowances(false)}
                              >
                                完了
                              </button>
                            </span>
                          ) : (
                            <span
                              onClick={(e) => setIsEditAllowances(true)}
                              className={
                                mySelectedAllowancesId == null ||
                                mySelectedAllowancesId == undefined
                                  ? "error"
                                  : "edit mb10"
                              }
                            >
                              {mySelectedAllowancesId.length !== 0 ? (
                                <span>
                                  {mySelectedAllowancesId.map((id, index) => (
                                    <React.Fragment key={id}>
                                      {index > 0 && ","}
                                      {findValue(id, "allowances")}
                                    </React.Fragment>
                                  ))}
                                </span>
                              ) : (
                                <span>未入力</span>
                              )}
                              <span className="edit_icon" />
                            </span>
                          )}
                        </dd>
                        <dt>各種手当補足</dt>
                        <dd className="txt_box">
                          {isEditAllowancesSupplemental ? (
                            <span className="mt10">
                              <textarea
                                aria-colspan={30}
                                aria-rowspan={10}
                                v-show="isEditAllowancesSupplemental"
                                onBlur={(e) =>
                                  setIsEditAllowancesSupplemental(false)
                                }
                                v-model="recruitment.salary.allowances_supplemental"
                                onChange={changeAllowancesSupplemental}
                                value={allowancesSupplemental || ""}
                              />
                            </span>
                          ) : (
                            <span
                              onClick={(e) =>
                                setIsEditAllowancesSupplemental(true)
                              }
                              className={`edit mt5 addClass ${
                                allowancesSupplemental.length === 0
                                  ? "optionGrey"
                                  : ""
                              }`}
                            >
                              <span>
                                {allowancesSupplemental || "補足事項を記入する"}
                              </span>
                              <span className="edit_icon" />
                            </span>
                          )}
                          {isErrorAllowancesSupplemental ? (
                            <div className="error_box">
                              <p className="error_text">
                                {allowancesSupplementalMaxLenError ? allowancesSupplementalMaxLenError - 200 : ''} 文字オーバー
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </dd>
                        <dt>
                          賞与<span className="required_icon">※</span>
                        </dt>
                        <dd>
                          {isEditAnnualNumberOfBonusExists ? (
                            <span>
                              <label>
                                <input
                                  type="radio"
                                  name="TrialPeriodExists"
                                  value="true"
                                  checked={
                                    annualNumberOfBonusExists ===
                                    true
                                  }
                                  onClick={(e) => {
                                    setEdited(true);
                                    setAnnualNumberOfBonusExists(true);
                                    setIsEditAnnualNumberOfBonusExists(false);
                                  }}
                                />
                                有
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="TrialPeriodExists"
                                  value="false"
                                  checked={
                                    annualNumberOfBonusExists ===
                                    false
                                  }
                                  onClick={(e) => {
                                    setEdited(true);
                                    setAnnualNumberOfBonusExists(false);
                                    setIsEditAnnualNumberOfBonusExists(false);
                                  }}
                                />
                                無
                              </label>
                            </span>
                          ) : (
                            <span
                              onClick={(e) =>
                                setIsEditAnnualNumberOfBonusExists(true)
                              }
                              className="edit"
                            >
                              <span>
                                {annualNumberOfBonusExists ? "有" : "無"}
                              </span>
                              <span className="edit_icon" />
                            </span>
                          )}
                          {isEditAnnualNumberOfBonus &&
                            annualNumberOfBonusExists && (
                              <span className="mt10">
                                <input
                                  type="text"
                                  v-model="recruitment.salary.annual_number_of_bonus"
                                  placeholder="賞与についての説明"
                                  onBlur={(e) =>
                                    setIsEditAnnualNumberOfBonus(false)
                                  }
                                  value={annualNumberOfBonus || ""}
                                  onChange={changeAnnualNumberOfBonus}
                                />
                              </span>
                            )}
                          {annualNumberOfBonusExists &&
                            !isEditAnnualNumberOfBonus && (
                              <span
                                onClick={(e) =>
                                  setIsEditAnnualNumberOfBonus(true)
                                }
                                className={`edit mt5 addClass ${
                                  annualNumberOfBonus.length === 0
                                    ? "optionGrey"
                                    : ""
                                }`}
                              >
                                <span>
                                  {annualNumberOfBonus || "補足事項を記入する"}
                                </span>
                                <span className="edit_icon" />
                              </span>
                            )}
                        </dd>
                        {isAnnualIncomeShow ? (
                          <div>
                            <dt>
                              想定年収<span className="required_icon">※</span>
                            </dt>
                            <dd>
                              {isEditEstimatedAnnualIncome ? (
                                <span v-if="isEditEstimatedAnnualIncome">
                                  <label>
                                    <input
                                      type="number"
                                      v-model="recruitment.salary.lower_estimated_annual_income"
                                      value={lowerEstimatedAnnualIncome ? lowerEstimatedAnnualIncome : ''}
                                      placeholder="下限"
                                      onChange={(e) =>
                                        changeLowerEstimatedAnnualIncome(e)
                                      }
                                    />
                                    円 ～
                                    <input
                                      type="number"
                                      v-model="recruitment.salary.upper_estimated_annual_income"
                                      placeholder="上限"
                                      value={ upperEstimatedAnnualIncome ? upperEstimatedAnnualIncome : ''}
                                      onChange={(e) =>
                                        changeUpperEstimatedAnnualIncome(e)
                                      }
                                    />
                                    円
                                  </label>
                                  <button
                                    onClick={(e) =>
                                      setIsEditEstimatedAnnualIncome(false)
                                    }
                                  >
                                    完了
                                  </button>
                                </span>
                              ) : (
                                <span
                                  onClick={(e) =>
                                    setIsEditEstimatedAnnualIncome(true)
                                  }
                                >
                                  {Number(
                                    lowerEstimatedAnnualIncome
                                  ).toLocaleString()}
                                  円 ～
                                  {Number(
                                    upperEstimatedAnnualIncome
                                  ).toLocaleString()}
                                  円
                                  <span
                                    className="edit_icon"
                                    onClick={(e) =>
                                      setIsEditEstimatedAnnualIncome(true)
                                    }
                                  />
                                </span>
                              )}
                            </dd>
                          </div>
                        ) : (
                          <></>
                        )}
                        <dt>
                          昇給<span className="required_icon">※</span>
                        </dt>
                        <dd>
                          {isEditAnnualNumberOfRaisesExists ? (
                            <span>
                              <label>
                                <input
                                  type="radio"
                                  name="AnnualNumberOfRaisesExists"
                                  value="true"
                                  v-model="selected"
                                  checked={
                                    annualNumberOfRaisesExists ===
                                    true
                                  }
                                  onClick={(e) => {
                                    setEdited(true);
                                    setAnnualNumberOfRaisesExists(true);
                                    setIsEditAnnualNumberOfRaisesExists(false);
                                  }}
                                />
                                有
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="AnnualNumberOfRaisesExists"
                                  value="false"
                                  v-model="selected"
                                  checked={
                                    annualNumberOfRaisesExists ===
                                    false
                                  }
                                  onClick={(e) => {
                                    setEdited(true);
                                    setAnnualNumberOfRaisesExists(false);
                                    setIsEditAnnualNumberOfRaisesExists(false);
                                  }}
                                />
                                無
                              </label>
                            </span>
                          ) : (
                            <span
                              onClick={(e) =>
                                setIsEditAnnualNumberOfRaisesExists(true)
                              }
                              className={
                                annualNumberOfRaisesExists == null
                                  ? "edit error"
                                  : "edit"
                              }
                            >
                              <span>
                                {annualNumberOfRaisesExists
                                  ? "有"
                                  : annualNumberOfRaisesExists == null
                                  ? "未入力"
                                  : "無"}
                              </span>
                              <span className="edit_icon" />
                            </span>
                          )}
                          {isEditAnnualNumberOfRaises &&
                            annualNumberOfRaisesExists && (
                              <span className="mt10">
                                <input
                                  type="text"
                                  placeholder="賞与についての説明"
                                  onBlur={(e) =>
                                    setIsEditAnnualNumberOfRaises(false)
                                  }
                                  value={annualNumberOfRaises || ""}
                                  onChange={changeAnnualNumberOfRaises}
                                />
                              </span>
                            )}
                          {annualNumberOfRaisesExists &&
                            !isEditAnnualNumberOfRaises && (
                              <span
                                onClick={(e) =>
                                  setIsEditAnnualNumberOfRaises(true)
                                }
                                className={`edit mt5 addClass ${
                                  annualNumberOfRaises.length === 0
                                    ? "optionGrey"
                                    : ""
                                }`}
                              >
                                <span>
                                  {annualNumberOfRaises || "補足事項を記入する"}
                                </span>
                                <span className="edit_icon" />
                              </span>
                            )}
                        </dd>
                      </dl>
                    </dd>
                  </dl>
                  <dl>
                    <dt>福利厚生</dt>
                    <dd>
                      <dl>
                        <dt>社会保険</dt>
                        <dd>
                          {isEditSocialInsurances ? (
                            <span>
                              {socialInsurances &&
                                socialInsurances.map((i: any, index: any) => (
                                  <label key={i.id}>
                                    <input
                                      type="checkbox"
                                      name="SocialInsurances"
                                      value={i.id}
                                      checked={checkedSocialInsurances.includes(i.id) ? true : false}
                                      v-model="selected"
                                      onChange={(e) =>
                                        onChangeSocialInsurances(e, index)
                                      }
                                    />
                                    {i.name}
                                  </label>
                                ))}
                              <button
                                onClick={(e) =>
                                  setIsEditSocialInsurances(false)
                                }
                              >
                                完了
                              </button>
                            </span>
                          ) : (
                            <span
                              onClick={(e) => setIsEditSocialInsurances(true)}
                              className={
                                checkedSocialInsurances == null ||
                                checkedSocialInsurances == undefined
                                  ? "error"
                                  : "edit mb10"
                              }
                            >
                              {checkedSocialInsurances.length !== 0 ? (
                                <span>
                                  {checkedSocialInsurances.map((id, index) => (
                                    <React.Fragment key={id}>
                                      {index > 0 && ","}
                                      {findValue(id, "social_insurances")}
                                    </React.Fragment>
                                  ))}
                                </span>
                              ) : (
                                <span>未入力</span>
                              )}
                              <span className="edit_icon" />
                            </span>
                          )}
                        </dd>
                        <dt>
                          退職金制度<span className="required_icon">※</span>
                        </dt>
                        <dd>
                          {isEditRetirementMoneyExists ? (
                            <span>
                              <label>
                                <input
                                  type="radio"
                                  name="RetirementMoneyExists"
                                  value="true"
                                  v-model="selected"
                                  checked={
                                    isRetirementMoneyExistsVal ===
                                    true
                                  }
                                  onClick={changeRetirementMoneyExists}
                                />
                                有
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="RetirementMoneyExists"
                                  value="false"
                                  v-model="selected"
                                  checked={
                                    isRetirementMoneyExistsVal ===
                                    false
                                  }
                                  onClick={changeRetirementMoneyExists}
                                />
                                無
                              </label>
                            </span>
                          ) : (
                            <span
                              onClick={(e) =>
                                setIsEditRetirementMoneyExists(true)
                              }
                              className={
                                isRetirementMoneyExistsVal == null
                                  ? "edit error"
                                  : "edit"
                              }
                            >
                              <span>
                                {isRetirementMoneyExistsVal
                                  ? "有"
                                  : isRetirementMoneyExistsVal == null
                                  ? "未入力"
                                  : "無"}
                              </span>
                              <span className="edit_icon" />
                            </span>
                          )}
                        </dd>
                        <dt>定年</dt>
                        <dd>
                          {isEditRetirementAge ? (
                            <span className="mt10">
                              <input
                                type="text"
                                v-model="recruitment.retirement_age"
                                placeholder="60歳"
                                value={retirementAge || ""}
                                onChange={(e) => changeRetirementAge(e)}
                                onBlur={(e) => setIsEditRetirementAge(false)}
                              />
                            </span>
                          ) : (
                            <span
                              onClick={(e) => setIsEditRetirementAge(true)}
                              className={`edit mt addClass ${
                                retirementAge === null || retirementAge === ""
                                  ? ""
                                  // ? "null_txt"
                                  : ""
                              }`}
                            >
                              <span>{retirementAge || "未入力"}</span>
                              <span className="edit_icon" />
                            </span>
                          )}
                        </dd>
                        <dt>その他補足</dt>
                        <dd className="txt_box">
                          {isEditBenefitSupplemental ? (
                            <span className="mt10">
                              <textarea
                                aria-colspan={30}
                                aria-rowspan={10}
                                v-show="isEditBenefitSupplemental"
                                onBlur={(e) =>
                                  setIsEditBenefitSupplemental(false)
                                }
                                v-model="recruitment.benefit_supplemental"
                                onChange={changeBenefitSupplemental}
                                value={benefitSupplemental || ""}
                              />
                            </span>
                          ) : (
                            <span
                              onClick={(e) =>
                                setIsEditBenefitSupplemental(true)
                              }
                              className={`edit mt5 addClass ${
                                benefitSupplemental.length === 0
                                  ? "optionGrey"
                                  : ""
                              }`}
                            >
                              <span>
                                {benefitSupplemental || "補足事項を記入する"}
                              </span>
                              <span className="edit_icon" />
                            </span>
                          )}
                          {isErrorBenefitSupplemental ? (
                            <div className="error_box">
                              <p className="error_text">
                                {benefitSupplementalMaxLenError ? benefitSupplementalMaxLenError - 200 : ''} 文字オーバー
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </dd>
                      </dl>
                    </dd>
                  </dl>
                  <dl>
                    <dt>選考プロセス</dt>
                    <dd>
                      <dl>
                        <dt>選考方法</dt>
                        <dd>
                          {isEditSelectionMethods ? (
                            <span>
                              {selectionMethods &&
                                selectionMethods.map((i: any, index: any) => (
                                  <label key={i.id}>
                                    <input
                                      type="checkbox"
                                      name="SocialInsurances"
                                      value={i.id}
                                      defaultChecked={checkedSelectionMethods.includes(
                                        i.id
                                      )}
                                      onChange={(e) =>
                                        onChangeSelectionMethods(e, index)
                                      }
                                    />
                                    {i.name}
                                  </label>
                                ))}
                              <button
                                onClick={(e) =>
                                  setIsEditSelectionMethods(false)
                                }
                              >
                                完了
                              </button>
                            </span>
                          ) : (
                            <span
                              onClick={(e) => setIsEditSelectionMethods(true)}
                              className={
                                checkedSelectionMethods == null ||
                                checkedSelectionMethods == undefined
                                  ? "error"
                                  : "edit mb10"
                              }
                            >
                              {checkedSelectionMethods.length !== 0 ? (
                                <span>
                                  {checkedSelectionMethods.map((id, index) => (
                                    <React.Fragment key={id}>
                                      {index > 0 && ","}
                                      {findValue(id, "selection_methods")}
                                    </React.Fragment>
                                  ))}
                                </span>
                              ) : (
                                <span>未入力</span>
                              )}
                              <span className="edit_icon" />
                            </span>
                          )}
                        </dd>
                        <dt>その他特記事項</dt>
                        <dd className="txt_box">
                          {isEditSelectionMethodSupplemental ? (
                            <span className="mt10">
                              <textarea
                                aria-colspan={30}
                                aria-rowspan={10}
                                v-show="isEditSelectionMethodSupplemental"
                                onBlur={(e) =>
                                  setIsEditSelectionMethodSupplemental(false)
                                }
                                v-model="recruitment.selection_method_supplemental"
                                onChange={changeSelectionMethodSupplemental}
                                value={selectionMethodSupplemental || ""}
                              />
                            </span>
                          ) : (
                            <span
                              onClick={(e) =>
                                setIsEditSelectionMethodSupplemental(true)
                              }
                              className={`edit mt5 addClass ${
                                selectionMethodSupplemental.length === 0
                                  ? "optionGrey"
                                  : ""
                              }`}
                            >
                              <span>
                                {selectionMethodSupplemental ||
                                  "補足事項を記入する"}
                              </span>
                              <span className="edit_icon" />
                            </span>
                          )}
                          {isErrorSelectionMethodSupplemental ? (
                            <div className="error_box">
                              <p className="error_text">
                                {selectionMethodSupplementalMaxLenError ? selectionMethodSupplementalMaxLenError - 400 : ''}{" "}
                                文字オーバー
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </dd>
                      </dl>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      想定採用人数<span className="required_icon">※</span>
                    </dt>
                    <dd>
                      {isEditNumberOfEmployees ? (
                        <span className="mt10">
                          <input
                            type="number"
                            min="0"
                            v-model="recruitment.target.number_of_employees"
                            value={numberOfEmployees || ''}
                            placeholder="1"
                            onBlur={(e) => setIsEditNumberOfEmployees(false)}
                            onChange={changeNumberOfEmployees}
                          />{" "}
                          人
                        </span>
                      ) : (
                        <span
                          onClick={(e) => setIsEditNumberOfEmployees(true)}
                          className={`edit mt5 addClass ${numberOfEmployees === null || numberOfEmployees === '' ? "null_txt" : ""}`}
                        >
                          <span>
                            {" "}
                            {numberOfEmployees
                              ? `${numberOfEmployees}人`
                              : "未入力"}
                          </span>
                          <span className="edit_icon" />
                        </span>
                      )}
                    </dd>
                  </dl>
                </div>
                <ul className="tab_btn bottom">
                  <li className="active">
                    <a>採用情報</a>
                  </li>
                  <li>
                    <a href="/recruiter/preview/">会社概要</a>
                  </li>
                </ul>
              </div>
            </section>
            <section className="detail_other">
              <div className="add_item_box">
                <h2>
                  一覧画面用 業務内容<span className="required">必須</span>
                  <span className="note">※1行20文字以内でご入力ください。</span>
                </h2>
                <div className="preview_display">
                  <ul>
                    <li key={0}>
                      <input
                        type="text"
                        name="previewText0"
                        value={previewDisplayTexts[0]}
                        placeholder="業務内容を箇条書きで記入してください"
                        onChange={(e) => changePreviewDisplayText(e)}
                      />
                      {isErrorPreviewDisplayText0 ? (
                        <div className="error_box">
                          <p className="error_text">
                            {previewDisplayText0MaxLenError ? previewDisplayText0MaxLenError - 20 : ""} 文字オーバー
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </li>
                    <li key={1}>
                      <input
                        type="text"
                        name="previewText1"
                        value={previewDisplayTexts[1]}
                        placeholder="業務内容を箇条書きで記入してください"
                        onChange={(e) => changePreviewDisplayText(e)}
                      />
                      {isErrorPreviewDisplayText1 ? (
                        <div className="error_box">
                          <p className="error_text">
                            {previewDisplayText1MaxLenError ? previewDisplayText1MaxLenError - 20 : ''} 文字オーバー
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </li>
                    <li key={2}>
                      <input
                        type="text"
                        name="previewText2"
                        value={previewDisplayTexts[2]}
                        placeholder="業務内容を箇条書きで記入してください"
                        onChange={(e) => changePreviewDisplayText(e)}
                      />
                      {isErrorPreviewDisplayText2 ? (
                        <div className="error_box">
                          <p className="error_text">
                            {previewDisplayText2MaxLenError ? previewDisplayText2MaxLenError - 20 : ''} 文字オーバー
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </li>
                    <li key={3}>
                      <input
                        type="text"
                        name="previewText3"
                        value={previewDisplayTexts[3]}
                        placeholder="業務内容を箇条書きで記入してください"
                        onChange={(e) => changePreviewDisplayText(e)}
                      />
                      {isErrorPreviewDisplayText3 ? (
                        <div className="error_box">
                          <p className="error_text">
                            {previewDisplayText3MaxLenError ? previewDisplayText3MaxLenError - 20 : ''} 文字オーバー
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </li>
                  </ul>
                  <p>
                    一覧での見え方を
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handlePreviewModal();
                      }}
                    >
                      プレビューする
                    </button>
                  </p>
                  <a
                    href="/recruitments/"
                    target="_blank"
                    className="btn type3 example"
                  >
                    表示例はこちら
                  </a>
                  {previewModal && (
                    <div className="modal_box">
                      <div className="modal_inner">
                        <h4>プレビュー</h4>
                        <div className="recruitment_inner">
                          <a
                            href="javascript:void(0)"
                            data-v-7f9abc22
                            className="nuxt-link-exact-active nuxt-link-active"
                            aria-current="page"
                          >
                            <div data-v-7f9abc22 className="img_box">
                              {images[0].url != "" ? (
                                <img
                                  src={images[0].url}
                                  alt="20230920160452_zR4KSxol4KbVUj6p3pByOTgsy"
                                />
                              ) : (
                                <img
                                  src="/assets/img/company/icon03.svg"
                                  alt="20230920160452_zR4KSxol4KbVUj6p3pByOTgsy"
                                />
                              )}
                            </div>
                            <div data-v-7f9abc22 className="text_box">
                              {is_new_publish && (
                                <p className="publish">新着求人</p>
                              )}
                              {is_new_update && (
                                <p className="update">最終更新：24時間以内</p>
                              )}
                              <h3 data-v-7f9abc22>
                                {job_title}／{company_name}
                              </h3>
                              <p data-v-7f9abc22>
                                {" "}
                                {findValueSalaryForm()}【
                                {lowerMonthlyBasicSalary} 〜{" "}
                                {upperMonthlyBasicSalary} 円】
                              </p>
                              <p data-v-7f9abc22 className="tag"></p>
                              <p data-v-7f9abc22 className="category">
                                {findValue(
                                  recruitmentEmploymentStatusId,
                                  "employment_statuses"
                                )}
                              </p>
                              <p data-v-7f9abc22 className="new_graduate">
                                {newGraduate ? "新卒" : "中途"}
                              </p>
                              <p data-v-7f9abc22 className="type">
                                {findValue(
                                  typeOfRecruitingCompanyId,
                                  "type_of_recruiting_companies"
                                )}
                              </p>
                              <p data-v-7f9abc22 className="address"></p>
                              <p data-v-7f9abc22>
                                <strong data-v-7f9abc22>業務内容</strong>
                                <ul data-v-7f9abc22>
                                  {previewDisplayTexts[0] ? (
                                    <li data-v-7f9abc22>
                                      {previewDisplayTexts[0]}
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                  {previewDisplayTexts[1] ? (
                                    <li data-v-7f9abc22>
                                      {previewDisplayTexts[1]}
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                  {previewDisplayTexts[2] ? (
                                    <li data-v-7f9abc22>
                                      {previewDisplayTexts[2]}
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                  {previewDisplayTexts[3] ? (
                                    <li data-v-7f9abc22>
                                      {previewDisplayTexts[3]}
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                </ul>
                              </p>
                              {already_applied && (
                                <p className="publish">エントリー済み</p>
                              )}
                            </div>
                          </a>
                        </div>
                        <p
                          data-v-47758f5f
                          className="close"
                          onClick={(e) => setPreviewModal(false)}
                        >
                          <span className="material-icons">close</span>
                        </p>
                      </div>
                      <div
                        data-v-47758f5f
                        className="overlay"
                        onClick={(e) => setPreviewModal(false)}
                      ></div>
                    </div>
                  )}
                </div>
              </div>

              <div className="add_item_box">
                <h2>希望の採用要件</h2>
                <dl>
                  <dt>
                    採用種別<span className="required">必須</span>
                  </dt>
                  <dd>
                    <label>
                      <input
                        type="radio"
                        name="new_graduate"
                        value="true"
                        checked={newGraduate}
                        v-model="recruitment.new_graduate"
                        onClick={() => {
                          setNewGraduate(true);
                          setEdited(true);
                        }}
                      />
                      新卒
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="new_graduate"
                        value="false"
                        checked={!newGraduate}
                        v-model="recruitment.new_graduate"
                        onClick={() => {
                          setNewGraduate(false);
                          setEdited(true);
                        }}
                      />
                      中途
                    </label>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    採用スケジュール<span className="required">必須</span>
                  </dt>
                  <dd>
                    <select
                      value={scheduleId || ""}
                      onChange={(e) => changeScheduleId(e)}
                    >
                      <option value="">選択してください</option>
                      {schedules &&
                        schedules.map((i: any) => (
                          <option key={i.id} value={i.id}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    地域<span className="required">必須</span>
                  </dt>
                  <dd className="edit_area">
                    <span
                      className="edit_icon"
                      onClick={(e) => setIsArea(true)}
                    />
                    {selectedPrefectures.length === 0 && !isArea ? (
                      <p className="edit null_txt">未入力</p>
                    ) : (
                      <div>
                        {!isArea ? (
                          <ul>
                            {selectedPrefectures.map((i: any) => (
                              <li key={i}>{findValue(i, "prefectures")}</li>
                            ))}
                          </ul>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    {isArea ? (
                      <div className="select_area">
                        <div className="_flex no_size">
                          {region.map((i: any, index: any) => (
                            <label key={i.id}>
                              <input
                                type="checkbox"
                                defaultChecked={prefectures.some(
                                  (prefecture) =>
                                    selectedPrefectures.includes(
                                      prefecture.id
                                    ) && prefecture.region_id === i.id
                                )}
                                onChange={(e) =>
                                  updateValuePrefectures(e, index)
                                }
                                value={i.id}
                              />
                              {i.name}
                              <br />
                            </label>
                          ))}
                          {mySelectedPrefectures.length !== 0 && (
                            <div className="checkbox_list area area_child _flex" style={{width:'100%'}}>
                              {prefectures
                                .filter((data) =>
                                  mySelectedPrefectures.includes(
                                    data.region_id.toString()
                                  )
                                )
                                .map((i: any, index: any) => (
                                  <label key={i.id} 
                                    style={{whiteSpace: "nowrap"}}>
                                    <input
                                      type="checkbox"
                                      defaultChecked={selectedPrefectures.includes(
                                        i.id
                                      )}
                                      onChange={(e) =>
                                        updateValuePrefectures2(e, i.id)
                                      }
                                      value={i.id}
                                    />
                                    {i.name}
                                    <br />
                                  </label>
                                ))}
                            </div>
                          )}
                        </div>
                        <button onClick={(e) => setIsArea(false)}>完了</button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </dd>
                </dl>
                <dl>
                  <dt>
                    障がい区分<span className="required">必須</span>
                  </dt>
                  <dd className="area edit_area">
                    <span
                      className="edit_icon"
                      onClick={(e) => setIsClassification(true)}
                    />
                    {selectHandicapTypesIds.length === 0 &&
                    !isClassification ? (
                      <p className="edit null_txt">未入力</p>
                    ) : (
                      <div>
                        {!isClassification ? (
                          <ul>
                            {selectHandicapTypesIds.map((i: any) => (
                              <li key={i}>{findValue(i, "handicap_types")}</li>
                            ))}
                          </ul>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    {isClassification ? (
                      <div className="select_area">
                        <div className="_flex no_size">
                          {handicapTypes.map((i: any, index: any) => (
                            <label v-for="i in handicap_types" key={i.id}>
                              <input
                                type="checkbox"
                                v-model="recruitment.handicap_types"
                                defaultChecked={selectHandicapTypesIds.includes(
                                  i.id
                                )}
                                onChange={(e) => updatePurpose(e, index)}
                                value={i.id}
                              />
                              {i.name}
                              <br />
                            </label>
                          ))}
                        </div>
                        <button onClick={(e) => setIsClassification(false)}>
                          完了
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </dd>
                </dl>
              </div>
              <div className="add_item_box half mb0">
                <h2>
                  エントリー時に聞きたい内容
                  <span className="required">必須</span>
                </h2>
                <ul>
                  <li>
                    <label>
                      <span>アピール項目1</span>
                      <select
                        name="appeal_item_1"
                        onChange={(e) => handler_appealItem(e)}
                      >
                        {appeal_item_1 == null ? (
                          <option value="" style={{ display: "none" }}>
                            選択してください
                          </option>
                        ) : (
                          <option
                            value={appeal_item_1}
                            selected={appeal_item_1}
                            style={{ display: "none" }}
                          >
                            {appeal_item_1_name}
                          </option>
                        )}
                        {appealItems &&
                          checkList1(appealItems).map((data: any) => (
                            <option value={data.id} key={data.id}>
                              {data.name}
                            </option>
                          ))}
                      </select>
                    </label>
                  </li>
                  <li>
                    <label>
                      <span>アピール項目2</span>
                      <select
                        name="appeal_item_2"
                        onChange={(e) => handler_appealItem(e)}
                      >
                        {appeal_item_2 == null ? (
                          <option value="" style={{ display: "none" }}>
                            選択してください
                          </option>
                        ) : (
                          <option
                            value={appeal_item_2}
                            selected={appeal_item_2}
                            style={{ display: "none" }}
                          >
                            {appeal_item_2_name}
                          </option>
                        )}
                        {appealItems &&
                          checkList1(appealItems).map((data: any) => (
                            <option value={data.id} key={data.id}>
                              {data.name}
                            </option>
                          ))}
                      </select>
                    </label>
                  </li>
                  <li>
                    <label>
                      <span>アピール項目3</span>
                      <select
                        name="appeal_item_3"
                        onChange={(e) => handler_appealItem(e)}
                      >
                        {appeal_item_3 == null ? (
                          <option value="" style={{ display: "none" }}>
                            選択してください
                          </option>
                        ) : (
                          <option
                            value={appeal_item_3}
                            selected={appeal_item_3}
                            style={{ display: "none" }}
                          >
                            {appeal_item_3_name}
                          </option>
                        )}
                        {appealItems &&
                          checkList1(appealItems).map((data: any) => (
                            <option
                              value={data.id}
                              id={`appeal_item_3_` + data.id}
                              key={data.id}
                            >
                              {data.name}
                            </option>
                          ))}
                      </select>
                    </label>
                  </li>
                </ul>
              </div>
              <div className="add_item_box half mb0_pc">
                <h2>求人ステータス</h2>
                <div className="recruitment_status">
                  <p>現在のステータス: {currentStatusName}</p>
                  {/* {currentStatusId == 2 || statusId == 2 ? 
                    <select
                      v-model="mySelected"
                      disabled={isdisabled()}
                      onChange={(e)=>updateStatus(Number(e.target.value))}
                    >
                      <option value="null" disabled selected style={{ display:"none" }}>{ defaultText() }</option>
                      {updateStatuses && updateStatuses.map((i: any) => (
                        <option value={i.id} key={i.id}>
                          {i.name}
                        </option>
                      ))}
                    </select>
                  :<></>} */}
                </div>
              </div>
              <div className="add_item_box mt20">
              <div>
                    <h2>本求人で採用できる（または実績のある）障害にチェックを入れてください</h2>
                    <div style={{padding:'10px', marginTop:'5px'}}>
                        <div style={{display:'ruby'}}>
                        {fullArray && fullArray.map((data, index) => (
                                <label key={data.id}  style={windowSize.width > 768 ? {width:"33%", display:'block'} : {width:"100%",display:'block'}}>
                                    <input style={{marginBottom:'20px'}}  type="checkbox" value={data.id}  checked={selectedMatomeIds.includes(data.id) ? true : false} onChange={(e) => onChangeMatome(e, index)}></input>
                                    {data.name}
                                </label>
                            ))}
                        </div>

                    </div>
                </div>
              </div>
            </section>
        

            <div className="btn_area">
              {currentStatusId == 1 || currentStatusId == 4 ? (
                <div>
                  <button
                    type="button"
                    onClick={(e) => update(true, updateBtn)}
                    className="btn type3"
                  >
                    更新する{" "}
                  </button>
                  <button
                    type="button"
                    className="btn type3 open_btn"
                    onClick={onClickPublish}
                  >
                    公開する
                  </button>
                </div>
              ) : currentStatusId == 2 ? (
                <div>
                  <button
                    type="button"
                    onClick={(e) => submit(true, publishBtn)}
                    className="btn type3 open_btn"
                  >
                    更新する
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    type="button"
                    onClick={(e) => submit(true, updateBtn)}
                    className="btn type3 open_btn"
                  >
                    更新する
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}
export default RecruitmentsDetail;
