import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdminNav from '../adminTop/AdminNav';
import { RootState } from "../../redax/reducers/rootReducer";
import { fetchAdminCompanyListRequest } from '../../redax/actions/adminCompanyListActions/adminCompanyListActions';
import Loader from '../loader/loader';
import '../adminTop/adminTop.css';


const AdminCompanyList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();    
    const [current_page, setCurrentPage] = useState(1);
    const [companyList, setCompanyList] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [total_pages, setTotalPages] = useState(1);
    const [keyword, setKeyword] = useState(''); 
    const companies = useSelector((state:RootState) => state.adminCompanyList);
    const [loadScreen, setLoadScreen] = useState(true);
    const [putResponse, setPutResponse] = useState(false);

    useEffect(() => {
        getCompanies(1,'')
    }, [dispatch]); 

    const getCompanies = (page:any, keyword:string) => {
        setLoadScreen(true);
        const data = {
            "page": page,
            "keyword": keyword ? keyword : '',
        }
        if(data != null) {
            dispatch(fetchAdminCompanyListRequest(data));
            if(companies.pagenation !== undefined) {
                setCurrentPage(companies.pagenation.current_page);
                setTotalPages(companies.pagenation.total_pages);
              }
        }
        setLoadScreen(false)
    }

    useEffect(() => {
        if(companies.companies) {
            setLoadScreen(false);
            setCompanyList(companies.companies)

            if(companies.pagenation !== undefined) {
                setCurrentPage(companies.pagenation.current_page);
                setTotalPages(companies.pagenation.total_pages);
              }
        } else if(companies.error != null) {
            navigate('/error?code=500');
        }
    }, [companies]);

    const shaping_pagenation = () => {
        if (total_pages === null) return [1]; // Add a check for total_pages being null
        if (total_pages === 1) return [1]; // Add a check for total_pages being null
        
        if (total_pages <= 11) {
          return Array.from({ length: total_pages }, (_, index) => index + 1);
        } else if (current_page < 7) {
          return Array.from({ length: 11 }, (_, index) => index + 1);
        } else if (total_pages - current_page <= 5) {
          const diff_current_total = total_pages - current_page;
          const pagenation_start = current_page - (11 - (diff_current_total + 1));
          const pagenation_end = current_page + diff_current_total;
          return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
        } else {
          const pagenation_start = current_page - 5;
          const pagenation_end = current_page + 5;
          return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
        }
    };

    const pageIncrement = () => {
        if (page >= total_pages) {
          setPage(total_pages);
          return;
        }
        setPage((prevPage) => prevPage + 1);
        setLoadScreen(true);
        getCompanies(page+1,keyword)
      }
  
      const pageDecrement = () => {
          if (page <= 1) {
              setPage(1);
              return;
          }
          setPage((prevPage) => prevPage - 1);
          setLoadScreen(true);
          getCompanies(page-1,keyword)
      }
  
      const updatePage = (p: any) => {
          setPage(p);
          setLoadScreen(true);
          getCompanies(p,keyword)
      }

    function handleInput(e: any) {  
        if(e.target.name == 'keyword') {
            setKeyword(e.target.value);
        }
    }

    function handleClick() {
        getCompanies(1,keyword)
    }


  return (
    <main id='adminHome'>
      <AdminNav/>
      {loadScreen ? <Loader></Loader> : <></>}
      <section>
        <div className="adminPageWrapper">
          <div className="header">
            <h2 className="title">企業一覧</h2>
            <div className="inputBox">
              <label className="label1">
                <span>企業名: </span>
                <input name="keyword" type="text" className="inputText" placeholder='企業名で検索...' onChange={handleInput}/>
                <button className="inputBtn" onClick={handleClick}>検索</button>
              </label>
            </div>
          </div>   
          <div className="body">
            <div className="adminTable1Wrapper">
              <div className="header">
                <div className="textWrapper">
                  <span className="text">{current_page}/{total_pages} ページ</span>
                </div>
                <div>
                <button className='createBtn' onClick={() =>navigate('/admin/companies/new')}  style={{padding:'10px 30px', fontSize:'14px',marginRight:'10px'}}>新規作成</button>
                </div>
              </div>
              <div className="body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>企業名</th>
                      <th>業種分類</th>
                      <th>住所</th>
                      <th>契約プラン/次回プラン</th>
                      <th>登録日/最終ログイン</th>
                      <th className="text-center">-</th>
                    </tr>
                  </thead>
                  <tbody>
                  {companyList.length != 0 && companyList.map((company: any, index: any) => (
                    <tr>
                      <td>{company.id}</td>
                      <td>{company.name}</td>
                      <td>{company.industry ? company.industry : '未登録'}</td>
                      <td>{company.full_address}</td>
                      {/* <td><span className="professional">プロ（保）（代）</span>/<span className="standard">スタンダード（代）</span></td> */}
                      <td><span>-</span>/<span>-</span></td>
                      <td>{company.created_at.substr(2,8)}/<br></br>{company.last_login_at ? company.last_login_at.substr(2,8) : 'No Login'} {company.last_login_at ? company.last_login_at.substr(11,5) : ''}</td>
                      <td><button className="btn" onClick={()=>navigate('/admin/companies/' + company.id)}>確認する</button></td>
                    </tr>    
                  ))}
                  </tbody>
                </table>
                <div className="paginationWrapper">
                    <div className="icon"></div>
                    <ul className="pagination">
                        {current_page && current_page > 1 && (
                        <li className="arrow prev">
                            <a onClick={() => pageDecrement()}>
                            <img src="/assets/img/company/icon06.svg" alt="前へ" />
                            </a>
                        </li>
                        )}

                        {shaping_pagenation().map((n: any) => (
                        <li key={n} className={n === current_page ? 'active' : ''}>
                            <a onClick={() => updatePage(n)}>{n}</a>
                        </li>
                        ))}

                        {current_page && current_page < total_pages && (
                        <li className="arrow next">
                            <a onClick={() => pageIncrement()}>
                            <img src="/assets/img/company/icon07.svg" alt="次へ" />
                            </a>
                        </li>
                        )}
                    </ul>
                </div>
              </div>
            </div>
          </div> 
        </div>      
      </section>
    </main>
  )
}

export default AdminCompanyList
