import { useEffect, useRef, useState } from 'react';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import './profile.scss';
import ProfileTab from './tab';
import ProfileIndex from '.';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { fetchApplicantsRequest } from '../../../redax/actions/applicantsActions/applicantsActions';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchRegionsRequest } from '../../../redax/actions/regionsActions/regionsActions';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { fetchPutRegionRequest } from '../../../redax/actions/putRegionActions/putRegionActions';
import Loader from '../../loader/loader';

export default function ProfilePage6() {
    const [loadScreen, setLoadScreen] = useState(true);
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);    
    const [authenticate, setAuthenticate] = useState(null);
    const [working_hours, setWorkingHours] = useState<any[]>([]);  
    const [search_policies, setSearchPolicies] = useState<any[]>([]);    
    const [region, setRegion] = useState<any[]>([]);    
    const [prefectures, setPrefectures] = useState<any[]>([]);    
    const [employment_status, setEmploymentStatus] = useState<any[]>([]); 
    const [join_months, setJoinMonths] = useState<any[]>([]); 
    const [annual_incomes, setAnnualIncomes] = useState<any[]>([]); 
    const [work_styles, setWorkStyles] = useState<any[]>([]); 
    const [work_from_home, setWorkFromHome] = useState<any[]>([]); 
    const [job_categories, setJobCategories] = useState<any[]>([]); 
    const [job_category_types, setJobCategoryTypes] = useState<any[]>([]); 
    const [commuting_method, setCommutingMethod] = useState<any[]>([]); 
    const [industries, setIndustries] = useState<any[]>([]); 
    const [prefecture_id_1, setPrefectureId1] = useState(null);
    const [prefecture_id_2, setPrefectureId2] = useState(null);
    const [region_1, setRegion1] = useState(null);
    const [region_2, setRegion2] = useState(null);
    const [industry_id_1, setIndustryId1] = useState(null);
    const [industry_id_2, setIndustryId2] = useState(null);
    const [job_category_id_1, setJobCategoryid1] = useState(null);
    const [job_category_id_2, setJobCategoryid2] = useState(null);
    const [job_type_1, setJobType1] = useState(null);
    const [job_type_2, setJobType2] = useState(null);
    const [employment_status_id, setEmploymentStatusId] = useState<any[]>([]);
    const [annual_income_id, setAnnualIncomeId] = useState(null);
    const [join_month_id, setJoinMonthsId] = useState(null);
    const [search_policy_id, setSearchPolicyId] = useState(null);
    const [commuting_methods, setCommutingMethods] = useState<any[]>([]);
    const [working_hour_id, setWorkingHourId] = useState(null);
    const [work_from_home_id, setWorkFromHomeId] = useState(null);
    const [nationwide, setNationwide] = useState(false);
    const [compatibilities, setCompatibilities] = useState<any[]>([]);
    const [employment_statuses, setEmploymentStatuses] = useState<any[]>([]); 
    const [is_activate, setIsActivate] = useState(false);
    const [tempolary, settempolary] = useState(false);
    const [compatibility, setCompatibility] = useState<any[]>([]);
    const [employments, setEmployments] = useState<any[]>([]);
    const [commutingMeth, setCommutingMeth] = useState<any[]>([]);
    const navigate = useNavigate(); 
    const dispatch = useDispatch();
    const loginData = useSelector((state: RootState) => state.login);
    const applicants = useSelector((state: RootState) => state.applicants);
    const regions = useSelector((state: RootState) => state.regions);
    const putregion = useSelector((state: RootState) => state.putregion);
    const [navigation_book, setNavigationBook] = useState(null); 
    const [edited,setEdited] = useState(false);
    const pageTitle = 'WEB履歴書（希望条件）｜パラちゃんねる';
    document.title = pageTitle;
    const queryParams = new URLSearchParams(window.location.search);
    const refs = useRef<any>(null);
    useEffect(() => {
      if (!localStorage.getItem('jobParachannelAuthToken')) {
        navigate('/login');
      } 
    }, [navigate, dispatch]);

    useEffect(() => {
      dispatch(fetchRegionsRequest());
      if(queryParams.get('tab') != null || queryParams.get('next') != null) {
        setTimeout(() => {              
            refs.current?.scrollIntoView({behavior:"smooth", block:"start"})
        }, 0);
    }
    }, [dispatch]);

    useEffect(() => {
      if(!edited) return;

        window.addEventListener("beforeunload", confirmSave);

        return () => window.removeEventListener("beforeunload", confirmSave);

    },[edited]);

    function confirmSave (event: any) {
        event.preventDefault();
        if (edited) {
            event.returnValue = "編集中のデータは保存されていませんが、移動しますか？"
        }
    }

    useEffect(() => {
        if (loginData.pending) {
          if(!loginData.login.is_init_complete) {
            navigate('/mypage/init/');
          }
          setResourceId(loginData.login.resource.id);
          setFamylyName(loginData.login.resource.family_name);
          setAuthenticate(loginData.login.resource.id);
          dispatch(fetchApplicantsRequest(loginData.login.resource.id, 'get', 0));
        }
    }, [dispatch, loginData.pending]);

    useEffect(() => {
      if (applicants.pending) {    
        const applicantData = applicants.applicants.applicant;
        setPrefectureId1(applicantData.preferred.prefecture_id_1);
        setPrefectureId2(applicantData.preferred.prefecture_id_2);
        setIndustryId1(applicantData.preferred.industry_id_1);
        setIndustryId2(applicantData.preferred.industry_id_2);
        setJobCategoryid1(applicantData.preferred.job_category_id_1);
        setJobCategoryid2(applicantData.preferred.job_category_id_2);
        setEmploymentStatusId(applicantData.preferred.employment_status_id);
        setAnnualIncomeId(applicantData.preferred.annual_income_id);
        setJoinMonthsId(applicantData.preferred.join_month_id);
        setSearchPolicyId(applicantData.preferred.search_policy_id);
        setCommutingMethods(applicantData.preferred.commuting_methods);
        setCommutingMeth(applicantData.preferred.commuting_methods);
        setWorkingHourId(applicantData.preferred.working_hour_id);
        setWorkFromHomeId(applicantData.preferred.work_from_home_id);
        setNationwide(applicantData.preferred.nationwide);
        setCompatibilities(applicantData.compatibilities);
        setCompatibility(applicantData.compatibilities);
        setEmploymentStatuses(applicantData.employment_statuses);
        setEmployments(applicantData.employment_statuses);
        setIsActivate(applicantData.is_active)
        setNavigationBook(applicantData.navigation_book)
        setLoadScreen(false);
      } else if(applicants.error != null) {
        setLoadScreen(false);
        navigate('/error?code=500');
      }
    }, [applicants]);

    useEffect(() => {
      if (regions.pending) {
        const regionsData = regions.regions;
        setWorkingHours(regionsData.working_hours);
        setSearchPolicies(regionsData.search_policies);
        setRegion(regionsData.regions);
        setPrefectures(regionsData.prefectures);
        setEmploymentStatus(regionsData.employment_statuses);
        setJoinMonths(regionsData.join_months);
        setAnnualIncomes(regionsData.annual_incomes);
        setWorkStyles(regionsData.work_styles);
        setWorkFromHome(regionsData.work_from_home);
        setJobCategories(regionsData.job_categories);
        setJobCategoryTypes(regionsData.job_category_types);
        setCommutingMethod(regionsData.commuting_methods);
        setIndustries(regionsData.industries);
      }
    }, [regions.pending]);

    useEffect(() => {
      if (putregion.pending) {
        if (localStorage.entryFlag) {
          if(window.confirm("エントリーページへ戻りますか？")) {
            let recruitment_id = localStorage.entryFlag;
            localStorage.removeItem('entryFlag');
            window.location.href = '/recruitments/' + recruitment_id + '/entry/';
            return;
          }
        }
      
        if (tempolary) {
            alert('保存しました');
            navigate('/mypage/');
            return;
        }
        // navigate('/mypage/profile7/');
        window.location.href = '/mypage/profile7/?next=true';
      } else if(putregion.error != null) {
        setLoadScreen(false);
        navigate('/error?code=500');
      }
    }, [putregion]);

    const findValue = (value: any, masterName: string | number) => {
      const dataSet = regions.regions[masterName];
      if (dataSet === undefined) {
        return null;
      }
      return value && value !== undefined ? dataSet.find((v: { id: number; }) => v.id === Number(value))?.name : '未登録';
    };
    
    const [searchPoliciesModal, setSearchPoliciesModal] = useState(false);
    const searchPoliciesModalToggle = () => {
      setSearchPoliciesModal(!searchPoliciesModal);
      setEdited(true);
    };
    const [prefectureModalSlide, setPrefectureModalSlide] = useState(0);
    const [prefectureModal, setPrefectureModalModal] = useState(false);
    const prefectureModalToggle = () => {
      setEdited(true);
      setPrefectureModalModal(!prefectureModal);
      setPrefectureModalSlide(0);
    };

    function prev() {
      setPrefectureModalSlide(0);
    }

    function prevJob() {
      setJobcategoryModalSlide(0);
    }

    const [prefectureModal2, setPrefectureModalModal2] = useState(false);
    const prefectureModalToggle2 = () => {
      setEdited(true);
      setPrefectureModalModal2(!prefectureModal2);
      setPrefectureModalSlide(0);
    };

    const [workStyleModal, setWorkStyleModal] = useState(false);
    const workStyleModalToggle = () => {
      setEdited(true);
      setWorkStyleModal(!workStyleModal);
    };
    const workStyleDelete = () => {
      setEdited(true);
      setCompatibilities([]);
      setCompatibility([]);
    };
    
    const handleCheckboxCompatibilities = (event: any, id: any) => {
      if ( id !== 0 ) {
        if (compatibility.includes(id)) {
          setCompatibility(compatibility.filter(skill => skill !== id));
        } else {
          setCompatibility([...compatibility, id]);
        }
      } 
      if (id === 0) {
        setCompatibilities(compatibility);
        workStyleModalToggle();
      }
    };
    const [commutingMethodsModal, setCommutingMethodsModal] = useState(false);
    const commutingMethodsModalToggle = () => {
      setEdited(true);
      setCommutingMethodsModal(!commutingMethodsModal);
    };
    const commutingMethodsDelete = () => {
      setEdited(true);
      setCommutingMethods([]);
    };
   
    const handleCheckboxCommutingMethods = (event: any, id: any) => {
      if ( id !== 0 ) {
        if (commutingMeth.includes(id)) {
          setCommutingMeth(commutingMeth.filter(skill => skill !== id));
        } else {
          setCommutingMeth([...commutingMeth, id]);
        }
      } 
      if (id === 0) {
        setCommutingMethods(commutingMeth);
        commutingMethodsModalToggle();
      }
    };
    const [jobCategoryModalSlide, setJobcategoryModalSlide] = useState(0);
    const [industriesModal, setIndustriesModal] = useState(false);
    const industriesModalToggle = () => {
      setIndustriesModal(!industriesModal);
    };
    const [industriesModal2, setIndustriesModal2] = useState(false);
    const industriesModalToggle2 = () => {
      setIndustriesModal2(!industriesModal2);
    };

    const [jobCategoryModal1, setJobcategoryModal1] = useState(false);
    const jobcategoryModalToggle1 = () => {
      setJobcategoryModal1(!jobCategoryModal1);
      setJobcategoryModalSlide(0);
    };
    const [jobCategoryModal2, setJobcategoryModal2] = useState(false);
    const jobcategoryModalToggle2 = () => {
      setJobcategoryModal2(!jobCategoryModal2);
      setJobcategoryModalSlide(0);
    };
    const [employmentStatusModal, setEmploymentsStatusModal] = useState(false);
    const employmentStatusModalToggle = () => {
      setEdited(true);
      setEmploymentsStatusModal(!employmentStatusModal);
    };
    const employmentStatusDelete = () => {
      setEmploymentStatuses([]);
    };

    function filterIndustries () {
      return industries.filter((v: any) => {
          if ( industry_id_1 == v.id ) { return false }
          if ( industry_id_2 == v.id ) { return false }
          return true
      });
    }
    
    const handleCheckboxEmploymentStatus = (event: any, id: any) => {
      if ( id !== 0 ) {
        if (employments.includes(id)) {
          setEmployments(employments.filter(skill => skill !== id));
        } else {
          setEmployments([...employments, id]);
        }
      } 
      if (id === 0) {
        setEmploymentStatuses(employments);
        employmentStatusModalToggle();
      }
    };
    const [annualIncomeModal, setAnnualIncomeModal] = useState(false);
    const annualIncomeModalToggle = () => {
      setEdited(true);
      setAnnualIncomeModal(!annualIncomeModal);
    };

    const [joinMonthsModal, setJoinMonthsModal] = useState(false);
    const joinMonthsModalToggle = () => {
      setEdited(true);  
      setJoinMonthsModal(!joinMonthsModal);
    };

    function submit (e: any, tempolary1 = false) {
      
      if(!tempolary1) {
        e.preventDefault();
      }

      const data = {
        applicant: {
          preferred: {
            annual_income_id: annual_income_id,
            commuting_methods: commuting_methods,
            employment_status_id: employment_status_id,
            industry_id_1: industry_id_1,
            industry_id_2: industry_id_2,
            job_category_id_1: job_category_id_1,
            job_category_id_2: job_category_id_2,
            join_month_id: join_month_id,
            nationwide: nationwide,
            prefecture_id_1: prefecture_id_1,
            prefecture_id_2: prefecture_id_2,
            search_policy_id: search_policy_id,
            working_hour_id: working_hour_id,
            work_from_home_id: work_from_home_id,
          },
          employment_statuses: employment_statuses,
          compatibilities: compatibilities,
          is_active: is_activate,
        }
      }
      setEdited(false);
      settempolary(tempolary1);
      setLoadScreen(true);
      dispatch(fetchPutRegionRequest(resourceId, data));

    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === "Enter") { 
        searchPoliciesModalToggle();
      }
  };


  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setSearchPoliciesModal(false);
        setWorkStyleModal(false);
        setPrefectureModalModal(false);
        setPrefectureModalModal2(false);
        setCommutingMethodsModal(false);
        setIndustriesModal(false);
        setIndustriesModal2(false);
        setJobcategoryModal1(false);
        setJobcategoryModal2(false);
        setEmploymentsStatusModal(false);
        setAnnualIncomeModal(false);
        setJoinMonthsModal(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);


    return (
      <main id="mypage">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="ApplicantList" />
        {loadScreen ? <Loader /> : <></>}
        <section id="mypage_inner">
            <ProfileIndex authenticate={authenticate} navigation_book_url={navigation_book}/>
            <ProfileTab ref={refs} index={6} />
            <form className="profile wrapper">
              <div className="cont1">
                <h3 className="ttl">希望条件</h3> 
                <p className="ex_info">※<span className="required">必須</span>が付いている項目はエントリーに必要な情報です。</p> 
                <div className="wrapper">
                  <table>
                    <tbody className="other">
                      <tr>
                        <th>仕事の探し方<span className="any">任意</span></th> 
                        <td className="nest">
                          <div className="nest_child select_cont">
                            <p className={search_policy_id ? 'select comp' : 'select'} tabIndex={0}   onKeyDown={handleKeyDown} >
                              <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={searchPoliciesModalToggle}>
                                {findValue(search_policy_id, 'search_policies') || '未登録'}
                              </a>
                            </p>
                          </div>
                        </td>
                        {searchPoliciesModal ?
                          <div className="modal_box selectRadio1">
                              <div className="modal_inner">
                              <h4>仕事の探し方</h4>
                              <form>
                                  <h5 className="modal_ttl">仕事の探し方を選択してください</h5>
                                  <div className="slider_item">
                                      {search_policies.map((d: any) => (
                                          <label key={d.id}>
                                              <input type="radio" name={d.name} defaultChecked={search_policy_id == d.id} value={d.id} onClick={() => {searchPoliciesModalToggle(); setSearchPolicyId(d.id);}} />
                                              <span>{ d.name }</span>
                                          </label>
                                      ))} 
                                  </div>
                              </form>
                              <p className="close" onClick={searchPoliciesModalToggle}>
                                  <span className="material-icons">close</span>
                              </p>
                              </div>
                              <div className="overlay"onClick={searchPoliciesModalToggle}></div>
                          </div>
                      : null }
                      </tr> 
                      <tr className='displayNone'>
                        <th>働き方
                          <span className="any">任意</span>
                        </th>
                        <td className="nest">
                          <div className="box">
                            <div className="nest_child acc_list_cont">
                              {compatibilities.length === 0 ? 
                              <p className="acc_ttl fixed" tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {workStyleModalToggle()}}} >
                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={workStyleModalToggle}>
                                 仕事の探し方を追加する
                                </a>
                              </p>
                              :
                              <><div className="nest_child">
                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                                <div className="txt_cont" onClick={workStyleModalToggle}>
                                  <p><span>{compatibilities.map((id, index) => (
                                    <React.Fragment key={id}>
                                      {index > 0 && ' / '}
                                      {findValue(id, 'work_styles')}
                                    </React.Fragment>
                                  ))}
                                  </span></p>
                                </div> <div className="icon_cont"  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {workStyleModalToggle()}}}>
                                  <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={workStyleModalToggle} alt="memo_icon" /></span>
                                </div></a>
                              </div>
                              <div className="nest_child acc_list_cont">
                                <p className="acc_ttl del" tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {workStyleDelete()}}}>
                                  <a onClick={workStyleDelete}>仕事の探し方を削除する</a>
                                </p>
                              </div></>
                              }
                            </div>
                          </div>
                        </td>
                        {workStyleModal ?
                          <div className="modal_box checkbox">
                              <div className="modal_inner">
                              <h4>働き方</h4>
                              <form>
                                  <h5 className="modal_ttl">希望する働き方を選択してください</h5>
                                  <p className="note">※最大5つまで選択可</p>
                                  <div className="slider_item">
                                      {work_styles.map((d: any) => (
                                          <label key={d.id}>
                                              <input type="checkbox"  name={d.name} defaultChecked={compatibilities.includes(d.id)} onChange= {(e) => handleCheckboxCompatibilities(e, d.id)} disabled={compatibility.length > 4 && !compatibility.includes(d.id)}/>
                                              <span>{ d.name }</span>
                                          </label>
                                      ))} 
                                  </div>
                              </form>
                              <p className="complete"  tabIndex={0} onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  workStyleModalToggle(); 
                                  handleCheckboxCompatibilities(e, 0)
                                  setTimeout(() => {
                                    const nextElement = document.querySelector(".after-modal") as HTMLElement;
                                    nextElement?.focus(); 
                                  }, 10);
                                }
                              }}  onClick= {(e) => handleCheckboxCompatibilities(e, 0)}>完了</p>
                              <p className="close" onClick={workStyleModalToggle}>
                                  <span className="material-icons">close</span>
                              </p>
                              </div>
                              <div className="overlay"onClick={workStyleModalToggle}></div>
                          </div>
                      : null }
                      </tr> 
                      <tr>
                        <th>就労時間<span className="any">任意</span></th> 
                        <td className="nest">
                          <div  className="nest_child radio_cont">
                            <ul  className="radio_list">                                
                            {working_hours.map((d: any) => (
                              <li key={d.id}>
                                <label>
                                  <input type="radio"  name="working_hours" checked={working_hour_id === d.id} onChange={() => { setEdited(true);setWorkingHourId(d.id); }} />
                                  <span className="m-form-radio-name">
                                    <span className="m-form-radio-text">{d.name}</span>
                                  </span>
                                </label>
                              </li>
                            ))}
                            </ul>
                          </div>
                        </td>
                      </tr> 
                      <tr>
                        <th>在宅・リモートワーク<br className="pc_only"/>希望<span className="any">任意</span></th> 
                        <td className="nest">
                          <div  className="nest_child radio_cont">
                            <ul  className="radio_list">
                              {work_from_home.map((d: any) => (
                                <li key={d.id}>
                                  <label>
                                    <input type="radio"  name="working_from_home" checked={work_from_home_id === d.id} onChange={() => {setEdited(true); setWorkFromHomeId(d.id); }} />
                                    <span className="m-form-radio-name">
                                      <span className="m-form-radio-text">{d.name1}</span>
                                    </span>
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </td>
                      </tr> 
                      <tr>
                        <th>勤務地<span className="any">任意</span></th> 
                        <td className="nest">
                          <div className="nest_child select_cont">
                            <h4>勤務地1</h4> 
                            <p tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {prefectureModalToggle()}}} className={prefecture_id_1 ? 'select comp' : 'select'}>
                              <a  title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={(e) => {prefectureModalToggle();}}>
                                {findValue(prefecture_id_1, 'prefectures')}
                              </a>
                            </p>
                          </div> 
                          {prefectureModal ? (
                            <div className="modal_box selectRadio1">
                              <div className="modal_inner">
                                <h4>勤務地1</h4>
                                {prefectureModalSlide > 0 ?
                                    <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                                : null }
                                <form>
                                  <h5 className="modal_ttl">希望勤務地を選択してください</h5>
                                  <div className="slider_item">
                                    {prefectureModalSlide == 0 &&
                                      <label className="column3">
                                        <input type="radio" name="radio1" onClick={() => {prefectureModalToggle(); setRegion1(null); setPrefectureId1(null)}} />
                                        <span>なし</span>
                                      </label>
                                    }
                                    {prefectureModalSlide == 0 &&
                                     region.map((d: any) => (
                                      <label key={d.id}>
                                        <input type="radio" name={d.name} defaultChecked={region_1 == d.id} value={d.id} onClick={() => { setRegion1(d.id); setPrefectureModalSlide(1)}}/>
                                        <span>{d.name}</span>
                                      </label>
                                    ))}
                                    {prefectureModalSlide == 1 && prefectures
                                        .filter(data => data.region_id === region_1 && data.id != prefecture_id_1 && data.id != prefecture_id_2)
                                        .map((d: any) => (
                                          <label key={d.id}>
                                            <input type="radio" name={d.name} defaultChecked={prefecture_id_1 === d.id} value={d.id} onClick={() => { prefectureModalToggle(); setPrefectureId1(d.id); setPrefectureModalSlide(0);}} />
                                            <span>{d.name}</span>
                                          </label>
                                        ))}
                                  </div>
                                </form>
                                <p className="close" onClick={() => {prefectureModalToggle(); setPrefectureModalSlide(0);}}>
                                  <span className="material-icons">close</span>
                                </p>
                              </div>
                              <div className="overlay" onClick={() => {prefectureModalToggle(); setPrefectureModalSlide(0);}}></div>
                            </div>
                          ) : null}
                          <div className="nest_child select_cont">
                            <h4>勤務地2</h4> 
                            <p  tabIndex={0}  onKeyDown={(e) => { if (e.key === "Enter") {prefectureModalToggle2()}}} className={prefecture_id_2 ? 'select comp' : 'select'}>
                              <a  title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={(e) => {prefectureModalToggle2(); }}>
                                {findValue(prefecture_id_2, 'prefectures')}
                              </a>
                            </p>
                          </div> 
                          {prefectureModal2 ? (
                            <div className="modal_box selectRadio1">
                              <div className="modal_inner">
                                <h4>勤務地2</h4>
                                {prefectureModalSlide > 0 ?
                                    <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                                : null }
                                <form>
                                  <h5 className="modal_ttl">希望勤務地を選択してください</h5>
                                  <div className="slider_item">
                                    {prefectureModalSlide == 0 &&
                                      <label className="column3">
                                        <input type="radio" name="radio1" onClick={() => {prefectureModalToggle2(); setRegion2(null); setPrefectureId2(null)}} />
                                        <span>なし</span>
                                      </label>
                                    }
                                    {prefectureModalSlide == 0 &&
                                     region.map((d: any) => (
                                      <label key={d.id}>
                                        <input type="radio" name={d.name} defaultChecked={region_2 == d.id} value={d.id} onClick={() => { setRegion2(d.id); setPrefectureModalSlide(1); }}/>
                                        <span>{d.name}</span>
                                      </label>
                                    ))}
                                    {prefectureModalSlide == 1 && prefectures
                                        .filter(data => data.region_id === region_2 && data.id != prefecture_id_1 && data.id != prefecture_id_2)
                                        .map((d: any) => (
                                          <label key={d.id}>
                                            <input type="radio" name={d.name} defaultChecked={prefecture_id_2 === d.id} value={d.id} onClick={() => { prefectureModalToggle2(); setPrefectureId2(d.id); setPrefectureModalSlide(0);}} />
                                            <span>{d.name}</span>
                                          </label>
                                        ))}
                                  </div>
                                </form>
                                <p className="close" onClick={() => {prefectureModalToggle2(); setPrefectureModalSlide(0);}}>
                                  <span className="material-icons">close</span>
                                </p>
                              </div>
                              <div className="overlay" onClick={() => {prefectureModalToggle2(); setPrefectureModalSlide(0);}}></div>
                            </div>
                          ) : null}
                          <div className="nest_child select_cont">
                            <h4>転勤可否</h4> 
                            <div  className="nest_child radio_cont">
                              <ul  className="radio_list">
                                <li >
                                  <label >
                                    <input  type="radio" name="nationwide" value="true" onClick={() => { setEdited(true);setNationwide(true)}} checked={nationwide === true}/> 
                                    <span  className="m-form-radio-name">
                                      <span  className="m-form-radio-text">可</span>
                                    </span>
                                  </label>
                                </li>
                                <li >
                                  <label >
                                    <input  type="radio" name="nationwide" value="false" onClick={() => {  setEdited(true); setNationwide(false)}} checked={nationwide === false}/> 
                                    <span  className="m-form-radio-name">
                                      <span  className="m-form-radio-text">否</span>
                                    </span>
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr> 
                      <tr>
                        <th>通勤手段<span className="any">任意</span></th> 
                        <td className="nest">
                          <div className="box">
                            <div className="nest_child acc_list_cont">
                              {commuting_methods.length === 0 ? 
                              <p className="acc_ttl fixed"  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {commutingMethodsModalToggle()}}}>
                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={commutingMethodsModalToggle}>
                                  通勤手段を追加する
                                </a>
                              </p>
                              :
                              <><div className="nest_child">
                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                                <div className="txt_cont" onClick={commutingMethodsModalToggle}>
                                  <p><span>{commuting_methods.map((id, index) => (
                                    <React.Fragment key={id}>
                                      {index > 0 && ' / '}
                                      {findValue(id, 'commuting_methods')}
                                    </React.Fragment>
                                  ))}
                                  </span></p>
                                </div> <div className="icon_cont"  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {commutingMethodsModalToggle()}}}>
                                  <span className="edit"><img src="/assets/img/mypage/memo_icon.svg" onClick={commutingMethodsModalToggle} alt="memo_icon" /></span>
                                </div></a>
                              </div>
                              <div className="nest_child acc_list_cont">
                                <p className="acc_ttl del" tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {commutingMethodsDelete()}}}>
                                  <a onClick={commutingMethodsDelete}>通勤手段を削除する</a>
                                </p>
                              </div></>
                              }
                            </div>
                          </div>
                        </td>
                        {commutingMethodsModal ?
                          <div className="modal_box checkbox">
                              <div className="modal_inner">
                              <h4>通勤手段</h4>
                              <form>
                                  <h5 className="modal_ttl">通勤手段を選択してください</h5>
                                  <p className="note">※複数選択可能</p>
                                  <div className="slider_item">
                                      {commuting_method.map((d: any) => (
                                          <label key={d.id}>
                                              <input type="checkbox" name={d.name} defaultChecked={commuting_methods.includes(d.id)} onChange= {(e) => handleCheckboxCommutingMethods(e, d.id)} />
                                              <span>{ d.name }</span>
                                          </label>
                                      ))} 
                                  </div>
                              </form>
                              <p className="complete"  tabIndex={0} onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleCheckboxCommutingMethods(e, 0)
                                  commutingMethodsModalToggle(); 
                                  setTimeout(() => {
                                    const nextElement = document.querySelector(".after-modal") as HTMLElement;
                                    nextElement?.focus(); 
                                  }, 10);
                                }
                              }}  onClick= {(e) => handleCheckboxCommutingMethods(e, 0)}>完了</p>
                              <p className="close" onClick={commutingMethodsModalToggle}>
                                  <span className="material-icons">close</span>
                              </p>
                              </div>
                              <div className="overlay"onClick={commutingMethodsModalToggle}></div>
                          </div>
                      : null }
                      </tr> 
                      <tr>
                      </tr>
                      {search_policy_id !== 2 && 
                      <><tr>
                        <th>業界<span className="any">任意</span></th>
                        <td className="nest">
                          <div className="nest_child select_cont">
                            <h4>業界1</h4>
                            <div className="nest_child select_cont">
                              <p   tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {industriesModalToggle()}}} className={industry_id_1 ? 'select comp' : 'select'}>
                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={industriesModalToggle}>
                                  {findValue(industry_id_1, 'industries') || '未登録'}
                                </a>
                              </p>
                            </div>
                            {industriesModal ?
                              <div className="modal_box selectRadio1">
                                <div className="modal_inner">
                                  <h4>業界1</h4>
                                  <form>
                                    <h5 className="modal_ttl">希望する業界を選択してください</h5>
                                    <div className="slider_item">
                                      <label className="column3">
                                        <input type="radio" name="radio1" onClick={() => {industriesModalToggle(); setIndustryId1(null)}} />
                                        <span>なし</span>
                                      </label>
                                      {filterIndustries().map((d: any) => (
                                        <label key={d.id}>
                                          <input type="radio" name={d.name} defaultChecked={industry_id_1 == d.id} value={d.id} onClick={() => { industriesModalToggle(); setIndustryId1(d.id); } } />
                                          <span>{d.name}</span>
                                        </label>
                                      ))}
                                    </div>
                                  </form>
                                  <p className="close" onClick={industriesModalToggle}>
                                    <span className="material-icons">close</span>
                                  </p>
                                </div>
                                <div className="overlay" onClick={industriesModalToggle}></div>
                              </div>
                              : null}
                          </div>
                          <div className="nest_child select_cont">
                            <h4>業界2</h4>
                            <div className="nest_child select_cont">
                              <p   tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {industriesModalToggle2()}}}  className={industry_id_2 ? 'select comp' : 'select'}>
                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={industriesModalToggle2}>
                                  {findValue(industry_id_2, 'industries') || '未登録'}
                                </a>
                              </p>
                            </div>
                            {industriesModal2 ?
                              <div className="modal_box selectRadio1">
                                <div className="modal_inner">
                                  <h4>業界2</h4>
                                  <form>
                                    <h5 className="modal_ttl">希望する業界を選択してください</h5>
                                    <div className="slider_item">
                                      <label className="column3">
                                        <input type="radio" name="radio1" onClick={() => {industriesModalToggle2(); setIndustryId2(null)}} />
                                        <span>なし</span>
                                      </label>
                                      {filterIndustries().map((d: any) => (
                                        <label key={d.id}>
                                          <input type="radio" name={d.name} defaultChecked={industry_id_2 == d.id} value={d.id} onClick={() => { industriesModalToggle2(); setIndustryId2(d.id); } } />
                                          <span>{d.name}</span>
                                        </label>
                                      ))}
                                    </div>
                                  </form>
                                  <p className="close" onClick={industriesModalToggle2}>
                                    <span className="material-icons">close</span>
                                  </p>
                                </div>
                                <div className="overlay" onClick={industriesModalToggle2}></div>
                              </div>
                              : null}
                          </div>
                        </td>
                      </tr>
                      <tr>
                          <th>職種<span className="any">任意</span></th>
                          <td className="nest">
                            <div className="nest_child select_cont">
                              <h4>職種1</h4>
                              <p   tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {jobcategoryModalToggle1()}}}  className={job_category_id_1 ? 'select comp' : 'select'}>
                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={(e) => {jobcategoryModalToggle1(); setJobcategoryModalSlide(0);}}>
                                  {findValue(job_category_id_1, 'job_categories')}
                                </a>
                              </p>
                            </div>
                            {jobCategoryModal1 ? (
                              <div className="modal_box selectRadio1">
                                <div className="modal_inner">
                                  <h4>職種1</h4>
                                  {jobCategoryModalSlide > 0 ?
                                    <p id="btn_prev" onClick={prevJob} className='prev'>戻る</p>
                                  : null }
                                  <form>
                                    <h5 className="modal_ttl">職種を選択してください</h5>
                                    <div className="slider_item">
                                      {jobCategoryModalSlide == 0 &&
                                      <label className="column3">
                                        <input type="radio" name="radio1" onClick={() => {jobcategoryModalToggle1(); setJobType1(null); setJobCategoryid1(null)}} />
                                        <span>なし</span>
                                      </label>
                                      }
                                      {jobCategoryModalSlide == 0 &&
                                        job_category_types.map((d: any) => (
                                          <label key={d.id}>
                                            <input type="radio" name={d.name} defaultChecked={job_type_1 == d.id} value={d.id} onClick={() => { setJobType1(d.id); setJobcategoryModalSlide(1);} } />
                                            <span>{d.name}</span>
                                          </label>
                                        ))}
                                      {jobCategoryModalSlide == 1 && job_categories
                                        .filter(data => data.job_category_type_id == job_type_1 && data.id != job_category_id_1 && data.id != job_category_id_2)
                                        .map((d: any) => (
                                          <label key={d.id}>
                                            <input type="radio" name={d.name} defaultChecked={job_category_id_1 == d.id} value={d.id} onClick={() => { jobcategoryModalToggle1(); setJobCategoryid1(d.id);}} />
                                            <span>{d.name}</span>
                                          </label>
                                        ))}
                                    </div>
                                  </form>
                                  <p className="close" onClick={() => {jobcategoryModalToggle1(); setJobcategoryModalSlide(0);}}>
                                    <span className="material-icons">close</span>
                                  </p>
                                </div>
                                <div className="overlay" onClick={() => {jobcategoryModalToggle1(); setJobcategoryModalSlide(0);}}></div>
                              </div>
                            ) : null}
                            <div className="nest_child select_cont">
                              <h4>職種2</h4>
                              <p   tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {jobcategoryModalToggle2()}}}  className={job_category_id_2 ? 'select comp' : 'select'}>
                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={(e) => {jobcategoryModalToggle2(); setJobcategoryModalSlide(0);}}>
                                  {findValue(job_category_id_2, 'job_categories')}
                                </a>
                              </p>
                            </div>
                            {jobCategoryModal2 ? (
                              <div className="modal_box selectRadio1">
                                <div className="modal_inner">
                                  <h4>職種2</h4>
                                  {jobCategoryModalSlide > 0 ?
                                    <p id="btn_prev" onClick={prevJob} className='prev'>戻る</p>
                                  : null }
                                  <form>
                                    <h5 className="modal_ttl">職種を選択してください</h5>
                                    <div className="slider_item">
                                      {jobCategoryModalSlide == 0 &&
                                      <label className="column3">
                                        <input type="radio" name="radio1" onClick={() => {jobcategoryModalToggle2(); setJobType2(null); setJobCategoryid2(null)}} />
                                        <span>なし</span>
                                      </label>
                                      }
                                      {jobCategoryModalSlide == 0 &&
                                        job_category_types.map((d: any) => (
                                          <label key={d.id}>
                                            <input type="radio" name={d.name} defaultChecked={job_type_2 == d.id} value={d.id} onClick={() => { setJobType2(d.id); setJobcategoryModalSlide(1);} } />
                                            <span>{d.name}</span>
                                          </label>
                                        ))}
                                      {jobCategoryModalSlide == 1 && job_categories
                                        .filter(data => data.job_category_type_id == job_type_2 && data.id != job_category_id_1 && data.id != job_category_id_2)
                                        .map((d: any) => (
                                          <label key={d.id}>
                                            <input type="radio" name={d.name} defaultChecked={job_category_id_2 == d.id} value={d.id} onClick={() => { jobcategoryModalToggle2(); setJobCategoryid2(d.id);}} />
                                            <span>{d.name}</span>
                                          </label>
                                        ))}
                                    </div>
                                  </form>
                                  <p className="close" onClick={() => {jobcategoryModalToggle2();  setJobcategoryModalSlide(0);}}>
                                    <span className="material-icons">close</span>
                                  </p>
                                </div>
                                <div className="overlay" onClick={() => {jobcategoryModalToggle2();  setJobcategoryModalSlide(0);}}></div>
                              </div>
                            ) : null}
                          </td>
                        </tr></> 
                      }
                      {employmentStatusModal ?
                              <div className="modal_box checkbox">
                                  <div className="modal_inner">
                                  <h4>雇用形態</h4>
                                  <form>
                                      <h5 className="modal_ttl">希望する雇用形態を選択してください</h5>
                                      <p className="note">※複数選択可能</p>
                                      <div className="slider_item">
                                          {employment_status.map((d: any, index: any) => (
                                              <label key={d.id}>
                                                  <input autoFocus = {index == 0 ? true : false} type="checkbox" name={d.name} defaultChecked={employment_statuses.includes(d.id)} onChange= {(e) => handleCheckboxEmploymentStatus(e, d.id)} />
                                                  <span>{ d.name }</span>
                                              </label>
                                          ))} 
                                      </div>
                                  </form>
                                  <p className="complete"  tabIndex={0} onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      handleCheckboxEmploymentStatus(e, 0);
                                      employmentStatusModalToggle(); 
                                      setTimeout(() => {
                                        const nextElement = document.querySelector(".after-modal") as HTMLElement;
                                        nextElement?.focus(); 
                                      }, 10);
                                    }
                                  }}  onClick= {(e) => handleCheckboxEmploymentStatus(e, 0)}>完了</p>
                                  <p className="close" onClick={employmentStatusModalToggle}>
                                      <span className="material-icons">close</span>
                                  </p>
                                  </div>
                                  <div className="overlay"onClick={employmentStatusModalToggle}></div>
                              </div>
                          : null }
                      <tr>
                        <th>雇用形態<span className="required">必須</span></th> 
                        <td className="nest">
                          <div className="box">
                            <div className="nest_child acc_list_cont">
                              {employment_statuses.length === 0 ? 
                              <p className="acc_ttl fixed"  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {employmentStatusModalToggle()}}}>
                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={employmentStatusModalToggle}>
                                希望する雇用形態を追加する
                                </a>
                              </p>
                              :
                              <><div className="nest_child">
                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                                <div className="txt_cont" onClick={employmentStatusModalToggle}>
                                  <p><span>{employment_statuses.map((id, index) => (
                                    <React.Fragment key={id}>
                                      {index > 0 && ' / '}
                                      {findValue(id, 'employment_statuses')}
                                    </React.Fragment>
                                  ))}
                                  </span></p>
                                </div> <div className="icon_cont">
                                  <span className="edit"  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {employmentStatusModalToggle()}}}><img src="/assets/img/mypage/memo_icon.svg" onClick={employmentStatusModalToggle} alt="memo_icon" /></span>
                                </div></a>
                              </div>
                              
                              <div className="nest_child acc_list_cont">
                                <p className="acc_ttl del" tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {employmentStatusDelete()}}}>
                                  <a onClick={employmentStatusDelete}>希望する雇用形態を削除する</a>
                                </p>
                              </div></>
                              }
                            </div>
                          </div>
                        </td>
                        
                      </tr> 
                      <tr>
                        <th>年収<span className="required">必須</span></th> 
                        <td className="nest">
                          <div className="nest_child select_cont">
                            <p  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {annualIncomeModalToggle()}}} className={annual_income_id ? 'select comp' : 'select'}>
                              <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={annualIncomeModalToggle}>
                                {findValue(annual_income_id, 'annual_incomes') || '未登録'}
                              </a>
                            </p>
                          </div>
                        </td>
                        {annualIncomeModal ?
                          <div className="modal_box selectRadio1">
                              <div className="modal_inner">
                              <h4>年収</h4>
                              <form>
                                  <h5 className="modal_ttl">希望年収を選択してください</h5>
                                  <div className="slider_item">
                                      <label className="column3">
                                        <input type="radio" name="radio1" onClick={() => {annualIncomeModalToggle(); setAnnualIncomeId(null);}} />
                                        <span>なし</span>
                                      </label>
                                      {annual_incomes.map((d: any) => (
                                          <label key={d.id}>
                                              <input type="radio" name={d.name} defaultChecked={annual_income_id == d.id} value={d.id} onClick={() => {annualIncomeModalToggle(); setAnnualIncomeId(d.id);}} />
                                              <span>{ d.name }</span>
                                          </label>
                                      ))} 
                                  </div>
                              </form>
                              <p className="close" onClick={annualIncomeModalToggle}>
                                  <span className="material-icons">close</span>
                              </p>
                              </div>
                              <div className="overlay"onClick={annualIncomeModalToggle}></div>
                          </div>
                      : null }
                      </tr> 
                      <tr>
                        <th>スカウトの受信<span className="any">任意</span></th> 
                        <td className="nest"><div  className="nest_child radio_cont">
                          <ul  className="radio_list">
                            <li >
                              <label >
                                <input  type="radio" name="job_search_status" onClick={() => {setEdited(true); setIsActivate(true)}} checked={is_activate === true}/> 
                                <span  className="m-form-radio-name">
                                  <span  className="m-form-radio-text">スカウトを受信する</span>
                                </span>
                              </label>
                            </li>
                            <li >
                              <label >
                              <input  type="radio" name="job_search_status" onClick={() => { setEdited(true);setIsActivate(false)}} checked={is_activate === false}/> 
                                <span  className="m-form-radio-name">
                                  <span  className="m-form-radio-text">スカウトを受信しない</span>
                                </span>
                              </label>
                            </li>
                          </ul>
                        </div> 
                        <div className="nest_child select_cont">
                          <h4>入社希望月</h4> 
                          <p  tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") {joinMonthsModalToggle()}}} className={join_month_id ? 'select comp' : 'select'}>
                              <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={joinMonthsModalToggle}>
                                {findValue(join_month_id, 'join_months') || '未登録'}
                              </a>
                            </p>
                          </div>
                          {joinMonthsModal ?
                            <div className="modal_box selectRadio1">
                                <div className="modal_inner">
                                <h4>入社希望月</h4>
                                <form>
                                    <h5 className="modal_ttl">入社希望月を選択してください</h5>
                                    <div className="slider_item">
                                        <label className="column3">
                                          <input type="radio" name="radio1" onClick={() => {joinMonthsModalToggle(); setJoinMonthsId(null);}} />
                                          <span>なし</span>
                                        </label>
                                        {join_months.map((d: any) => (
                                            <label key={d.id}>
                                                <input type="radio" name={d.name} defaultChecked={join_month_id == d.id} value={d.id} onClick={() => {joinMonthsModalToggle(); setJoinMonthsId(d.id);}} />
                                                <span>{ d.name }</span>
                                            </label>
                                        ))} 
                                    </div>
                                </form>
                                <p className="close" onClick={joinMonthsModalToggle}>
                                    <span className="material-icons">close</span>
                                </p>
                                </div>
                                <div className="overlay"onClick={joinMonthsModalToggle}></div>
                            </div>
                        : null }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> 
            <div className="btn_box">
              <button type="button" onClick={(e) => submit(e, true)} className="btn type3">一時保存する</button> 
              <button type="submit" onClick={(e) => submit(e, false)} className="btn type3 orange">保存して次へ         
                <img src="/assets/img/mypage/arrow02.svg" alt=""/>
              </button>
            </div>
          </form>
            </section>
      </main>
    );
}