import { useEffect, useState } from "react";
import Modal from "../modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecruitmentsListRequest } from "../../redax/actions/recruitmentsListActions/recruitmentsListActions";
import { RootState } from "../../redax/reducers/rootReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchRecruitmentsSearchRequest } from "../../redax/actions/recruitmentsSearchActions/recruitmentsSearchActions";
import { fetchRegionsRequest } from "../../redax/actions/regionsActions/regionsActions";

import Loader from '../loader/loader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Recruitment from "../../components/Recruitment/Recruitment";
import { fetchSavedConditionsCreateRequest } from "../../redax/actions/savedConditionsCreateActions/savedConditionsCreateActions";
import { fetchLoginRequest } from "../../redax/actions/loginActions/loginActions";
import './recruitments.scss';
import { handicapsTypes } from "../../redax/ActionTypes/handicapsTypes";
import { fetchMasterInfoRequest } from '../../redax/actions/masterInfoActions/masterInfoActions';


let pages : any = null;
let all_count : any = null;
let total_count  : any = null;
let search_criteria = [];
let recruitmentsLists: any = [];
let page: any;
let  prefecture_ids: any[]  = [];
let job_category_type_ids: any[] = [];
let employment_status_ids: any[] = [];
let region_ids: any[] = [];
let job_category_ids: any[] = [];
let handicap_type_ids: any[] = [];
let consideration_condition_ids: any[] = [];
let compatibility_ids: any[] = [];
let annual_income_ids: any[] = [];
let a = true;
let limit = 5;

function  Recruitments() {
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);    
    const params = location.state;
    const [loadScreen, setLoadScreen] = useState(true);
    const dispatch = useDispatch();

      const { recruitmentsList} = useSelector(
        (state: RootState) => state.recruitmentsList
      );
      const recList = useSelector(
        (state: RootState) => state.recruitmentsList
      );
      const recruitmentsSearch = useSelector(
        (state: RootState) => state.recruitmentsSearch
      );
      const loginData = useSelector((state: RootState) => state.login);
      const savedConditionsCreate = useSelector((state: RootState) => state.savedConditionsCreate);
      const { masterInfo } = useSelector((state: RootState) => state.masterInfo);
      let sorts = queryParams.get('sort');
      if(queryParams.get('page')){
        page =  queryParams.get('page');
      } else {
        page = 1
      }
      let [currentPage, setCurrentPage] = useState(parseInt(page));
      const [jobs, setJob] = useState<boolean[]>([]);
      const [ smallChk, setSmallChk] = useState<boolean[]>([]);
      const [region, setRegion] = useState<boolean[]>([]);
      const [ prefectures, setprefectures] = useState<boolean[]>([]);
      const [conditionsType, setconditionsType] = useState<boolean[]>([]);
      const [ conditions, setConditions] = useState<boolean[]>([]);
      const [ handy, setHandy] = useState<boolean[]>([]);
      const [ workStyles, setWorkStyles] = useState<boolean[]>([]);
      const [ employmentStatus, setemploymentStatus] = useState<boolean[]>([]);
      const [activeItemIdsRegion, setActiveItemIdsRegion] = useState<string[]>([]);
      const [activeItemIdsJobCategory, setActiveItemIdsJobCategory] = useState<string[]>([]);
      const [activeItemIdsHandy, setActiveItemIdsHandy] = useState<string[]>([]);
      const [activeItemIdsCondition, setActiveItemIdsCondition] = useState<string[]>([]);
      const [activeItemIdsWorkStyle, setActiveItemIdsWorkStyle] = useState<string[]>([]);
      const [activeItemIdsEmployment, setActiveItemIdsEmployment] = useState<string[]>([]);
      const [prefecturesValue, setPrefecturesValue] =  useState<any[]>([]);
      const [jobCategoryType, setJobCategoryType] = useState<any[]>([]);
      const [employmentStatusValue, setEmploymentStatusValue] = useState<any[]>([]);
      const [regionValue, setRegionValue] = useState<any[]>([]);
      const [handyValue, setHandyValue] = useState<any[]>([]);
      const [conditionsValue, setconditionsValue] = useState<any[]>([]);
      const [workStylesValue, setworkStylesValue] = useState<any[]>([]);
      const [incomeId, setIncomeIdValue] =  useState<any[]>([]);
      const [jobCategoryId, setJobCategoryId] = useState<any[]>([]);
      let [checkFirst, setCheckFirst] = useState(false);
      const [isModalOpen, setModalOpen] = useState(false);
      const [notifyWhenJobFinded, setNotifyWhenJobFinded] = useState(false);      
      const [modal, setModal] = useState('');
      const [keyword, setKeyword] = useState('');
      const [ isNewPublish, setIsNewPublish] = useState(false);
      const [workFromHomeId1, setWorkFromHomeId1] = useState<any>('');
      const [workFromHomeId2, setWorkFromHomeId2] = useState<any>('');
      const [workFromHomeId3, setWorkFromHomeId3] = useState<any>('');
      const [isToggled1, setIsToggled1] = useState<boolean>(false);
      const [isToggled2, setIsToggled2] = useState<boolean>(false);
      const [isToggled3, setIsToggled3] = useState<boolean>(false);

      const [newGraduate, setNewGraduate] = useState<any>('');
      const [conditionsId, setConditionsId] = useState('');
      const [conditionsName, setConditionsName] = useState('');
      const itemsPerPage = 1;
      const [reclist, setRecList] = useState<any[]>([]);      
      const [sort, setSort] = useState(sorts);
      const [isLogin, setIsLogin] = useState('');
      const [isApplicantLogin, setIsApplicantLogin] = useState(false);
      const navigate = useNavigate();
      const pageTitle = '障がい者雇用専門の求人サイトの求人一覧｜パラちゃんねる';
      document.title = pageTitle;
      const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      useEffect(() => {
        // Attach the event listener on component mount
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };

      }, [windowSize]);


      let recruitments = masterInfo;
       
      useEffect(() => {
        let query = '';
        const arr = [];
        if(masterInfo && Object.keys(masterInfo).length !== 0){

          
          setLoadScreen(false);
          if(params != null || params != undefined) {
            if(params.keyword != null) {
              setKeyword(params.keyword);
              arr.push('keyword=' + params.keyword.toString());
              query = arr.join('&');
              dispatch(fetchRecruitmentsListRequest('', 'get', query));
            } 
            if(params.jobCategoryModal == true) {
              toggleModal('JobCategoryModal');
            }
            if(params.considerationConditionsModal == true) {
              // toggleModal('ConsiderationConditionsModal');
              toggleModal('EmploymentStatusesModal');
            }
            if(params.work_from_home_id !== undefined) {
              for(let i = 0; i < params.work_from_home_id.length; i++){
                  arr.push('work_from_home_id[]=' + params.work_from_home_id[i].work_from_home_id);
                  if( params.work_from_home_id[i].work_from_home_id === 1){
                    setWorkFromHomeId1(params.work_from_home_id[i].work_from_home_id.toString());
                    setIsToggled1(true);
                  }
                  if( params.work_from_home_id[i].work_from_home_id === 3){
                    setWorkFromHomeId3(params.work_from_home_id[i].work_from_home_id.toString());
                    setIsToggled3(true);
                  }
                  query = arr.join('&');
              }
              dispatch(fetchRecruitmentsListRequest('', 'get', query));
            }
            if(params.is_new_publish) {
              setIsNewPublish(true);
              arr.push('is_new_publish=' + params.is_new_publish)
              query = arr.join('&');
              dispatch(fetchRecruitmentsListRequest('', 'get', query));
            }
          } else {

            dispatch(fetchRecruitmentsListRequest('', 'get', queryParams));
          }
          const element = document.querySelector('#load-area');
          const elementList = document.querySelector('#jobs_list');
          element!.classList.remove('load-area-close');
          element!.classList.add('load-area-open');
          elementList!.classList.add('load-area-close');
          elementList!.classList.remove('load-area-open');
        }
        
      }, [dispatch,masterInfo]);



      useEffect(() => {
        dispatch(fetchRecruitmentsSearchRequest('recruitments', queryParams));
        if (!localStorage.getItem('jobParachannelAuthToken')) {
          a = false
        }
      }, [dispatch]);

      useEffect(() => {
        // dispatch(fetchRegionsRequest());
        let param = 'regions=true&job_categories=true&handicap_types=true&consideration_conditions=true&recruitments=true&applicants=true&work_styles=true';
        dispatch(fetchMasterInfoRequest(param));
      }, [dispatch]);



      useEffect(() => {
        const variableValue = queryParams.get('keyword');
        if (variableValue !== null) {
          setKeyword(variableValue);
        }
        window.scrollTo(0,0);
      }, []);

      useEffect(() => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        let workFromHomeIds: any[] = []
        workFromHomeIds = params.getAll('work_from_home_id[]');
        if (workFromHomeIds.length !== 0){
            for(let i = 0; i< workFromHomeIds.length; i++){
                if(workFromHomeIds[i] === "1") {
                  setIsToggled1(true)
                }
                if(workFromHomeIds[i] === "2") {
                  setIsToggled2(true)
                }
                if(workFromHomeIds[i] === "3") {
                  setIsToggled3(true)
                }
            }
        }
        // if (val !== null) {
        //   setWorkFromHomeId(val);
        // }
      }, []);

      useEffect(() => {
        const val = queryParams.get('new_graduate');
        if (val !== null) {
          setNewGraduate(val);
        }
      }, []);

      useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
          dispatch(fetchLoginRequest('', '',''))
        }
      }, [dispatch]);
  
      useEffect(() => {
        if (loginData.pending) {
          setIsLogin(loginData.login.authenticate.resource_type);
          setConditionsId(loginData.login.resource.id);
          if (loginData && loginData.login.authenticate.resource_type == 'Applicant'){
            setIsApplicantLogin(true)
          }
        }
      }, [loginData.pending]);

      useEffect(() => {
        if (recList.pending) {
          setRecList(recList.recruitmentsList.recruitments)
        }
      }, [recList.pending]);

      useEffect(() => {
        if (savedConditionsCreate.pending) {
          if(notifyWhenJobFinded) {
            window.confirm("この条件に合う求人が公開されたら通知を受け取るよう設定いたしました。\n公開されるまで今暫くお待ちください。")
          } else {
            window.confirm("現在の検索条件を保存しました")
          }
        } else if(savedConditionsCreate.error != null) {
          window.confirm("検索条件の保存に失敗しました")
        }
      }, [savedConditionsCreate.pending, savedConditionsCreate.error]);

      if(recruitmentsList.pagenation){
        // pages = 25;
         pages = recruitmentsList.pagenation.total_pages;
         all_count =  recruitmentsList.pagenation.all_count;
         total_count =  recruitmentsList.pagenation.total_count;
      }
      const shuffleArray = (array: any) => {
        const cloneArray = [...array];
        const result = cloneArray.reduce((_, cur, idx) => {
          let rand = Math.floor(Math.random() * (idx + 1));
          cloneArray[idx] = cloneArray[rand];
          cloneArray[rand] = cur;
          return cloneArray;
        }, []);
        return result;
      };
      const totalPages = Math.ceil(pages / itemsPerPage);
      if(recruitmentsList !== undefined){
        // if (sort == 'active') {
        //   recruitmentsLists = shuffleArray(recruitmentsList.recruitments);
        // } else {
        //   recruitmentsLists = recruitmentsList.recruitments;
        // }
        const element = document.querySelector('#load-area');
        const elementList = document.querySelector('#jobs_list');        
        if(element !== null) {
          setTimeout(() => {
            element!.classList.add('load-area-close');
            element!.classList.remove('load-area-open');
            elementList!.classList.remove('load-area-close');
            elementList!.classList.add('load-area-open');
          }, 5000);
        }
        if(sort == 'active') {
          recruitmentsLists = shuffleArray(reclist);
        } else {
          recruitmentsLists = recruitmentsList.recruitments;
        }
      }

      function sortChange(data: any) {
        let query = '';
        const arr = [];
        setSort(data);
        arr.push('sort=' + data);
        arr.push('page=' + currentPage);
        if(regionValue !== undefined) {
          for(let i = 0; i < regionValue.length; i++){
            arr.push('region_ids[]=' + regionValue[i].id);
          }
        }
        if(prefecturesValue !== undefined) {
          for(let i =0; i < prefecturesValue.length; i++) {
            arr.push('prefecture_ids[]=' + prefecturesValue[i].id);
          }
        }

        if(jobCategoryType !== undefined) {
            for(let i = 0; i < jobCategoryType.length; i++){
              arr.push('job_category_type_ids[]=' + jobCategoryType[i].id);
            }
        }
        if(jobCategoryId !== undefined) {
          for(let i = 0; i < jobCategoryId.length; i++) {
            arr.push('job_category_ids[]=' + jobCategoryId[i].id);
          }
        }
        if(handyValue !== undefined) {
          for(let i = 0; i < handyValue.length; i++) {
            arr.push('handicap_type_ids[]=' + handyValue[i].id);
          }
        }
        if(conditionsValue !== undefined) {
          for(let i = 0; i < conditionsValue.length; i++) {
            arr.push('consideration_condition_ids[]=' + conditionsValue[i].id);
          }
        }
        if(workStylesValue !== undefined) {
          for(let i = 0; i < workStylesValue.length; i++) {
            arr.push('compatibility_ids[]=' + workStylesValue[i].id);
          }
        }
        if(employmentStatusValue !== undefined) {
          for(let i = 0; i < employmentStatusValue.length; i++) {
            arr.push('employment_status_ids[]=' + employmentStatusValue[i].id);
          }
        }
        // if(keyword !== null || keyword !== '') {
        //   arr.push('keyword[]=' + keyword);
        // }
        if (sort) { queryParams.set('sort', sort) }
        if (page) { queryParams.set('page', page.toString()) }
        query = arr.join('&');
        const element = document.querySelector('#load-area');
        const elementList = document.querySelector('#jobs_list');
        element!.classList.remove('load-area-close');
        element!.classList.add('load-area-open');
        elementList!.classList.add('load-area-close');
        elementList!.classList.remove('load-area-open');
        // dispatch(fetchRecruitmentsListRequest(query));
        dispatch(fetchRecruitmentsListRequest('', 'get', query));
        dispatch(fetchRecruitmentsSearchRequest('recruitments', query));
        navigate('/recruitments/?' + query);
        window.location.reload();
        // window.location.href = '/recruitments/?' + query;
      }
      function clickPage (data: any) {
        const element = document.querySelector('#load-area');
        const elementList = document.querySelector('#jobs_list');
        element!.classList.remove('load-area-close');
        element!.classList.add('load-area-open');
        elementList!.classList.add('load-area-close');
        elementList!.classList.remove('load-area-open');
        let query = '';
        const arr = [];
        setCurrentPage(data);
        if(sort !== null){
          arr.push('sort=' + sort);
        }
        if(regionValue !== undefined) {
          for(let i = 0; i < regionValue.length; i++){
            arr.push('region_ids[]=' + regionValue[i].id);
          }
        }
        if(prefecturesValue !== undefined) {
          for(let i =0; i < prefecturesValue.length; i++) {
            arr.push('prefecture_ids[]=' + prefecturesValue[i].id);
          }
        }

        if(jobCategoryType !== undefined) {
            for(let i = 0; i < jobCategoryType.length; i++){
              arr.push('job_category_type_ids[]=' + jobCategoryType[i].id);
            }
        }
        if(jobCategoryId !== undefined) {
          for(let i = 0; i < jobCategoryId.length; i++) {
            arr.push('job_category_ids[]=' + jobCategoryId[i].id);
          }
        }
        if(handyValue !== undefined) {
          for(let i = 0; i < handyValue.length; i++) {
            arr.push('handicap_type_ids[]=' + handyValue[i].id);
          }
        }
        if(conditionsValue !== undefined) {
          for(let i = 0; i < conditionsValue.length; i++) {
            arr.push('consideration_condition_ids[]=' + conditionsValue[i].id);
          }
        }
        if(workStylesValue !== undefined) {
          for(let i = 0; i < workStylesValue.length; i++) {
            arr.push('compatibility_ids[]=' + workStylesValue[i].id);
          }
        }
        if(employmentStatusValue !== undefined) {
          for(let i = 0; i < employmentStatusValue.length; i++) {
            arr.push('employment_status_ids[]=' + employmentStatusValue[i].id);
          }
        }
        if(isNewPublish) {
          arr.push('is_new_publish=' + true);
        }
        if(workFromHomeId1 !== '') {
          arr.push('work_from_home_id[]=' + workFromHomeId1);
          setIsToggled1(true);
        }
        if(workFromHomeId2 !== '') {
          arr.push('work_from_home_id[]=' + workFromHomeId2);
          setIsToggled2(true);
        }
        if(workFromHomeId3 !== '') {
          arr.push('work_from_home_id[]=' + workFromHomeId3);
          setIsToggled3(true);
        }
        if(keyword !== null || keyword !== '') {
          arr.push('keyword=' + keyword);
        }
        if(newGraduate !== null){
          arr.push('new_graduate=' + newGraduate);
        }
        if (sort) { queryParams.set('sort', sort) }
        if (page) { queryParams.set('page', data) }
        arr.push('page=' + data);
        query = arr.join('&');
        // dispatch(fetchRecruitmentsListRequest(query));
        dispatch(fetchRecruitmentsListRequest('', 'get', query));
        dispatch(fetchRecruitmentsSearchRequest('recruitments', query));
        navigate('/recruitments/?' + query);
        window.location.reload();
        // window.location.href = '/recruitments/?' + query;
      }

      // const shuffleArray = (array: any) => {
      //   const cloneArray = [...array];
      //   const result = cloneArray.reduce((_, cur, idx) => {
      //     let rand = Math.floor(Math.random() * (idx + 1));
      //     cloneArray[idx] = cloneArray[rand];
      //     cloneArray[rand] = cur;
      //     return cloneArray;
      //   }, []);
      //   return result;
      // };
      const [checkPending, setCheckPending] = useState(false);
      useEffect(() => {
        if (recruitmentsSearch.pending) {
          if(checkPending == false){
            if(recruitmentsSearch !== undefined){
              region_ids = [];
              prefecture_ids = [];
              employment_status_ids = [];
              job_category_type_ids = [];
              job_category_ids = [];
              handicap_type_ids = [];
              consideration_condition_ids = [];
              compatibility_ids = [];
              annual_income_ids = [];

              search_criteria =  recruitmentsSearch.recruitmentsSearch;
              if(search_criteria.regions !== undefined ){
               for(let i = 0; i < search_criteria.regions.length; i++) {
                 region_ids.push(search_criteria.regions[i]);
               }
               setRegion(region_ids);
               console.log(search_criteria.regions);

              }
              if(search_criteria.prefectures !== undefined){
               for(let i = 0; i < search_criteria.prefectures.length; i++ ){
                 prefecture_ids.push(search_criteria.prefectures[i]);
               }
               setPrefecturesValue(prefecture_ids);
               console.log(search_criteria.prefectures);
              }
              if(search_criteria.job_category_types !== undefined ){
               for(let i = 0; i < search_criteria.job_category_types.length; i++) {
                 job_category_type_ids.push(search_criteria.job_category_types[i]);
               }
               setJobCategoryType(job_category_type_ids);
              }
              if(search_criteria.employment_statuses !== undefined ){
               for(let i = 0; i < search_criteria.employment_statuses.length; i++) {
                 employment_status_ids.push(search_criteria.employment_statuses[i]);
               }
               setEmploymentStatusValue(employment_status_ids);
              }         
              if(search_criteria.job_categories !== undefined ){
               for(let i = 0; i < search_criteria.job_categories.length; i++){
                 job_category_ids.push(search_criteria.job_categories[i]);
               }
               setJobCategoryId(job_category_ids);
              }
              if(search_criteria.handicap_types !== undefined ){
               for(let i = 0; i < search_criteria.handicap_types.length; i++) {
                 handicap_type_ids.push(search_criteria.handicap_types[i]);
               }
               setHandyValue(handicap_type_ids);
              }
              if(search_criteria.consideration_conditions !== undefined ){
               for(let i = 0; i < search_criteria.consideration_conditions.length; i++) {
                 consideration_condition_ids.push(search_criteria.consideration_conditions[i]);
               }
               setconditionsValue(consideration_condition_ids);
              }
     
              if(search_criteria.compatibilities !== undefined ){
               for(let i = 0; i < search_criteria.compatibilities.length; i++) {
                 compatibility_ids.push(search_criteria.compatibilities[i]);
               }
               setworkStylesValue(compatibility_ids);
              }
              if(search_criteria.annual_incomes !== undefined ){
               for(let i = 0; i < search_criteria.annual_incomes.length; i++) {
                 annual_income_ids.push(search_criteria.annual_incomes[i]);
               }
               setIncomeIdValue(annual_income_ids);
              }
              if(search_criteria.consideration_condition_types !== undefined){

              }
              
            }
            setCheckPending(true);
          }
        }
      }, [recruitmentsSearch.pending]);
     

      const allSave = () => {
        let query = '';
        const arr = [];
        // arr.push('page=' + page);
        window.scrollTo(0,0);
        if(sort !== null){
          arr.push('sort=' + sort);
        }
        if(regionValue !== undefined) {
          for(let i = 0; i < regionValue.length; i++){
            // arr.push('region_ids[]=' + regionValue[i].id);
          }
        }
        if(prefecturesValue !== undefined) {
          for(let i =0; i < prefecturesValue.length; i++) {
            arr.push('prefecture_ids[]=' + prefecturesValue[i].id);
          }
        }

        if(jobCategoryType !== undefined) {
            for(let i = 0; i < jobCategoryType.length; i++){
              arr.push('job_category_type_ids[]=' + jobCategoryType[i].id);
            }
        }
        if(jobCategoryId !== undefined) {
          for(let i = 0; i < jobCategoryId.length; i++) {
            arr.push('job_category_ids[]=' + jobCategoryId[i].id);
          }
        }
        if(handyValue !== undefined) {
          for(let i = 0; i < handyValue.length; i++) {
            arr.push('handicap_type_ids[]=' + handyValue[i].id);
          }
        }
        if(conditionsValue !== undefined) {
          for(let i = 0; i < conditionsValue.length; i++) {
            arr.push('consideration_condition_ids[]=' + conditionsValue[i].id);
          }
        }
        if(workStylesValue !== undefined) {
          for(let i = 0; i < workStylesValue.length; i++) {
            arr.push('compatibility_ids[]=' + workStylesValue[i].id);
          }
        }
        if(employmentStatusValue !== undefined) {
          for(let i = 0; i < employmentStatusValue.length; i++) {
            arr.push('employment_status_ids[]=' + employmentStatusValue[i].id);
          }
        }
        if(keyword !== '') {
          arr.push('keyword=' + keyword);
        }
        if(workFromHomeId1 !== '') {
          // queryParams.set('page', '1');
          // arr.push('page=', 1);
          arr.push('work_from_home_id[]=' + workFromHomeId1);
        }
        if(workFromHomeId2 !== '') {
          // queryParams.set('page', '1');
          // arr.push('page=', 1);
          arr.push('work_from_home_id[]=' + workFromHomeId2);
        }
        if(workFromHomeId3 !== '') {
          // queryParams.set('page', '1');
          // arr.push('page=', 1);
          arr.push('work_from_home_id[]=' + workFromHomeId3);
        }
        if(workFromHomeId1 !== '' ||  workFromHomeId2 !== '' || workFromHomeId3 !== ''){
          queryParams.set('page', '1');
          setCurrentPage(1);
          arr.push('page=', 1);
        }

        if(newGraduate !== '') {
          arr.push('new_graduate=' + newGraduate);
        }
    
        if(incomeId !== undefined) {
          for(let i = 0; i < incomeId.length; i++) {
            arr.push('annual_income_ids=' + incomeId[i]);
          }
        }
        if(queryParams.get(page)) {
        } else {
          arr.push('page=' + page);
        }
        query = arr.join('&');
        window.location.href = '/recruitments/?' + query;
        // dispatch(fetchRecruitmentsListRequest(query));
        dispatch(fetchRecruitmentsListRequest('', 'get', query));
        dispatch(fetchRecruitmentsSearchRequest('recruitments', query));
        const element = document.querySelector('#load-area');
        const elementList = document.querySelector('#jobs_list');
        element!.classList.remove('load-area-close');
        element!.classList.add('load-area-open');
        elementList!.classList.add('load-area-close');
        elementList!.classList.remove('load-area-open');
    };
      let  largeChoices = recruitments.job_category_types;
      let  smallChoices = recruitments.job_categories;
      let parentIdKeyName="job_category_type_id";



      const pageIncrement = () => {
        if (currentPage >= totalPages) {
          setCurrentPage(totalPages);
          return;
        }
        const element = document.querySelector('#load-area');
        const elementList = document.querySelector('#jobs_list');
        element!.classList.remove('load-area-close');
        element!.classList.add('load-area-open');
        elementList!.classList.add('load-area-close');
        elementList!.classList.remove('load-area-open');
        setCurrentPage(currentPage + 1);
        clickPage(currentPage + 1);
      };

      const pageDecrement = () => {        
        if (currentPage <= 1) {
          setCurrentPage(1);
          return;
        }
        const element = document.querySelector('#load-area');
        const elementList = document.querySelector('#jobs_list');
        element!.classList.remove('load-area-close');
        element!.classList.add('load-area-open');
        elementList!.classList.add('load-area-close');
        elementList!.classList.remove('load-area-open');
        setCurrentPage(currentPage - 1);
        clickPage(currentPage - 1);
      };
      const pageNumbers = Array.from({ length: pages }, (_, index) => index + 1);

      const toggleModal = (id: any) => {
        setModal(id);
        setModalOpen(true);
      };


      const closeModal = () => {
        setModalOpen(false);
      };

      const clearRegion = () => {
        setRegionValue([]);
        setPrefecturesValue([]);
        setRegion([]);
        setActiveItemIdsRegion([]);
      }

      const clearJobCategory = () => {
        setJob([]);
        setSmallChk([]);
        setJobCategoryType([]);
        setJobCategoryId([]);
        setActiveItemIdsJobCategory([]);
      }
      const clearHandy = () => {
        setHandy([]);
        setHandyValue([]);
        setActiveItemIdsHandy([]);
      }

      const clearConditions = () => {
        setConditions([]);
        setconditionsValue([]);
        setconditionsType([]);
        setActiveItemIdsCondition([]);
      }


      const clearWorkStyles = () => {
        setWorkStyles([]);
        setworkStylesValue([]);
        setActiveItemIdsWorkStyle([]);
      }


      const clearEmployee = () => {
        let c = [];
        for(let i = 0; i < employmentStatus.length; i++) {
          c.push(employmentStatus[i]);
        }
        for(let i = 0; i < c.length; i++) {
          c[i] = false;
        }
        setemploymentStatus(c);
        setEmploymentStatusValue([]);
        setActiveItemIdsEmployment([]);
      }

      const clearSelectBox = () => {
        setIncomeIdValue([]);
      }

      const save = () => {
        closeModal()
      }
      const allClear = () => {
        clearRegion();
        clearJobCategory();
        clearHandy();
        clearConditions();
        clearEmployee();
        clearSelectBox();
        clearWorkStyles();
        setKeyword('');
        setNewGraduate('');
        setWorkFromHomeId1('');
        setWorkFromHomeId3('');
        setWorkFromHomeId2('');
        setIsToggled1(false);
        setIsToggled2(false);
        setIsToggled3(false);
        // dispatch(fetchRecruitmentsListRequest('', 'get', ''));
        // window.location.href('/recruitments/');
        window.location.href = '/recruitments/?page=1';
        // window.location.reload();
        const element = document.querySelector('#load-area');
        const elementList = document.querySelector('#jobs_list');
        element!.classList.remove('load-area-close');
        element!.classList.add('load-area-open');
        elementList!.classList.add('load-area-close');
        elementList!.classList.remove('load-area-open');
      }



      useEffect(() => {
        if(isToggled1) {
          setWorkFromHomeId1(1);
        } else{
          setWorkFromHomeId1('');
        }
        if (isToggled2) {
          setWorkFromHomeId2(2);
        } else {
          setWorkFromHomeId2('');
        } 
        if (isToggled3) {
          setWorkFromHomeId3(3);
        } else {
          setWorkFromHomeId3('');
        }
      }, [isToggled1,isToggled2,isToggled3]);

      const newGraduateClick = (item: any) => {
        setNewGraduate(item);
      };

      useEffect(() => {
        if (largeChoices && job_category_type_ids) {
          const large = largeChoices.map((largeChoice: any) => 
            job_category_type_ids.some((jobCategoryId: any) => jobCategoryId.id === largeChoice.id)
          );
      
          const updatedJobCategoryType = largeChoices.filter((largeChoice: any) => 
            job_category_type_ids.some((jobCategoryId: any) => jobCategoryId.id === largeChoice.id)
          );
          
          const updatedActiveIds = updatedJobCategoryType.map((item: any) => item.id);
      
          setJobCategoryType(updatedJobCategoryType);
          setActiveItemIdsJobCategory(updatedActiveIds);
          setJob(large);
        } else {
          if (largeChoices) {
            const large = new Array(largeChoices.length).fill(false);
            setJob(large);
          }
        }
      }, [largeChoices, job_category_type_ids]);


      useEffect(() => {
        if (smallChoices && job_category_ids) {
          const small = smallChoices.map((smallChoice: any) =>
            job_category_ids.some((jobCategoryId: any) => jobCategoryId.id === smallChoice.id)
          );

          const updatedJobCategories = smallChoices.filter((smallChoice: any) =>
            job_category_ids.some((jobCategoryId: any) => jobCategoryId.id === smallChoice.id)
          );
          setSmallChk(small);
        } else {
          if (smallChoices) {
            const small = new Array(smallChoices.length).fill(false);
            setSmallChk(small);
          }
        }
      }, [smallChoices, job_category_ids]);


     

      


      useEffect(() => {
        if(employmentStatusValue !== undefined) {
          // if(recruitments.employment_statuses !== undefined) {
          //   const newEmploymentStatus = [];
          //   for(let i = 0; i < recruitments.employment_statuses.length; i++) {
          //     for(let k = 0; k < employmentStatusValue.length; k++) {
          //       if(employmentStatusValue[k].id === recruitments.employment_statuses[i].id) {
          //         console.log(employmentStatusValue[k].id);
          //         console.log(recruitments.employment_statuses[i].id);

          //         newEmploymentStatus.push(true);
          //         // setActiveItemIdsEmployment([...activeItemIdsEmployment,  recruitments.employment_statuses[i].id]);
          //       } else {
          //         newEmploymentStatus.push(false);
          //       }
          //     }
          //   }
          //   console.log(newEmploymentStatus);
          //   setemploymentStatus(newEmploymentStatus);
          // }
          if(recruitments.employment_statuses !== undefined) {
            const newEmploymentStatus = [];
            let ch = false;
            for(let i = 0; i < recruitments.employment_statuses.length; i++) {
              ch = false;
              for(let j = 0; j < employmentStatusValue.length; j++) {
                if( recruitments.employment_statuses[i].name === employmentStatusValue[j].name){
                    newEmploymentStatus.push(true);
                    ch = true;
                }
              }
              if(!ch) {
                newEmploymentStatus.push(false);
              }
            }
            setemploymentStatus(newEmploymentStatus);
          }

        
        } else {
          if(recruitments.employment_statuses !== undefined) {
            const newEmploymentStatus = [];

            for(let i = 0; i < recruitments.employment_statuses.length; i++) {
              newEmploymentStatus.push(false);
            }
            setemploymentStatus(newEmploymentStatus);
          }
        }

      }, [recruitments.employment_statuses]);

      useEffect(() => {
        if (workStylesValue !== undefined && recruitments.work_styles !== undefined) {
      
          // `workStyles` болон `activeItemIdsWorkStyle` массивыг шинэчлэх
          const newWorkStyles = recruitments.work_styles.map((item: { id: any; }) =>
            workStylesValue.some((ws) => ws.id === item.id)
          );
      
          const newActiveItems = recruitments.work_styles
            .filter((item: { id: any; }) => workStylesValue.some((ws) => ws.id === item.id))
            .map((item: { id: any; }) => item.id);
      
          setWorkStyles(newWorkStyles);
          setActiveItemIdsWorkStyle(newActiveItems);
        } else {
          if (recruitments.work_styles !== undefined) {
            setWorkStyles(Array(recruitments.work_styles.length).fill(false));
          }
        }
      }, [workStylesValue, recruitments.work_styles]);


      useEffect(() => {
        if (handyValue !== undefined && recruitments.handicap_types !== undefined) {
      
          // `handy` болон `activeItemIdsHandy` массивыг шинэчлэх
          const newHandicapTypes = recruitments.handicap_types.map((item: { id: any; }) =>
            handyValue.some((handy) => handy.id === item.id)
          );
      
          const newActiveItems = recruitments.handicap_types
            .filter((item: { id: any; }) => handyValue.some((handy) => handy.id === item.id))
            .map((item: { id: any; }) => item.id);
      
          setHandy(newHandicapTypes);
          setActiveItemIdsHandy(newActiveItems);
        } else {
          if (recruitments.handicap_types !== undefined) {
            setHandy(Array(recruitments.handicap_types.length).fill(false));
          }
        }
      }, [handyValue, recruitments.handicap_types]);

    

      useEffect(() => {
        if (
          recruitments.consideration_condition_types !== undefined &&
          recruitments.consideration_conditions !== undefined
        ) {
          const newConditionsType = recruitments.consideration_condition_types.map(() => false);
          
          const newConditions = recruitments.consideration_conditions.map(() => false);
      
          if (conditionsValue !== undefined) {
            conditionsValue.forEach((searchCond) => {
              const typeIndex = recruitments.consideration_condition_types.findIndex(
                (type: { id: any; }) => type.id === searchCond.consideration_condition_type_id
              );
              if (typeIndex !== -1) {
                newConditionsType[typeIndex] = true;
              }
              const condIndex = recruitments.consideration_conditions.findIndex(
                (cond: { id: any; }) => cond.id === searchCond.id
              );
              if (condIndex !== -1) {
                newConditions[condIndex] = true;
              }
            });
          }
          setconditionsType(newConditionsType);
          setConditions(newConditions);
        }
      }, [
        recruitments.consideration_condition_types,
        recruitments.consideration_conditions,
        conditionsValue
      ]);


      


      

       // useEffect(() => {
      //   if (isModalOpen && modal === 'JobCategoryModal') {

      //     if (largeChoices && job_category_type_ids) {
      //       const large = largeChoices.map((largeChoice: any) => 
      //         job_category_type_ids.some((jobCategoryId: any) => jobCategoryId.id === largeChoice.id)
      //       );
            
      //       setJob(large);
      //     }
      //   }
      // }, [largeChoices, job_category_type_ids]);

      useEffect(() => {
        if (recruitments.regions !== undefined && recruitments.prefectures !== undefined) {
          const newRegion = recruitments.regions.map(() => false);
          const newPrefectures = recruitments.prefectures.map(() => false);
          let regionVal:any = [];
          let activeRegion:any = [];
      
          if (prefecturesValue !== undefined) {
            const selectedRegions = new Set(); // ✅ Сонгогдсон `region_id`-г хадгалах
            prefecturesValue.forEach((searchPrefecture) => {
              // ✅ 1. prefectures дотор `id` таарсан эсэхийг шалгах
              const prefectureIndex = recruitments.prefectures.findIndex(
                (prefecture: { id: any; }) => prefecture.id === searchPrefecture.id
              );
              if (prefectureIndex !== -1) {
                newPrefectures[prefectureIndex] = true;
                selectedRegions.add(recruitments.prefectures[prefectureIndex].region_id);
              }
            });
      
            // ✅ 2. Сонгогдсон `region_id`-тэй `regions`-г шалгах
            recruitments.regions.forEach((region:any, index:any) => {
              if (selectedRegions.has(region.id)) {
                newRegion[index] = true;
                regionVal.push(region);
                activeRegion.push(region.id);
              }
            });
          }
      
          setRegionValue(regionVal);
          setRegion(newRegion);
          setprefectures(newPrefectures);
          setActiveItemIdsRegion(activeRegion);
        }
      }, [recruitments.regions, recruitments.prefectures,prefecturesValue]);
      
      

      const incomeIdChange = (e:any) => {
        setIncomeIdValue([e.target.value]);
      };
      const handleKeywordChange = (event:any) => {
        setKeyword(event.target.value);
      };

      function toggle (key: any, id: any) {
        if(modal == 'JobCategoryModal') {
          if(jobs[key]) {
            jobs[key] = false;
            for(let i = 0; i< largeChoices.length; i++){
              if(largeChoices[i].id == id){
                setJobCategoryId([]);
                setSmallChk([]);
                setActiveItemIdsJobCategory(activeItemIdsJobCategory.filter((id) => id !== largeChoices[i].id));
                setJobCategoryType(jobCategoryType.filter((item: any) => item !== largeChoices[i]));
                for(let j = 0; j < jobCategoryType.length; j++) {
                  if(largeChoices[i].id == jobCategoryType[j].id) {
                    jobCategoryType.splice(j, 1);
                  }
                }
              }
            }
          } else {            
            jobs[key] = true;
            for(let i = 0; i< largeChoices.length; i++){
              if(largeChoices[i].id == id){
                setActiveItemIdsJobCategory([...activeItemIdsJobCategory, largeChoices[i].id]);
                setJobCategoryType([...jobCategoryType, largeChoices[i]]);
                // setJobCategoryType(jobCategoryType.filter((item: any) => item !== largeChoices[i]));
              }
            }
          }
          setJob(jobs);
        } else if (modal == 'regionModal') {
          let r = [];
          for(let j = 0; j < region.length; j++) {
            r.push(region[j]);
          }
          if(r[key]) {
            r[key] = false;
            let c = [];
            for(let j = 0; j < prefecturesValue.length; j++) {
              c.push(prefecturesValue[j]);
            }
            let p = [];
            for(let j = 0; j < prefectures.length; j++) {
              p.push(prefectures[j]);
            }            
            for(let i = 0; i< recruitments.regions.length; i++){
              if(recruitments.regions[i].id == id){
                // setPrefecturesValue([]);
                // setprefectures([]);
                setActiveItemIdsRegion(activeItemIdsRegion.filter((id) => id !==recruitments.regions[i].id));
                setRegionValue(regionValue.filter((item: any) => item !== recruitments.regions[i]));
                for(let j = 0; j < c.length; j++) {                  
                  if(c[j].region_id == id) {
                    c.splice(j, 1);
                    j--;
                  }
                }
                for(let j = 0; j < recruitments.prefectures.length; j++){
                  if(recruitments.prefectures[j].region_id == id) {
                    p[j] = false;
                  }
                }
                setPrefecturesValue(c);
                setprefectures(p);
              }
            }
          } else {
            r[key] = true;
            let c = [];
            for(let j = 0; j < prefecturesValue.length; j++) {
              c.push(prefecturesValue[j]);
            }
            let p = [];
            for(let j = 0; j < prefectures.length; j++) {
              p.push(prefectures[j]);
            }
            for(let i = 0; i< recruitments.regions.length; i++){
              if(recruitments.regions[i].id == id){
                setActiveItemIdsRegion([...activeItemIdsRegion, recruitments.regions[i].id]);
                setRegionValue([...regionValue, recruitments.regions[i]]);
                for(let j = 0; j < recruitments.prefectures.length; j++){
                  if(recruitments.prefectures[j].region_id == id) {
                    c.push(recruitments.prefectures[j]);
                    p[j] = true;
                  }
                }
                setPrefecturesValue(c);
                setprefectures(p);
              }
            }
          }
          setRegion(r);
        } else if (modal == 'HandicapTypesModal') {
          if(handy[key]) {
            handy[key] = false;
            for(let i = 0; i< recruitments.handicap_types.length; i++){
              if(recruitments.handicap_types[i].id == id){
                for(let j = 0; j < handyValue.length; j++) {
                  if(handyValue[j].id == recruitments.handicap_types[i].id) {
                    handyValue.splice(j, 1);
                  }
                }
                setActiveItemIdsHandy(activeItemIdsHandy.filter((id) => id !== recruitments.handicap_types[i].id));
                setHandyValue(handyValue.filter((item: any) => item !== recruitments.handicap_types[i]));
              }
            }
          } else {
            handy[key] = true;
            for(let i = 0; i< recruitments.handicap_types.length; i++){
              if(recruitments.handicap_types[i].id == id){

                let ch = true;
                for(let j = 0; j < handyValue.length; j++) {
                  if(handyValue[j].name == recruitments.handicap_types[i].name) {
                    ch = false;
                  }
                }
                if(ch) {
                  setHandyValue([...handyValue, recruitments.handicap_types[i]]);
                }
                setActiveItemIdsHandy([...activeItemIdsHandy, recruitments.handicap_types[i].id]);
              }
            }
          }
          setHandy(handy);
        } else if (modal == 'ConsiderationConditionsModal') {
          if(conditionsType[key]) {
            conditionsType[key] = false;
            setconditionsValue([]);
            setConditions([]);
            for(let i = 0; i< recruitments.consideration_condition_types.length; i++){
              if(recruitments.consideration_condition_types[i].id == id){
                setActiveItemIdsCondition(activeItemIdsCondition.filter((id) => id !== recruitments.consideration_condition_types[i].id));
              }
            }
          } else {
            conditionsType[key] = true;
            for(let i = 0; i< recruitments.consideration_condition_types.length; i++){
              if(recruitments.consideration_condition_types[i].id == id){
                setActiveItemIdsCondition([...activeItemIdsCondition, recruitments.consideration_condition_types[i].id]);
              }
            }
          }
          setconditionsType(conditionsType);
        } else if (modal == 'WorkStyleModal') {
          if(workStyles[key]) {
            workStyles[key] = false;
            for(let i = 0; i< recruitments.work_styles.length; i++){
              if(recruitments.work_styles[i].id == id){
                setActiveItemIdsWorkStyle(activeItemIdsWorkStyle.filter((id) => id !== recruitments.work_styles[i].id));
                setworkStylesValue(workStylesValue.filter((item: any) => item !== recruitments.work_styles[i]));
              }
            }
          } else {
            workStyles[key] = true;
            for(let i = 0; i< recruitments.work_styles.length; i++){
              if(recruitments.work_styles[i].id == id){
                setActiveItemIdsWorkStyle([...activeItemIdsWorkStyle, recruitments.work_styles[i].id]);
                setworkStylesValue([...workStylesValue, recruitments.work_styles[i]]);
              }
            }
          }
          setWorkStyles(workStyles);
        } else if (modal == 'EmploymentStatusesModal') {
          if(employmentStatus[key]) {
            employmentStatus[key] = false;
            for(let i = 0; i< recruitments.employment_statuses.length; i++){
              if(recruitments.employment_statuses[i].id == id){
                setActiveItemIdsEmployment(activeItemIdsEmployment.filter((id) => id !== recruitments.employment_statuses[i].id));
                setEmploymentStatusValue(employmentStatusValue.filter((item: any) => item.id !== recruitments.employment_statuses[i].id));
              }
            }
          } else {
            employmentStatus[key] = true;
            for(let i = 0; i< recruitments.employment_statuses.length; i++){
              if(recruitments.employment_statuses[i].id == id){
                setActiveItemIdsEmployment([...activeItemIdsEmployment,  recruitments.employment_statuses[i].id]);
                setEmploymentStatusValue([...employmentStatusValue, recruitments.employment_statuses[i]]);
              }
            }
          }
          setemploymentStatus(employmentStatus);
        } 
      }
      
      function smallToggle (key: any, id: any) {
        if (modal === 'JobCategoryModal') {
          const updatedSmallChk = [...smallChk];
          let updatedJobCategoryId = [...jobCategoryId];
      
          if (updatedSmallChk[key]) {
            updatedSmallChk[key] = false;
            updatedJobCategoryId = updatedJobCategoryId.filter((item: any) => item.id !== id);
          } else {
            updatedSmallChk[key] = true;
            const selectedItem = smallChoices.find((item: any) => item.id === id);
            if (selectedItem) {
              updatedJobCategoryId.push(selectedItem);
            }
          }
      
          setSmallChk(updatedSmallChk);
          setJobCategoryId(updatedJobCategoryId);
        }else if (modal == 'regionModal') {
          if(prefectures[key]) {
            prefectures[key] = false;
            for(let i = 0; i< recruitments.prefectures.length; i++){
              if(recruitments.prefectures[i].id == id){
                for(let j = 0; j < prefecturesValue.length; j++) {
                  if(prefecturesValue[j].id == recruitments.prefectures[i].id) {
                    prefecturesValue.splice(j, 1);
                  }
                }
                setPrefecturesValue(prefecturesValue.filter((item: any) => item !== recruitments.prefectures[i]));                
              }
            }
          } else {
            prefectures[key] = true;
            for(let i = 0; i< recruitments.prefectures.length; i++){
              if(recruitments.prefectures[i].id == id) {
                let ch = true;
                for(let j = 0; j < prefecturesValue.length; j++) {
                  if(prefecturesValue[j].name == recruitments.prefectures[i].name) {
                    ch = false;
                  }
                }
                if(ch) {
                  setPrefecturesValue([...prefecturesValue, recruitments.prefectures[i]]);
                }
              }
            }
          }
          setprefectures(prefectures);
        } else if (modal == 'ConsiderationConditionsModal') {
          if(conditions[key]) {
            conditions[key] = false;
            for(let i = 0; i< recruitments.consideration_conditions.length; i++){
              if(recruitments.consideration_conditions[i].id == id){
                setconditionsValue(conditionsValue.filter((item: any) => item !== recruitments.consideration_conditions[i]));
              }
            }
          } else {
            conditions[key] = true;
            for(let i = 0; i< recruitments.consideration_conditions.length; i++){
              if(recruitments.consideration_conditions[i].id == id){
                setconditionsValue([...conditionsValue, recruitments.consideration_conditions[i]]);
              }
            }
          }
          setConditions(conditions);
        } else {
          
        }
      }
      const [currentURL, setCurrentURL] = useState('');

      useEffect(() => {
        // Get the current URL
        const url = window.location.href;
        const questionMarkIndex = url.indexOf('?');
        // Extract the query parameters after the question mark
        const trimmedURL = questionMarkIndex !== -1 ? url.substring(questionMarkIndex + 1) : '';
        // Update the state with the trimmed URL
        setCurrentURL(trimmedURL);
      }, []);

      function createParameter (type: any) {
        const queryParams = new URLSearchParams()
        let japaneseParams : any = []
  
        // 並び替え sortした時はページネーションが1ページ目になるようにする
        if (type === 1) {
          page = 1
          if (sort) { queryParams.set('sort', sort) }
          if (page) { queryParams.set('page', page) }
        } else {
          if (sort) { queryParams.set('sort', sort) }
          if (page) { queryParams.set('page', page) }
        }
  
        // 条件検索
        // 勤務地（地域）
        if (regionValue.length) {
          japaneseParams.push('勤務地（地域）：')
          regionValue.forEach(function(value, index) {
            queryParams.append('region_ids[]', value.id)
            japaneseParams.push(value.name + ', ')
          })
        }
        // 勤務地（都道府県）
        if (prefecturesValue.length) {
          japaneseParams.push('勤務地（都道府県）：')
          prefecturesValue.forEach(function(value, index) {
            queryParams.append('prefecture_ids[]', value.id)
            japaneseParams.push(value.name + ', ')
          })
        }
        // 働き方・テレワーク
        if (workFromHomeId1 == 1 || workFromHomeId2 == 2) {
          japaneseParams.push('働き方・テレワーク：')
          if (workFromHomeId1 == 1) {
            japaneseParams.push('在宅勤務, ')
          } else if (workFromHomeId2 == 2) {
            japaneseParams.push('オフィス勤務, ')
          }
          if(workFromHomeId1 == 1 ){
            queryParams.append('work_from_home_id[]=', workFromHomeId1)
          }
          if(workFromHomeId1 == 2 ){
            queryParams.append('work_from_home_id[]=', workFromHomeId2)
          }
        }
        // 職種（分類）
        if (jobCategoryType.length) {
          japaneseParams.push('職種（分類）：')
          jobCategoryType.forEach(function(value, index) {
            queryParams.append('job_category_type_ids[]', value.id)
            japaneseParams.push(value.name + ', ')
          })
        }
        // 職種
        if (jobCategoryId.length) {
          japaneseParams.push('職種：')
          jobCategoryId.forEach(function(value, index) {
            queryParams.append('job_category_ids[]', value.id)
            japaneseParams.push(value.name + ', ')
          })
        }
        // 障がい種別
        if (handyValue.length) {
          japaneseParams.push('障がい種別：')
          handyValue.forEach(function(value, index) {
            queryParams.append('handicap_type_ids[]', value.id)
            japaneseParams.push(value.name + ', ')
          })
        }
        // 配慮
        if (conditionsValue.length) {
          japaneseParams.push('配慮：')
          conditionsValue.forEach(function(value, index) {
            queryParams.append('consideration_condition_ids[]', value.id)
            japaneseParams.push(value.name + ', ')
          })
        }
        // 相性
        if (workStylesValue.length) {
          japaneseParams.push('相性：')
          workStylesValue.forEach(function(value, index) {
            queryParams.append('compatibility_ids[]', value.id)
            japaneseParams.push(value.name + ', ')
          })
        }
        // 就職カテゴリー
        if (newGraduate == 0 || newGraduate == 1) {
          japaneseParams.push('就職カテゴリー：')
          if (newGraduate == 0) {
            japaneseParams.push('中途, ')
          } else if (newGraduate == 1) {
            japaneseParams.push('新卒, ')
          }
          queryParams.append('new_graduate', newGraduate)
        }
        // 雇用形態
        if (employmentStatusValue.length) {
          japaneseParams.push('雇用形態：')
          employmentStatusValue.forEach(function(value, index) {
            queryParams.append('employment_status_ids[]', value.id)
            japaneseParams.push(value.name + ', ')
          })
        }
        // 年収レンジ
        if (incomeId[0]) {
          japaneseParams.push('年収レンジ：')
          queryParams.append('annual_income_ids', incomeId[0].id)
          japaneseParams.push(incomeId[0].name + ', ')
        }
        // キーワード
        if (keyword.length) {
          queryParams.append('keyword', keyword)
          japaneseParams.push('キーワード：')
          japaneseParams.push(keyword)
        }
        let queryParams2 = queryParams.toString()
        japaneseParams = japaneseParams.join("")
        // 日本語パラメーター文字列の末尾の", "をトル
        let endStr = japaneseParams.slice(-2)
        if (endStr.toString() == ', ') { japaneseParams = japaneseParams.slice(0, -2) }
        return [japaneseParams, queryParams2];
      }

      const SaveConditions = (id: any, notifyWhenJobFinded1: any) => {
        if (!isLogin) {
          navigate('/login/');
          return
        }
        let japaneseParams = createParameter('search');
        if (japaneseParams[0] === '') {
          window.confirm("検索条件を指定してください")
          return
        }
        setNotifyWhenJobFinded(notifyWhenJobFinded1);
        const data = {
          applicant_id: id,
          name: japaneseParams[0],
          params: japaneseParams[1],
          notify_when_job_finded: notifyWhenJobFinded1
        }
        try {
          dispatch(fetchSavedConditionsCreateRequest(id, data));
        } catch (error) {
          console.error(error);
        }
      };

    return (
        <main id="jobs" style={{padding:'0'}}>
          {loadScreen ? <Loader></Loader> : <></>}
            <section className="wrapper">
            {windowSize.width <= 768 ? 
            <button className="premium-button w-100" onClick={()=> navigate("/jobs/recommended")}>
              <img style={{width:'20px', marginRight:'10px'}} src="/assets/img/top/premium.svg" alt=""/>プレミアム求人一覧
            </button>
            : <></>}
                <h2 className="ttl type3">求人一覧</h2>
                <div className="cont_box">
                {windowSize.width > 768 && 
                <div className="ext-header">
                  <button className="premium-button-md" onClick={()=> navigate("/jobs/recommended")}>
                    <img style={{width:'20px', marginRight:'10px'}} src="/assets/img/top/premium.svg" alt=""/>プレミアム求人一覧
                  </button>
                </div>
                }
                  <p>検索結果 { total_count }件 / { all_count }件</p>
                  {recruitmentsLists && recruitmentsLists.length !== 0 ?
                  <ul className="pagination small">
                  <li className="prev">
                    <a href="#" onClick={pageDecrement} className={currentPage <= 1 ? 'disabled' : ''}>
                      前へ
                    </a>
                  </li>
                  {(pageNumbers.length <= 5 ? pageNumbers : pageNumbers.slice((page - 1), limit + (page - 1))).map((page) => (
                    <li key={page} className={currentPage === page ? 'active' : ''} style={{display: "inline-block"}}>
                      <a href="#" onClick={() => clickPage(page)} style={{display: "inline-block"}}>
                        <span>{page}</span>
                      </a>
                    </li>
                  ))}
                  <li className="next">
                    <a href="#" onClick={pageIncrement} className={currentPage >= totalPages ? 'disabled' : ''}>
                      次へ
                    </a>
                  </li>
                </ul>: <></>}
                  <ul className="tab_btn">
                    <li className={sort === 'date' ? 'active' : ''}><a href="#" onClick = {() => sortChange('date')}>新着順</a></li>
                    {/* <li className={sort === 'view' ? 'active' : ''}><a href="#" onClick={() => setSort('view')}>人気順</a></li> */}
                    <li className={sort === 'active' ? 'active' : ''}><a href="#" onClick = {() => sortChange('active')}>ログイン企業順</a></li>
                    <li className={sort === 'salary' ? 'active' : ''}><a href="#" onClick = {() => sortChange('salary')} >年収の高い順</a></li>
                    <li className={sort === 'employment' ? 'active' : ''}><a href="#" onClick = {() => sortChange('employment')}>雇用実績順</a></li>
                  </ul>
                  {sort === 'active' ? <p v-if="sort == 'active'">※直近でログインしている企業の求人情報を優先的に紹介しています。</p>: <></>}
                  <br/>
                  <ul className="tab_box" id = 'recruiment' >
                    <li className="active">
                      <div className="load-area" id="load-area">
                        <div className="load-text">
                          <h2>検索しています…</h2>
                        </div>
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                      </div>
                      <ul className="jobs_list" id="jobs_list">
                        {recruitmentsLists && recruitmentsLists.length == 0 ? <li className="not_text">条件に一致する求人が見つかりませんでした。</li>: <></>}
                        {recruitmentsLists && recruitmentsLists.map((data: any, index: any) => (     
                            <li key={index}>
                              <Recruitment recruitment={data} isLogin={isLogin} temporary_is_favorite={data.is_favorite ? 1 : 0} />
                            </li>
                            ))}
                        
                      </ul>
                    </li>
                    <li>50音順</li>
                    <li>雇用実績順</li>
                    <li>業種順</li>
                    <li>駅近順</li>
                  </ul>
                  {recruitmentsLists && recruitmentsLists.length < 5 ? (
                  <button
                    type="button"
                    onClick={() => SaveConditions(conditionsId, true)}
                    className="btn type3 notify">
                    この条件に合う求人が公開されたら通知を受け取る
                  </button>
                  ) : null}

                  {recruitmentsLists && recruitmentsLists.length !== 0 ?
                  <ul className="pagination">
                  <li className="prev">
                    <a href="#" onClick={pageDecrement} className={currentPage <= 1 ? 'disabled' : ''}>
                      前へ
                    </a>
                  </li>
                  {(pageNumbers.length <= 5 ? pageNumbers : pageNumbers.slice((page - 1), limit + (page - 1))).map((page) => (
                  // {pageNumbers.map((page) => (
                    <li key={page} className={currentPage === page ? 'active' : ''} style={{display: "inline-block"}}>
                      <a href="#" onClick={() => clickPage(page)} style={{display: "inline-block"}}>
                        <span>{page}</span>
                      </a>
                    </li>
                  ))}
                  <li className="next">
                    <a href="#" onClick={pageIncrement} className={currentPage >= totalPages ? 'disabled' : ''}>
                      次へ
                    </a>
                  </li>
                  </ul>: <></>
                  }
                  
                </div>
                <div className="sidebar">
                  {isApplicantLogin ?
                    <a className="btn type3 mb-1" onClick={() => SaveConditions(conditionsId, false)}>
                      <img src="/assets/img/jobs/icon01.svg" alt="" />
                      現在の検索条件を保存する
                    </a> 
                  : <></>}
                  <br></br>
                  <div>
                    <div className="ttl_box">
                        <h3>キーワード</h3>
                    </div>
                    <form>
                        <input
                        type="text"
                        name="keyword"
                        value={keyword}
                        placeholder="検索例：データ入力業務"
                        onChange={handleKeywordChange}
                        />
                    </form>
                    <p className="note">※複数キーワードの入力には対応していません。</p>
                    
                    </div>
                    <div>
                      <div className="ttl_box">
                          <h3>勤務地</h3>
                          <a href="javascript:void(0)" onClick = {() => toggleModal('regionModal')}>追加・変更する<img src="/assets/img/jobs/arrow01.svg" alt=""/></a>
                      </div>
                      {/* {regionValue.map((data: any, index: any) => (
                          <p className="largecategory" key= {'region' + index}>{ data.name }</p>
                      ))} */}
                      {prefecturesValue.map((data: any, index: any) => (
                          <p className="smallcategory" key= {'prefecture' + index}>{ data.name }</p>
                      ))}
                    </div>
                    
                    <div className="category">
                    <div className="ttl_box">
                        <h3>働き方・テレワーク</h3>
                    </div>
                    <ul>
                      <li>
                        <a  className={`btn type3 workBtn ${isToggled1 ? 'active' : ''}`} onClick={() =>setIsToggled1(!isToggled1)}>
                          在宅勤務のみ
                        </a>
                      </li>
                      <li>
                        <a className={`btn type3 workBtn ${isToggled2  ? 'active' : ''}`} onClick={() => setIsToggled2(!isToggled2)}>
                          オフィス・現場勤務
                        </a>
                      </li>
                      <li>
                        <a className={`btn type3 workBtn ${isToggled3  ? 'active' : ''}`} onClick={() =>setIsToggled3(!isToggled3)}>
                        どちらでも・応相談
                        </a>
                      </li>
                    </ul>
                    </div>
                    <div>
                    
                    <div className="ttl_box">
                        <h3>職種</h3>
                        <a href="javascript:void(0)" onClick = {() => toggleModal('JobCategoryModal')}>追加・変更する<img src="/assets/img/jobs/arrow01.svg" alt=""/></a>
                    </div>
                    
                      {jobCategoryType.map((data: any, index: any) => (
                          <p className="largecategory" key= {'job_category_type' + index}>{ data.name }</p>
                      ))}
                      {jobCategoryId.map((data: any, index: any) => (
                          <p className="smallcategory" key= {'job_category' + index}>{ data.name }</p>
                      ))}
                    </div>
                    <div>
                    <div className="ttl_box">
                        <h3>障がい種別</h3>
                        <a href="javascript:void(0)" onClick = {() => toggleModal('HandicapTypesModal')}>追加・変更する<img src="/assets/img/jobs/arrow01.svg" alt=""/></a>
                    </div>
                    {handyValue.map((data: any, index: any) => (
                          <p  key= { index}>{ data.name }</p>
                      ))}
                    </div>
                    <div>
                    <div className="ttl_box">
                        <h3>配慮</h3>
                        <a href="javascript:void(0)" onClick={() => toggleModal('ConsiderationConditionsModal')} >追加・変更する<img src="/assets/img/jobs/arrow01.svg" alt=""/></a>
                    </div>
                    {conditionsValue.map((data: any, index: any) => (
                          <p  key= { index}>{ data.name }</p>
                      ))}
                    </div>
                    <div>
                        <div className="ttl_box">
                            <h3>相性</h3>
                            <a href="javascript:void(0)" onClick={() => toggleModal('WorkStyleModal')}>追加・変更する<img src="/assets/img/jobs/arrow01.svg" alt=""/></a>
                        </div>
                        {workStylesValue.map((data: any, index: any) => (
                          <p  key= { index}>{ data.name }</p>
                      ))}
                    </div>
                    <div className="category">
                    <div className="ttl_box">
                        <h3>就職カテゴリー</h3>
                    </div>
                  

                    <ul>
                      <li>
                        <a  className={`btn type3 ${newGraduate === '1' ? 'active' : ''}`} onClick={() => newGraduateClick('1')}>
                          新卒
                        </a>
                      </li>
                      <li>
                        <a className={`btn type3 ${newGraduate === '0' ? 'active' : ''}`} onClick={() => newGraduateClick('0')}>
                          中途
                        </a>
                      </li>
                    </ul>
                    </div>
                    <div>
                    <div className="ttl_box">
                        <h3>雇用形態</h3>
                        <a href="javascript:void(0)" onClick={() => toggleModal('EmploymentStatusesModal')}>追加・変更する<img src="/assets/img/jobs/arrow01.svg" alt=""/></a>
                    </div>
                    {employmentStatusValue.map((data: any, index: any) => (
                          <p  key= { index}>{ data.name }</p>
                    ))}
                    </div>
                    <div>
                    {/* <div className="ttl_box">
                        <h3>年収レンジ</h3>
                        <a href="#" onClick={() => toggleModal('selectboxModal')}>追加・変更する<img src="/assets/img/jobs/arrow01.svg" alt=""/></a>
                    </div> */}
                    <p> {incomeId}</p>
                    </div>
                    <div className="btn_box">
                    <button type="button" onClick={allSave} className="btn type3 green">絞り込み検索</button>
                    <a onClick={allClear} className="btn type3 gray" style={{display:'flex'}}>リセットする</a>
                    </div>
                    <p>※検索条件は各項目の複合検索（AND検索）となります。</p>
                    {isApplicantLogin &&
                      <a href="/saved_conditions/">保存した条件ページ<img src="/assets/img/jobs/arrow02.svg" alt="" /></a>
                    }
                    <br></br>
                    {/* <button type="button" onClick={() => { window.location.href = ('/jobs/recommended')}} className="btn type3" style={{backgroundColor: "#00a0e9", color: "#fff", border: "none"}}>今月のpick up求人</button> */}
            </div>
            </section>

            {isModalOpen && modal == 'JobCategoryModal' ?
             <div className="modal_box">
             <div className="modal_inner">
               <h4>職種</h4>
               <form>
                 <div className="slider_item" style={{ maxHeight: "345px" }}>
                   {largeChoices && largeChoices.map((data1: any, index1: any) => (
                     <div key={'large-' + index1}>
                       <label className={`large_category ${activeItemIdsJobCategory.includes(data1.id) ? 'on' : ''}`}>
                         <input
                           type="checkbox"
                           value={data1}
                           onChange={() => toggle(index1, data1.id)}
                           checked={jobs[index1] || false}
                         />
                         <span>{data1.name}</span>
                       </label>
                       {jobs[index1] && smallChoices && smallChoices.map((data2: any, index2: any) => {
                         if (data1.id === data2[parentIdKeyName]) {
                           return (
                             <label className="small_category" key={'small-' + index2}>
                               <input 
                                 type="checkbox" 
                                 checked={smallChk[index2] || false} 
                                 value={data2}  
                                 onChange={() => smallToggle(index2, data2.id)}  
                               />
                               <span>{data2.name}</span>
                             </label>
                           );
                         }
                         return null;
                       })}
                     </div>
                   ))}
                 </div>
                 <div className="btn_area">
                   <a onClick={clearJobCategory} className="btn_reset">リセットする</a>
                   <button type="button" onClick={save} className="btn type3">保存する</button>
                 </div>
               </form>
               <p className="close" onClick={closeModal}>
                 <FontAwesomeIcon icon={faX} />
               </p>
             </div>
             <div className="overlay" onClick={closeModal}></div>
           </div>
               : isModalOpen && modal == 'regionModal' ?
            <div className ="modal_box" >
            <div className="modal_inner">
                <h4>勤務地</h4>
                <form>
                <div className="slider_item">
                {recruitments.regions && recruitments.regions.map((data1: any, index1: any) => (
                    <div key={'large-' + index1}>
                      <label   className={`large_category ${activeItemIdsRegion.includes(data1.id) ? 'on' : ''}`}>
                        <input
                          type="checkbox"
                          value={data1}
                          onChange={() => toggle(index1, data1.id)}
                          checked={regionValue.includes(data1) ? true : false}
                        />
                        <span>{data1.name}</span>
                      </label> 
                      {regionValue.includes(data1) && recruitments.prefectures && recruitments.prefectures.map((data2: any, index2: any) => {
                        if (data1.id === data2['region_id']) {
                          let id_s = prefecturesValue.findIndex(d => d.id === data2.id);
                          return (
                            <label className="small_category" key={'small-' + index2}>
                             <input type="checkbox" checked={prefecturesValue[id_s]?.id === data2.id ? true : false} value={data2}  onChange={() => smallToggle(index2, data2.id)}  />
                              <span>{data2.name}</span>
                            </label>
                          );
                        }
                        return null;
                      })}
                    </div>
                  ))}
                </div>
            <div className="btn_area">
                <a  onClick={clearRegion} className="btn_reset">リセットする</a>
                <button type="button" onClick={save}  className="btn type3">保存する</button>
            </div>
            </form>
            <p className="close" onClick={closeModal}><FontAwesomeIcon icon={faX} /></p>
          </div>
          <div className="overlay"  onClick={closeModal}></div>
          </div>: isModalOpen && modal == 'HandicapTypesModal' ? 
            <div className ="modal_box" >
            <div className="modal_inner">
                <h4>障がい種別</h4>
                <form>
                <div className="slider_item">
                {recruitments.handicap_types && recruitments.handicap_types.map((data1: any, index1: any) => (
                  <div key={'large-' + index1}>
                    <label className={`large_category ${activeItemIdsHandy.includes(data1.id) ? 'on' : ''}`}>
                      <input
                        type="checkbox"
                        value={data1.id}
                        onChange={() => toggle(index1, data1.id)}
                        checked={handy[index1] || false}
                      />
                      <span>{data1.name}</span>
                    </label>
                  </div>
                ))}
                </div>
            <div className="btn_area">
                <a onClick={clearHandy} className="btn_reset">リセットする</a>
                <button type="button" onClick={save} className="btn type3">保存する</button>
            </div>
            </form>
            <p className="close" onClick={closeModal}><FontAwesomeIcon icon={faX} /></p>
          </div>
          <div className="overlay"  onClick={closeModal}></div>
          </div>
          : isModalOpen && modal == 'ConsiderationConditionsModal' ?
          <div className ="modal_box" >
            <div className="modal_inner">
                <h4>配慮</h4>
                <form>
                <div className="slider_item" style={{ maxHeight: "354px" }}>
                  {recruitments.consideration_condition_types &&
                    recruitments.consideration_condition_types.map((data1: any, index1: any) => (
                      <div key={"large-" + index1}>
                        <label className={`large_category ${conditionsType[index1] ? "on" : ""}`}>
                          <input
                            type="checkbox"
                            value={data1.id}
                            onChange={() => toggle(index1, data1.id)}
                            checked={conditionsType[index1] || false}
                          />
                          <span>{data1.name}</span>
                        </label>

                        {conditionsType[index1] &&
                          recruitments.consideration_conditions &&
                          recruitments.consideration_conditions.map((data2: any, index2: any) => {
                            if (data1.id === data2.consideration_condition_type_id) {
                              return (
                                <label className="small_category" key={"small-" + index2}>
                                  <input
                                    type="checkbox"
                                    checked={conditions[index2] || false}
                                    value={data2.id}
                                    onChange={() => smallToggle(index2, data2.id)}
                                  />
                                  <span>{data2.name}</span>
                                </label>
                              );
                            }
                            return null;
                          })}
                      </div>
                    ))}
                </div>

            <div className="btn_area">
                <a onClick={clearConditions} className="btn_reset">リセットする</a>
                <button type="button" onClick={save} className="btn type3">保存する</button>
            </div>
            </form>
            <p className="close" onClick={closeModal}><FontAwesomeIcon icon={faX} /></p>
          </div>
          <div className="overlay"  onClick={closeModal}></div>
          </div>
          : isModalOpen && modal == 'WorkStyleModal' ?
          <div className ="modal_box" >
          <div className="modal_inner">
              <h4>配慮</h4>
              <form>
              <div className="slider_item" style={{ maxHeight: "353px" }}>
                {recruitments.work_styles &&
                  recruitments.work_styles.map((data: any, index: any) => (
                    <div key={"large-" + index}>
                      <label
                        className={`large_category ${
                          activeItemIdsWorkStyle.includes(data.id) ? "on" : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          value={data.id}
                          onChange={() => toggle(index, data.id)}
                          checked={workStyles[index] || false} 
                        />
                        <span>{data.name}</span>
                      </label>
                    </div>
                  ))}
              </div>
          <div className="btn_area">
              <a onClick={clearWorkStyles}  className="btn_reset">リセットする</a>
              <button type="button"  onClick={save}  className="btn type3">保存する</button>
          </div>
          </form>
          <p className="close" onClick={closeModal}><FontAwesomeIcon icon={faX} /></p>
        </div>
        <div className="overlay"  onClick={closeModal}></div>
        </div>
          : isModalOpen && modal == 'EmploymentStatusesModal' ?
            <div className ="modal_box" >
            <div className="modal_inner">
                <h4>雇用形態</h4>
                <form>
                <div className="slider_item" style={{maxHeight: "353px"}}>
                {recruitments.employment_statuses && recruitments.employment_statuses.map((data1: any, index1: any) => (
                    <div key={'large-' + index1}>
                      <label  className={`large_category ${activeItemIdsEmployment.includes(data1.id) ? 'on' : ''}`}>
                        <input
                          type="checkbox"
                          value={data1}
                          onChange={() => toggle(index1, data1.id)}
                          checked={employmentStatus[index1]}
                        />
                        <span>{data1.name}</span>
                      </label> 
                    </div>
                  ))}
                </div>
            <div className="btn_area">
                <a onClick={clearEmployee}  className="btn_reset">リセットする</a>
                <button type="button" onClick={save}  className="btn type3">保存する</button>
            </div>
            </form>
            <p className="close" onClick={closeModal}><FontAwesomeIcon icon={faX} /></p>
          </div>
          <div className="overlay"  onClick={closeModal}></div>
          </div>
          : isModalOpen && modal == 'selectboxModal' ?
          <div className ="modal_box" >
            <div className="modal_inner">
                <h4>配慮</h4>
                <form>
                <div className="slider_item" style={{maxHeight: "354px"}}>
                  <select value = {incomeId[0] || ''} onChange={incomeIdChange}>
                    <option disabled value={[]}>年収レンジを選択してください</option>
                    {recruitments.annual_incomes && recruitments.annual_incomes.map((data: any, index: any) => (
                    <option key={`choices-${index}`} value={data.value}>{data.name}</option>
                  ))}
                  </select>
                </div>
            <div className="btn_area">
                <a onClick={clearSelectBox}  className="btn_reset">リセットする</a>
                <button type="button" onClick={save}  className="btn type3">保存する</button>
            </div>
            </form>
            <p className="close" onClick={closeModal}><FontAwesomeIcon icon={faX} /></p>
          </div>
          <div className="overlay"  onClick={closeModal}></div>
          </div>
          : <></>  
          }
        </main>
    );
  }
  
  export default Recruitments;