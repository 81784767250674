import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchApplicantRequest } from '../../../redax/actions/applicantActions/applicantActions';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { useNavigate } from 'react-router-dom';
import Loader from '../../loader/loader';
import { fetchFavCountRequest } from "../../../redax/actions/favCountActions/favCountActions";
import { fetchRecruiterNewsRequest } from '../../../redax/actions/recruiterNewsActions/recruiterNewsActions';

function MypageIndex() {
    const dispatch = useDispatch();
    const [jsonObject, setJsonObject] = useState<any>(null);
    const [news, setNews] = useState<any[]>([]);
    const [oldNews, setOldNews] = useState<any>([]);
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [authenticate, setAuthenticate] = useState(null);
    const [url, setUrl] = useState('');
    const [filteredNews, setFilteredNews] = useState<any[]>([]);
    const [schedules, setSchedules] = useState<any[]>([]);
    const [applicationRecruitments, setApplicationRecruitments] = useState<any[]>([]);
    const [recommendRecruitments, setRecommendRecruitments] = useState<any[]>([]);
    const [show, setShow] = useState<{ [key: number]: boolean }>({});
    const [slide, setSlide] = useState(0);
    const recommend_modals: any[] = [];
    const [loadScreen, setLoadScreen] = useState(true);
    const [totalPage, setTotalPage] = useState<any>(10);
    const [isRecommendModalOpen, setModalOpen] = useState<any>(false);
    const favCount = useSelector((state: RootState) => state.favCount);
    const [pages1, setPages1] = useState(1);
    const [pages, setPages] = useState(1);
    const [count, setCount] = useState<any>(null);
    let perPage = 5;
    const [numbers, setNumbers] = useState<any>([]);
    const [numbers1, setNumbers1] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [page1, setPage1] = useState(1);
    const navigate = useNavigate();
    let limit = 5;
    let limit1 = 3;
    const [indexArr, setIndexArr] = useState<any[]>([]);
    const [chk, setChk] = useState(-1);

    useEffect(() => {
      if (!localStorage.getItem('jobParachannelAuthToken')) {
        navigate('/login');
      } else {
        if(localStorage.getItem('load') == '1') {
          localStorage.setItem('load', '2');
          window.location.reload();
        }
        // dispatch(fetchLoginRequest('', '',''));
      }
    }, [navigate, dispatch]);
  


    const loginData = useSelector((state: RootState) => state.login);
    const applicant = useSelector((state: RootState) => state.applicant);
    const recruiterNews = useSelector((state: RootState) => state.recruiterNews);
  
    useEffect(() => {
      if (loginData.pending) {
        if(!loginData.login.is_init_complete) {
          // navigate('/mypage/init/');
          window.location.href = '/mypage/init/';
        }
        dispatch(fetchApplicantRequest(loginData.login.resource.id));
        setResourceId(loginData.login.resource.id);
        setFamylyName(loginData.login.resource.family_name);
        // setAuthenticate(loginData.login.authenticate.resource_type);
      }
    }, [loginData.pending]);


    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });


    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };


    useEffect(() => {
      // Attach the event listener on component mount
      window.addEventListener('resize', handleResize);
      console.log(windowSize.width);
      
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [windowSize]);

    useEffect(() => {
      if (recruiterNews.pending) {
        if(chk !== -1){
          const indexArrtt = [...indexArr]
          indexArrtt[chk] = true;
          setIndexArr(indexArrtt);
          setChk(-1);
        }
      }
  }, [recruiterNews]);

    useEffect(() => {
      let tmp: any = [];
      let tmp1: any = [];
      let tmp2: any = [];

      if (applicant.pending) {
        setNews(applicant.applicant.news);
        if(applicant.applicant.schedules !== undefined){
          tmp1 = applicant.applicant.schedules;
        }
        if(applicant.applicant.application_recruitments !== undefined) {
          tmp = applicant.applicant.application_recruitments; 
        }
        if(applicant.applicant.recommend_recruitments !== undefined) {
          tmp2 = applicant.applicant.recommend_recruitments; 
        }
        let pageNumber = parseInt('' + applicant.applicant.recommend_recruitments.length / limit);
        if (applicant.applicant.recommend_recruitments.length  % limit != 0) {
            pageNumber++;
        }
        setPages(pageNumber);
        let c = [];            
        for(let i = 1; i <= pageNumber; i++) {
            c.push(i);
        }
        setNumbers(c);
        let pageNumber1 = parseInt('' + applicant.applicant.application_recruitments.length / limit1);
        if (applicant.applicant.application_recruitments.length  % limit1 != 0) {
            pageNumber1++;
        }
        setPages1(pageNumber1);
        let c1 = [];           
        for(let i = 1; i <= pageNumber1; i++) {
            c1.push(i);
        }
        setNumbers1(c1);
        setApplicationRecruitments(tmp);
        setSchedules(tmp1);
        setRecommendRecruitments(tmp2);
        setLoadScreen(false);
      } else if(applicant.error != null) {
        setLoadScreen(false);
        navigate('/error?code=500');
      }
    }, [applicant]);

    useEffect(() => {
      const startIndex = (page - 1) * perPage;
      const endIndex = page * perPage;
      if (news) {
        const tmp = news.slice(startIndex, endIndex);
        setFilteredNews(tmp);
        const totalPage =  Math.ceil(news.length / 5)
        setTotalPage(totalPage);
        const count = news.length
        setCount(count);

      }
    }, [news, page]);


    useEffect(() => {
      let tmpe = [];
      if(news && loginData.pending) {
         if(!loginData.login.is_active && news) {
              for(let i = 0; i< news.length; i++) {
                  if(news[i].is_read){
                      tmpe.push(news[i]);
                  }
              }
         }
      }
      setOldNews(tmpe);
  }, [news,loginData]);

    function logout() {
      if (localStorage.entryFlag) {
        localStorage.removeItem('entryFlag')
      }
      if (localStorage.jobParachannelAuthToken) {
        localStorage.removeItem('jobParachannelAuthToken')
        navigate('/');
        window.location.reload();
      }
    }
    
    // const newsToggle = (index: any) => {
    //   setShow((prevShow) => ({
    //     ...prevShow,
    //     [index]: !prevShow[index],
    //   }));
    // };

    const newsToggle = (index: any,url: any) => {
      
      if (url) {
        console.log(url);
        dispatch(fetchRecruiterNewsRequest(url));
      }
      setChk(index);
      

      setShow((prevShow) => ({
        ...prevShow,
        [index]: !prevShow[index],
      }));
    };

    async function newsLink(news: any, url: any) {
      if (news.read_url) {
        dispatch(fetchRecruiterNewsRequest(news.read_url));
      }
    
      const sameUrlLink = url.startsWith(process.env.REACT_APP_BASE_URL);
      if (sameUrlLink) {
        window.location.href = url.replace(process.env.REACT_APP_BASE_URL, '');
      } else {
        window.open(url, '_blank');
      }
    }

    const onPrev = () => {
      if (page > 1) {
        setPage(page - 1);
      }
    };
  
    const onNext = () => {
      if (page < totalPage) {
        setPage(page + 1);
      }
    };

    const nextSlide = () => {
      if (slide + 1 >= recommend_modals.length) {
        setSlide(0);
        return;
      }
      setSlide(slide + 1);
    };
  
    const prevSlide = () => {
      if (slide <= 0) {
        setSlide(recommend_modals.length - 1);
        return;
      }
      setSlide(slide - 1);
    };
  
    const replacedContent = (value: any) => {
      if (value == null) {
        return null;
      }
      return value.replaceAll('\n', '<br>');
    };
  
    const fetchFavorite = (recruitmentId:any) => {
      dispatch(fetchFavCountRequest(recruitmentId, 'post', 'recruitment'));
    };

    const pageDecriment = () => {
      if (page <= 1) {
        setPage(pages);
        return;
      }
      setPage(page - 1);
    }
  
    const pageIncriment = () => {
      if (page >= pages) {
        setPage(1);
        return;
      }
      setPage(page + 1);
    }

    const pageDecriment1 = () => {
      if (page1 <= 1) {
        setPage1(pages1);
        return;
      }
      setPage(page - 1);
    }
  
    const pageIncriment1 = () => {
      if (page1 >= pages1) {
        setPage1(1);
        return;
      }
      setPage1(page1 + 1);
    }

 

    return (
      
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="ApplicantList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="#">マイページ</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">マイページTOP</h2>
                <ul>
                <li><a href="javascript:void(0)" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            <div className="top mypage">
              <div>
                <div className='row'>
                    <div className='column_A'>
                    <h2 className="ttl">おすすめ求人</h2>
                    </div>
                  <div className='column_B'></div>
                  </div>
                {recommendRecruitments.length === 0 ?
                  <ul className="cont4" v-if="recommend_recruitments_list.length == 0">
                    <li>
                      <div><p>お住まいの近くの求人はありません</p></div>
                    </li>
                  </ul>
                  :
                  <ul style={{width: "100%", display: "flex", flexWrap: "wrap",}}>
                    {Array.isArray(recommendRecruitments) && recommendRecruitments.map((data: any) => (
                      <div className='flexRow' style={{marginBottom:'15px'}}>
                        <a className='wid30' style={{paddingLeft:'10px'}} href={'/recruitments/'+ data.id}>
                          {windowSize.width < 769 ?
                          <>
                            <h4 className='customTextDiv'>{data.job_title}</h4>
                            <div className='flexRow1'>
                              <span className='paramText wid50' >雇用形態: {data.employment_status}</span> <span className='paramText wid50' >給与形態:{data.salary_form}{data.lower_monthly_basic_salary.toLocaleString()}円</span> 
                            </div>
                            <div className='flexRow1'>
                              <span className='paramText wid50' >住所:{data.prefecture}</span> <span className='paramText wid50' >会社名:{data.company_name}</span> 
                            </div>
                          </>
                          // <div className='flexRow1'>
                          //   <div className='pr-5 box'>
                          //     <span className='titleText' >会社名</span> <br />
                          //     <div className='pb-5 box'></div>
                          //     <span className='paramText'>{data.company_name}</span> <br />
                          //   </div>
                          //   <div className='pr-5 box'>
                          //     <span className='titleText'>職種名</span> <br />
                          //     <div className='pb-5'></div>
                          //     <span className='paramText'>{data.job_title}</span>
                          //   </div>
                          //   <div className='paddingnone box'>
                          //     <span className='titleText'>住所</span> <br/>
                          //     <div className='pb-5 box'></div>
                          //     <span className='paramText'>{data.prefecture}</span> <br />
                          //   </div>
                          // </div> 
                          : 
                          <>
                            <h4 className='customTextDiv'>{data.job_title}</h4>
                            <div className='flexRow1'>
                              <span className='paramText wid50' >雇用形態: {data.employment_status}</span> <span className='paramText wid50' >給与形態: {data.salary_form}{data.lower_monthly_basic_salary.toLocaleString()}円</span> 
                            </div>
                            <div className='flexRow1'>
                              <span className='paramText wid50' >住所:{data.prefecture}</span> <span className='paramText wid50' >会社名:{data.company_name}</span> 
                            </div>
                          </>
                        }                         
                      </a>
                      <div className='wid70'>
                        {/* <span className='titleText'>内容</span> */}
                        <textarea className='textaria' value={data.business_content || ''} disabled></textarea>
                      </div>
                      </div>                      
                      ))}
                  </ul> 
                //   <ul className="cont1">
                //     {recommendRecruitments.map((recommend_recruitment:any, index:any) => (
                //       <li key={'recommend_recruitment'+{index}}>
                //        <a href= {'/recruitments/' + recommend_recruitment.id}>
                //         <p className="date">{ recommend_recruitment.company_name }</p>
                //         <p>{ recommend_recruitment.job_title }</p>
                //        </a>
                //       </li>
                //      ))}
                // </ul> 
                  }
              </div>
                <div>
                  <div>
                    <h2>お知らせ</h2>
                  </div>
                  
                  {news && news.length == 0 ? 
                  <ul className="cont1">
                      <li>
                          <div><p>お知らせはありません</p></div>
                      </li>
                  </ul>: 
                  <>
                  {loginData.login.is_active ?
                  <ul className="cont1 news">
                        {filteredNews.map((news:any, index:any) => (
                          <li key={`news-${index}`} className={` ${show[index] ? 'active' : ''} ${news.is_scout && !news.is_read ? 'scout' : ''}`}>
                            <a href="#" onClick={(e) => { e.preventDefault(); newsToggle(index, news.read_url); }}>
                              <p className="date">{news.date}</p>
                              {/* { !news.is_read && <p className="new">NEW</p> } */}
                              { !indexArr[index] && <p className="new">NEW</p> }
                              <p>{news.title}</p>
                            </a>
                            { show[index] && (
                              <div>
                                { news.message && <p>{news.message}</p> }
                                { news.url && (
                                  <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.url); }}>{news.content}</a>
                                )}
                                { !news.url && news.content && <p>{news.content}</p> }
                                { news.sub_url1 && (
                                  <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.sub_url1); }}>{news.sub_content1}</a>
                                )}
                                { !news.sub_url1 && news.sub_content1 && <p>{news.sub_content1}</p> }
                                { news.sub_url2 && (
                                  <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.sub_url2); }}>{news.sub_content2}</a>
                                )}
                                { !news.sub_url2 && news.sub_content2 && <p>{news.sub_content2}</p> }
                              </div>
                            )}
                          </li>
                        ))}
                  </ul>
                  :
                  <ul className="cont1 news">
                        {oldNews.map((news:any, index:any) => (
                          <li key={`news-${index}`} className={` ${show[index] ? 'active' : ''} ${news.is_scout && !news.is_read ? 'scout' : ''}`}>
                            <a href="#" onClick={(e) => { e.preventDefault(); newsToggle(index, news.read_url); }}>
                              <p className="date">{news.date}</p>
                              {/* { !news.is_read && <p className="new">NEW</p> } */}
                              { !indexArr[index] && <p className="new">NEW</p> }
                              <p>{news.title}</p>
                            </a>
                            { show[index] && (
                              <div>
                                { news.message && <p>{news.message}</p> }
                                { news.url && (
                                  <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.url); }}>{news.content}</a>
                                )}
                                { !news.url && news.content && <p>{news.content}</p> }
                                { news.sub_url1 && (
                                  <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.sub_url1); }}>{news.sub_content1}</a>
                                )}
                                { !news.sub_url1 && news.sub_content1 && <p>{news.sub_content1}</p> }
                                { news.sub_url2 && (
                                  <a href="#" onClick={(e) => { e.preventDefault(); newsLink(news, news.sub_url2); }}>{news.sub_content2}</a>
                                )}
                                { !news.sub_url2 && news.sub_content2 && <p>{news.sub_content2}</p> }
                              </div>
                            )}
                          </li>
                        ))}
                  </ul>
                  }
                    
                  </>
                  
                  }
                   <div className="btn_box">
                      <a href="#" onClick={onPrev} className={page <= 1 ? 'disabled' : ''}>
                        <img src="/assets/img/company/icon19.svg" alt="" />
                      </a>
                      <a href="#" onClick={onNext} className={page >= totalPage ? 'disabled' : ''}>
                        <img src="/assets/img/company/icon20.svg" alt="" />
                      </a>
                    </div>
                </div>

                <div>
                  <h2 className="ttl">直近のスケジュール</h2>
                  { schedules.length == 0 ?
                    <ul className="cont1">
                      <li>
                        <div><p>スケジュールはありません</p></div>
                      </li>
                    </ul>
                  : 
                  <ul className="cont3 schedule">
                     {schedules.map((schedule:any, index:any) => (
                      <li key={'schedule'+{index}}>
                        <div>
                          <p className="date">{ schedule.date }</p>
                          <p className="category">{ schedule.status }</p>
                          <p>{ schedule.job_title }</p>
                        </div>
                      </li>
                     ))}
                  </ul>
                  }
                  {schedules.length  ? <a href="/mypage/schedules/" className="btn type3">もっと見る</a> : <></>} 
                </div>
                <div>
                  <h2 className="ttl">応募済みの求人</h2>
                  {applicationRecruitments.length === 0 ?
                    <ul className="cont1" v-if="application_recruitments_list.length == 0">
                      <li>
                        <div><p>応募済みの求人はありません</p></div>
                      </li>
                    </ul>
                  :
                  <ul className="cont1">
                  {applicationRecruitments.map((application_recruitment:any, index:any) => (
                      <li key={'application_recruitment'+{index}}>
                       <a href= {'/mypage/applications/' + application_recruitment.id}>
                        <p className='date'>{ application_recruitment.company_name }</p>
                        <p>{ application_recruitment.job_title }</p>
                       </a>
                      </li>
                     ))}
                  </ul>
                  }
                  {applicationRecruitments.length ? <a href="/mypage/applications/" className="btn type3" v-if="application_recruitments_list.length">もっと見る</a> : <></>}    
                  
                </div>
                {/* <div>
                  <div className='row'>
                    <div className='column_A'>
                      <h2 className="ttl">応募済みの求人</h2>
                    </div>                
                    <div className='column_B'>
                      <ul className="pagination_circle">
                        <li className={"prev " + (page1 == 1 ? "disabled" : "")}>
                        <a onClick={pageDecriment1}>前へ</a>
                        </li>
                        {numbers1.map((e:any) => (
                        <li className={page1 == e ? 'active' : ''}>
                        <a onClick={() => setPage1(e)}>
                            <span>{e}</span>
                        </a>
                        </li>
                        ))}
                        <li className={"next " + (page1 == pages1 ? "disabled" : "")}>
                        <a onClick={pageIncriment1}>次へ</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {applicationRecruitments.length === 0 ?
                    <ul className="cont1" v-if="application_recruitments_list.length == 0">
                      <li>
                        <div><p>応募済みの求人はありません</p></div>
                      </li>
                    </ul>
                  :
                  <>
                  {windowSize.width < 450 ?
                  <ul style={{width: "100%", display: "flex", flexWrap: "wrap", justifyContent:'center'}}>
                  {Array.isArray(applicationRecruitments) && applicationRecruitments.slice((page1 - 1)*limit1, limit1 + (page1 - 1)*limit1).map((data: any) => (
                  <li style={{width: "100%", border: "none", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", transition: "0.3s", padding: "0 5px", margin: "5px"}} >
                      <a href={'/recruitments/'+ data.id } style={{background: "none", backgroundSize: "0", backgroundPosition: "none", padding: "21px 30px 21px 25px", display:'block'}}>
                        <ul>
                          <li style={{marginTop: "10px", display: "flex", height: "35px", alignItems: "center"}}>
                            <span style={{padding: "0 10px 0 0px", whiteSpace: "nowrap"}}>会社名</span>
                            <span style={{fontSize: "12px", fontWeight: "500"}}>{data.company_name}</span>
                          </li>
                          <li style={{marginTop: "10px", display: "flex", height: "35px", alignItems: "center"}}>
                            <span style={{padding: "0 10px 0 0px", whiteSpace: "nowrap"}}>職種名</span>
                            <span style={{fontSize: "12px", fontWeight: "500"}}>{data.job_title}</span>
                          </li>
                          <li style={{marginTop: "10px", border: "none"}}>
                            <span style={{padding: "0 10px 0 0px", whiteSpace: "nowrap"}}>内容</span>
                            <textarea style={{background:"#D9D9D9", margin: "5px", fontSize: "12px", fontWeight: "500"}} >{}</textarea>
                          </li>
                        </ul>
                      </a>
                  </li>
                  ))}
                </ul>
                :
                  windowSize.width < 600 ?
                  <ul style={{width: "100%", display: "flex", flexWrap: "wrap",}}>
                    {Array.isArray(applicationRecruitments) && applicationRecruitments.slice((page1 - 1)*limit1, limit1 + (page1 - 1)*limit1).map((data: any) => (
                    <li style={{width: "45%", border: "none", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", transition: "0.3s", padding: "0 5px", margin: "5px"}} >
                        <a href={'/recruitments/'+ data.id } style={{background: "none", backgroundSize: "0", backgroundPosition: "none", padding: "21px 30px 21px 25px"}}>
                          <ul>
                            <li style={{marginTop: "10px", display: "flex", height: "35px", alignItems: "center"}}>
                              <span style={{padding: "0 10px 0 0px", whiteSpace: "nowrap"}}>会社名</span>
                              <span style={{fontSize: "12px", fontWeight: "500"}}>{data.company_name}</span>
                            </li>
                            <li style={{marginTop: "10px", display: "flex", height: "35px", alignItems: "center"}}>
                              <span style={{padding: "0 10px 0 0px", whiteSpace: "nowrap"}}>職種名</span>
                              <span style={{fontSize: "12px", fontWeight: "500"}}>{data.job_title}</span>
                            </li>
                            <li style={{marginTop: "10px", border: "none"}}>
                              <span style={{padding: "0 10px 0 0px", whiteSpace: "nowrap"}}>内容</span>
                              <textarea style={{background:"#D9D9D9", margin: "5px", fontSize: "12px", fontWeight: "500"}} >{}</textarea>
                            </li>
                          </ul>
                        </a>
                    </li>
                    ))}
                  </ul>
                  :
                  <ul style={{width: "100%", display: "flex", flexWrap: "wrap",}}>
                    
                    {Array.isArray(applicationRecruitments) && applicationRecruitments.slice((page1 - 1)*limit1, limit1 + (page1 - 1)*limit1).map((data: any) => (
                    <li style={{width: "31.5%", border: "none", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", transition: "0.3s", padding: "0 5px", margin: "5px"}} >
                        <a href={'/recruitments/'+ data.id } style={{background: "none", backgroundSize: "0", backgroundPosition: "none", padding: "21px 20px 21px 25px"}}>
                          <ul>
                            <li style={{marginTop: "10px", display: "flex", height: "35px", alignItems: "center"}}>
                              <span style={{padding: "0 10px 0 0px", whiteSpace: "nowrap"}}>会社名</span>
                              <span style={{fontSize: "12px", fontWeight: "500"}}>{data.company_name}</span>
                            </li>
                            <li style={{marginTop: "10px", display: "flex", height: "35px", alignItems: "center"}}>
                              <span style={{padding: "0 10px 0 0px", whiteSpace: "nowrap"}}>職種名</span>
                              <span style={{fontSize: "12px", fontWeight: "500"}}>{data.job_title}</span>
                            </li>
                            <li style={{marginTop: "10px", border: "none"}}>
                              <span style={{padding: "0 10px 0 0px", whiteSpace: "nowrap"}}>内容</span>
                              <textarea style={{background:"#D9D9D9", margin: "5px", fontSize: "12px", fontWeight: "500"}} >{}</textarea>
                            </li>
                          </ul>
                        </a>
                    </li>
                    ))}
                  </ul>
                  }
                  
                  </>
                  
                  }
                  {applicationRecruitments.length ? <a href="/mypage/applications/" className="btn type3" v-if="application_recruitments_list.length">もっと見る</a> : <></>}    
                  
                </div>               */}
            </div>
         
            </section>
      </main>
    );
  }
  
  export default MypageIndex;